import { apiSlice } from './apiSlice'

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ['EscalationSteps'],
})

const escalationStepsApi = apiWithTags.injectEndpoints({
    endpoints: builder => ({
        getEscalationSteps: builder.query<IEscalationStep[], void>({
            query: () => 'api/EscalationSteps',
        }),
        saveEscalationSteps: builder.mutation<
            IEscalationStep[],
            IEscalationStep[]
        >({
            query: steps => ({
                url: 'api/EscalationSteps',
                method: 'PUT',
                body: steps,
            }),
        }),
    }),
})

export const { useGetEscalationStepsQuery, useSaveEscalationStepsMutation } =
    escalationStepsApi
