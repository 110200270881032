import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
} from '@fluentui/react-components'
import { useRef, useState } from 'react'

import { FilePond } from 'react-filepond'
import { FilePond as FilePondType } from 'filepond'
import ModalStatusEnum from 'constants/ModalStatusEnum'
import ModalTypeEnum from 'constants/ModalTypeEnum'
import VehicleGroupListsAdd from './VehicleGroupLists/VehicleGroupListsAdd'
import { acquireApiAccessToken } from 'authInstance'
import { triggerMessage } from 'slices/messageSlice'
import { useAppDispatch } from 'store'
import { useCreateVehicleGroupListsMutation } from 'api/vehicleGroupList'

type VehicleGroupListManualActionsProps = {
    toggleOpen: () => void
    modalType: ModalTypeEnum
    type: VehicleGroupListSupportedPages
}

const VehicleGroupListManualActions = ({
    toggleOpen,
    modalType,
    type,
}: VehicleGroupListManualActionsProps) => {
    const dispatch = useAppDispatch()

    const [createVehicleGroupLists] = useCreateVehicleGroupListsMutation()
    const [vinList, setVinList] = useState<string[]>([])
    const [groupListName, setGroupListName] = useState('')

    const filePondInstance = useRef<FilePond & { _pond: FilePondType }>(null)
    const [addByFilePond, setAddByFilePond] = useState<boolean>(false)

    const processFilePond = async () => {
        const accessToken = await acquireApiAccessToken()

        filePondInstance.current?._pond.setOptions({
            server: {
                url: `${process.env.REACT_APP_REFLECT_BACKEND_API}`,
                process: {
                    url: `/api/VehicleGroupList/${type}/GroupListReadFile/${encodeURIComponent(
                        groupListName
                    )}`,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    method: 'POST',
                    ondata: (formData: FormData) => formData,
                    withCredentials: false,
                },
                revert: null,
                restore: null,
                load: null,
                fetch: null,
            },
        })
        await filePondInstance.current
            ?.processFiles()
            .then(response => {
                const serverResponse = JSON.parse(response[0].serverId)

                dispatch(
                    triggerMessage({
                        title: 'Success',
                        message: `${serverResponse.vehicleCount} vehicles added to the new list: ${groupListName}!`,
                        intent: 'success',
                    })
                )
            })
            .catch(e => {
                dispatch(
                    triggerMessage({
                        title: 'Error',
                        message: `Could not add vehicle(s) to the list: ${groupListName}.
                          Please check the file or contact support, statuscode: ${e.error.code}.`,
                        intent: 'error',
                    })
                )
            })
            .finally(() => {
                toggleOpen()
            })
    }

    const onSaveVehicleGroupList = async () => {
        if (addByFilePond) {
            await processFilePond()
        } else {
            const postVehicleGroupList: IVehicleGroup = {
                name: groupListName,
                vins: vinList,
                operation: 'Add',
            }
            const response = await createVehicleGroupLists({
                vehicleGroupType: type,
                postVehicleGroupList,
            })

            if ('data' in response) {
                dispatch(
                    triggerMessage({
                        title: 'Success',
                        message: `${vinList.length} vehicles added to new the list: ${groupListName}!`,
                        intent: 'success',
                    })
                )
            } else {
                dispatch(
                    triggerMessage({
                        title: 'Error',
                        message: 'Could not add vehicle(s) to the list',
                        intent: 'error',
                    })
                )
            }
        }
        toggleOpen()
    }

    return (
        <>
            <DialogContent>
                <VehicleGroupListsAdd
                    vehicleGroupType={type}
                    setVinList={setVinList}
                    vinList={vinList}
                    setGroupListName={setGroupListName}
                    groupListName={groupListName}
                    modalType={modalType}
                    modalStatus={ModalStatusEnum.Manual}
                    externalFilePondInstance={filePondInstance}
                    setAddByExternalFilePond={setAddByFilePond}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    appearance='primary'
                    disabled={groupListName.length === 0}
                    onClick={onSaveVehicleGroupList}
                >
                    Save
                </Button>
                <DialogTrigger disableButtonEnhancement>
                    <Button>Cancel</Button>
                </DialogTrigger>
            </DialogActions>
        </>
    )
}

export default VehicleGroupListManualActions
