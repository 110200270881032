import {
    Button,
    Checkbox,
    Combobox,
    DialogActions,
    DialogContent,
    Field,
    makeStyles,
    Option,
    shorthands,
    Tab,
    TabList,
    Text,
} from '@fluentui/react-components'
import { selectLastOdataQuery, selectRowCount } from 'slices/gridSlice'
import { useAppDispatch, useAppSelector } from 'store'
import {
    useCreateVehicleGroupListsMutation,
    useEditVehicleGroupListsMutation,
    useGetVehicleGroupListsFiltersQuery,
} from 'api/vehicleGroupList'
import { useEffect, useMemo, useState } from 'react'

import ModalStatusEnum from 'constants/ModalStatusEnum'
import ModalType from 'constants/ModalTypeEnum'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import { selectUser } from 'slices/authSlice'
import { triggerMessage } from 'slices/messageSlice'
import { useGrid } from 'contexts/GridContext'
import VehicleGroupListsAdd from './VehicleGroupLists/VehicleGroupListsAdd'

type VehicleGroupListActionsProps = {
    toggleOpen: () => void
    modalStatus: ModalStatusEnum
    modalType: ModalType
    type: VehicleGroupListSupportedPages
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin('10px'),
        ...shorthands.padding('10px'),
    },
    tab: {
        marginBottom: '22px',
    },
    checkbox: {
        marginLeft: 'auto',
    },
})

const VehicleGroupListActions = ({
    toggleOpen,
    modalStatus,
    type,
    modalType,
}: VehicleGroupListActionsProps) => {
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const [selected, setSelected] = useState<VehicleGroupListListType>()
    const [filteredVins, setFilteredVins] = useState<string[]>(null)
    const [groupListName, setGroupListName] = useState('')
    const [showOnlyMine, setShowOnlyMine] = useState(false)
    const [selectedValue, setSelectedValue] = useState('create')

    const [addVehicles, { isLoading: addingVehicles }] =
        useEditVehicleGroupListsMutation()
    const [createVehicleGroupLists] = useCreateVehicleGroupListsMutation()
    const lastODataQuery = useAppSelector(selectLastOdataQuery)
    const rowCount = useAppSelector(selectRowCount)

    const { gridRef } = useGrid()
    const user = useAppSelector(selectUser)

    const selectedVehicleVins = useMemo(
        () => gridRef.current?.api.getSelectedRows().map(({ Vin }) => Vin),
        [gridRef]
    )

    const { data: vehicleGroupList = [] } =
        useGetVehicleGroupListsFiltersQuery(type)

    const options = useMemo(() => {
        if (showOnlyMine) {
            return vehicleGroupList
                .filter(u => u.createdBy === user.name)
                .map(({ id, name }) => ({
                    key: id,
                    text: name,
                }))
        } else {
            return vehicleGroupList.map(({ id, name }) => ({
                key: id,
                text: name,
            }))
        }
    }, [vehicleGroupList, showOnlyMine, user])

    const [matchingOptions, setMatchingOptions] = useState([...options])

    const collectAllVehicleVins = async () => {
        const param = new URLSearchParams(lastODataQuery)
        param.delete('$skip')
        param.delete('$top')
        param.delete('$select')
        param.append('$select', 'Vin')

        const { data } = await reflectApiAxiosInstance.get(
            decodeURIComponent(param.toString())
        )
        return data.value.map(({ Vin }) => Vin)
    }

    useEffect(
        () => {
            const fetchFilteredVins = async () => {
                const vins = await collectAllVehicleVins()
                setFilteredVins(vins)
            }

            if (modalStatus === ModalStatusEnum.Filtered) {
                fetchFilteredVins()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [lastODataQuery]
    )

    const onUpdateVehicleGroupList = async () => {
        const vehicleVins =
            modalStatus === ModalStatusEnum.Selected
                ? selectedVehicleVins
                : filteredVins

        const putVehicleGroupList: IVehicleGroup = {
            id: selected.id,
            name: selected.name,
            vins: vehicleVins,
            operation: 'Add',
        }

        const response = await addVehicles({
            vehicleGroupType: type,
            vehicleGroupListId: selected.id,
            putVehicleGroupList: putVehicleGroupList,
        })

        if ('data' in response) {
            dispatch(
                triggerMessage({
                    title: 'Success',
                    message: `Update existing list:${groupListName} with ${vehicleVins.length} vehicles!`,
                    intent: 'success',
                })
            )
        } else {
            dispatch(
                triggerMessage({
                    title: 'Error',
                    message: 'Could not add vehicle(s) to the list',
                    intent: 'error',
                })
            )
        }
    }

    const onNewVehicleGroupList = async () => {
        const vehicleVins =
            modalStatus === ModalStatusEnum.Selected
                ? selectedVehicleVins
                : filteredVins

        const postVehicleGroupList: IVehicleGroup = {
            name: groupListName,
            vins: vehicleVins,
            operation: 'Add',
        }
        const response = await createVehicleGroupLists({
            vehicleGroupType: type,
            postVehicleGroupList,
        })

        if ('data' in response) {
            dispatch(
                triggerMessage({
                    title: 'Success',
                    message: `${vehicleVins.length} vehicles added to the new list: ${groupListName}!`,
                    intent: 'success',
                })
            )
        } else {
            dispatch(
                triggerMessage({
                    title: 'Error',
                    message: 'Could not add vehicle(s) to the list',
                    intent: 'error',
                })
            )
        }
    }

    const count = selectedVehicleVins?.length || rowCount

    const onChange = event => {
        const value = event.target.value.trim()
        const matches = options.filter(
            option =>
                option.text.toLowerCase().indexOf(value.toLowerCase()) === 0
        )
        setMatchingOptions(matches)
        if (value.length && matches.length < 1) {
            setGroupListName(value)
        } else {
            setGroupListName('')
        }
    }

    useEffect(() => {
        setMatchingOptions(options)
    }, [options, setMatchingOptions])

    const onSave = () => {
        if (selectedValue === 'create') {
            onNewVehicleGroupList().then(() => toggleOpen())
        } else {
            onUpdateVehicleGroupList().then(() => toggleOpen())
        }
    }

    return (
        <>
            <DialogContent>
                <Text block>{`Adding ${count} vehicles to list`}</Text>
                {count > 1000 && (
                    <Text block>
                        Warning! This action can take a very long time!
                    </Text>
                )}
                <div className={classes.container}>
                    <TabList
                        selectedValue={selectedValue}
                        onTabSelect={(_e, d) =>
                            setSelectedValue(d.value as string)
                        }
                    >
                        <Tab className={classes.tab} id='create' value='create'>
                            Create new list
                        </Tab>
                        <Tab className={classes.tab} id='add' value='add'>
                            Add to existing list
                        </Tab>
                    </TabList>

                    {selectedValue === 'create' && (
                        <VehicleGroupListsAdd
                            vehicleGroupType={type}
                            modalType={modalType}
                            modalStatus={modalStatus}
                            setGroupListName={setGroupListName}
                            groupListName={groupListName}
                        />
                    )}

                    {selectedValue === 'add' && (
                        <>
                            <Field>
                                <Combobox
                                    onChange={onChange}
                                    onOptionSelect={(_e, data) => {
                                        const list = vehicleGroupList.find(
                                            item => item.id === data.optionValue
                                        )
                                        setSelected(list)
                                    }}
                                    freeform
                                    clearable
                                    placeholder='Search'
                                >
                                    {matchingOptions.map(option => (
                                        <Option
                                            key={option.key}
                                            value={option.key}
                                        >
                                            {option.text}
                                        </Option>
                                    ))}
                                </Combobox>
                            </Field>
                            <Checkbox
                                className={classes.checkbox}
                                checked={showOnlyMine}
                                onChange={() => setShowOnlyMine(prev => !prev)}
                                label='Show only my lists'
                            />
                        </>
                    )}
                </div>
            </DialogContent>
            <DialogActions fluid>
                <Button
                    appearance='primary'
                    onClick={onSave}
                    disabled={addingVehicles}
                >
                    Save
                </Button>
                <Button onClick={toggleOpen}>Cancel</Button>
            </DialogActions>
        </>
    )
}

export default VehicleGroupListActions
