import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    Field,
    Switch,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import { useEffect, useState } from 'react'

import VehicleEditFieldType from 'components/Ui/VehicleEditFieldType'
import consoleErrorData from 'helpers/consoleErrorData'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import { triggerMessage } from 'slices/messageSlice'
import { useAppDispatch } from 'store'
import { useGrid } from 'contexts/GridContext'

interface IBatchEditVehiclesProps {
    toggleOpen: () => void
}

type value = string | number | boolean | string[]

interface IBatchEditableFields {
    id: string
    columnName: string
    value?: value
    isEditable: boolean
    active: boolean
}

type VehicleData = {
    [key: string]: Pick<IBatchEditableFields, 'value' | 'active'>
}

const useStyles = makeStyles({
    container: {
        marginBottom: '16px',
        alignItems: 'flex-end',
        display: 'flex',
    },
    inputField: { flexGrow: 1 },
    switch: {
        marginLeft: '-0.5rem',
        ...shorthands.padding('5px'),
    },
})

const BatchEditVehicles = ({ toggleOpen }: IBatchEditVehiclesProps) => {
    const classes = useStyles()

    const [batchFields, setFields] = useState<IBatchEditableFields[]>()

    const [vehicleData, setVehicleBatchData] = useState<VehicleData>()

    const { gridRef } = useGrid()
    const selectedRowCount = gridRef.current?.api.getSelectedRows().length
    const selectedIds = gridRef.current?.api.getSelectedRows().map(sr => sr.Id)

    const dispatch = useAppDispatch()

    const inRange = (x: number, min: number, max: number) =>
        (x - min) * (x - max) <= 0

    useEffect(() => {
        if (
            (!batchFields || batchFields?.length === 0) &&
            inRange(selectedRowCount, 1, 250)
        ) {
            reflectApiAxiosInstance
                .get('/api/VehicleDetails/BatchEditableColumnsList')
                .then(response => {
                    const batchData: VehicleData = {}
                    response.data?.forEach(
                        ({ id, value, active }: IBatchEditableFields) => {
                            batchData[id] = {
                                value,
                                active,
                            }
                        }
                    )

                    setFields(response.data)
                    setVehicleBatchData(batchData)
                })
                .catch(error => {
                    consoleErrorData(error)
                })
        }
    }, [batchFields, selectedRowCount])

    const onChangeTextField = (newValue: value, key: string) => {
        setVehicleBatchData(pre => ({
            ...pre,
            [key]: { ...pre[key], value: newValue, active: true },
        }))
    }

    const onChangeToggle = (key: string, checked?: boolean) =>
        setVehicleBatchData(pre => ({
            ...pre,
            [key]: {
                ...pre[key],
                active: checked ?? false,
            },
        }))

    const batchUpdateVehicles = async () => {
        if (vehicleData) {
            const dataToUpdate = Object.entries(vehicleData)
                .map(([key, { value, active }]) =>
                    active && value ? { key, value } : null
                )
                .filter(Boolean)

            const _vehicleData = dataToUpdate.filter(
                ({ key }) => !['Contacts'].includes(key)
            )

            if (_vehicleData.length) {
                await Promise.all([
                    selectedIds.map(async (vehicleId: string) =>
                        _vehicleData.map(({ key, value }) =>
                            reflectApiAxiosInstance.put(
                                `/api/Vehicles/${vehicleId}/Attributes/${key}`,
                                { data: value }
                            )
                        )
                    ),
                ])
                    .catch(error => {
                        dispatch(
                            triggerMessage({
                                title: 'Error updating vehicles',
                                message: 'Vehicles have not been updated',
                                intent: 'error',
                            })
                        )
                        consoleErrorData(error)
                    })
                    .then(() => {
                        dispatch(
                            triggerMessage({
                                title: 'Vehicles updated',
                                message: 'Vehicles have been updated',
                                intent: 'success',
                            })
                        )
                    })
            }
            toggleOpen()
        }
    }

    return (
        <>
            {selectedRowCount > 250 ? (
                <>
                    <DialogContent>
                        You can batch edit a maximum of 250 vehicles at a time.
                    </DialogContent>

                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={toggleOpen}>Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            ) : inRange(selectedRowCount, 1, 250) ? (
                <>
                    <DialogContent>
                        <b>Edit</b>
                        {batchFields?.map(({ id, columnName }) => (
                            <div key={id} className={classes.container}>
                                <Field>
                                    <Switch
                                        checked={
                                            vehicleData &&
                                            vehicleData[id]?.active
                                        }
                                        onChange={e =>
                                            onChangeToggle(
                                                id,
                                                e.currentTarget.checked
                                            )
                                        }
                                        className={classes.switch}
                                    />
                                </Field>

                                <div className={classes.inputField}>
                                    <VehicleEditFieldType
                                        column={columnName}
                                        value={
                                            vehicleData &&
                                            vehicleData[id]?.value
                                        }
                                        onChangeTextField={onChangeTextField}
                                        disabled={
                                            !(
                                                vehicleData &&
                                                vehicleData[id]?.active
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance='primary'
                            onClick={batchUpdateVehicles}
                        >
                            Update {selectedRowCount} vehicles
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={toggleOpen}>Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            ) : null}
        </>
    )
}

export default BatchEditVehicles
