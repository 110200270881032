/* eslint-disable max-len */

import { makeStyles } from '@fluentui/react-components'

const useStyles = makeStyles({
    svg: {
        height: '50px',
        width: '50px',
    },
})

const Trillion = () => {
    const classes = useStyles()
    return (
        <svg
            id='Layer_1'
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 128 123.65'
            className={classes.svg}
        >
            <g>
                <path
                    fill='#a623c1'
                    strokeWidth={0}
                    d='m116.37,47.71H48.55c-.02-.39-.05-5.21-.08-32.74,0-.98.27-1.27,1.41-1.27,12.7.04,25.41.01,38.11.05.63,0,1.42.31,1.86.72,8.65,7.85,17.26,15.73,25.85,23.63.37.34.68.92.68,1.38'
                />
                <path
                    fill='#af54c6'
                    strokeWidth={0}
                    d='m87.96,19.03v20.05h20.18c-6.77-6.73-13.36-13.27-20.18-20.05Z'
                />
                <path
                    fill='#c646c1'
                    strokeWidth={0}
                    d='m48.46,47.71h67.93v20.35H48.46v-20.35Z'
                />
                <path
                    fill='#d861d8'
                    strokeWidth={0}
                    d='m48.48,68.06h67.93v20.15H48.48v-20.15Z'
                />
                <path
                    fill='#dd8add'
                    strokeWidth={0}
                    d='m48.48,88.21h67.93v19.35H48.48v-19.35Z'
                />
            </g>
            <path
                fill='#c646c1'
                opacity={0.38}
                strokeWidth={0}
                d='m64.87,26.23h-16.41v70.97h17.15c3.21,0,5.82-2.65,5.82-5.91v-59.15c0-3.27-2.6-5.91-6.55-5.91Z'
            />
            <path
                fill='#822f82'
                opacity={0.76}
                strokeWidth={0}
                d='m61.05,31.01h-12.59v61.42h13.39c3.49,0,6.31-2.29,6.31-5.12v-51.19c0-2.83-2.83-5.12-7.11-5.12Z'
            />
            <g>
                <path
                    fill='#cc48cc'
                    strokeWidth={0}
                    d='m11.97,32.5h47.9c3.25,0,5.4,2.18,5.4,4.87v48.71c0,2.69-2.14,4.87-4.79,4.87H12.58c-2.64,0-4.79-2.18-4.79-4.87v-48.71c0-2.69,2.14-4.87,4.18-4.87Z'
                />
                <path
                    fill='#fff'
                    strokeWidth={0}
                    d='m50.51,49.22h-11.02v31.56h-8.73v-31.56h-10.97v-7.09h30.72v7.09Z'
                />
            </g>
        </svg>
    )
}

export default Trillion
