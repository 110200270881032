import 'react-quill/dist/quill.snow.css'

import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Field,
    Input,
    MessageBar,
    makeStyles,
} from '@fluentui/react-components'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
    useEditNewsItemMutation,
    usePostCreateNewsItemMutation,
} from 'api/portalNews'

import ConfirmActionDialog from './ConfirmActionDialog'
import MediaUploader from './MediaUpload'
import PreviewCreateNews from './PreviewCreateNews'
import ReactQuill from 'react-quill'
import { toast } from 'react-toastify'
import { useGrid } from 'contexts/GridContext'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gridColumnStart: 1,
        gridColumnEnd: 4,

        '& > div:last-of-type': {
            marginTop: '1rem',
            alignSelf: 'flex-end',
        },
    },
    mediaUpload: {
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    quill: {
        marginBottom: '70px',
        height: '150px',
    },
})

interface INewsModal {
    formTitle: string
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    selected: number
    setSelected: Dispatch<SetStateAction<number>>
    data: IPortalNews
}

const PortalNewsForm = ({
    formTitle,
    open,
    setOpen,
    data,
    selected,
    setSelected,
}: INewsModal) => {
    const classes = useStyles()
    const { gridRef } = useGrid()

    const initialFormData = {
        Title: data?.Title || '',
        Introduction: data?.Introduction || '',
        Text: data?.Text || '',
        ImageUrl: data?.ImageUrl || '',
        ThumbnailUrl: data?.ThumbnailUrl || '',
        PublishDate: data?.PublishDate || '',
        UnPublishDate: data?.UnPublishDate || '',
        Countries: data?.Countries || [],
        NewsLists: data?.NewsLists || [],
    }

    const [formData, setFormData] = useState(initialFormData)
    const [newThumbnail, setNewThumbnail] = useState(undefined)
    const [newImage, setNewImage] = useState(undefined)
    const [showCancelDialog, setShowCancelDialog] = useState(false)
    const [showPreview, setShowPreview] = useState(false)

    const [editNewsItem] = useEditNewsItemMutation()
    const [createNewsItem] = usePostCreateNewsItemMutation()

    const existingItem = data && data.Id === selected ? data : null

    const hidePreviewDialog = () => setShowPreview(false)
    const hideAllDialogs = () => {
        setShowPreview(false)
        setOpen(false)
    }

    const onSave = async () => {
        try {
            if (existingItem) {
                await editNewsItem({
                    Id: selected,
                    ...formData,
                    ImageUrl: newImage || formData.ImageUrl,
                    ThumbnailUrl: newThumbnail || formData.ThumbnailUrl,
                })
            } else {
                await createNewsItem({
                    ...formData,
                    ImageUrl: newImage,
                    ThumbnailUrl: newThumbnail,
                })
            }
        } catch (error) {
            toast.error(error)
        } finally {
            toast.success(
                `Successfully ${existingItem ? 'edited' : 'created'} news item`
            )
            setOpen(false)
            setSelected(-1)
        }
        gridRef.current?.api.refreshServerSide({ purge: true })
    }

    useEffect(() => {
        if (open) {
            if (selected > 0 && data && data.Id === selected) {
                setFormData(initialFormData)
            } else if (selected === -1) {
                setFormData({
                    Title: '',
                    Introduction: '',
                    Text: '',
                    ImageUrl: '',
                    ThumbnailUrl: '',
                    PublishDate: '',
                    UnPublishDate: '',
                    Countries: [],
                    NewsLists: [],
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, selected, data])

    const isDisabled =
        !formData.Title ||
        !formData.Introduction ||
        !formData.Text ||
        !formData.ImageUrl ||
        !formData.ThumbnailUrl

    return (
        <>
            {data?.VirtualStatus === 'Published' && (
                <MessageBar
                    style={{
                        marginBottom: '5px',
                        whiteSpace: 'initial',
                        wordWrap: 'break-word',
                    }}
                    intent='warning'
                >
                    You are editing a published item, this will not notify users
                    who received the original version.
                </MessageBar>
            )}
            <DialogTitle>{formTitle}</DialogTitle>

            <DialogContent className={classes.root}>
                <Field required label='Title'>
                    <Input
                        name='title'
                        value={formData.Title}
                        onChange={e =>
                            setFormData({
                                ...formData,
                                Title: e.target.value,
                            })
                        }
                    />
                </Field>

                <Field required label='Introduction'>
                    <Input
                        name='introduction'
                        value={formData.Introduction}
                        onChange={e =>
                            setFormData({
                                ...formData,
                                Introduction: e.target.value,
                            })
                        }
                    />
                </Field>

                <Field required label={'Body text'}>
                    <ReactQuill
                        theme='snow'
                        value={formData.Text}
                        onChange={value =>
                            setFormData({ ...formData, Text: value })
                        }
                        className={classes.quill}
                    />
                </Field>
                <Field className={classes.mediaUpload} required>
                    <MediaUploader
                        title='Image'
                        setMediaUrl={url => {
                            setFormData({ ...formData, ImageUrl: url })
                            setNewImage(url)
                        }}
                        media={formData.ImageUrl}
                    />
                    <MediaUploader
                        title='Thumbnail'
                        setMediaUrl={url => {
                            setFormData({ ...formData, ThumbnailUrl: url })
                            setNewThumbnail(url)
                        }}
                        media={formData.ThumbnailUrl}
                    />
                </Field>

                <DialogActions fluid>
                    <PreviewCreateNews
                        showPreview={showPreview}
                        hideAllDialogs={hideAllDialogs}
                        hidePreviewDialog={hidePreviewDialog}
                        setShowPreview={setShowPreview}
                        formData={formData}
                        onSave={onSave}
                        isDisabled={isDisabled}
                    />
                    <Button
                        appearance='primary'
                        onClick={onSave}
                        disabled={isDisabled}
                    >
                        Save
                    </Button>

                    <Button onClick={() => setShowCancelDialog(true)}>
                        Cancel
                    </Button>
                    {showCancelDialog && (
                        <ConfirmActionDialog
                            setIsOpen={setShowCancelDialog}
                            isOpen={showCancelDialog}
                            callback={hideAllDialogs}
                            action='cancel the creation/editing of'
                        />
                    )}
                </DialogActions>
            </DialogContent>
        </>
    )
}

export default PortalNewsForm
