import {
    Beaker24Regular,
    Filter24Regular,
    Grid24Regular,
    Mail24Regular,
    Pen24Regular,
    Person24Regular,
    Phone24Regular,
    Send24Regular,
    WindowWrench24Regular,
} from '@fluentui/react-icons'
import {
    Button,
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
} from '@fluentui/react-components'

import ActionsMenu from 'components/Ui/Layout/ActionsMenu'
import EmailActions from 'components/Actions/EmailActions'
import Header from 'components/Ui/Layout/Header'
import { IActionsMenu } from 'Types/ActionsMenu'
import ModalStatusEnum from 'constants/ModalStatusEnum'
import ROUTES from 'constants/routes'
import SMSActions from 'components/Actions/SMSActions'
import { useGrid } from 'contexts/GridContext'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import WorkshopBatchEditModal from './WorkshopBatchEditModal'

interface IWorkshopHeaderProps {
    vehicleTest: IVehicleTest
}

const WorkshopHeader = ({ vehicleTest }: IWorkshopHeaderProps) => {
    const navigate = useNavigate()
    const { gridRef } = useGrid()

    const [selectedRows, setSelectedRows] = useState([])
    gridRef.current?.api.addEventListener('selectionChanged', () => {
        setSelectedRows(gridRef.current?.api.getSelectedRows())
    })

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(null)

    const openDialog = (body: JSX.Element, title: string) => {
        setIsDialogOpen(true)
        setDialogContent({ body, title })
    }

    const menuItems: IActionsMenu[] = [
        {
            label: 'Grid View',
            icon: <Grid24Regular />,
            link: '',
            appearance: location.pathname.endsWith('view')
                ? 'subtle'
                : 'primary',
        },
        {
            label: 'Workshop View',
            icon: <WindowWrench24Regular />,
            link: 'view',
            appearance: location.pathname.endsWith('view')
                ? 'primary'
                : 'subtle',
        },
        {
            label: 'Edit',
            icon: <Pen24Regular />,
            disabled:
                selectedRows.length <= 0 || location.pathname.endsWith('view'),
            onClick: () =>
                openDialog(
                    <WorkshopBatchEditModal
                        toggleOpen={() => setIsDialogOpen(false)}
                        selectedRows={selectedRows}
                    />,
                    'Edit Workshop'
                ),
        },
        {
            label: 'Contact Drivers',
            icon: <Person24Regular />,
            children: [
                {
                    label: 'Send SMS',
                    icon: <Phone24Regular />,
                    restricted: 'sms',
                    children: [
                        {
                            label: 'Send SMS to selected drivers',
                            disabled: !selectedRows.length,
                            icon: <Send24Regular />,
                            onClick: () =>
                                openDialog(
                                    <SMSActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Selected}
                                    />,
                                    'Send SMS Message to selected drivers'
                                ),
                        },
                        {
                            label: 'Send SMS to Filtered drivers',
                            icon: <Filter24Regular />,
                            onClick: () =>
                                openDialog(
                                    <SMSActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Filtered}
                                    />,
                                    'Send SMS Message to filtered drivers'
                                ),
                        },
                    ],
                },
                {
                    label: 'Send Email',
                    icon: <Mail24Regular />,
                    restricted: 'email',
                    children: [
                        {
                            label: 'Send Email to Selected drivers',
                            disabled: !selectedRows.length,
                            icon: <Send24Regular />,
                            onClick: () =>
                                openDialog(
                                    <EmailActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Selected}
                                    />,
                                    'Send Email to Selected drivers'
                                ),
                        },
                        {
                            label: 'Send Email to Filtered drivers',
                            icon: <Filter24Regular />,
                            onClick: () =>
                                openDialog(
                                    <EmailActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Filtered}
                                    />,
                                    'Send Email to Filtered drivers'
                                ),
                        },
                    ],
                },
            ],
        },
    ]

    if (!vehicleTest) {
        return null
    }

    return (
        <>
            <Header
                title={`Workshop for "${vehicleTest?.title}" (${vehicleTest?.status})`}
                personalViewType='TestPurposeWorkshop'
                isWorkshopGrid
            />

            <ActionsMenu
                goBack={{
                    label: 'Vehicle Tests',
                    onClick: () => navigate('/vehicletests'),
                }}
                items={menuItems}
                extraComponents={
                    <Button
                        appearance='subtle'
                        icon={<Beaker24Regular />}
                        onClick={() =>
                            navigate(
                                `/${ROUTES.VEHICLE_TESTS}/${vehicleTest?.id}`
                            )
                        }
                    >
                        Go to Vehicle Test
                    </Button>
                }
            />

            <Dialog
                open={isDialogOpen}
                onOpenChange={(_event, data) => setIsDialogOpen(data.open)}
            >
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>{dialogContent?.title}</DialogTitle>
                        {dialogContent?.body}
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    )
}

export default WorkshopHeader
