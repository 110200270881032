// From https://github.com/jquery/jquery-ui/blob/main/ui/widgets/datepicker.js

import { ICellRendererParams } from 'ag-grid-community'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

export const DATETIME_FORMAT = 'YYYY-MM-DD  HH:mm'

export const dateRenderWithTime = ({ value }: Partial<ICellRendererParams>) => {
    const data = dayjs(value).utc()
    const utcMarker = data.isUTC() ? 'UTC' : ''
    return value ? `${data.format(DATETIME_FORMAT)} ${utcMarker}` : ''
}

export const dateStringRenderWithTime = (value: string | Date) => {
    const data = dayjs(value).utc()
    const utcMarker = data.isUTC() ? 'UTC' : ''
    return value ? `${data.format(DATETIME_FORMAT)} ${utcMarker}` : ''
}

// TODO: [DQ-Time] This exists because of data quality issues with the import into REFLECT
// We simply import a date and time and assume it is in UTC when in fact it is CET
// This function corrects that issue by converting data from REFLECT into CET first and then
// back to UTC. This is not meant as a permanent fix, the fix should be in the backend.
// Remove when backend supplies correct UTC time, see where this applies search
// TODO: [DQ-Time] comments in code
export const dateRenderWithAdjustedCETtoUTC = ({
    value,
}: Partial<ICellRendererParams>) => {
    if (!value) {
        return ''
    }
    const adjusted = dayjs.tz(value.toString(), 'CET')
    return dateStringRenderWithTime(adjusted.toString())
}

export const dateComparator = (filterDate: Date, cellValue: string) => {
    const cellValueDate = new Date(cellValue)
    cellValueDate.setHours(0, 0, 0, 0)
    if (cellValueDate < filterDate) {
        return -1
    } else if (cellValueDate > filterDate) {
        return 1
    }
    return 0
}
