import { apiSlice } from './apiSlice'
import { lowerCaseKeys } from 'helpers/objectManipulation'

const pgwCollectionApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCollections: builder.query<IPGWCollection[], string | void>({
            query: (query = '') => `/odata/PGWCollection${query}`,
            transformResponse: (
                response: IODataResponse<IPGWCollectionOdata[]>
            ) => lowerCaseKeys(response.value) as unknown as IPGWCollection[],
        }),
    }),
})

export const { useGetCollectionsQuery } = pgwCollectionApi
