import { BRIDGE_INFO_KEYS, VEHICLE_INFO_KEYS } from 'constants/bridgeVehicle'
import { ColDef, FilterChangedEvent, GridReadyEvent } from 'ag-grid-community'
import {
    VehicleTestCustomFilters,
    VehicleTestOdataProvider,
} from 'helpers/vehicleTestsGridHelper'
import {
    booleanColumns,
    csvColumns,
    dateColumns,
    numberColumns,
    vehicleGridColumns,
} from 'constants/vehicleTest'
import {
    dateComparator,
    dateRenderWithAdjustedCETtoUTC,
    dateRenderWithTime,
} from 'helpers/dateHelpers'
import {
    defaultColDef,
    getContextMenuItems,
    sideBar,
    statusBar,
} from 'helpers/gridHelper'
import { useMemo, useRef } from 'react'

import AgGridContainer from 'components/Ui/Layout/AgGridContainer'
import { AgGridReact } from 'ag-grid-react'
import CustomTextListFilter from 'components/AgGrid/Filters/CustomTextListFilter'
import EscalationStatus from 'components/Escalations/EscalationStatus'
import Loader from 'components/Ui/Loader'
import VehicleActions from 'components/VehicleTest/VehicleActions'
import { getOdataApi } from 'helpers/oDataApiHelper'
import { insertSpaces } from 'helpers/stringHelpers'
import { setLastOdataQuery } from 'slices/gridSlice'
import { useAppDispatch } from 'store'
import { useGetVehicleClassificationsQuery } from 'api/vehicleTests'
import { useGetVehicleGroupListsQuery } from 'api/vehicleGroupList'
import { useGrid } from 'contexts/GridContext'
import useGridFilterUrl from 'hooks/useGridFilterUrl'
import { vehicleGridRowClassRules } from 'helpers/vehicleGridHelper'

interface IVehicleTestGridProps {
    vehicleTest: IVehicleTest
}

const VehicleTestGrid = ({ vehicleTest }: IVehicleTestGridProps) => {
    const { setGridRef } = useGrid()
    const gridRef = useRef<AgGridReact>(null)

    const { data: classificationTypes } = useGetVehicleClassificationsQuery()
    const { data: tagLists } = useGetVehicleGroupListsQuery('TagList')
    const [pushFiltersToHistory, setFiltersFromHistory] = useGridFilterUrl()
    const dispatch = useAppDispatch()

    const columnDefs: ColDef[] = useMemo(() => {
        if (!vehicleTest || !classificationTypes || !tagLists) {
            return null
        }

        const colDefs: ColDef[] = [
            {
                colId: 'CheckBoxSelect',
                checkboxSelection: true,
                lockPosition: true,
                lockPinned: true,
                pinned: 'left',
                suppressMenu: true,
                width: 55,
                suppressColumnsToolPanel: true,
                filter: false,
                sortable: false,
            },
            {
                filter: false,
                sortable: false,
                width: 150,
                suppressMenu: true,
                suppressColumnsToolPanel: true,
                cellRenderer: VehicleActions,
                cellRendererParams: {
                    disabled:
                        vehicleTest.status === 'Deleted' ||
                        !vehicleTest.plannedStart,
                    testTitle: vehicleTest.title,
                },
            },
            {
                field: 'BridgeInfo.TestStatus',
                headerName: 'Test Status',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: ['Allocated', 'Planned', 'Ongoing', 'Finished'],
                },
            },
            {
                field: 'VehicleInfo.VehicleStatus',
                headerName: 'Vehicle Status',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: ['Active', 'Ended', 'Planned', 'Undefined'],
                },
            },
            {
                field: 'VehicleInfo.TagLists',
                headerName: 'Tag Lists',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: [null, ...tagLists.map(tagList => tagList.name)],
                },
            },
            {
                field: 'BridgeInfo.VehicleClassification',
                headerName: 'Vehicle Classification',
                cellRenderer: params => {
                    const classification = classificationTypes.find(
                        i =>
                            i.id === params.value ||
                            i.title === params.value ||
                            i.title === params.value?.title
                    )
                    return classification?.title ?? null
                },
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: [
                        null,
                        ...classificationTypes.map(classification =>
                            classification.id.toString()
                        ),
                    ],
                    cellRenderer: params => {
                        const classification = classificationTypes.find(
                            i => i.id.toString() === params.value
                        )
                        return (
                            // '(Select All)' is an option, included in params.value
                            classification?.title ?? params.value ?? '(Blanks)'
                        )
                    },
                    valueFormatter: params => {
                        const classification = classificationTypes.find(
                            i => i.id.toString() === params.value
                        )
                        return classification?.title ?? params.value
                    },
                },
            },
            {
                field: 'EscalationStatus',
                filter: false,
                sortable: false,
                cellRenderer: EscalationStatus,
                cellRendererParams: {
                    id: vehicleTest?.id,
                    type: 'VehicleTest',
                },
            },
        ]

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const getRealField = (field: any) => {
            if (BRIDGE_INFO_KEYS.includes(field)) {
                return 'BridgeInfo.' + field
            }
            if (VEHICLE_INFO_KEYS.includes(field)) {
                return 'VehicleInfo.' + field
            }
            return field
        }

        vehicleGridColumns.forEach(c => {
            if (dateColumns.includes(c)) {
                // TODO: [DQ-Time] Date in backend suffers from data-quality issues.
                // this is a quickfix for those issues, more info in dateRenderWithAdjustedCETtoUTC func
                return colDefs.push({
                    field: getRealField(c),
                    headerName: insertSpaces(c),
                    cellRenderer:
                        c === 'LatestVehicleVersion' || c === 'FirstPds'
                            ? dateRenderWithAdjustedCETtoUTC
                            : dateRenderWithTime,
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        comparator: dateComparator,
                        maxNumConditions: 1,
                        buttons: ['reset', 'apply'],
                    },
                })
            } else if (csvColumns.includes(c)) {
                return colDefs.push({
                    field: getRealField(c),
                    headerName: insertSpaces(c),
                    filter: CustomTextListFilter,
                })
            } else if (numberColumns.includes(c)) {
                return colDefs.push({
                    field: getRealField(c),
                    headerName: insertSpaces(c),
                    filter: 'agNumberColumnFilter',
                    filterParams: {
                        filterOptions: ['equals', 'lessThan', 'greaterThan'],
                    },
                })
            } else if (booleanColumns.includes(c)) {
                return colDefs.push({
                    field: getRealField(c),
                    headerName: insertSpaces(c),
                    filter: 'agSetColumnFilter',
                    filterParams: {
                        values: ['true', 'false'],
                    },
                })
            }

            if (!colDefs.some(x => x.field === c)) {
                colDefs.push({
                    field: getRealField(c),
                    headerName: insertSpaces(c),
                    filter: 'agTextColumnFilter',
                })
            }
        })

        return colDefs
    }, [vehicleTest, classificationTypes, tagLists])

    const onGridReady = (params: GridReadyEvent) => {
        params.api.setServerSideDatasource(
            new VehicleTestOdataProvider({
                callApi: async options => {
                    // Create the $compute string for the OData query
                    // this makes OData crates a flat object with all the properties
                    let compute = BRIDGE_INFO_KEYS.map(
                        key => `bridgeInfo/${key} as ${key}`
                    ).join(',')
                    compute += ','
                    compute += VEHICLE_INFO_KEYS.map(
                        key => `vehicleInfo/${key} as ${key}`
                    ).join(',')

                    options += `&$compute=${compute}&$select=*`

                    dispatch(
                        setLastOdataQuery(`/odata/BridgeVehicle${options}`)
                    )

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return getOdataApi<IBridgeInfoOdata[]>(
                        `/odata/BridgeVehicle${options}`
                    ).then(response => response.data)
                },
                beforeRequest: query => {
                    query['count'] = true
                    query.filter = query.filter || []
                    query.filter.push(
                        `TestPurposeId eq ${vehicleTest.id} and Deleted eq false`
                    )
                    query.expand = ['bridgeInfo,vehicleInfo']
                },
                customFilters: VehicleTestCustomFilters,
            })
        )
        setGridRef(gridRef)
        setFiltersFromHistory(params.api)
    }

    const onFilterChanged = (event: FilterChangedEvent) => {
        pushFiltersToHistory(event.api)
    }

    if (!vehicleTest) {
        return <Loader text='Loading test' />
    }

    return (
        <AgGridContainer>
            <AgGridReact
                className='cy-vehicle-test-grid'
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                enableRangeSelection
                getContextMenuItems={getContextMenuItems}
                getRowId={params =>
                    `${params.data?.Id?.toString()}-${Math.random().toString()}`
                }
                onFilterChanged={onFilterChanged}
                onGridReady={onGridReady}
                ref={gridRef}
                rowClassRules={vehicleGridRowClassRules}
                rowModelType='serverSide'
                rowSelection='multiple'
                sideBar={sideBar}
                statusBar={statusBar}
            />
        </AgGridContainer>
    )
}

export default VehicleTestGrid
