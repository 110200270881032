import { Field, Select } from '@fluentui/react-components'

type PGWCollectionTypeDropdownProps = {
    disabled?: boolean
    selectedItemKey: string
    setSelectedItemKey: (selectedItemKey: string) => void
}

const PGWCollectionTypeDropdown = ({
    selectedItemKey,
    setSelectedItemKey,
    disabled,
}: PGWCollectionTypeDropdownProps) => {
    const dropdownOptions = [
        {
            key: 'CUSTOMER',
            text: 'CUSTOMER',
        },
        {
            key: 'CO_DEV',
            text: 'CO_DEV',
        },
        {
            key: 'TEST',
            text: 'TEST',
        },
    ]

    return (
        <Field label='Collection Type' style={{ width: '100%' }}>
            <Select
                id='collectionType'
                disabled={disabled}
                value={selectedItemKey}
                onChange={(_e, data) => {
                    setSelectedItemKey(data.value)
                }}
            >
                {dropdownOptions.map(option => (
                    <option key={option.key} value={option.key}>
                        {option.text}
                    </option>
                ))}
            </Select>
        </Field>
    )
}

export default PGWCollectionTypeDropdown
