import { Field, Select, makeStyles } from '@fluentui/react-components'

import { useGetListQuery } from 'api/lists'
import { useMemo } from 'react'

interface IBaseProps {
    apiListName: string
    disabled: boolean
    label: string
    required?: boolean
    selectedItem: string
    hint?: string
    setSelectedItem: (value: string) => void
}

const useStyles = makeStyles({
    label: {
        fontWeight: 600,
    },
    select: {
        width: '100%',
    },
})

const Base = ({
    apiListName,
    disabled,
    label,
    required,
    selectedItem,
    hint,
    setSelectedItem,
}: IBaseProps) => {
    const classes = useStyles()
    const { data: listItems, isLoading, isError } = useGetListQuery(apiListName)

    const options = useMemo(() => {
        if (listItems) {
            return listItems.map(option => ({
                key: option,
                text: option,
            }))
        }
        if (isLoading) {
            return [{ key: 'loading', text: 'Loading...' }]
        }
        if (isError) {
            return [{ key: 'error', text: 'An error occurred' }]
        }
        return []
    }, [listItems, isError, isLoading])

    const handleChange = (_e, data): void => {
        setSelectedItem(data.value)
    }

    return (
        <Field label={label} required={required} hint={hint}>
            <Select
                value={String(selectedItem).toLocaleLowerCase()}
                onChange={handleChange}
                disabled={disabled}
                className={classes.select}
            >
                {options.map(option => (
                    <option
                        key={option.key}
                        value={option.key.toLocaleLowerCase()}
                    >
                        {option.text}
                    </option>
                ))}
            </Select>
        </Field>
    )
}

export default Base
