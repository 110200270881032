import { ICellRendererParams } from 'ag-grid-community'
import { makeStyles } from '@fluentui/react-components'
import ParticipationDialog from './ParticipationDialog'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        gridGap: '8px',
    },
})

const DriverParticipationCell = ({
    data,
}: ICellRendererParams<IDriverStudyDriver>) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            {data.ParticipationCount ?? 0}
            {data.ParticipationCount > 0 && <ParticipationDialog data={data} />}
        </div>
    )
}

export default DriverParticipationCell
