import { useCallback, useEffect, useState } from 'react'
import { IStatusPanelParams } from 'ag-grid-community'

type FilterModel = {
    [key: string]: unknown
}

const RowCounter = ({ api }: IStatusPanelParams) => {
    const [filterModel, setFilterModel] = useState<FilterModel>({})
    const [count, setCount] = useState<number>()

    const filterCount = Object.keys(filterModel)?.length

    useEffect(() => {
        setCount(api.getModel().getRowCount())
    }, [api])

    const setData = useCallback(() => {
        setFilterModel(api.getFilterModel())
        setCount(api.getModel().getRowCount())
    }, [api])

    useEffect(() => {
        api.addEventListener('modelUpdated', setData)
        return () => api.removeEventListener('modelUpdated', setData)
    }, [api, setData])

    return (
        <div className='ag-status-name-value'>
            <span>
                {filterCount < 1 ? 'Number of rows:' : 'Filtered rows:'}{' '}
            </span>
            <span className='ag-status-name-value-value'>{count ?? '---'}</span>
        </div>
    )
}

export default RowCounter
