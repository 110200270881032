import { Provider } from 'react-redux'
import Router from 'components/Router'
import { fetchUser } from 'slices/authSlice'
import { saveState } from 'helpers/localStorage'
import { store } from 'store'
import { useMsal } from '@azure/msal-react'

// Load the app and redux store
const App = () => {
    const { inProgress } = useMsal()
    if (inProgress !== 'none') return null

    // Fetch user after the app has loaded, the timeout is to avoid the error:
    // "Cannot update a component while rendering a different component"
    setTimeout(() => {
        store.dispatch(fetchUser())
        store.subscribe(() => {
            saveState({
                user: store.getState().auth.user,
                preference: store.getState().preference,
            })
        })
    }, 0)

    return (
        <Provider store={store}>
            <Router />
        </Provider>
    )
}

export default App
