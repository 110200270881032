import {
    ColumnMovedEvent,
    ColumnState,
    ColumnVisibleEvent,
    FilterChangedEvent,
    FirstDataRenderedEvent,
    GetRowIdParams,
    GridReadyEvent,
    SortChangedEvent,
} from 'ag-grid-community'
import { setLastOdataQuery, setRowCount } from 'slices/gridSlice'
import { useAppDispatch, useAppSelector } from 'store'
import { useEffect, useMemo, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import CustomGuidFilter from 'components/AgGrid/Filters/CustomGuidFilter'
import CustomStructureWeekFilter from 'components/AgGrid/Filters/CustomStructureWeekFilter'
import CustomTextListFilter from 'components/AgGrid/Filters/CustomTextListFilter'
import { ExtendedOdataProvider } from 'components/Search/ResultGrid/ResultGridProvider'
import Loader from 'components/Ui/Loader'
import ResultGridHelper from 'components/Search/ResultGrid/ResultGridHelper'
import { getOdataApi } from 'helpers/oDataApiHelper'
import { selectUser } from 'slices/authSlice'
import { statusBar } from 'helpers/gridHelper'
import { useGrid } from 'contexts/GridContext'
import useGridFilterUrl from 'hooks/useGridFilterUrl'
import useSchemas from '../../hooks/useSchemas'
import useSearchGridHelper from 'hooks/useSearchGridHelper'
import { useSearchParams } from 'react-router-dom'

const ResultGrid = () => {
    const [viewColumnState, setViewColumnState] = useState<ColumnState[]>()
    const customQueryFilter = useRef<string[]>([])
    const gridRef = useRef<AgGridReact>(null)
    const [isSearching, setSearching] = useState(false)

    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)

    const [searchParams] = useSearchParams()

    const { setGridRef } = useGrid()
    const [pushFiltersToHistory, setFiltersFromHistory] = useGridFilterUrl()
    const { schema, isLoading } = useSchemas()

    const { sideBar, columnDefs, excelStyles, getContextMenu } =
        useSearchGridHelper(schema, isLoading)

    const customFilters = useMemo(
        () => (isLoading ? null : ResultGridHelper.getCustomFilters(schema)),
        [schema, isLoading]
    )

    const exportColumnKeys = useMemo(() => {
        if (viewColumnState) {
            return ResultGridHelper.GetExportColumnKeys(viewColumnState)
        } else if (schema && !isLoading) {
            return ResultGridHelper.GetExportColumnKeys(schema)
        }
    }, [viewColumnState, schema, isLoading])

    useEffect(() => {
        if (searchParams.has('search') && gridRef.current) {
            setSearching(true)
            customQueryFilter.current =
                ResultGridHelper.CreateExpandedOdataQuery(
                    searchParams.get('search'),
                    user.view
                )
            gridRef.current?.api.refreshServerSide()
        } else if (customQueryFilter.current.length > 0 && gridRef.current) {
            setSearching(true)
            customQueryFilter.current = []
            gridRef.current?.api.refreshServerSide()
        }
    }, [user.view, gridRef, searchParams])

    const onSortChanged = (event: SortChangedEvent) => {
        const colState = event.columnApi.getColumnState()
        setViewColumnState(colState)
    }

    const onColumnsChanged = (event: ColumnMovedEvent | ColumnVisibleEvent) => {
        const colState = event.columnApi.getColumnState()
        setViewColumnState(colState)
    }

    const onGridReady = async (event: GridReadyEvent) => {
        event.api?.setServerSideDatasource(
            new ExtendedOdataProvider({
                callApi: async options =>
                    getOdataApi(`/odata/${user.view}${options}`).then(resp => {
                        dispatch(
                            setLastOdataQuery(`/odata/${user.view}${options}`)
                        )

                        if (
                            resp.data['@odata.count'] !== null &&
                            typeof resp.data['@odata.count'] !== 'undefined'
                        ) {
                            dispatch(
                                setRowCount(resp.data['@odata.count'] ?? 0)
                            )
                        }
                        return resp.data
                    }),
                beforeRequest: query => {
                    // This is a AG-Grid bug, if we set query.filter = customQueryFilter, it will override
                    // the variable with the old filter so we must use the spread operator
                    // to create a new array and not pass the reference, nice one AG-Grid :)
                    query.filter = [...customQueryFilter.current]
                },
                afterLoadData: () => {
                    setSearching(false)
                },
                customFilters,
            })
        )

        setGridRef(gridRef)
    }

    const onFilterChanged = (event: FilterChangedEvent) => {
        pushFiltersToHistory(event.api)
    }

    const onFirstDataRendered = (event: FirstDataRenderedEvent<unknown>) => {
        setFiltersFromHistory(event.api)

        if (searchParams.has('search')) {
            setSearching(true)
            customQueryFilter.current =
                ResultGridHelper.CreateExpandedOdataQuery(
                    searchParams.get('search'),
                    user.view
                )
            gridRef.current?.api.refreshServerSide()
        }
    }

    if (isLoading) {
        return <Loader text='Loading data...' />
    }

    return (
        <>
            <AgGridReact
                enableRangeSelection
                ref={gridRef}
                className='cy-vehicle-grid'
                sideBar={sideBar}
                columnDefs={columnDefs}
                excelStyles={excelStyles}
                rowModelType='serverSide'
                blockLoadDebounceMillis={250}
                getRowId={(params: GetRowIdParams) =>
                    `${params.data?.Id?.toString()}-${Math.random().toString()}`
                }
                cacheBlockSize={1000}
                maxBlocksInCache={15}
                getContextMenuItems={getContextMenu}
                defaultExcelExportParams={{
                    columnKeys: exportColumnKeys,
                }}
                onFilterChanged={onFilterChanged}
                onFirstDataRendered={onFirstDataRendered}
                onColumnPinned={onColumnsChanged}
                onColumnMoved={onColumnsChanged}
                onColumnVisible={onColumnsChanged}
                onSortChanged={onSortChanged}
                onGridReady={onGridReady}
                components={{
                    agStructureWeek: CustomStructureWeekFilter,
                    agGuidFilter: CustomGuidFilter,
                    modelCodeFilter: CustomTextListFilter,
                }}
                rowSelection='multiple'
                statusBar={statusBar}
            />
            {isSearching && <Loader text='Searching...' />}
        </>
    )
}

export default ResultGrid
