import {
    Combobox,
    ComboboxProps,
    Field,
    Option,
    Text,
} from '@fluentui/react-components'

import { useState } from 'react'

interface IFreeFormProps {
    options: { key: string; text: string }[]
    label: string
    placeholder: string
    onSelect: (selected: string[]) => void
    selected: string[]
}

export const Freeform = ({
    options,
    label,
    placeholder,
    onSelect,
    selected,
}: IFreeFormProps) => {
    const [matchingOptions, setMatchingOptions] = useState([...options])
    const [customSearch, setCustomSearch] = useState<string | undefined>()

    const onChange: ComboboxProps['onChange'] = event => {
        const value = event.target.value.trim()
        const matches = options.filter(
            option =>
                option.text.toLowerCase().indexOf(value.toLowerCase()) === 0
        )
        setMatchingOptions(matches)
        if (value.length && matches.length < 1) {
            setCustomSearch(value)
        } else {
            setCustomSearch(undefined)
        }
    }

    const onOptionSelect: ComboboxProps['onOptionSelect'] = (_ev, data) => {
        const matchingOption =
            data.optionText &&
            options.map(o => o.text).includes(data.optionText)
        if (matchingOption) {
            setCustomSearch(undefined)
            onSelect(data.selectedOptions)
        } else {
            setCustomSearch(data.optionText)
        }
    }

    return (
        <Field label={label}>
            <Combobox
                multiselect={true}
                placeholder={placeholder}
                onChange={onChange}
                onOptionSelect={onOptionSelect}
                selectedOptions={selected}
            >
                {customSearch ? (
                    <Option key='freeform' text={customSearch}>
                        Search for &qute;{customSearch}&qute;
                    </Option>
                ) : null}
                {matchingOptions.map(option => (
                    <Option key={option.key}>{option.text}</Option>
                ))}
            </Combobox>
            {selected.length ? (
                <Text size={200}>Chosen: {selected.join(', ')}</Text>
            ) : null}
        </Field>
    )
}
