enum ViewsEnum {
    super = 'SuperVehicleViews',
    ota = 'OtaVehicleViews',
    codev = 'CodevVehicleViews',
    vehicle = 'ReadVehicleViews',
    china = 'ChinaVehicleViews',
    america = 'AmericaVehicleViews',
}

export default ViewsEnum
