import {
    Button,
    DialogActions,
    DialogTrigger,
    MessageBar,
    makeStyles,
} from '@fluentui/react-components'

import AxiosStateTypeEnum from 'constants/AxiosStateTypeEnum'

interface IImportFeedbackProps {
    uploadStatus: AxiosStateTypeEnum
    toggleOpen: () => void
    onClickTryAgain: () => void
}

const useStyles = makeStyles({
    button: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '8px',
    },
})

const ImportFeedback = ({
    uploadStatus,
    toggleOpen,
    onClickTryAgain,
}: IImportFeedbackProps) => {
    const classes = useStyles()

    return (
        <>
            <>
                {uploadStatus === AxiosStateTypeEnum.Success && (
                    <>
                        <MessageBar intent='success'>
                            Successfully imported vehicle(s)!
                        </MessageBar>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button
                                    style={{
                                        marginLeft: 'auto',
                                    }}
                                    onClick={toggleOpen}
                                >
                                    Close
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </>
                )}
                {uploadStatus === AxiosStateTypeEnum.Error && (
                    <>
                        <MessageBar intent='error'>
                            An error occurred while importing the vehicle(s).
                            Try again and contact technical support if the error
                            persists.
                        </MessageBar>
                        <DialogActions className={classes.button}>
                            <Button
                                appearance='primary'
                                onClick={onClickTryAgain}
                            >
                                Try again
                            </Button>
                            <DialogTrigger disableButtonEnhancement>
                                <Button onClick={toggleOpen}>Cancel</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </>
                )}
            </>
        </>
    )
}

export default ImportFeedback
