import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    makeStyles,
} from '@fluentui/react-components'
import {
    useArchiveVehicleTestMutation,
    useUnArchiveVehicleTestMutation,
} from 'api/vehicleTests'

import { Archive48Regular } from '@fluentui/react-icons'
import { ICellRendererParams } from 'ag-grid-community'
import { triggerMessage } from 'slices/messageSlice'
import { useAppDispatch } from 'store'
import { useState } from 'react'

const useStyle = makeStyles({
    red: {
        color: 'red',
    },
})

const ArchiveAction = ({
    data,
    api,
}: ICellRendererParams<IVehicleTestOdata>) => {
    const [open, setOpen] = useState(false)
    const [archiveVehicleTest, { isLoading: archiving }] =
        useArchiveVehicleTestMutation()
    const [unarchiveVehicleTest, { isLoading: unarchiving }] =
        useUnArchiveVehicleTestMutation()
    const dispatch = useAppDispatch()

    const classes = useStyle()

    const archiveTest = async () => {
        let response

        if (!data.Archived) {
            response = await archiveVehicleTest(data.Id)
        } else {
            response = await unarchiveVehicleTest(data.Id)
        }

        if ('data' in response) {
            dispatch(
                triggerMessage({
                    intent: 'success',
                    message: `${data.Title} was ${
                        data.Archived ? 'un' : ''
                    }archived successfully.`,
                })
            )
            setOpen(false)
            api.refreshServerSide()
        } else if ('error' in response) {
            dispatch(
                triggerMessage({
                    intent: 'warning',
                    title: `Error: ${response.error.data}`,
                    message: `There was an error processing ${data.Title}, please try again or contact support.`,
                })
            )
        }
    }

    return (
        <Dialog open={open} onOpenChange={(_e, d) => setOpen(d.open)}>
            <DialogTrigger>
                <Button
                    data-cy='archive-button'
                    appearance='transparent'
                    icon={<Archive48Regular />}
                    className={data.Archived ? classes.red : null}
                    disabled={data.OngoingVehicles > 0}
                    title={
                        data.Archived
                            ? 'Unarchive'
                            : data.OngoingVehicles > 0
                            ? 'There are still vehicles with unfinished test-status assigned to this test'
                            : 'Archive'
                    }
                />
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>
                        {data.Archived ? 'Unarchive' : 'Archive'} Test &quot;
                        {data.Title}&quot;
                    </DialogTitle>
                    <DialogContent>
                        {'Do you want to change the status of this test?'}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            data-cy='archive-confirm-button'
                            appearance='primary'
                            onClick={archiveTest}
                            disabled={archiving || unarchiving}
                        >
                            {archiving || unarchiving
                                ? 'Loading...'
                                : data.Archived
                                ? 'Unarchive'
                                : 'Archive'}
                        </Button>
                        <DialogTrigger>
                            <Button>Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default ArchiveAction
