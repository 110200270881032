/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    makeStyles,
} from '@fluentui/react-components'
import { ColDef, ExcelStyle, GridReadyEvent } from 'ag-grid-community'
import { useEffect, useMemo, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import ModalStatusEnum from 'constants/ModalStatusEnum'
import { chunkArray } from 'helpers/arrayHelper'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import { selectLastOdataQuery } from 'slices/gridSlice'
import { toast } from 'react-toastify'
import { truncate } from 'helpers/stringHelpers'
import { useAppSelector } from 'store'

interface ICreateKeyCardsModalProps {
    selectedRows: any[]
    vehicleTest: IVehicleTest
    toggleOpen: () => void
    modalStatus: ModalStatusEnum
}

const useStyles = makeStyles({
    hidden: { height: '0', width: '0', display: 'none' },
})

const CreateKeyCardsModal = ({
    selectedRows,
    vehicleTest,
    toggleOpen,
    modalStatus,
}: ICreateKeyCardsModalProps) => {
    const [createGrid, setCreate] = useState(false)
    const lastODataQuery = useAppSelector(selectLastOdataQuery)
    const [vehicleData, setVehicleData] = useState<
        { VehicleInfo: IVehicleInfoOdata }[]
    >([])
    const gridRef = useRef<AgGridReact>(null)

    const classes = useStyles()

    useEffect(() => {
        if (!lastODataQuery) return
        const fetchVehicleData = async () => {
            const param = new URLSearchParams(lastODataQuery)
            param.delete('$skip')
            param.delete('$top')
            param.delete('$select')

            reflectApiAxiosInstance
                .get(decodeURIComponent(param.toString()))
                .then(response => setVehicleData(response?.data?.value))
                .catch(e => {
                    toast.error(e)
                })
        }
        if (createGrid) {
            fetchVehicleData()
        }
    }, [lastODataQuery, createGrid])

    const getExportOptions = () => {
        const dateTimeNow = new Intl.DateTimeFormat('sv-SE', {
            dateStyle: 'short',
            timeStyle: 'short',
        })
            .format(new Date())
            .replace(/\s/g, '_')
            .replace(/:|-/g, '')

        const testTitle = vehicleTest.title
            .split(/(?=[A-Z])/)
            .join('_')
            .toLowerCase()
            .replace(/[^a-z0-9]/g, '_')

        return {
            fileName: `Key_Cards_${testTitle}_${dateTimeNow}`,
            sheetName: 'tests',
        }
    }

    const onGridReady = (params: GridReadyEvent) => {
        params.api.exportDataAsExcel(getExportOptions())
    }

    const trimmNull = (string?: string) =>
        string === null || !string ? '' : string

    const fieldNames = useMemo(
        () => ['columnOne', 'columnTwo', 'columnThree', 'columnFour'],
        []
    )

    const onCreateKeyCards = () => {
        gridRef.current?.api.exportDataAsExcel(getExportOptions())
        setCreate(true)
    }

    const rowData = useMemo(() => {
        const dataSet = vehicleData?.map(data => {
            // Create a string that contains all the data
            return `${truncate(trimmNull(vehicleTest?.title), 30)}\n${trimmNull(
                data.VehicleInfo?.FirstName
            )} ${trimmNull(data.VehicleInfo?.LastName)}\n${trimmNull(
                data.VehicleInfo?.RegistrationNo
            )} ${trimmNull(data.VehicleInfo?.Model)}\n${trimmNull(
                data.VehicleInfo?.Phone
            )}\n${trimmNull(data.VehicleInfo?.Vin)}\n`
        })

        return chunkArray(dataSet, 4)?.map(part =>
            Object.fromEntries(
                part.map((c, index: number) => [fieldNames[index], c])
            )
        )
    }, [fieldNames, vehicleTest, vehicleData])

    const selectedRowData = useMemo(() => {
        const dataSet = selectedRows?.map(data => {
            return `${truncate(trimmNull(vehicleTest?.title), 30)}\n${trimmNull(
                data.VehicleInfo?.FirstName
            )} ${trimmNull(data.VehicleInfo?.LastName)}\n${trimmNull(
                data.VehicleInfo?.RegistrationNo
            )} ${trimmNull(data.VehicleInfo?.Model)}\n${trimmNull(
                data.VehicleInfo?.Phone
            )}\n${trimmNull(data.VehicleInfo?.Vin)}\n`
        })

        return chunkArray(dataSet, 4)?.map(part =>
            Object.fromEntries(
                part.map((c, index: number) => [fieldNames[index], c])
            )
        )
    }, [selectedRows, vehicleTest?.title, fieldNames])

    const columnDefs = useMemo<ColDef[]>(
        () =>
            fieldNames.map((field, index) => {
                // Every other column in excel will get a different bg color
                const cellClass =
                    index % 2 ? ['multiline'] : ['multiline', 'darkbg']
                return {
                    field,
                    minWidth: 200,
                    cellClass,
                }
            }),
        [fieldNames]
    )

    const excelStyles = useMemo<ExcelStyle[]>(() => {
        return [
            {
                id: 'multiline',
                alignment: {
                    wrapText: true,
                },
            },
            {
                id: 'darkbg',
                interior: {
                    color: '#eeeeee',
                    pattern: 'Solid',
                    patternColor: undefined,
                },
            },
        ]
    }, [])

    return (
        <>
            <DialogContent>
                <p>
                    This will create an excel file for{' '}
                    {modalStatus === ModalStatusEnum.Selected
                        ? `${selectedRows.length}`
                        : 'all'}{' '}
                    vehicle(s).
                </p>
                {createGrid && vehicleData.length > 0 && (
                    <div className={classes.hidden}>
                        <AgGridReact
                            ref={gridRef}
                            onGridReady={onGridReady}
                            rowData={
                                modalStatus === ModalStatusEnum.Selected
                                    ? selectedRowData
                                    : rowData
                            }
                            columnDefs={columnDefs}
                            excelStyles={excelStyles}
                        />
                    </div>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    appearance='primary'
                    onClick={onCreateKeyCards}
                    disabled={!lastODataQuery}
                >
                    Create
                </Button>
                <DialogTrigger disableButtonEnhancement>
                    <Button onClick={() => toggleOpen()}>Cancel</Button>
                </DialogTrigger>
            </DialogActions>
        </>
    )
}

export default CreateKeyCardsModal
