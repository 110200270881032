import {
    Add24Regular,
    AddSquareMultiple24Regular,
    AppsList24Regular,
    ArrowDownload24Regular,
    ArrowExport24Regular,
    ArrowSync24Regular,
    Beaker24Regular,
    Book24Regular,
    Edit24Regular,
    Filter24Regular,
    Mail24Regular,
    Pen24Regular,
    Person24Regular,
    Phone24Regular,
    Send24Regular,
    TableSimpleExclude24Regular,
    TableSimpleInclude24Regular,
    Tag24Regular,
    News24Filled,
    VehicleCar24Regular,
    Wifi124Regular,
    Wrench24Regular,
} from '@fluentui/react-icons'
import {
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
} from '@fluentui/react-components'

import ActionsMenu from 'components/Ui/Layout/ActionsMenu'
import AddDriver from 'components/Actions/Drivers/AddDriver'
import AddPGWCollection from 'components/Actions/PGW/AddPGWCollection'
import BatchEditVehicles from 'components/Actions/BatchEditVehicles/BatchEditVehicles'
import DriverStudyActions from 'components/Actions/DriverStudyActions'
import EmailActions from 'components/Actions/EmailActions'
import { IActionsMenu } from 'Types/ActionsMenu'
import ImportVehicle from 'components/Actions/ImportVehicle/ImportVehicle'
import ModalStatusEnum from 'constants/ModalStatusEnum'
import ModalType from 'constants/ModalTypeEnum'
import OTAReport from 'components/Actions/OTAReport/OTAReport'
import PGWManagement from 'components/Actions/PGW/PGWManagement'
import SMSActions from 'components/Actions/SMSActions'
import UpdateDriver from 'components/Actions/Drivers/UpdateDriver'
import UpdatePGWCollection from 'components/Actions/PGW/UpdatePGWCollection'
import VehicleGroupLists from 'components/Actions/VehicleGroupLists/VehicleGroupLists'
import VehicleGroupListActions from 'components/Actions/VehicleGroupListActions'
import VehicleGroupListManualActions from 'components/Actions/VehicleGroupListManualActions'
import VehicleTestActions from 'components/Actions/VehicleTestActions'
import { getExportOptions } from 'helpers/excelExportHelpers'
import useDialogStyles from 'components/styles/dialogStyles'
import { useGrid } from 'contexts/GridContext'
import { useState } from 'react'

const MainGridActions = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(null)
    const [isVehicleImportModalOpen, setIsVehicleImportModalOpen] =
        useState(false)

    const largeDialogs = [
        'Import Vehicles',
        'Tag List',
        'News List',
        'Exclusion List',
        'Inclusion List',
        'PGW Management',
    ]

    const classes = useDialogStyles()

    const { gridRef } = useGrid()
    const [selectedRowCount, setSelectedRowCount] = useState(0)
    gridRef.current?.api.addEventListener('selectionChanged', () => {
        setSelectedRowCount(gridRef.current?.api.getSelectedRows().length ?? 0)
    })
    const [isAnyFilterPresent, setIsAnyFilterPresent] = useState(false)
    gridRef.current?.api.addEventListener('filterChanged', () => {
        setIsAnyFilterPresent(gridRef.current?.api.isAnyFilterPresent())
    })

    const openDialog = (body: JSX.Element, title: string) => {
        setIsDialogOpen(true)
        setDialogContent({ body, title })
    }

    const exportToExcel = () =>
        gridRef.current?.api.exportDataAsExcel(
            getExportOptions(
                selectedRowCount,
                gridRef.current?.api.getDisplayedRowCount()
            )
        )

    const exportToExcelInfo = () => (
        <div>
            <div>
                Narrow your filtering to be able to export vehicles, or manually
                select vehicles to export.
            </div>
            <div>
                Please note that you can not export more than 1000 filtered
                vehicles.
            </div>
        </div>
    )

    const menuItems: IActionsMenu[] = [
        {
            label: 'Import Vehicles',
            icon: <VehicleCar24Regular />,
            onClick: () => setIsVehicleImportModalOpen(true),
            restricted: 'vehicleImport',
        },
        {
            label: 'Tag List',
            icon: <Tag24Regular />,
            onClick: () =>
                openDialog(
                    <VehicleGroupLists
                        vehicleGroupType={'TagList'}
                        toggleOpen={() => setIsDialogOpen(false)}
                    />,
                    'Tag List'
                ),
            restricted: 'tagListManage',
            children: [
                {
                    label: 'Show Tag Lists',
                    icon: <AppsList24Regular />,
                    onClick: () =>
                        openDialog(
                            <VehicleGroupLists
                                vehicleGroupType={'TagList'}
                                toggleOpen={() => setIsDialogOpen(false)}
                            />,
                            'Tag List'
                        ),
                },
                {
                    label: 'Add Manual Vehicles',
                    icon: <AddSquareMultiple24Regular />,
                    onClick: () =>
                        openDialog(
                            <VehicleGroupListManualActions
                                toggleOpen={() => setIsDialogOpen(false)}
                                modalType={ModalType.QuickModal}
                                type='TagList'
                            />,
                            'Add Manual Vehicles to Tag List'
                        ),
                },
                {
                    label: 'Add Selected Vehicles',
                    icon: <AddSquareMultiple24Regular />,
                    disabled: !selectedRowCount,
                    onClick: () =>
                        openDialog(
                            <VehicleGroupListActions
                                toggleOpen={() => setIsDialogOpen(false)}
                                modalStatus={ModalStatusEnum.Selected}
                                modalType={ModalType.QuickModal}
                                type='TagList'
                            />,
                            'Add Selected Vehicles to Tag List'
                        ),
                },
                {
                    label: 'Add Filtered Vehicles',
                    icon: <Filter24Regular />,
                    disabled: !isAnyFilterPresent,
                    onClick: () =>
                        openDialog(
                            <VehicleGroupListActions
                                toggleOpen={() => setIsDialogOpen(false)}
                                modalStatus={ModalStatusEnum.Filtered}
                                modalType={ModalType.QuickModal}
                                type='TagList'
                            />,
                            'Add Filtered Vehicles to Tag List'
                        ),
                },
            ],
        },
        {
            label: 'News List',
            icon: <News24Filled />,
            onClick: () =>
                openDialog(
                    <VehicleGroupLists
                        vehicleGroupType={'NewsList'}
                        toggleOpen={() => setIsDialogOpen(false)}
                    />,
                    'News List'
                ),
            restricted: 'newsListManage',
            children: [
                {
                    label: 'Show News Lists',
                    icon: <AppsList24Regular />,
                    onClick: () =>
                        openDialog(
                            <VehicleGroupLists
                                vehicleGroupType={'NewsList'}
                                toggleOpen={() => setIsDialogOpen(false)}
                            />,
                            'News List'
                        ),
                },
                {
                    label: 'Add Manual Vehicles',
                    icon: <AddSquareMultiple24Regular />,
                    onClick: () =>
                        openDialog(
                            <VehicleGroupListManualActions
                                toggleOpen={() => setIsDialogOpen(false)}
                                modalType={ModalType.QuickModal}
                                type='NewsList'
                            />,
                            'Add Manual Vehicles to News List'
                        ),
                },
                {
                    label: 'Add Selected Vehicles',
                    icon: <AddSquareMultiple24Regular />,
                    disabled: !selectedRowCount,
                    onClick: () =>
                        openDialog(
                            <VehicleGroupListActions
                                toggleOpen={() => setIsDialogOpen(false)}
                                modalStatus={ModalStatusEnum.Selected}
                                modalType={ModalType.QuickModal}
                                type='NewsList'
                            />,
                            'Add Selected Vehicles to News List'
                        ),
                },
                {
                    label: 'Add Filtered Vehicles',
                    icon: <Filter24Regular />,
                    disabled: !isAnyFilterPresent,
                    onClick: () =>
                        openDialog(
                            <VehicleGroupListActions
                                toggleOpen={() => setIsDialogOpen(false)}
                                modalStatus={ModalStatusEnum.Filtered}
                                modalType={ModalType.QuickModal}
                                type='NewsList'
                            />,
                            'Add Filtered Vehicles to News List'
                        ),
                },
            ],
        },
        {
            label: 'OTA',
            icon: <Wifi124Regular />,
            restricted: 'otaManage',
            children: [
                {
                    label: 'Exclusion List',
                    icon: <TableSimpleExclude24Regular />,
                    onClick: () =>
                        openDialog(
                            <VehicleGroupLists
                                vehicleGroupType={'ExclusionList'}
                                toggleOpen={() => setIsDialogOpen(false)}
                            />,
                            'Exclusion List'
                        ),
                },
                {
                    label: 'Inclusion List',
                    icon: <TableSimpleInclude24Regular />,
                    onClick: () =>
                        openDialog(
                            <VehicleGroupLists
                                vehicleGroupType={'InclusionList'}
                                toggleOpen={() => setIsDialogOpen(false)}
                            />,
                            'Inclusion List'
                        ),
                },
                {
                    label: 'Download OTA Report',
                    icon: <ArrowDownload24Regular />,
                    onClick: () =>
                        openDialog(
                            <OTAReport
                                toggleOpen={() => setIsDialogOpen(false)}
                            />,
                            'Download OTA Report'
                        ),
                },
            ],
        },
        {
            label: 'Drivers',
            icon: <Person24Regular />,
            restricted: 'driver',
            children: [
                {
                    label: 'Add Driver',
                    icon: <Add24Regular />,
                    onClick: () =>
                        openDialog(
                            <AddDriver
                                toggleOpen={() => setIsDialogOpen(false)}
                            />,
                            'Add Driver'
                        ),
                },
                {
                    label: 'Update from filtered / Selected vehicles',
                    icon: <Edit24Regular />,
                    onClick: () =>
                        openDialog(
                            <UpdateDriver
                                toggleOpen={() => setIsDialogOpen(false)}
                            />,
                            'Update driver'
                        ),
                },
            ],
        },
        {
            label: 'Vehicle Test',
            icon: <Beaker24Regular />,
            restricted: 'vehicleTestsManage',
            children: [
                {
                    label: 'Add Selected Vehicles',
                    icon: <AddSquareMultiple24Regular />,
                    disabled: !selectedRowCount,
                    onClick: () =>
                        openDialog(
                            <VehicleTestActions
                                toggleOpen={() => setIsDialogOpen(false)}
                                modalStatus={ModalStatusEnum.Selected}
                            />,
                            'Add Selected Vehicles to test'
                        ),
                },
                {
                    label: 'Add Filtered Vehicles',
                    icon: <Filter24Regular />,
                    onClick: () =>
                        openDialog(
                            <VehicleTestActions
                                toggleOpen={() => setIsDialogOpen(false)}
                                modalStatus={ModalStatusEnum.Filtered}
                            />,
                            'Add Filtered Vehicles to test'
                        ),
                },
            ],
        },
        {
            label: 'Driver Studies',
            icon: <Book24Regular />,
            restricted: 'driverStudiesManage',
            children: [
                {
                    label: 'Add Selected Drivers',
                    icon: <AddSquareMultiple24Regular />,
                    disabled: !selectedRowCount,

                    onClick: () =>
                        openDialog(
                            <DriverStudyActions
                                toggleOpen={() => setIsDialogOpen(false)}
                                modalStatus={ModalStatusEnum.Selected}
                            />,
                            'Add drivers to a study'
                        ),
                },
                {
                    label: 'Add Filtered Drivers',
                    icon: <Filter24Regular />,
                    onClick: () =>
                        openDialog(
                            <DriverStudyActions
                                toggleOpen={() => setIsDialogOpen(false)}
                                modalStatus={ModalStatusEnum.Filtered}
                            />,
                            'Add drivers to a study'
                        ),
                },
            ],
        },
        {
            label: 'Contact Drivers',
            icon: <Person24Regular />,
            children: [
                {
                    label: 'Send SMS',
                    icon: <Phone24Regular />,
                    restricted: 'sms',
                    children: [
                        {
                            label: 'Send SMS to selected drivers',
                            disabled: !selectedRowCount,
                            icon: <Send24Regular />,
                            onClick: () =>
                                openDialog(
                                    <SMSActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Selected}
                                    />,
                                    'Send SMS Message to selected drivers'
                                ),
                        },
                        {
                            label: 'Send SMS to Filtered drivers',
                            icon: <Filter24Regular />,
                            onClick: () =>
                                openDialog(
                                    <SMSActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Filtered}
                                    />,
                                    'Send SMS Message to filtered drivers'
                                ),
                        },
                    ],
                },
                {
                    label: 'Send Email',
                    icon: <Mail24Regular />,
                    restricted: 'email',
                    children: [
                        {
                            label: 'Send Email to Selected drivers',
                            disabled: !selectedRowCount,
                            icon: <Send24Regular />,
                            onClick: () =>
                                openDialog(
                                    <EmailActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Selected}
                                    />,
                                    'Send Email to Selected drivers'
                                ),
                        },
                        {
                            label: 'Send Email to Filtered drivers',
                            icon: <Filter24Regular />,
                            onClick: () =>
                                openDialog(
                                    <EmailActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Filtered}
                                    />,
                                    'Send Email to Filtered drivers'
                                ),
                        },
                    ],
                },
            ],
        },
        {
            label: 'Batch Edit',
            icon: <Pen24Regular />,
            disabled: !selectedRowCount,
            onClick: () =>
                openDialog(
                    <BatchEditVehicles
                        toggleOpen={() => setIsDialogOpen(false)}
                    />,
                    `Batch edit ${selectedRowCount} selected vehicles`
                ),
            restricted: 'batchEdit',
        },
        {
            label: 'PGW Collections',
            icon: <Wrench24Regular />,
            restricted: 'pgwCreate',
            children: [
                {
                    label: 'New from filtered / Selected vehicles',
                    icon: <Add24Regular />,
                    onClick: () =>
                        openDialog(
                            <AddPGWCollection
                                toggleOpen={() => setIsDialogOpen(false)}
                            />,
                            'Add PGW Collection'
                        ),
                    disabled: !selectedRowCount,
                },
                {
                    label: 'Update from filtered / Selected vehicles',
                    icon: <Filter24Regular />,
                    onClick: () =>
                        openDialog(
                            <UpdatePGWCollection
                                toggleOpen={() => setIsDialogOpen(false)}
                            />,
                            'Update collection'
                        ),
                },
                {
                    label: 'Manage sync etc...',
                    icon: <ArrowSync24Regular />,
                    onClick: () =>
                        openDialog(
                            <PGWManagement
                                toggleOpen={() => setIsDialogOpen(false)}
                            />,
                            'PGW Management'
                        ),
                    restricted: 'pgwSync',
                },
            ],
        },
        {
            label: 'Export to Excel',
            icon: <ArrowExport24Regular />,
            disabled: !selectedRowCount,
            onClick: () => exportToExcel(),
            info: exportToExcelInfo,
        },
    ]

    return (
        <>
            <ActionsMenu items={menuItems} />
            <Dialog
                open={isDialogOpen}
                onOpenChange={(_event, data) => setIsDialogOpen(data.open)}
            >
                <DialogSurface
                    className={
                        largeDialogs.includes(dialogContent?.title)
                            ? classes.dialogSurface
                            : undefined
                    }
                >
                    <DialogBody
                        className={
                            largeDialogs.includes(dialogContent?.title)
                                ? classes.dialogBody
                                : undefined
                        }
                    >
                        <DialogTitle>{dialogContent?.title}</DialogTitle>
                        {dialogContent?.body}
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            {isVehicleImportModalOpen && (
                <ImportVehicle
                    open={isVehicleImportModalOpen}
                    toggleOpen={() =>
                        setIsVehicleImportModalOpen(!isVehicleImportModalOpen)
                    }
                />
            )}
        </>
    )
}

export default MainGridActions
