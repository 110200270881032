import {
    Button,
    makeStyles,
    mergeClasses,
    shorthands,
} from '@fluentui/react-components'

import { useNavigate } from 'react-router'

const useStyle = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 16px)',
        backgroundImage:
            'linear-gradient(180deg, #f2f3ee 0%, #f2f3ee 50%, #A7C5C5 50%, #A7C5C5 100%)',
    },
    header: {
        fontSize: '15rem',
        fontWeight: 700,
        ...shorthands.margin(0, 0, '1rem'),
        lineHeight: 1,
        color: '#A7C5C5',
    },
    animation: {
        display: 'inline-block',
        animationIterationCount: 'infinite',
        animationDuration: '10s',
        animationName: {
            '0%': { transform: 'translateY(0px)' },
            '25%': { transform: 'translateY(6px)' },
            '50%': { transform: 'translateY(2px)' },
            '75%': { transform: 'translateY(4px)' },
            '100%': { transform: 'translateY(0px)' },
        },
    },
    delay1: {
        animationDelay: '1s',
    },
    delay2: {
        animationDelay: '2s',
    },
    subheader: {
        fontSize: '4rem',
        fontWeight: 400,
        lineHeight: 1,
        ...shorthands.margin(0, 0, '1rem'),
        color: '#f2f3ee',
    },
    text: {
        fontSize: '2rem',
        fontWeight: 400,
        lineHeight: 1,
        ...shorthands.margin(0),
        color: '#f2f3ee',
    },
    button: {
        marginTop: '2rem',
        textTransform: 'uppercase',
        ...shorthands.borderColor('#f2f3ee'),
        color: '#f2f3ee',
    },
})

const NoMatch = () => {
    const classes = useStyle()
    const navigate = useNavigate()

    return (
        <div className={classes.root}>
            <h1 className={classes.header}>
                <span className={classes.animation}>4</span>
                <span
                    className={mergeClasses(classes.animation, classes.delay1)}
                >
                    0
                </span>
                <span
                    className={mergeClasses(classes.animation, classes.delay2)}
                >
                    4
                </span>
            </h1>
            <p className={classes.subheader}>Sorry, Page not found</p>
            <p className={classes.text}>
                The page you are looking for does not exist.
            </p>
            <Button
                appearance='outline'
                className={classes.button}
                onClick={() => {
                    navigate('/')
                }}
            >
                Go back home
            </Button>
        </div>
    )
}

export default NoMatch
