import ActionsMenu from 'components/Ui/Layout/ActionsMenu'
import AddClassificationModal from 'components/VehicleClassifications/AddClassificationModal'
import { ClipboardTextLtr24Regular } from '@fluentui/react-icons'
import Content from 'components/Ui/Layout/Content'
import Header from 'components/Ui/Layout/Header'
import VehicleClassificationsGrid from 'components/VehicleClassifications/VehicleClassificationsGrid'

const VehicleClassification = () => {
    return (
        <>
            <Header
                title='Vehicle Classification'
                icon={<ClipboardTextLtr24Regular />}
            />
            <ActionsMenu
                items={[
                    {
                        component: <AddClassificationModal />,
                        restricted: 'vehicleClassification',
                    },
                ]}
            />

            <Content>
                <VehicleClassificationsGrid />
            </Content>
        </>
    )
}

export default VehicleClassification
