import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import { CellEditRequestEvent, ColDef } from 'ag-grid-community'
import {
    WorkshopOdataProvider,
    defaultColDef,
    getBooleanCustomFilters,
    getContextMenuItems,
    getGuidCustomFilters,
    getListCustomFilters,
    sideBar,
    statusBar,
} from 'helpers/gridHelper'
import { dateComparator, dateRenderWithTime } from 'helpers/dateHelpers'
import { useEffect, useRef } from 'react'
import {
    useGetWorkshopNotificationTypesQuery,
    useGetWorkshopVisitTypesQuery,
    useOldUpdateWorkshopVisitMutation,
} from 'api/workshop'
import { useParams, useSearchParams } from 'react-router-dom'

import AgGridContainer from 'components/Ui/Layout/AgGridContainer'
import CreateWorkshopVisit from './CreateWorkshopVisit'
import CustomGuidFilter from 'components/AgGrid/Filters/CustomGuidFilter'
import CustomTextListFilter from 'components/AgGrid/Filters/CustomTextListFilter'
import DateEdit from 'components/AgGrid/CellRenderers/DateEdit'
import Loader from 'components/Ui/Loader'
import WorkshopToggleCellRenderer from './WorkshopToggleCellRenderer'
import { getOdataApi } from 'helpers/oDataApiHelper'
import { setLastOdataQuery } from 'slices/gridSlice'
import { textFilterOptions } from 'hooks/useSearchGridHelper'
import { useAppDispatch } from 'store'
import { useGetVehicleClassificationsQuery } from 'api/vehicleTests'
import { useGrid } from 'contexts/GridContext'

const WorkshopGrid = () => {
    const customQueryFilter = useRef<string>('')
    const gridRef = useRef<AgGridReact>(null)

    const dispatch = useAppDispatch()

    const { data: visitTypes = [] } = useGetWorkshopVisitTypesQuery()
    const { data: notificationTypes = [] } =
        useGetWorkshopNotificationTypesQuery()
    const { data: classificationTypes = [] } =
        useGetVehicleClassificationsQuery()

    const [updateWorkshopVisit] = useOldUpdateWorkshopVisitMutation()

    const [searchParams] = useSearchParams()

    const { setGridRef } = useGrid()
    const { id } = useParams()

    const columnDef: ColDef[] = [
        {
            colId: 'checkBoxSelect',
            checkboxSelection: true,
            lockPosition: true,
            lockPinned: true,
            pinned: 'left',
            suppressMenu: true,
            width: 55,
            suppressColumnsToolPanel: true,
            filter: false,
            sortable: false,
        },
        {
            field: 'WsWaiting',
            headerName: 'Waiting',
            cellRenderer: WorkshopToggleCellRenderer,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: [false, true],
                buttons: ['reset', 'apply'],
            },
        },
        {
            field: 'Vin',
        },
        {
            headerName: 'Registration No',
            field: 'RegistrationNo',
            filter: CustomTextListFilter,
        },
        {
            headerName: 'Latest Vehicle Version',
            field: 'LatestVehicleVersion',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: dateComparator,
                maxNumConditions: 1,
                buttons: ['reset', 'apply'],
            },
            cellRenderer: dateRenderWithTime,
        },
        {
            headerName: 'OTA Version',
            field: 'OtaVersion',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains', 'blank'],
                buttons: ['reset', 'apply'],
            },
        },
        {
            headerName: 'Driver Name',
            field: 'PrimaryDriver',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains', 'blank'],
                buttons: ['reset', 'apply'],
            },
        },
        {
            headerName: 'Cdsid',
            field: 'Cdsid',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains', 'blank'],
                buttons: ['reset', 'apply'],
            },
        },
        {
            field: 'WsArrival',
            headerName: 'Arrival',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: dateComparator,
                maxNumConditions: 1,
                buttons: ['reset', 'apply'],
            },
            cellRenderer: DateEdit,
            cellRendererParams: {
                min: ['WsReady', 'WsInstalled'],
            },
        },

        {
            field: 'WsComment',
            headerName: 'Comment',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: textFilterOptions,
                buttons: ['reset', 'apply'],
            },
            editable: true,
        },
        {
            field: 'WsInitiatedBy',
            headerName: 'Initiated By',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: textFilterOptions,
                buttons: ['reset', 'apply'],
            },
            editable: true,
        },
        {
            field: 'WsInstalled',
            headerName: 'Installed',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: dateComparator,
                maxNumConditions: 1,
                buttons: ['reset', 'apply'],
            },
            cellRenderer: DateEdit,
            cellRendererParams: {
                min: ['WsArrival'],
                max: ['WsReady'],
            },
        },
        {
            field: 'WsInvitationFrom',
            headerName: 'Invitation From',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: dateComparator,
                maxNumConditions: 1,
                buttons: ['reset', 'apply'],
            },
            cellRenderer: DateEdit,
            cellRendererParams: {
                max: ['WsInvitationTo'],
            },
        },
        {
            field: 'WsInvitationTo',
            headerName: 'Invitation To',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: dateComparator,
                maxNumConditions: 1,
                buttons: ['reset', 'apply'],
            },
            cellRenderer: DateEdit,
            cellRendererParams: {
                min: ['WsInvitationFrom'],
            },
        },
        {
            field: 'WsNotificationTypeId',
            headerName: 'Notification Type',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
                values: notificationTypes,
            },
            editable: true,
            cellEditorPopup: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: [null, ...notificationTypes],
                buttons: ['reset', 'apply'],
            },
        },
        {
            field: 'Classification',
            headerName: 'Vehicle Classification',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
                values: classificationTypes,
                formatValue: (value: IVehicleClassification) =>
                    value?.title || value,
            },
            editable: true,
            cellRenderer: params => {
                const classification = classificationTypes.find(
                    i =>
                        i.id === params.value ||
                        i.title === params.value ||
                        i.title === params.value?.title
                )
                return classification?.title ?? null
            },
            cellEditorPopup: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: [
                    null,
                    ...classificationTypes.map(
                        classification => classification.title
                    ),
                ],
                buttons: ['reset', 'apply'],
            },
        },
        {
            field: 'WsReady',
            headerName: 'Ready',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: dateComparator,
                maxNumConditions: 1,
                buttons: ['reset', 'apply'],
            },
            cellRenderer: DateEdit,
            cellRendererParams: {
                min: ['WsReady', 'WsInstalled'],
            },
        },
        {
            field: 'WsReturnedToDriver',
            headerName: 'Returned To Driver',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: dateComparator,
                maxNumConditions: 1,
                buttons: ['reset', 'apply'],
            },
            cellRenderer: DateEdit,
        },
        {
            field: 'WsVisitTypeId',
            headerName: 'Visit Type',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
                values: visitTypes,
            },
            editable: true,
            cellEditorPopup: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: [null, ...visitTypes],
                buttons: ['reset', 'apply'],
            },
        },
        {
            cellRenderer: CreateWorkshopVisit,
            maxWidth: 80,
            filter: false,
            pinned: 'right',
            lockPinned: true,
            lockPosition: true,
            suppressColumnsToolPanel: true,
            suppressMenu: true,
            sortable: false,
        },
    ]

    const formatString = (value: string) =>
        value
            .split(',')
            .map(s => `'${s.trim()}'`)
            // eslint-disable-next-line quotes
            .filter(s => s !== "''")
            .join(',')

    useEffect(() => {
        if (searchParams.has('search') && gridRef.current) {
            customQueryFilter.current = `RegistrationNo in (${formatString(
                searchParams.get('search')
            )})`

            gridRef.current?.api.refreshServerSide()
        } else if (customQueryFilter.current !== '' && gridRef.current) {
            customQueryFilter.current = ''
            gridRef.current?.api.refreshServerSide()
        }
    }, [searchParams])

    const onGridReady = ({ api }: AgGridReactProps) => {
        setGridRef(gridRef)

        api.setServerSideDatasource(
            new WorkshopOdataProvider({
                callApi: (query: string) =>
                    getOdataApi<IExtendedWorkshop[]>(
                        `odata/WorkshopViews${query}`
                    ).then(response => {
                        dispatch(
                            setLastOdataQuery(`odata/WorkshopViews${query}`)
                        )
                        return {
                            '@odata.count': response?.data?.value?.length,
                            value: response?.data?.value,
                        }
                    }),
                customFilters: {
                    ...getBooleanCustomFilters(['WsWaiting']),
                    ...getListCustomFilters([
                        'WsNotificationTypeId',
                        'WsVisitTypeId',
                    ]),
                    ...getGuidCustomFilters(['VehicleId']),
                    RegistrationNo: (
                        _colName: string,
                        col: { value: string },
                        _isCaseSensitive: boolean,
                        provider: WorkshopOdataProvider
                    ) =>
                        col.value
                            ? provider.odataOperator.inWithNullAndUrlEncode(
                                  'RegistrationNo',
                                  col.value?.split(',').map(s => s.trim())
                              )
                            : false,
                },
                beforeRequest: query => {
                    query.filter = [
                        `TestpurposeId eq ${id}`,
                        'Deleted eq false',
                        customQueryFilter.current,
                    ].filter(Boolean)
                },
            })
        )
    }

    const onCellValueChanged = async (
        event: CellEditRequestEvent<IWorkshop>
    ) => {
        if (event.newValue == event.oldValue) {
            return
        }

        if (event.colDef.field == 'WsReturnedToDriver') {
            await updateWorkshopVisit({
                id: event.data.Id,
                field: 'WsWaiting',
                value: false,
            })
        }

        updateWorkshopVisit({
            id: event.data.Id,
            field: event.colDef.field,
            value: event.value,
        }).finally(() => {
            if (event.colDef.field === 'WsReturnedToDriver') {
                event.api.refreshServerSide()
            }
        })
    }

    if (visitTypes.length === 0) {
        return <Loader text='Loading visit types...' />
    }

    return (
        <AgGridContainer>
            <AgGridReact
                className='cy-vehicle-workshop-grid'
                columnDefs={columnDef}
                components={{ agGuidFilter: CustomGuidFilter }}
                defaultColDef={defaultColDef}
                enableRangeSelection
                getContextMenuItems={getContextMenuItems}
                onCellValueChanged={onCellValueChanged}
                onGridReady={onGridReady}
                ref={gridRef}
                rowModelType='serverSide'
                rowSelection='multiple'
                sideBar={sideBar}
                statusBar={statusBar}
            />
        </AgGridContainer>
    )
}

export default WorkshopGrid
