import { apiSlice } from './apiSlice'
import { lowerCaseKeys } from 'helpers/objectManipulation'

const driversApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDriverEscalations: builder.query<IDriverEscalations, string>({
            query: actorId => `api/DriverEscalations/${actorId}/latest`,
        }),

        getActors: builder.query<IActor[], string | void>({
            query: (query = '') => `/odata/Actors${query}`,
            transformResponse: (response: IODataResponse<IActorOdata[]>) =>
                lowerCaseKeys(response.value) as unknown as IActor[],
        }),
    }),
})

export const {
    useGetDriverEscalationsQuery,

    useGetActorsQuery,
} = driversApi
