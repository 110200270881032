import {
    Button,
    Popover,
    PopoverSurface,
    PopoverTrigger,
} from '@fluentui/react-components'
import {
    ColDef,
    FilterChangedEvent,
    GridReadyEvent,
    NewValueParams,
} from 'ag-grid-community'
import { dateComparator, dateRenderWithTime } from 'helpers/dateHelpers'
import {
    defaultColDef,
    getContextMenuItems,
    sideBar,
    statusBar,
} from 'helpers/gridHelper'
import { useEffect, useMemo, useRef } from 'react'
import {
    useGetFactDriverStudyAssignmentsQuery,
    usePatchFactDriverStudyAssignmentsMutation,
} from 'api/driverStudyParticipation'

import AgGridContainer from 'components/Ui/Layout/AgGridContainer'
import { AgGridReact } from 'ag-grid-react'
import CustomTextListFilter from 'components/AgGrid/Filters/CustomTextListFilter'
import DriverActionsColumn from 'components/DriverStudy/DriverActionsColumn'
import EscalationStatus from 'components/Escalations/EscalationStatus'
import Loader from 'components/Ui/Loader'
import { Warning24Regular } from '@fluentui/react-icons'
import { setLastOdataQuery } from 'slices/gridSlice'
import { useAppDispatch } from 'store'
import { useGrid } from 'contexts/GridContext'
import useGridFilterUrl from 'hooks/useGridFilterUrl'
import { vehicleGridRowClassRules } from 'helpers/vehicleGridHelper'

interface IStudyParticipationGridProps {
    driverStudy: IDriverStudy
    disabled?: boolean
}

const StudyParticipationGrid = ({
    driverStudy,
    disabled,
}: IStudyParticipationGridProps) => {
    const gridRef = useRef()
    const dispatch = useAppDispatch()

    const [pushFiltersToHistory, setFiltersFromHistory] = useGridFilterUrl()
    const { setGridRef } = useGrid()

    const { data: driverStudyAssignments, isLoading } =
        useGetFactDriverStudyAssignmentsQuery(driverStudy.id)
    const rowData = useMemo(
        () =>
            driverStudyAssignments?.value.map(f => ({
                ...f,
                Cdsid: f.Vehicle.BridgeActors[0]?.Actor.Cdsid,
                FirstName: f.Vehicle.BridgeActors[0]?.Actor.FirstName,
                LastName: f.Vehicle.BridgeActors[0]?.Actor.LastName,
                Phone: f.Vehicle.BridgeActors[0]?.Actor.Phone,
                PreferredEmail: f.Vehicle.BridgeActors[0]?.Actor.PreferredEmail,
                Model: f.Vehicle.Model,
                CarUsage: f.Vehicle.VehicleUsageTypeId,
                DeliveryCountry: f.Vehicle.DeliveryCountry,
                Tags: f.Reflect_BridgeSpreadTags.map(
                    x => x.Reflect_SpreadTag.Name
                ).join(', '),
            })),
        [driverStudyAssignments]
    )

    useEffect(() => {
        dispatch(
            setLastOdataQuery(
                // eslint-disable-next-line max-len
                `/odata/FactDriverStudyAssignments?$filter=spreadId eq ${driverStudy.id} &expand=Vehicle(expand=BridgeActors/Actor($select=FirstName,LastName,Cdsid,Phone,PreferredEmail), Reflect_FactSpreadAssignments(filter=Reflect_Spread/Status in ('Ongoing', 'Planned') and SpreadId ne ${driverStudy.id};expand=Reflect_Spread(select=Title))), Reflect_BridgeSpreadTags(expand=Reflect_SpreadTag), Actor(expand=Reflect_FactSpreadAssignments(filter=Reflect_Spread/Status in ('Ongoing', 'Planned') and SpreadId ne ${driverStudy.id};expand=Reflect_Spread(select=Title)))`
            )
        )
    }, [dispatch, driverStudy.id])

    const [patchFactDriverStudyAssignments] =
        usePatchFactDriverStudyAssignmentsMutation()

    const warningDisplayer = (messageContent: JSX.Element) => (
        <Popover
            appearance='brand'
            openOnHover={true}
            withArrow={true}
            mouseLeaveDelay={0}
        >
            <PopoverTrigger disableButtonEnhancement>
                <Button
                    appearance='transparent'
                    icon={<Warning24Regular primaryFill='red' />}
                />
            </PopoverTrigger>
            <PopoverSurface>{messageContent}</PopoverSurface>
        </Popover>
    )

    const columnDefs: ColDef[] = useMemo(
        () => [
            {
                colId: 'checkBoxSelect',
                checkboxSelection: true,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                lockPosition: true,
                lockPinned: true,
                pinned: 'left',
                suppressMenu: true,
                width: 55,
                suppressColumnsToolPanel: true,
                filter: false,
            },
            {
                cellRenderer: DriverActionsColumn,
                filter: false,
                width: 110,
                suppressColumnsToolPanel: true,
                cellRendererParams: {
                    disabled: disabled,
                },
            },
            {
                field: 'Participation',
                filterParams: {
                    values: ['true', 'false'],
                },
            },
            {
                field: 'ParticipationDate',
                filter: 'agDateColumnFilter',
                cellRenderer: dateRenderWithTime,
                filterParams: {
                    comparator: dateComparator,
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                field: 'ActorId',
                filter: CustomTextListFilter,
                cellRenderer: data => {
                    const otherStudies =
                        data.data.Actor?.Reflect_FactSpreadAssignments?.map(
                            x => x.Reflect_Spread.Title
                        )?.map(x => `'${x}'`) ?? []

                    return (
                        <span>
                            {otherStudies.length > 0 &&
                                warningDisplayer(
                                    <span>
                                        This actor is already in Driver Study
                                        <br />
                                        {[...new Set(otherStudies)].join(', ')}.
                                        Make sure that
                                        <br />
                                        these studies will not affect each
                                        other.
                                    </span>
                                )}
                            {data.data.ActorId}
                        </span>
                    )
                },
            },
            {
                field: 'EscalationStatus',
                filter: false,
                cellRenderer: EscalationStatus,
                cellRendererParams: {
                    id: driverStudy?.id,
                    type: 'DriverStudy',
                },
            },
            {
                field: 'VehicleId',
                filter: CustomTextListFilter,
                cellRenderer: data => {
                    const otherStudies =
                        data.data.Vehicle?.Reflect_FactSpreadAssignments?.map(
                            x => x.Reflect_Spread.Title
                        )?.map(x => `'${x}'`) ?? []

                    return (
                        <span>
                            {otherStudies.length > 0 &&
                                warningDisplayer(
                                    <span>
                                        This vehicle is already in Driver Study
                                        <br />
                                        {[...new Set(otherStudies)].join(', ')}.
                                        Make sure that
                                        <br />
                                        these studies will not affect each
                                        other.
                                    </span>
                                )}
                            {data.data.VehicleId}
                        </span>
                    )
                },
            },
            {
                field: 'Comments',
                filter: false,
                editable: true,
            },
            {
                field: 'Cdsid',
                filter: CustomTextListFilter,
            },
            {
                field: 'FirstName',
                filter: CustomTextListFilter,
            },
            {
                field: 'LastName',
                filter: CustomTextListFilter,
            },
            {
                field: 'Phone',
                filter: CustomTextListFilter,
            },
            {
                field: 'PreferredEmail',
                filter: CustomTextListFilter,
            },
            {
                field: 'Model',
                filter: CustomTextListFilter,
            },
            {
                field: 'CarUsage',
                filter: CustomTextListFilter,
            },
            {
                field: 'DeliveryCountry',
                filter: CustomTextListFilter,
            },
            {
                field: 'Tags',
                filter: 'agTextColumnFilter',
                hide: true, //we'll hide this column for the time being, but why and when will we show it?
            },
        ],
        [disabled, driverStudy]
    )

    const onGridReady = (params: GridReadyEvent) => {
        setFiltersFromHistory(params.api)
        setGridRef(gridRef)
    }

    const onFilterChanged = (event: FilterChangedEvent) => {
        pushFiltersToHistory(event.api)
    }

    const onCellValueChanged = async ({
        colDef,
        data,
        newValue,
    }: NewValueParams<IFactDriverStudyAssignment>) => {
        await patchFactDriverStudyAssignments({
            id: data.Id,
            changeSets: [
                {
                    operation: 'Add',
                    path: colDef.field as keyof IFactDriverStudyAssignment,
                    value: newValue,
                },
            ],
        })
    }

    if (isLoading) {
        return <Loader text='Loading driver study...' />
    }

    return (
        <AgGridContainer>
            <AgGridReact<IFactDriverStudyAssignment>
                className='cy-driver-study-grid'
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                enableRangeSelection
                getContextMenuItems={getContextMenuItems}
                onCellValueChanged={onCellValueChanged}
                onFilterChanged={onFilterChanged}
                onGridReady={onGridReady}
                ref={gridRef}
                rowClassRules={vehicleGridRowClassRules}
                rowData={rowData}
                rowSelection='multiple'
                sideBar={sideBar}
                statusBar={statusBar}
            />
        </AgGridContainer>
    )
}

export default StudyParticipationGrid
