import Base from 'components/ApiDropdowns/Base'

interface IApiDropdownProps {
    disabled?: boolean
    selectedItem: string
    setSelectedItem: (value: string) => void
}

const MarketCodes = ({
    selectedItem: selectedItem,
    setSelectedItem: setSelectedItem,
    disabled,
}: IApiDropdownProps) => (
    <Base
        label='Market codes'
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        apiListName='MarketCodes'
        disabled={disabled}
    />
)

export default MarketCodes
