import { Button, Field, Input, makeStyles } from '@fluentui/react-components'
import {
    ClipboardPaste32Regular,
    Clock32Regular,
    Dismiss24Regular,
} from '@fluentui/react-icons'

const useStyles = makeStyles({
    inputs: {
        display: 'flex',
        flexDirection: 'row',
        gridGap: '4px',
        width: '100%',
    },
    dateTime: {
        width: '100%',
    },
})

interface IDateTimePickerProps {
    value?: Date | string
    onChange: (date: string) => void
    label: string
    disabled?: boolean
    max?: Date | string
    min?: Date | string
    copyFromClipboard?: boolean
    nowTime?: boolean
    clear?: boolean
    validationMessage?: string
}

// Lots of try/catch blocks in this component. This is because the creating a new Date()
// can throw an error if the date is not valid and we want to catch that error and not crash
// the app every time the user types in an invalid date.

const DateTimePicker = ({
    value,
    onChange,
    label,
    disabled,
    max,
    min,
    copyFromClipboard,
    nowTime,
    clear,
    validationMessage,
}: IDateTimePickerProps) => {
    const classes = useStyles()

    // Try to convert the date to a string that the input can understand
    // ex. 2021-08-31T12:00
    let date, maxDate, minDate
    try {
        date = value && new Date(value).toISOString().substring(0, 16)
        maxDate = max && new Date(max).toISOString().substring(0, 16)
        minDate = min && new Date(min).toISOString().substring(0, 16)
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
    }

    const handlePaste = async () => {
        try {
            const paste = await window.navigator.clipboard.readText()
            const time = new Date(paste)
            // add timezone offset so that when we convert to UTC it doesn't change the time
            // might be a better way to do this... but this works
            time.setMinutes(time.getMinutes() - time.getTimezoneOffset())
            onChange(time.toISOString())
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }

    return (
        <Field
            data-cy='date-time-picker'
            label={label}
            hint='Time is in UTC'
            validationMessage={validationMessage}
        >
            <div className={classes.inputs}>
                <Input
                    type='datetime-local'
                    value={value ? date : ''}
                    onChange={(_e, target) => {
                        if (!target.value) {
                            onChange(null)
                        } else {
                            try {
                                const time = new Date(target.value + 'Z')
                                onChange(time.toISOString())
                            } catch (error) {
                                // eslint-disable-next-line no-console
                                console.error(error)
                            }
                        }
                    }}
                    max={maxDate}
                    min={minDate}
                    size='large'
                    className={classes.dateTime}
                    disabled={disabled}
                />
                {copyFromClipboard && (
                    <Button
                        appearance='transparent'
                        icon={<ClipboardPaste32Regular />}
                        size='large'
                        title='Paste time from clipboard (assumes time is in UTC)'
                        onClick={handlePaste}
                        disabled={disabled}
                    />
                )}

                {nowTime && (
                    <Button
                        appearance='transparent'
                        icon={<Clock32Regular />}
                        size='large'
                        title='Set date-time to now'
                        onClick={() => onChange(new Date().toISOString())}
                        disabled={disabled}
                    />
                )}

                {clear && (
                    <Button
                        appearance='transparent'
                        icon={<Dismiss24Regular />}
                        size='large'
                        title='Clear date-time'
                        onClick={() => onChange(null)}
                        disabled={disabled}
                    />
                )}
            </div>
        </Field>
    )
}

export default DateTimePicker
