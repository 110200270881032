import 'react-quill/dist/quill.snow.css'

import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    Field,
    Input,
    Text,
    makeStyles,
} from '@fluentui/react-components'
import { useAppDispatch, useAppSelector } from 'store'
import { useEffect, useState } from 'react'

import Loader from 'components/Ui/Loader'
import ModalStatusEnum from 'constants/ModalStatusEnum'
import ReactQuill from 'react-quill'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import { selectLastOdataQuery } from 'slices/gridSlice'
import { triggerMessage } from 'slices/messageSlice'
import { useGrid } from 'contexts/GridContext'
import { useSendEmailMutation } from 'api/messaging'

interface IEmailActionsProps {
    toggleOpen: () => void
    modalStatus: ModalStatusEnum
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gridGap: '8px',
    },
})

const EmailActions = ({ toggleOpen, modalStatus }: IEmailActionsProps) => {
    const [subject, setSubject] = useState('')
    const [body, setBody] = useState('')
    const classes = useStyles()

    const [sendEmail] = useSendEmailMutation()
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)

    const { gridRef } = useGrid()
    const selectedActorIds =
        gridRef.current?.api
            .getSelectedRows()
            .map(sr => sr.ActorId)
            .filter(id => !!id) || []

    const [filteredActorIds, setFilteredActorIds] = useState<string[]>([])
    const lastODataQuery = useAppSelector(selectLastOdataQuery)
    useEffect(() => {
        const collectActorIds = async () => {
            setLoading(true)
            const param = new URLSearchParams(lastODataQuery)
            param.delete('$skip')
            param.delete('$top')
            param.delete('$select')
            param.append('$select', 'ActorId')

            const { data } = await reflectApiAxiosInstance.get(
                decodeURIComponent(param.toString())
            )

            setFilteredActorIds(data.value.map(({ ActorId }) => ActorId))

            setLoading(false)
        }

        if (
            lastODataQuery &&
            modalStatus === ModalStatusEnum.Filtered &&
            filteredActorIds.length === 0
        ) {
            collectActorIds()
        }
    }, [filteredActorIds.length, lastODataQuery, loading, modalStatus])

    const driversCount =
        modalStatus === ModalStatusEnum.Selected
            ? selectedActorIds.length
            : filteredActorIds.length

    const onSend = async () => {
        const response = await sendEmail({
            actorIds:
                modalStatus === ModalStatusEnum.Selected
                    ? selectedActorIds
                    : filteredActorIds,
            subject: subject,
            message: body,
            isHtml: true,
        })

        if ('data' in response) {
            const status = response.data.isSuccessStatusCode
            if (!status) {
                dispatch(
                    triggerMessage({
                        intent: 'error',
                        title: 'Error sending email',
                        message:
                            'Something went wrong while sending email, please try again.',
                    })
                )
            } else {
                setSubject('')
                setBody('')
                dispatch(
                    triggerMessage({
                        intent: 'success',
                        title: 'Email sent',
                        message: 'Email has been sent successfully.',
                    })
                )
                toggleOpen()
            }
        }
    }

    return (
        <>
            <DialogContent>
                {loading ? (
                    <Loader text='Fetching filtered data...' />
                ) : (
                    <div className={classes.container}>
                        <Field label='Subject'>
                            <Input
                                value={subject ?? ''}
                                onChange={(_ev, data) =>
                                    setSubject(data?.value || '')
                                }
                            />
                        </Field>

                        <ReactQuill
                            style={{ height: 150, paddingBottom: 42 }}
                            theme='snow'
                            value={body}
                            onChange={value => setBody(value)}
                        />
                        <Text>Sending Email to {driversCount} drivers.</Text>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    appearance='primary'
                    onClick={onSend}
                    disabled={loading || !subject || driversCount <= 0}
                >
                    Send Email
                </Button>
                <DialogTrigger disableButtonEnhancement>
                    <Button onClick={() => toggleOpen()}>Cancel</Button>
                </DialogTrigger>
            </DialogActions>
        </>
    )
}

export default EmailActions
