import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components'

interface IConfirmNewsProps {
    setIsOpen: (value: boolean) => void
    isOpen: boolean
    callback: () => void
    action?: string
    recipients?: number
    isLoading?: boolean
    countryCount?: number
}

const ConfirmActionDialog = ({
    setIsOpen,
    isOpen,
    callback,
    action,
    recipients,
    isLoading,
    countryCount,
}: IConfirmNewsProps) => {
    return (
        <Dialog open={isOpen} onOpenChange={(_e, d) => setIsOpen(d.open)}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>
                        Are you sure you want to {action} this news item?
                    </DialogTitle>
                    {action === 'publish' &&
                        !countryCount && ( // once we have a vehicleCount for countries we can remove this condition
                            <DialogContent>
                                It will reach {recipients} clients
                            </DialogContent>
                        )}
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button>No</Button>
                        </DialogTrigger>
                        <Button appearance='primary' onClick={callback}>
                            {isLoading ? 'Loading...' : 'Yes'}
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default ConfirmActionDialog
