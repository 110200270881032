import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    makeStyles,
} from '@fluentui/react-components'
import {
    useArchiveDriverStudyMutation,
    useUnArchiveDriverStudyMutation,
} from 'api/driverStudies'

import { Archive48Regular } from '@fluentui/react-icons'
import { ICellRendererParams } from 'ag-grid-community'
import { toast } from 'react-toastify'
import { useState } from 'react'

const useStyles = makeStyles({
    red: {
        color: 'red',
    },
})

const DriverStudyArchiveAction = ({
    data,
}: ICellRendererParams<IDriverStudy>) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles()

    const [archiveDriverStudy] = useArchiveDriverStudyMutation()
    const [unArchiveDriverStudy] = useUnArchiveDriverStudyMutation()

    const archiveFunction = data.isArchived
        ? unArchiveDriverStudy
        : archiveDriverStudy

    const archiveStudy = async () => {
        const response = await archiveFunction(data.id)
        if ('data' in response) {
            toast.success(
                `Driver Study ${data.title} ${
                    data.isArchived ? 'unarchived' : 'archived'
                } successfully!`
            )
        } else {
            toast.error('Something went wrong, please try again.')
        }
        setOpen(false)
    }

    return (
        <Dialog open={open} onOpenChange={(_e, d) => setOpen(d.open)}>
            <DialogTrigger disableButtonEnhancement>
                <Button
                    data-cy='archive-driver-study-button'
                    className={!data.isArchived ? null : classes.red}
                    appearance='transparent'
                    icon={<Archive48Regular />}
                    title={
                        data.isArchived
                            ? 'Unarchive Driver Study'
                            : 'Archive Driver Study'
                    }
                />
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>
                        {data.isArchived ? 'Unarchive' : 'Archive'} Driver
                        Study&nbsp; &quot;{data.title}&quot;
                    </DialogTitle>
                    <DialogContent>
                        Are you sure you want to change the status of this
                        driver study?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            data-cy='archive-driver-study-confirm-button'
                            appearance='primary'
                            onClick={archiveStudy}
                        >
                            {data.isArchived ? 'Unarchive' : 'Archive'}
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button>Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default DriverStudyArchiveAction
