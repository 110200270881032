import Constants from 'constants/constants'

/**
 * Function that will error something to the console if IS_DEBUG_ON is true
 * @param postData data in any format
 */
const consoleErrorData = (postData: unknown) => {
    if (Constants.IS_DEBUG_ON) {
        // eslint-disable-next-line no-console
        console.error(postData)
    }
}

export default consoleErrorData
