import { apiSlice } from './apiSlice'

interface IChangeSet {
    operation: 'Add' | 'Remove' | 'Replace'
    path: keyof IFactDriverStudyAssignment
    value: unknown
}

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: [
        'DriverStudyDriverViews',
        'DriverStudyAssignments',
        'DriverStudyEscalations',
    ],
})

const driverStudyParticipationApi = apiWithTags.injectEndpoints({
    endpoints: builder => ({
        getDriverStudiesForDriver: builder.query<
            IDriverStudiesForDriver[],
            string
        >({
            query: actorId =>
                `/api/DriverStudyDriverViews/GetDriverStudiesForDriver/?actorId=${actorId}`,
            providesTags: ['DriverStudyDriverViews'],
        }),
        setParticipationForDriver: builder.mutation<
            void,
            { participationId: number; onDate: string }
        >({
            query: data => ({
                url: 'api/FactDriverStudyAssignments/SetParticipation',
                method: 'POST',
                body: [data],
            }),
            invalidatesTags: [
                'DriverStudyDriverViews',
                'DriverStudyAssignments',
            ],
        }),
        getFactDriverStudyAssignments: builder.query<
            IODataResponse<IFactDriverStudyAssignment[]>,
            number
        >({
            query: id =>
                // eslint-disable-next-line max-len
                `/odata/FactDriverStudyAssignments?$filter=spreadId eq ${id} &expand=Vehicle(expand=BridgeActors/Actor($select=FirstName,LastName,Cdsid,Phone,PreferredEmail), Reflect_FactSpreadAssignments(filter=Reflect_Spread/Status in ('Ongoing', 'Planned') and SpreadId ne ${id};expand=Reflect_Spread(select=Title))), Reflect_BridgeSpreadTags(expand=Reflect_SpreadTag), Actor(expand=Reflect_FactSpreadAssignments(filter=Reflect_Spread/Status in ('Ongoing', 'Planned') and SpreadId ne ${id};expand=Reflect_Spread(select=Title)))`,
            providesTags: ['DriverStudyAssignments'],
        }),
        addFactDriverStudyAssignmentsBatch: builder.mutation<
            void,
            {
                driverStudyId: number
                items: unknown[]
            }
        >({
            query: body => ({
                url: 'api/FactDriverStudyAssignments/CreateBatch',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['DriverStudyAssignments'],
        }),
        patchFactDriverStudyAssignments: builder.mutation<
            void,
            { id: number; changeSets: IChangeSet[] }
        >({
            query: ({ id, changeSets }) => ({
                url: `api/FactDriverStudyAssignments/${id}`,
                method: 'PATCH',
                body: changeSets,
            }),
            invalidatesTags: [
                'DriverStudyAssignments',
                'DriverStudyEscalations',
            ],
        }),
        deleteFactDriverStudyAssignments: builder.mutation<void, number>({
            query: id => ({
                url: `api/FactDriverStudyAssignments/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['DriverStudyAssignments'],
        }),

        getLatestDriverStudyEscalations: builder.query<
            IDriverStudyEscalation[],
            number
        >({
            query: id => `api/DriverStudyEscalations/${id}/latest`,
            providesTags: ['DriverStudyEscalations'],
        }),
        getDriverStudyEscalationHistory: builder.query<
            IDriverStudyEscalation[],
            { id: number; actorId: string }
        >({
            query: ({ id, actorId }) =>
                `api/DriverStudyEscalations/${id}/${actorId}/history`,
            providesTags: ['DriverStudyEscalations'],
        }),
        postDriverStudyEscalation: builder.mutation<
            IDriverStudyEscalation,
            {
                driverStudyId: number
                actorId: string
                escalationStepId: number
            }
        >({
            query: body => ({
                url: 'api/DriverStudyEscalations/add',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['DriverStudyEscalations'],
        }),
        deleteDriverStudyEscalation: builder.mutation<void, number>({
            query: escalationId => ({
                url: `api/DriverStudyEscalations/${escalationId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['DriverStudyEscalations'],
        }),
    }),
})

export const {
    useGetDriverStudiesForDriverQuery,
    useSetParticipationForDriverMutation,
    useGetFactDriverStudyAssignmentsQuery,
    useAddFactDriverStudyAssignmentsBatchMutation,
    usePatchFactDriverStudyAssignmentsMutation,
    useDeleteFactDriverStudyAssignmentsMutation,

    useGetLatestDriverStudyEscalationsQuery,
    useGetDriverStudyEscalationHistoryQuery,
    usePostDriverStudyEscalationMutation,
    useDeleteDriverStudyEscalationMutation,
} = driverStudyParticipationApi
