import {
    ColDef,
    FilterChangedEvent,
    GridReadyEvent,
    ICellRendererParams,
} from 'ag-grid-community'
import {
    VehicleTestCustomFilters,
    VehicleTestOdataProvider,
} from 'helpers/vehicleTestsGridHelper'
import { dateComparator, dateRenderWithTime } from 'helpers/dateHelpers'
import {
    defaultColDef,
    getContextMenuItems,
    sideBar,
    statusBar,
} from 'helpers/gridHelper'
import { useAppDispatch, useAppSelector } from 'store'
import { useEffect, useMemo, useRef, useState } from 'react'

import AgGridContainer from 'components/Ui/Layout/AgGridContainer'
import { AgGridReact } from 'ag-grid-react'
import ArchiveAction from './ArchiveAction'
import DateRenderer from 'components/AgGrid/CellRenderers/DateRenderer'
import OpenVehicleTestButton from 'components/VehicleTest/OpenVehicleTestButton'
import OpenWorkshopButton from 'components/VehicleTest/OpenWorkshopButton'
import ViraLink from 'components/AgGrid/CellRenderers/ViraLink'
import { getOdataApi } from 'helpers/oDataApiHelper'
import { permissions } from 'slices/authSlice'
import { setLastOdataQuery } from 'slices/gridSlice'
import { useGrid } from 'contexts/GridContext'
import useGridFilterUrl from 'hooks/useGridFilterUrl'
import visitWorkshopCellRenderer from './VehicleTestVisitWorkshopCellRenderer'

interface IVehicleTestsGridProps {
    filterOption: string
}

const VehicleTestsGrid = ({ filterOption }: IVehicleTestsGridProps) => {
    const [dataProvider, setDataProvider] = useState<VehicleTestOdataProvider>()
    const [isGridReady, setIsGridReady] = useState(false)

    const gridRef = useRef<AgGridReact>(null)

    const dispatch = useAppDispatch()
    const { setGridRef } = useGrid()
    const [pushFiltersToHistory, setFiltersFromHistory] = useGridFilterUrl()

    const createViraLink = ({ value }: ICellRendererParams) => (
        <ViraLink viraNumber={value} />
    )

    const {
        vehicleTestsManage: hasVehicleTestManagePermissions,
        workshop: hasWorkshopPermissions,
    } = useAppSelector(permissions)

    const columnDefs: ColDef[] = useMemo(() => {
        return [
            {
                field: 'CreatedOn',
                cellRenderer: DateRenderer,
                headerName: 'Creation Date',
                sort: 'desc',
                filter: 'agDateColumnFilter',
                filterParams: {
                    comparator: dateComparator,
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                field: 'Status',
                headerName: 'Status',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: [
                        'Deleted',
                        'Canceled',
                        'Archived',
                        'Planned',
                        'Active',
                        'Ended',
                        'Undefined',
                    ],
                },
                sortable: true,
            },
            { field: 'Title' },
            { field: 'Description' },
            {
                field: 'Type',
                headerName: 'Type',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: [
                        null,
                        'Test',
                        'REFERENCE',
                        'Software Releases',
                        'Analysis',
                        'Development Test',
                    ],
                },
            },
            {
                field: 'PlannedStart',
                filter: 'agDateColumnFilter',
                filterParams: {
                    comparator: dateComparator,
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
                cellRenderer: dateRenderWithTime,
            },
            {
                field: 'PlannedFinish',
                filter: 'agDateColumnFilter',
                filterParams: {
                    comparator: dateComparator,
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
                cellRenderer: dateRenderWithTime,
            },
            { field: 'Responsible' },
            { field: 'ResponsibleTeam' },
            {
                field: 'ViraLink',
                cellRenderer: createViraLink,
            },
            {
                field: 'RequiresWorkshopVisit',
                cellRenderer: visitWorkshopCellRenderer,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: [false, true],
                    buttons: ['reset', 'apply'],
                },
            },
            {
                field: 'TotalVehiclesInTest',
                headerName: 'Total Vehicles In Test',
                filter: false,
                suppressMenu: true,
            },
            {
                field: 'Createdvehicles',
                headerName: 'Created Vehicles',
                filter: false,
                sortable: false,
                suppressMenu: true,
            },
            {
                field: 'PlannedVehicles',
                headerName: 'Planned Vehicles',
                filter: false,
                sortable: false,
                suppressMenu: true,
            },
            {
                field: 'OngoingVehicles',
                headerName: 'Ongoing Vehicles',
                filter: false,
                sortable: false,
                suppressMenu: true,
            },
            {
                field: 'FinnishedVehicles',
                headerName: 'Finished Vehicles',
                filter: false,
                sortable: false,
                suppressMenu: true,
            },
            hasVehicleTestManagePermissions && {
                cellRenderer: OpenVehicleTestButton,
                maxWidth: 50,
                filter: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: true,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                sortable: false,
            },
            hasWorkshopPermissions && {
                cellRenderer: OpenWorkshopButton,
                maxWidth: 50,
                filter: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: true,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                sortable: false,
            },
            hasVehicleTestManagePermissions && {
                cellRenderer: ArchiveAction,
                maxWidth: 70,
                filter: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: true,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                sortable: false,
            },
        ].filter(Boolean) as ColDef[]
    }, [hasVehicleTestManagePermissions, hasWorkshopPermissions])

    const onGridReady = (params: GridReadyEvent) => {
        setIsGridReady(true)

        const dProvider = new VehicleTestOdataProvider({
            callApi: async options => {
                //Add default sorting if none is set
                const search = new URLSearchParams(options)
                if (!search.has('$orderby')) {
                    search.set('$orderby', 'CreatedOn desc')
                }
                const { data } = await getOdataApi<IVehicleTestOdata[]>(
                    `/odata/VehicleTests?${search.toString()}`
                )
                dispatch(setLastOdataQuery(`/odata/VehicleTests${options}`))
                return data
            },
            customFilters: VehicleTestCustomFilters,
            isCaseSensitiveStringFilter: false,
        })

        setDataProvider(dProvider)
        params.api.setServerSideDatasource(dProvider)

        setGridRef(gridRef)
        setFiltersFromHistory(params.api)
    }

    const onFilterChanged = (event: FilterChangedEvent) => {
        pushFiltersToHistory(event.api)
    }

    useEffect(() => {
        if (isGridReady && gridRef.current && dataProvider) {
            dataProvider.beforeRequest = query => {
                switch (filterOption) {
                    case 'Unarchived':
                        query.filter = ['Archived eq false']
                        break
                    case 'Archived':
                        query.filter = ['Archived eq true']
                        break
                    default:
                        break
                }
            }
            gridRef.current?.api.refreshServerSide()
        }
    }, [dataProvider, isGridReady, filterOption])

    return (
        <AgGridContainer>
            <AgGridReact
                cacheBlockSize={100}
                className='cy-vehicle-tests-grid'
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                enableRangeSelection
                getContextMenuItems={getContextMenuItems}
                onFilterChanged={onFilterChanged}
                onGridReady={onGridReady}
                ref={gridRef}
                rowModelType='serverSide'
                sideBar={sideBar}
                statusBar={statusBar}
            />
        </AgGridContainer>
    )
}

export default VehicleTestsGrid
