import { Field, Select } from '@fluentui/react-components'

import ActionsMenu from 'components/Ui/Layout/ActionsMenu'
import { Beaker24Regular } from '@fluentui/react-icons'
import Content from 'components/Ui/Layout/Content'
import CreateVehicleTestDialog from 'components/VehicleTest/CreateVehicleTestDialog'
import Header from 'components/Ui/Layout/Header'
import VehicleTestsGrid from 'components/VehicleTest/VehicleTestsGrid'
import { useState } from 'react'

const VehicleTestsIndex = () => {
    const [filterOption, setFilterOption] = useState('Unarchived')

    return (
        <>
            <Header
                title='Vehicle Tests'
                icon={<Beaker24Regular />}
                personalViewType='TestPurpose'
            />
            <ActionsMenu
                items={[
                    {
                        restricted: 'vehicleTestsManage',
                        component: <CreateVehicleTestDialog />,
                    },
                ]}
                extraComponents={
                    <Field
                        label='Show only'
                        orientation='horizontal'
                        style={{ minWidth: '270px', alignItems: 'center' }}
                    >
                        <Select
                            data-cy='archive-select'
                            value={filterOption}
                            onChange={(_e, data) => setFilterOption(data.value)}
                        >
                            <option value='Unarchived'>Unarchived</option>
                            <option value='Archived'>Archived</option>
                            <option value='showAll'>Show All</option>
                        </Select>
                    </Field>
                }
            />

            <Content>
                <VehicleTestsGrid filterOption={filterOption} />
            </Content>
        </>
    )
}

export default VehicleTestsIndex
