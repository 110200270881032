import { apiSlice } from './apiSlice'

const schemasApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSuperVehicleSchema: builder.query<SearchViewColumn[], void>({
            query: () => 'api/SuperVehicleViews/schema',
        }),
        getCodevVehicleViewsSchema: builder.query<SearchViewColumn[], void>({
            query: () => 'api/CodevVehicleViews/schema',
        }),
        getOtaVehicleSchema: builder.query<SearchViewColumn[], void>({
            query: () => 'api/OTAVehicleViews/schema',
        }),
        getReadVehicleSchema: builder.query<SearchViewColumn[], void>({
            query: () => 'api/ReadVehicleViews/schema',
        }),
        getChinaVehicleSchema: builder.query<SearchViewColumn[], void>({
            query: () => 'api/ChinaVehicleViews/schema',
        }),
        getAmericaVehicleSchema: builder.query<SearchViewColumn[], void>({
            query: () => 'api/AmericaVehicleViews/schema',
        }),
    }),
})

export const {
    useGetSuperVehicleSchemaQuery,
    useGetCodevVehicleViewsSchemaQuery,
    useGetOtaVehicleSchemaQuery,
    useGetReadVehicleSchemaQuery,
    useGetChinaVehicleSchemaQuery,
    useGetAmericaVehicleSchemaQuery,
} = schemasApi
