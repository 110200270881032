import { Dispatch, SetStateAction } from 'react'

import { Button } from '@fluentui/react-components'
import { ICellRendererParams } from 'ag-grid-community'

export type DeploymentJobActionButtonProps = ICellRendererParams & {
    deploymentJobUuId: string
    deploymentJobName: string
    icon: string
    label: string
    dispatchAction: Dispatch<
        SetStateAction<
            | {
                  name: string
                  id: string
              }
            | undefined
        >
    >
    rootGroup: boolean
}

const DeploymentJobActionButton = ({
    deploymentJobUuId,
    deploymentJobName,
    icon,
    label,
    dispatchAction,
    rootGroup,
}: DeploymentJobActionButtonProps) => {
    return rootGroup ? (
        <Button
            icon={icon}
            appearance='transparent'
            title={label}
            onClick={() =>
                dispatchAction({
                    id: deploymentJobUuId,
                    name: deploymentJobName,
                })
            }
        />
    ) : null
}

export default DeploymentJobActionButton
