import AgGridContainer from 'components/Ui/Layout/AgGridContainer'
import Content from 'components/Ui/Layout/Content'
import Header from 'components/Ui/Layout/Header'
import MainGridActions from 'components/Search/Header/MainGridActions'
import ResultGrid from 'components/Search/ResultGrid'
import { VehicleCar24Regular } from '@fluentui/react-icons'

const Home = () => {
    return (
        <>
            <Header
                title='Vehicles'
                personalViewType='Reflect'
                icon={<VehicleCar24Regular />}
                isMainGrid={true}
            />

            <MainGridActions />
            <Content>
                <AgGridContainer>
                    <ResultGrid />
                </AgGridContainer>
            </Content>
        </>
    )
}

export default Home
