import { Link, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { permissions, selectUser } from 'slices/authSlice'

import DriverStudies from 'pages/DriverStudies'
import DriverStudiesIndex from 'pages/DriverStudiesIndex'
import DriverStudy from 'pages/DriverStudy'
import Drivers from 'pages/Drivers'
import Home from 'pages/Home'
import Layout from 'pages/Layout'
import NoMatch from 'pages/NoMatch'
import NoViews from 'pages/NoViews'
import ROUTES from 'constants/routes'
import RolePicker from 'pages/RolePicker'
import VehicleClassification from 'pages/VehicleClassification'
import VehicleTest from 'pages/VehicleTest'
import VehicleTestIndex from 'pages/VehicleTestIndex'
import VehicleTests from 'pages/VehicleTests'
import VehicleTestsIndex from 'pages/VehicleTestsIndex'
import Workshop from 'pages/Workshop'
import WorkshopIndex from 'pages/WorkshopIndex'
import WorkshopView from 'pages/WorkshopVisit'
import { useAppSelector } from 'store'
import PortalNews from 'pages/PortalNews'
import PortalNewsIndex from 'pages/PortalNewsIndex'

const Router = () => {
    const user = useAppSelector(selectUser)
    const permissionsCollection = useAppSelector(permissions)
    const router = createBrowserRouter([
        {
            path: '/',
            element: user?.view?.length > 0 ? <Layout /> : <NoViews />,
            handle: {
                crumb: () => <Link to={'/'}>Home</Link>,
            },
            children: [
                {
                    index: true,
                    element: <Home />,
                },
                permissionsCollection.vehicleClassification && {
                    path: ROUTES.VEHICLE_CLASSIFICATIONS,
                    element: <VehicleClassification />,
                    handle: {
                        crumb: () => (
                            <Link to={ROUTES.VEHICLE_CLASSIFICATIONS}>
                                Vehicle Classification
                            </Link>
                        ),
                    },
                },
                permissionsCollection.driverStudiesDriver && {
                    path: ROUTES.DRIVERS,
                    element: <Drivers />,
                    handle: {
                        crumb: () => (
                            <Link to={ROUTES.DRIVERS}>Driver Details</Link>
                        ),
                    },
                },
                permissionsCollection.driverStudies && {
                    path: ROUTES.DRIVER_STUDIES,
                    element: <DriverStudies />,
                    handle: {
                        crumb: () => (
                            <Link to={`/${ROUTES.DRIVER_STUDIES}`}>
                                Driver Studies
                            </Link>
                        ),
                    },
                    children: [
                        {
                            index: true,
                            element: <DriverStudiesIndex />,
                        },
                        {
                            path: ':id',
                            element: <DriverStudy />,
                            handle: {
                                crumb: () => <Link to='..'>Driver Study</Link>,
                            },
                        },
                    ],
                },
                permissionsCollection.vehicleTests && {
                    path: ROUTES.VEHICLE_TESTS,
                    element: <VehicleTests />,
                    handle: {
                        crumb: () => (
                            <Link to={`/${ROUTES.VEHICLE_TESTS}`}>
                                Vehicle Tests
                            </Link>
                        ),
                    },
                    children: [
                        {
                            index: true,
                            element: <VehicleTestsIndex />,
                        },
                        {
                            path: ':id',
                            element: <VehicleTest />,
                            children: [
                                {
                                    index: true,
                                    element: <VehicleTestIndex />,
                                    handle: {
                                        crumb: () => (
                                            <Link
                                                to={`/${ROUTES.VEHICLE_TESTS}/:id`}
                                            >
                                                Vehicle Test
                                            </Link>
                                        ),
                                    },
                                },
                                {
                                    path: ROUTES.WORKSHOP,
                                    element: <Workshop />,
                                    handle: {
                                        crumb: () => (
                                            <Link to='..'>Vehicle Test</Link>
                                        ),
                                    },
                                    children: [
                                        {
                                            index: true,
                                            element: <WorkshopIndex />,
                                            handle: {
                                                crumb: () => (
                                                    <Link to='..'>
                                                        Workshop
                                                    </Link>
                                                ),
                                            },
                                        },
                                        {
                                            path: 'view',
                                            element: <WorkshopView />,
                                            handle: {
                                                crumb: () => (
                                                    <Link to='..'>
                                                        Workshop
                                                    </Link>
                                                ),
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                (permissionsCollection.rivendellEdit ||
                    permissionsCollection.rivendellRead) && {
                    path: ROUTES.PORTAL_NEWS,
                    element: <PortalNews />,
                    handle: {
                        crumb: () => (
                            <Link to={`/${ROUTES.PORTAL_NEWS}`}>
                                Portal News
                            </Link>
                        ),
                    },
                    children: [
                        {
                            index: true,
                            element: <PortalNewsIndex />,
                        },
                    ],
                },
                {
                    path: ROUTES.ROLE_PICKER,
                    element: <RolePicker />,
                    handle: {
                        crumb: () => (
                            <Link to={ROUTES.ROLE_PICKER}>Role picker</Link>
                        ),
                    },
                },
                {
                    path: '*',
                    element: <NoMatch />,
                },
            ],
        },
    ])
    return <RouterProvider router={router} />
}

export default Router
