import {
    Button,
    Combobox,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Dropdown,
    Field,
    Option,
    makeStyles,
    mergeClasses,
} from '@fluentui/react-components'

import { DatePicker } from '@fluentui/react-datepicker-compat'
import PreviewPublishNews from './PreviewPublishNews'
import { ShareAndroidRegular } from '@fluentui/react-icons'
import { useGetMarketsQuery } from 'api/portalNews'
import { useGetVehicleGroupListsQuery } from 'api/vehicleGroupList'
import { useState } from 'react'

const useStyles = makeStyles({
    title: {
        marginBottom: '1rem',
    },
    content: {
        display: 'flex',
        marginBottom: '1rem',
    },
    dropdown: {
        flexBasis: '80%',
    },
    spacing: {
        marginLeft: '10px',
    },
})

const PublishNewsDialog = ({ id, data }: { id: number; data: IPortalNews }) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(-1)
    const [publishDate, setPublishDate] = useState(undefined)
    const [unPublishDate, setUnpublishDate] = useState(undefined)
    const [selectedCountries, setSelectedCountries] = useState([])

    const [selectedNewsLists, setSelectedNewsLists] = useState([])
    const [isAllListsSelected, setIsAllListsSelected] = useState(false)
    const [isAllCountriesSelected, setIsAllCountriesSelected] = useState(false)

    const [searchTerm, setSearchTerm] = useState('')
    const [inputValue, setInputValue] = useState('')

    const { data: newsList } = useGetVehicleGroupListsQuery('NewsList')
    const { data: countries } = useGetMarketsQuery()

    const filteredNewsList = newsList?.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    const selectedOptionIds = selectedNewsLists?.map(item => item.id)

    const handleOptionSelect = (e, optionValue, type) => {
        if (type === 'country') {
            if (optionValue === 'Select All') {
                if (selectedCountries.length === countries.length) {
                    setSelectedCountries([])
                    setIsAllCountriesSelected(false)
                } else {
                    setIsAllCountriesSelected(true)
                    setSelectedCountries(countries)
                }
            } else {
                setSelectedCountries(prevSelectedCountries => {
                    if (prevSelectedCountries.includes(optionValue)) {
                        return prevSelectedCountries.filter(
                            country => country !== optionValue
                        )
                    } else {
                        return [...prevSelectedCountries, optionValue]
                    }
                })
            }
        } else if (type === 'newsList') {
            const selectedOption = newsList.find(
                item => item.id === optionValue
            )
            let updatedSelectedNewsLists = [...selectedNewsLists]

            if (selectedNewsLists.some(item => item.id === optionValue)) {
                updatedSelectedNewsLists = updatedSelectedNewsLists.filter(
                    item => item.id !== optionValue
                )
            } else {
                updatedSelectedNewsLists = [
                    ...updatedSelectedNewsLists,
                    selectedOption,
                ]
            }

            setSelectedNewsLists(updatedSelectedNewsLists)
            setInputValue(
                updatedSelectedNewsLists?.map(item => item.name).join(', ')
            )
            setIsAllListsSelected(
                updatedSelectedNewsLists.length === newsList.length
            )
            setSearchTerm('')
        }
    }

    const handleSelectAll = () => {
        if (isAllListsSelected) {
            setSelectedNewsLists([])
            setIsAllListsSelected(false)
            setInputValue('')
            setSearchTerm('')
        } else {
            setSelectedNewsLists(newsList)
            setIsAllListsSelected(true)
            setInputValue(newsList?.map(n => n.name).join(', '))
            setSearchTerm('')
        }
    }

    return (
        <Dialog
            modalType='alert'
            open={open}
            onOpenChange={(_e, d) => setOpen(d.open)}
        >
            <DialogTrigger>
                <Button
                    appearance='subtle'
                    icon={<ShareAndroidRegular />}
                    title='Publish News Item'
                    size='small'
                    onClick={() => setSelected(id)}
                />
            </DialogTrigger>

            <DialogSurface>
                <DialogBody>
                    <DialogTitle className={classes.title}>
                        Publish News Item
                    </DialogTitle>
                    <DialogContent>
                        <div className={classes.content}>
                            <Dropdown
                                className={classes.dropdown}
                                onOptionSelect={(e, d) =>
                                    handleOptionSelect(
                                        e,
                                        d.optionValue,
                                        'country'
                                    )
                                }
                                multiselect
                                placeholder='Select Country'
                                value={selectedCountries?.toString()}
                                disabled={!!selectedNewsLists.length}
                                selectedOptions={selectedCountries}
                            >
                                <Option key='select-all' value='Select All'>
                                    {isAllCountriesSelected
                                        ? 'Deselect All'
                                        : 'Select All'}
                                </Option>
                                {countries?.map(c => (
                                    <Option key={c} value={c}>
                                        {c}
                                    </Option>
                                ))}
                            </Dropdown>
                            <DatePicker
                                className={mergeClasses(
                                    classes.dropdown,
                                    classes.spacing
                                )}
                                onSelectDate={e => setPublishDate(e)}
                                showWeekNumbers
                                placeholder='Publish Date'
                                value={
                                    publishDate
                                        ? new Date(publishDate)
                                        : undefined
                                }
                            />
                        </div>

                        <div className={classes.content}>
                            <Field className={classes.dropdown}>
                                <Combobox
                                    value={searchTerm || inputValue}
                                    selectedOptions={selectedOptionIds}
                                    onChange={e =>
                                        setSearchTerm(e.target.value)
                                    }
                                    onOptionSelect={(e, d) => {
                                        if (d.optionValue === 'Select All') {
                                            handleSelectAll()
                                        } else {
                                            handleOptionSelect(
                                                e,
                                                d.optionValue,
                                                'newsList'
                                            )
                                        }
                                    }}
                                    freeform
                                    clearable
                                    placeholder='Select News List'
                                    multiselect
                                    disabled={!!selectedCountries.length}
                                >
                                    <Option key='select-all' value='Select All'>
                                        {isAllListsSelected
                                            ? 'Deselect All'
                                            : 'Select All'}
                                    </Option>
                                    {filteredNewsList?.map(option => (
                                        <Option
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </Option>
                                    ))}
                                </Combobox>
                            </Field>
                            <DatePicker
                                className={mergeClasses(
                                    classes.dropdown,
                                    classes.spacing
                                )}
                                placeholder='Unpublish Date'
                                onSelectDate={e => setUnpublishDate(e)}
                                showWeekNumbers
                                value={
                                    unPublishDate
                                        ? new Date(unPublishDate)
                                        : undefined
                                }
                                minDate={new Date(publishDate)}
                            />
                        </div>
                        <PreviewPublishNews
                            selectedNewsList={selectedNewsLists}
                            selectedCountries={selectedCountries}
                            selected={selected}
                            newsItem={data}
                            publishDate={publishDate}
                            unPublishDate={unPublishDate}
                            setSelected={setSelected}
                            setOpen={setOpen}
                        />
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default PublishNewsDialog
