/**
 * Check if a string is formatted as a phone number.
 * @param phoneNumber the string to check
 * @returns boolean
 */
const isPhoneNumber = (phoneNumber: string): boolean => {
    /**
     * RegExp check for phone numbers:
     * - Allows most formatting of phone numbers
     */
    const re = /^[+]*(\d{0,})[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
    return re.test(String(phoneNumber).toLowerCase())
}

export default isPhoneNumber
