import { apiSlice } from './apiSlice'
import { lowerCaseKeys } from 'helpers/objectManipulation'

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ['PersonalViews'],
})

const personalViewsApi = apiWithTags.injectEndpoints({
    endpoints: builder => ({
        getPersonalViews: builder.query<IPersonalView[], string>({
            query: type => `odata/PersonalViews?filter=ViewType eq '${type}'`,
            transformResponse: (response: IODataResponse<IPersonalView[]>) =>
                lowerCaseKeys(response.value) as unknown as IPersonalView[],
            providesTags: ['PersonalViews'],
        }),
        getPersonalViewById: builder.query<IPersonalView, string>({
            query: id => `/api/PersonalViews/${id}`,
            transformResponse: (response: IPersonalView) => {
                return response
            },
            providesTags: ['PersonalViews'],
        }),
        postPersonalViews: builder.mutation<
            IPersonalView,
            Partial<IPersonalView>
        >({
            query: view => ({
                url: 'api/PersonalViews',
                method: 'POST',
                body: view,
            }),
            invalidatesTags: ['PersonalViews'],
        }),
        putPersonalViews: builder.mutation<
            IPersonalView,
            { id: number; view: Partial<IPersonalView> }
        >({
            query: ({ id, view }) => ({
                url: `api/PersonalViews/${id}`,
                method: 'PUT',
                body: view,
            }),
            invalidatesTags: ['PersonalViews'],
        }),
        deletePersonalViews: builder.mutation<void, number>({
            query: id => ({
                url: `api/PersonalViews/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['PersonalViews'],
        }),
    }),
})

export const {
    useGetPersonalViewsQuery,
    useGetPersonalViewByIdQuery,

    usePostPersonalViewsMutation,
    usePutPersonalViewsMutation,
    useDeletePersonalViewsMutation,
} = personalViewsApi
