import { Button, MessageBar } from '@fluentui/react-components'
import { useRef, useState } from 'react'

import { FilePond } from 'react-filepond'
import { FilePond as FilePondType } from 'filepond'
import { ISetFilter } from 'ag-grid-enterprise'
import Loader from 'components/Ui/Loader'
import ModalStatusEnum from 'constants/ModalStatusEnum'
import ModalTypeEnum from 'constants/ModalTypeEnum'
import VehicleGroupListsModalForm from './VehicleGroupListsForm'
import { acquireApiAccessToken } from 'authInstance'
import consoleErrorData from 'helpers/consoleErrorData'
import { insertSpaces } from 'helpers/stringHelpers'
import { triggerMessage } from 'slices/messageSlice'
import { useAppDispatch } from 'store'
import {
    useCreateVehicleGroupListsMutation,
    apiUtil,
} from 'api/vehicleGroupList'
import { useGrid } from 'contexts/GridContext'

export const vehicleGroupListNewDefault: IVehicleGroup = {
    name: '',
    vins: [''],
    operation: 'Add',
}

type VehicleGroupListsModalAddTabProps = {
    vehicleGroupType: VehicleGroupListSupportedPages
    setGroupListName?: (value: string) => void
    groupListName?: string
    setVinList?: (value: string[]) => void
    vinList?: string[]
    modalType?: ModalTypeEnum
    modalStatus?: ModalStatusEnum
    externalFilePondInstance?: React.MutableRefObject<
        FilePond & { _pond: FilePondType }
    >
    setAddByExternalFilePond?: (value: boolean) => void
}

const VehicleGroupListsModalAddTab = ({
    vehicleGroupType,
    setGroupListName,
    groupListName,
    setVinList,
    vinList,
    modalType,
    modalStatus,
    externalFilePondInstance,
    setAddByExternalFilePond,
}: VehicleGroupListsModalAddTabProps) => {
    const [vinAddType, setVinAddType] = useState<string>('vinField')
    const [vehicleGroupListNew, setVehicleGroupListNew] =
        useState<IVehicleGroup>(vehicleGroupListNewDefault)
    const [
        newVehicleGroupListAddedFormNotReset,
        setNewVehicleGroupListAddedFormNotReset,
    ] = useState<boolean>(false)
    const [vehicleGroupListAddedVehicles, setVehicleGroupListAddedVehicles] =
        useState<number>()
    const [accessToken, setAccessToken] = useState<string>()

    const localfilePondInstance = useRef<FilePond & { _pond: FilePondType }>(
        null
    )
    const filePondInstance = externalFilePondInstance || localfilePondInstance

    const { gridRef } = useGrid()
    const dispatch = useAppDispatch()

    const [createVehicleGroupLists, { isLoading, isError, isSuccess }] =
        useCreateVehicleGroupListsMutation()

    const getAccessToken = async () => {
        setAccessToken(await acquireApiAccessToken())
    }

    const updateGridFilter = (filterName: string, newValue) => {
        gridRef.current?.api.refreshServerSide()

        // Update the filter options
        const filterInstance =
            gridRef.current?.api.getFilterInstance<ISetFilter>(filterName)
        const values = filterInstance?.getFilterValues()
        filterInstance?.setFilterValues([...values, newValue])
    }

    const onSaveVehicleGroupList = async () => {
        if (vinAddType === 'vinField') {
            const postVehicleGroupList: IVehicleGroup = {
                name: vehicleGroupListNew.name,
                vins: vehicleGroupListNew.vins,
                operation: 'Add',
            }
            const response = await createVehicleGroupLists({
                vehicleGroupType,
                postVehicleGroupList,
            })

            if ('data' in response) {
                setVehicleGroupListAddedVehicles(response.data.vehicleCount)
                setNewVehicleGroupListAddedFormNotReset(true)
                updateGridFilter(
                    `${vehicleGroupType}s`,
                    vehicleGroupListNew.name
                )
            }
        }

        if (vinAddType === 'vinExcel' && vehicleGroupListNew.name) {
            filePondInstance.current?._pond.setOptions({
                server: {
                    url: `${process.env.REACT_APP_REFLECT_BACKEND_API}`,
                    process: {
                        url: `api/VehicleGroupList/${vehicleGroupType}/GroupListReadFile/${encodeURIComponent(
                            vehicleGroupListNew.name
                        )}`,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        method: 'POST',
                        ondata: (formData: FormData) => formData,
                        withCredentials: false,
                        onerror: response => {
                            consoleErrorData(response)
                            return response.data
                        },
                    },
                    revert: null,
                    restore: null,
                    load: null,
                    fetch: null,
                },
            })
            filePondInstance.current
                ?.processFiles()
                .then(response => {
                    const serverResponse = JSON.parse(response[0].serverId)

                    dispatch(
                        triggerMessage({
                            title: 'Success',
                            message: `${serverResponse.vehicleCount} vehicles added to the new group: ${vehicleGroupListNew.name}!`,
                            intent: 'success',
                        })
                    )

                    setVehicleGroupListAddedVehicles(
                        serverResponse.vehicleCount
                    )
                    setNewVehicleGroupListAddedFormNotReset(true)
                    updateGridFilter(
                        `${vehicleGroupType}s`,
                        vehicleGroupListNew.name
                    )
                    dispatch(
                        apiUtil.invalidateTags([
                            'VehicleGroupLists',
                            'VehicleGroupListsFilters',
                        ])
                    )
                })
                .catch(e => {
                    dispatch(
                        triggerMessage({
                            title: 'Error',
                            message: `Could not add vehicle(s) to the group: ${vehicleGroupListNew.name}.
                     Please check the file or contact support, statuscode: ${e.error.code}.`,
                            intent: 'error',
                        })
                    )
                })
        }
    }

    const checkSaveVehicleGroupListDisabled = () => {
        if (newVehicleGroupListAddedFormNotReset) {
            return true
        }
        if (
            !vehicleGroupListNew.name ||
            (vinAddType === 'vinField' &&
                (!vehicleGroupListNew.vins ||
                    vehicleGroupListNew.vins.length <= 0)) ||
            (vinAddType === 'vinExcel' &&
                (!filePondInstance ||
                    (filePondInstance &&
                        filePondInstance.current &&
                        filePondInstance.current.getFiles().length <= 0)))
        ) {
            return true
        }
        return false
    }

    return (
        <>
            <VehicleGroupListsModalForm
                vehicleGroupList={vehicleGroupListNew}
                setVehicleGroupList={setVehicleGroupListNew}
                filePondInstance={filePondInstance}
                getAccessToken={getAccessToken}
                setVinAddType={setVinAddType}
                vinAddType={vinAddType}
                vehicleGroupType={vehicleGroupType}
                setGroupListName={setGroupListName}
                groupListName={groupListName}
                setVinList={setVinList}
                vinList={vinList}
                modalType={modalType}
                modalStatus={modalStatus}
                setAddByExternalFilePond={setAddByExternalFilePond}
            />

            {isLoading && (
                <Loader text={`Adding ${insertSpaces(vehicleGroupType)}...`} />
            )}
            {isError && (
                <MessageBar intent='error'>
                    Failed to add {insertSpaces(vehicleGroupType)}. Please
                    retry, contact technical support if the error persists.
                </MessageBar>
            )}
            {isSuccess && newVehicleGroupListAddedFormNotReset && (
                <MessageBar intent='success'>
                    {insertSpaces(vehicleGroupType)} added with{' '}
                    {vehicleGroupListAddedVehicles} vehicles!
                </MessageBar>
            )}

            {modalType !== ModalTypeEnum.QuickModal && (
                <Button
                    id='saveVehicleGroupList'
                    appearance='primary'
                    onClick={onSaveVehicleGroupList}
                    disabled={checkSaveVehicleGroupListDisabled()}
                >
                    Save VehicleGroup list
                </Button>
            )}
        </>
    )
}

export default VehicleGroupListsModalAddTab
