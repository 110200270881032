/**
 * Creates and multidimensional array with 'size' number of item in each
 *
 * ae. [1, 2, 3, 4, 5] => chunk(3) => [[1, 2, 3], [4, 5]]
 *
 * @param  {T[]} arr
 * @param  {number} size
 * @returns T[][]
 */
export const chunkArray = <T>(arr: T[], size: number): T[][] =>
    arr?.reduce(
        (bucket, _, index, orig) =>
            !(index % size)
                ? bucket.concat([orig.slice(index, index + size)])
                : bucket,
        []
    )
