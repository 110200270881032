import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components'

import { Delete48Regular } from '@fluentui/react-icons'
import { ICellRendererParams } from 'ag-grid-community'
import { toast } from 'react-toastify'
import { useDeleteVehicleClassificationMutation } from 'api/vehicleTests'
import { useState } from 'react'

const DeleteVehicleClassification = ({ data }: ICellRendererParams) => {
    const [open, setOpen] = useState(false)

    const [deleteVehicleClassification, { isLoading }] =
        useDeleteVehicleClassificationMutation()

    const deleteClassification = async () => {
        await deleteVehicleClassification(data.id)

        toast.info(`Vehicle Classification ${data.title} has been deleted`)
        setOpen(false)
    }

    return (
        <Dialog open={open} onOpenChange={(_e, d) => setOpen(d.open)}>
            <DialogTrigger disableButtonEnhancement>
                <Button
                    data-cy='remove-classification-button'
                    appearance='transparent'
                    icon={<Delete48Regular />}
                />
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Delete Classification</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete the Vehicle
                        Classification {data.title}?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            data-cy='delete-classification-button'
                            appearance='primary'
                            onClick={deleteClassification}
                            disabled={isLoading}
                        >
                            Delete
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button>Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default DeleteVehicleClassification
