/**
 * Keys for the VehicleGrid component.
 * Man, this is a lot of keys! I wonder if there's a way to get them from the database?
 **/
export const vehicleGridColumns = [
    'StartDate',
    'EndDate',
    'Cdsid',
    // end of custom sorted columns the rest is alphabetical
    'BaselineVersion',
    'Blacklist',
    'CarUsage',
    'CdbMarket',
    'Color',
    'ColorCode',
    'Comments',
    'ConsentCategory',
    'CreatedBy',
    'CustomDelivered',
    'CustomReturned',
    'DeliveryCountry',
    'Email',
    'EngineCode',
    'EngineDescription',
    'EquipmentId',
    'EquipmentType',
    'ExclusionLists',
    'FactoryComplete',
    'FeedbackTools',
    'FirstName',
    'FirstPds',
    'FirstUpdateDone',
    'FleetName',
    'FuelType',
    'Fyon',
    'GearboxCode',
    'GearboxDescription',
    'Id',
    'InclusionList',
    'LastName',
    'LatestVehicleVersion',
    'MarketingCode',
    'MissedAppointments',
    'Model',
    'ModelCode',
    'ModelYear',
    'OtaVersion',
    'Phone',
    'Platform',
    'PreferredEmail',
    'RegistrationNo',
    'ResourceGroup',
    'Responsible',
    'SalesVersion',
    'SalesVersionDescription',
    'SssVersion',
    'SteeringCode',
    'SteeringDescription',
    'StructureWeek',
    'VehicleTestsFinished',
    'VehicleTestsOngoing',
    'VehicleTestsPlanned',
    'UpholsteryCode',
    'UpholsteryDescription',
    'VariantCode',
    'VehicleClassification',
    'ScheduledDelivery',
    'ScheduledReturn',
    'VehicleDelivered',
    'VehicleReturned',
    'VehicleType',
    'VidaClientId',
    'Vin',
    'WcuId',
    'Window',
    'WorkshopDealer',
    'WsActorId',
    'WsArrival',
    'WsComment',
    'WsInitiatedBy',
    'WsInstalled',
    'WsInvitationFrom',
    'WsInvitationTo',
    'WsNotificationTypeId',
    'WsReady',
    'WsReturnedToDriver',
    'WsVisitTypeId',
    'WsWaiting',
]

export const numberColumns = [
    'MissedAppointments',
    'ColorCode',
    'MarketingCode',
    'ModelCode',
    'MarketingYear',
    'SteringCode',
    'VidaClientId',
]
export const dateColumns = [
    'CarOnlineDate',
    'CreatedOn',
    'CustomDelivered',
    'CustomReturned',
    'EndDate',
    'FactoryComplete',
    'FirstPds',
    'InsertDate',
    'LatestVehicleVersion',
    'StartDate',
    'UpdateDate',
    'ScheduledDelivery',
    'ScheduledReturn',
    'VehicleDelivered',
    'VehicleReturned',
]
export const csvColumns = ['Vin', 'RegistrationNo', 'Cdsid']
export const booleanColumns = ['Blacklist', 'FirstUpdateDone', 'WsWaiting']
