import { UIMatch as RawUIMatch, useMatches } from 'react-router-dom'
import { makeStyles, shorthands } from '@fluentui/react-components'

const useStyle = makeStyles({
    list: {
        ...shorthands.padding('0'),
        ...shorthands.margin('4px', '0', '4px', '8px'),
    },
    listItem: {
        display: 'inline-block',
        fontSize: '14px',

        '&:not(:last-child)': {
            '& > a:hover': {
                ...shorthands.textDecoration('underline'),
            },
            '&::after': {
                content: '"›"',
                display: 'inline-block',
                ...shorthands.margin('0', '8px'),
            },
        },

        '&:last-child': {
            fontWeight: 'bold',
            pointerEvents: 'none',
            cursor: 'default',
        },

        '& > a': {
            ...shorthands.textDecoration('none'),
            color: 'inherit',
        },
    },
})

interface IHandle {
    crumb: () => React.ReactElement
}

type UIMatch = RawUIMatch<unknown, IHandle>

const Breadcrumbs = () => {
    const classes = useStyle()
    const matches = useMatches() as UIMatch[]
    const crumbs = matches
        .filter(match => Boolean(match.handle?.crumb))
        .map(match => match.handle.crumb())

    return (
        <ol className={classes.list}>
            {crumbs.map((crumb, index) => (
                <li key={index} className={classes.listItem}>
                    {crumb}
                </li>
            ))}
        </ol>
    )
}

export default Breadcrumbs
