import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Tab,
    TabList,
} from '@fluentui/react-components'

import { ClipboardTextEdit24Regular } from '@fluentui/react-icons'
import DataSourcesHistory from './DataSourcesHistory'
import { GridApi } from 'ag-grid-community'
import VehicleDetailsList from 'components/VehicleDetails/VehicleDetailsList'
import VehicleTagLists from './VehicleTagLists'
import VehicleTestList from './VehicleTestList'
import useDialogStyles from 'components/styles/dialogStyles'
import { useState } from 'react'

interface IVehicleDetailProps {
    data: IVehicleInfoOdata
    api: GridApi
}

const VehicleDetailsDialog = ({ data, api }: IVehicleDetailProps) => {
    const [selectedValue, setSelectedValue] = useState('details')
    const classes = useDialogStyles()

    return (
        <Dialog>
            <DialogTrigger>
                <Button
                    appearance='transparent'
                    title='Open Vehicle Details'
                    icon={<ClipboardTextEdit24Regular />}
                    data-cy='vehicle-details-button'
                />
            </DialogTrigger>
            <DialogSurface className={classes.dialogSurface}>
                <DialogBody
                    data-cy='vehicle-details-body'
                    className={classes.dialogBody}
                >
                    <DialogTitle>Vehicle details</DialogTitle>
                    <DialogContent>
                        <TabList
                            selectedValue={selectedValue}
                            onTabSelect={(_e, d) =>
                                setSelectedValue(d.value as string)
                            }
                        >
                            <Tab
                                data-cy='vehicle-details-details-tab'
                                id='Details'
                                value='details'
                            >
                                Vehicle details
                            </Tab>
                            <Tab
                                data-cy='vehicle-details-tests-tab'
                                id='VehicleTests'
                                value='vehicleTests'
                            >
                                Vehicle tests
                            </Tab>
                            <Tab
                                data-cy='vehicle-details-history-tab'
                                id='DataSources'
                                value='dataSources'
                            >
                                Insert/Update history
                            </Tab>
                            <Tab
                                data-cy='vehicle-details-taglist-tab'
                                id='TagLists'
                                value='tagLists'
                            >
                                Tag Lists
                            </Tab>
                        </TabList>

                        {selectedValue === 'details' && (
                            <VehicleDetailsList data={data} api={api} />
                        )}

                        {selectedValue === 'vehicleTests' && (
                            <VehicleTestList id={data.Id} />
                        )}

                        {selectedValue === 'dataSources' && (
                            <DataSourcesHistory id={data.Id} />
                        )}
                        {selectedValue === 'tagLists' && (
                            <VehicleTagLists data={data} />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger>
                            <Button>Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default VehicleDetailsDialog
