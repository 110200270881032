import { apiSlice } from './apiSlice'

const messageApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        sendEmail: builder.mutation<
            { isSuccessStatusCode: boolean },
            {
                actorIds: unknown[]
                subject: string
                message: string
                isHtml: boolean
            }
        >({
            query: body => ({
                url: 'api/Messaging/SendEmailByActorId',
                method: 'POST',
                body,
            }),
        }),
        sendSms: builder.mutation<
            { failedNumberValidations: string[] },
            {
                actorIds: unknown[]
                message: string
            }
        >({
            query: body => ({
                url: 'api/Messaging/SendSMSByActorId',
                method: 'POST',
                body,
            }),
        }),
    }),
})

export const { useSendEmailMutation, useSendSmsMutation } = messageApi
