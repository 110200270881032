import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'

import DateTimePicker from 'components/Ui/DateTimePicker'
import { Edit32Regular } from '@fluentui/react-icons'
import { ICellRendererParams } from 'ag-grid-community'
import { dateRenderWithTime } from 'helpers/dateHelpers'
import { useOldUpdateWorkshopVisitMutation } from 'api/workshop'
import { useState } from 'react'

interface IWorkshopProps extends ICellRendererParams<IWorkshop> {
    min: string[]
    max: string[]
}

const useStyles = makeStyles({
    button: {
        ...shorthands.padding('0'),
        height: '100%',
    },
})

const DateEdit = ({
    value,
    data,
    colDef,
    api,
    min = [],
    max = [],
}: IWorkshopProps) => {
    const [newValue, setNewValue] = useState(value)
    const classes = useStyles()

    let minDate, maxDate

    min.forEach(column => {
        if (column in data && data[column] !== null) {
            minDate = data[column]
        }
    })

    max.forEach(column => {
        if (column in data && data[column] !== null) {
            maxDate = data[column]
        }
    })

    const [updateWorkshopVisit, { isLoading }] =
        useOldUpdateWorkshopVisitMutation()

    const onSave = () => {
        updateWorkshopVisit({
            id: data.Id,
            field: colDef.field,
            value: newValue,
        }).then(() => {
            api.refreshServerSide()
        })
    }

    return (
        <Dialog>
            <DialogTrigger>
                <Button
                    appearance='transparent'
                    icon={<Edit32Regular />}
                    title={`Edit ${colDef.headerName}`}
                    className={classes.button}
                >
                    {dateRenderWithTime({ value })}
                </Button>
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>
                        Choose date and time for: {colDef.headerName}
                    </DialogTitle>
                    <DialogContent>
                        <DateTimePicker
                            nowTime
                            clear
                            copyFromClipboard
                            label='Date and time'
                            value={newValue}
                            onChange={changeDate => {
                                setNewValue(changeDate)
                            }}
                            max={maxDate}
                            min={minDate}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance='primary'
                            onClick={onSave}
                            disabled={isLoading}
                        >
                            Update
                        </Button>
                        <DialogTrigger>
                            <Button>Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default DateEdit
