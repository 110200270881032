import {
    AddSquareMultiple24Regular,
    Archive24Regular,
    Book24Regular,
    Dismiss24Filled,
    Filter24Regular,
    Mail24Regular,
    Pen24Regular,
    Person24Regular,
    Phone24Regular,
    Send24Regular,
} from '@fluentui/react-icons'
import {
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
} from '@fluentui/react-components'
import {
    useArchiveDriverStudyMutation,
    useEditDriverStudiesMutation,
    useGetDriverStudyByIdQuery,
} from 'api/driverStudies'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import ActionsMenu from 'components/Ui/Layout/ActionsMenu'
import AddFilteredVehiclesToVehicleTest from 'components/VehicleTest/AddFilteredVehiclesToVehicleTest'
import AddSelectedVehiclesToVehicleTest from 'components/VehicleTest/AddSelectedVehiclesToVehicleTest'
import BatchEditDriversModal from 'components/DriverStudy/BatchEditDriversModal'
import Content from 'components/Ui/Layout/Content'
import DeleteDriversModal from 'components/DriverStudy/DeleteDriversModal'
import DriverStudyForm from 'components/DriverStudy/DriverStudyForm'
import EmailActions from 'components/Actions/EmailActions'
import Header from 'components/Ui/Layout/Header'
import { IActionsMenu } from 'Types/ActionsMenu'
import Loader from 'components/Ui/Loader'
import ModalStatusEnum from 'constants/ModalStatusEnum'
import ROUTES from 'constants/routes'
import SMSActions from 'components/Actions/SMSActions'
import StudyParticipationGrid from 'components/DriverStudy/StudyParticipationGrid'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { triggerMessage } from 'slices/messageSlice'
import { useAppDispatch } from 'store'
import { useGrid } from 'contexts/GridContext'
import { useState } from 'react'

const DriverStudy = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { gridRef } = useGrid()

    const { data: driverStudy, isLoading } = useGetDriverStudyByIdQuery(id)
    const [archiveDriverStudy] = useArchiveDriverStudyMutation()

    const showArchiveFailureMessage = error => {
        toast.error(error.data)
    }

    const [selectedRowCount, setSelectedRowCount] = useState(0)
    gridRef.current?.api.addEventListener('selectionChanged', () => {
        setSelectedRowCount(gridRef.current?.api.getSelectedRows().length ?? 0)
    })

    const handleArchiveClick = () => {
        archiveDriverStudy(driverStudy.id).then(response => {
            if ('error' in response) {
                showArchiveFailureMessage(response.error)
            } else {
                toast.success(`Successfully archived ${driverStudy.title}`)
            }
        })
    }

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(null)

    const openDialog = (body: JSX.Element, title: string) => {
        setIsDialogOpen(true)
        setDialogContent({ body, title })
    }

    const dispatch = useAppDispatch()

    const [editDriverStudies] = useEditDriverStudiesMutation()

    const onEditDriverStudy = async ({
        title,
        description,
        studyTypeId,
        stakeholder,
        startDate,
        endDate,
        msFormId,
    }: IDriverStudy) => {
        const response = await editDriverStudies({
            id: String(driverStudy.id),
            changeSets: [
                {
                    operation: 'Add',
                    path: 'title',
                    value: title,
                },
                {
                    operation: 'Add',
                    path: 'description',
                    value: description,
                },
                {
                    operation: 'Add',
                    path: 'studyTypeId',
                    value: studyTypeId,
                },
                {
                    operation: 'Add',
                    path: 'stakeholder',
                    value: stakeholder,
                },
                {
                    operation: 'Add',
                    path: 'startDate',
                    value: startDate ? dayjs(startDate).toISOString() : null,
                },
                {
                    operation: 'Add',
                    path: 'endDate',
                    value: endDate ? dayjs(endDate).toISOString() : null,
                },
                {
                    operation: msFormId ? 'Add' : 'Remove',
                    path: 'msFormId',
                    value: msFormId ? msFormId : null,
                },
            ],
        })

        if ('data' in response) {
            dispatch(
                triggerMessage({
                    intent: 'success',
                    title: 'Driver Study updated',
                    message: 'Study has been updated successfully',
                })
            )
        } else {
            dispatch(
                triggerMessage({
                    intent: 'error',
                    title: 'Driver Study update failed',
                    message: String(response.error),
                })
            )
        }

        setIsDialogOpen(false)
    }

    const menuItems: IActionsMenu[] = [
        {
            label: 'Information',
            icon: <Book24Regular />,
            onClick: () =>
                openDialog(
                    <DriverStudyForm
                        data={driverStudy}
                        onSave={onEditDriverStudy}
                    />,
                    'Study Information'
                ),
        },
        {
            label: 'Archive',
            icon: <Archive24Regular />,
            onClick: () => handleArchiveClick(),
            disabled: driverStudy?.isArchived,
        },
        {
            label: 'Edit',
            icon: <Pen24Regular />,
            disabled: selectedRowCount <= 0,
            onClick: () =>
                openDialog(
                    <BatchEditDriversModal
                        toggleOpen={() => setIsDialogOpen(false)}
                    />,
                    `Edit ${selectedRowCount}`
                ),
        },
        {
            label: 'Remove',
            icon: <Dismiss24Filled />,
            disabled: selectedRowCount <= 0,
            onClick: () =>
                openDialog(
                    <DeleteDriversModal
                        toggleOpen={() => setIsDialogOpen(false)}
                    />,
                    `Remove ${selectedRowCount}`
                ),
        },
        {
            label: 'Vehicle Tests',
            icon: <Book24Regular />,
            restricted: 'vehicleTestsManage',
            children: [
                {
                    label: 'Add Selected Vehicles',
                    icon: <AddSquareMultiple24Regular />,
                    disabled: selectedRowCount < 1,
                    onClick: () =>
                        openDialog(
                            <AddSelectedVehiclesToVehicleTest
                                onClose={() => setIsDialogOpen(false)}
                            />,
                            'Add Selected Vehicles to test'
                        ),
                },
                {
                    label: 'Add Filtered Vehicles',
                    icon: <Filter24Regular />,
                    onClick: () =>
                        openDialog(
                            <AddFilteredVehiclesToVehicleTest
                                onClose={() => setIsDialogOpen(false)}
                            />,
                            'Add Filtered Vehicles to test'
                        ),
                },
            ],
        },
        {
            label: 'Contact Drivers',
            icon: <Person24Regular />,
            children: [
                {
                    label: 'Send SMS',
                    icon: <Phone24Regular />,
                    restricted: 'sms',
                    children: [
                        {
                            label: 'Send SMS to selected drivers',
                            disabled: !selectedRowCount,
                            icon: <Send24Regular />,
                            onClick: () =>
                                openDialog(
                                    <SMSActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Selected}
                                    />,
                                    'Send SMS Message to selected drivers'
                                ),
                        },
                        {
                            label: 'Send SMS to Filtered drivers',
                            icon: <Filter24Regular />,
                            onClick: () =>
                                openDialog(
                                    <SMSActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Filtered}
                                    />,
                                    'Send SMS Message to filtered drivers'
                                ),
                        },
                    ],
                },
                {
                    label: 'Send Email',
                    icon: <Mail24Regular />,
                    restricted: 'email',
                    children: [
                        {
                            label: 'Send Email to Selected drivers',
                            disabled: !selectedRowCount,
                            icon: <Send24Regular />,
                            onClick: () =>
                                openDialog(
                                    <EmailActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Selected}
                                    />,
                                    'Send Email to Selected drivers'
                                ),
                        },
                        {
                            label: 'Send Email to Filtered drivers',
                            icon: <Filter24Regular />,
                            onClick: () =>
                                openDialog(
                                    <EmailActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Filtered}
                                    />,
                                    'Send Email to Filtered drivers'
                                ),
                        },
                    ],
                },
            ],
        },
    ]

    if (isLoading) {
        return <Loader text='Loading DriverStudy...' />
    }

    return (
        <>
            <Header
                title={`Study - "${driverStudy.title}" ${
                    driverStudy.isArchived ? '(Archived)' : ''
                }`}
                personalViewType='SpreadParticipation'
            />
            <ActionsMenu
                items={menuItems}
                goBack={{
                    label: 'Driver Studies',
                    onClick: () => navigate(`/${ROUTES.DRIVER_STUDIES}`),
                }}
            />
            <Content>
                <StudyParticipationGrid
                    driverStudy={driverStudy}
                    disabled={!driverStudy.startDate}
                />
            </Content>

            <Dialog
                open={isDialogOpen}
                onOpenChange={(_event, data) => setIsDialogOpen(data.open)}
            >
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>{dialogContent?.title}</DialogTitle>
                        {dialogContent?.body}
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    )
}

type ContextType = {
    driverStudy: IDriverStudy | null
}

export const useDriverStudy = () => {
    return useOutletContext<ContextType>()
}

export default DriverStudy
