import { Add24Filled, Dismiss20Filled } from '@fluentui/react-icons'
import {
    Button,
    Dropdown,
    Field,
    Option,
    Tab,
    TabList,
    TabValue,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import {
    useGetWorkshopViewsByIdQuery,
    usePutIExtendedWorkshopMutation,
} from 'api/workshop'

import Loader from 'components/Ui/Loader'
import WorkshopVisitForm from '../components/Workshop/WorkshopVisitForm'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

const useStyles = makeStyles({
    tabrow: {
        marginBottom: '16px',
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        width: '33%',
        fontWeight: 'bold',
    },
    hidden: {
        display: 'none',
        visibility: 'hidden',
    },
    tabs: {
        '& > span': {
            display: 'flex',
            justifyContent: 'center',
            gridGap: '4px',

            '& > svg: hover': {
                color: 'grey',
            },
        },
    },
    margin: {
        ...shorthands.padding('0', '8px'),
    },
})

const WorkshopVisit = () => {
    const [tabs, setTabs] = useState<string[]>(['Empty'])
    const [activeTab, setActiveTab] = useState<TabValue>(0)

    const classes = useStyles()

    const { id } = useParams()
    const { data: gridData, isLoading } = useGetWorkshopViewsByIdQuery(id)

    const [putWorkshopVisitItem] = usePutIExtendedWorkshopMutation()
    const updateWorkshopVisitItem = (workshopVisitItem: IExtendedWorkshop) => {
        putWorkshopVisitItem(workshopVisitItem)
        toast.success('Successfully updated the grid!')
    }

    const handleRemoveTab = (event, i: number) => {
        event.stopPropagation()
        const newTabArray = [...tabs]
        newTabArray.splice(i, 1)
        if (activeTab === i) {
            setActiveTab(Math.max(0, i - 1))
        }

        setTabs(newTabArray)
    }

    const handleAddTab = () => {
        setTabs(prev => [...prev, 'Empty'])
        setActiveTab(tabs.length)
    }

    if (isLoading) return <Loader text='Loading workshop views...' />

    return (
        <div data-cy='workshop-view'>
            <TabList
                className={classes.tabrow}
                selectedValue={activeTab}
                defaultSelectedValue={0}
                onTabSelect={(_e, data) => setActiveTab(data.value)}
            >
                {tabs.map((tab, i) => (
                    <Tab key={i} value={i} className={classes.tabs}>
                        {tab}
                        <Dismiss20Filled onClick={e => handleRemoveTab(e, i)} />
                    </Tab>
                ))}
                <Button
                    appearance='transparent'
                    icon={<Add24Filled />}
                    onClick={handleAddTab}
                    disabled={tabs?.length === gridData?.length}
                    value={'add'}
                />
            </TabList>

            <div className={classes.margin}>
                {tabs.map((tab, i) => (
                    <div
                        className={i !== activeTab ? classes.hidden : null}
                        key={i}
                    >
                        <Field label='Select Vehicle'>
                            <Dropdown
                                inlinePopup
                                value={tab}
                                selectedOptions={[tab]}
                                onOptionSelect={(_e, data) => {
                                    setTabs(prev => {
                                        const newTabs = [...prev]
                                        newTabs[i] = data.optionValue
                                        return newTabs
                                    })
                                }}
                            >
                                {gridData
                                    ?.filter(
                                        data =>
                                            !tabs.includes(
                                                data.RegistrationNo
                                            ) && data.RegistrationNo
                                    )
                                    .map(data => (
                                        <Option
                                            key={data.RegistrationNo}
                                            value={data.RegistrationNo}
                                        >
                                            {data.RegistrationNo}
                                        </Option>
                                    ))}
                            </Dropdown>
                        </Field>

                        <WorkshopVisitForm
                            data={gridData.find(
                                data => data.RegistrationNo === tab
                            )}
                            onSubmit={(data: IExtendedWorkshop) =>
                                updateWorkshopVisitItem(data)
                            }
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
export default WorkshopVisit
