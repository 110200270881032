import * as yup from 'yup'

import {
    Button,
    DialogActions,
    DialogTrigger,
    Field,
    Input,
    Select,
    Textarea,
    makeStyles,
} from '@fluentui/react-components'

import DateTimePicker from 'components/Ui/DateTimePicker'
import { useEffect } from 'react'
import { useFormik } from 'formik'
import { useGetStudyTypesQuery } from 'api/driverStudies'

interface IDriverStudyFormProps {
    data?: Partial<IDriverStudy>
    onSave: (
        value: Partial<IDriverStudy>,
        setSubmitting: (isSubmitting: boolean) => void
    ) => void
}

const initialValues: Partial<IDriverStudy> = {
    title: '',
    description: '',
    studyTypeId: undefined,
    stakeholder: '',
    startDate: undefined,
    endDate: undefined,
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gridColumnStart: 1,
        gridColumnEnd: 4,

        '& > div:last-of-type': {
            marginTop: '1rem',
            alignSelf: 'flex-end',
        },
    },
})

const DriverStudyForm = ({
    data = initialValues,
    onSave,
}: IDriverStudyFormProps) => {
    const classes = useStyles()

    const { data: studyTypes } = useGetStudyTypesQuery()

    const {
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        status,
        setSubmitting,
        touched,
    } = useFormik({
        initialValues: data,
        validateOnBlur: true,
        validationSchema: yup.object({
            title: yup.string().required('Please fill out this field'),
            startDate: yup.date().nullable(),
            endDate: yup
                .date()
                .nullable()
                .when('startDate', (startDate, schema) =>
                    startDate
                        ? schema.min(
                              startDate,
                              'End date has to be after start date'
                          )
                        : schema
                ),
            stakeholder: yup.string().required('Please fill out this field'),
        }),
        onSubmit: value => {
            if (value.studyTypeId !== 2) {
                value.msFormId = null
            }
            onSave(value, setSubmitting)
        },
    })

    useEffect(() => {
        if (!data.studyTypeId && studyTypes?.length > 0) {
            setFieldValue('studyTypeId', studyTypes[0].id)
        }
    }, [studyTypes, setFieldValue, data.studyTypeId])

    return (
        <form
            data-cy='driver-study-form'
            className={classes.root}
            onSubmit={handleSubmit}
        >
            <Field
                required
                label='Title'
                validationMessage={touched.title ? errors.title : null}
            >
                <Input
                    data-cy='title-input'
                    name='title'
                    value={values.title ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Field>

            <Field label='Description'>
                <Textarea
                    data-cy='description-input'
                    name='description'
                    value={values.description ?? ''}
                    onChange={handleChange}
                />
            </Field>

            <DateTimePicker
                nowTime
                copyFromClipboard
                label='Start date'
                value={values.startDate || ''}
                onChange={newStartdate => {
                    setFieldValue('startDate', newStartdate)
                }}
                validationMessage={
                    touched.startDate && errors.startDate
                        ? errors.startDate
                        : null
                }
            />

            <DateTimePicker
                nowTime
                copyFromClipboard
                label='End date'
                value={values.endDate || ''}
                onChange={newStartdate => {
                    setFieldValue('endDate', newStartdate)
                }}
                validationMessage={
                    touched.endDate && errors.endDate ? errors.endDate : null
                }
            />

            <Field
                required
                label='Stakeholder'
                validationMessage={
                    touched.stakeholder ? errors.stakeholder : null
                }
            >
                <Input
                    data-cy='stakeholder-input'
                    name='stakeholder'
                    value={values.stakeholder ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Field>

            <Field label='Type'>
                <Select
                    data-cy='study-type-select'
                    name='studyTypeId'
                    onChange={(_e, d) => {
                        setFieldValue('studyTypeId', parseInt(d.value, 10))
                    }}
                    value={values.studyTypeId}
                >
                    {studyTypes?.map(st => (
                        <option key={st.id} value={st.id}>
                            {st.name}
                        </option>
                    ))}
                </Select>
            </Field>

            {values.studyTypeId === 2 && (
                <Field label='Microsoft forms ID'>
                    <Input
                        data-cy='msFormId-input'
                        name='msFormId'
                        max={100}
                        value={values.msFormId ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Field>
            )}

            <DialogActions>
                <Button
                    appearance='primary'
                    type='submit'
                    disabled={status === 'submitting'}
                    data-cy='submit-button'
                >
                    Submit
                </Button>
                <DialogTrigger>
                    <Button>Cancel</Button>
                </DialogTrigger>
            </DialogActions>
        </form>
    )
}

export default DriverStudyForm
