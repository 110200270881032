import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
} from '@fluentui/react-components'

import SyncDeploymentJobsTab from './SyncDeploymentJobsTab'

type PGWManagementProps = {
    toggleOpen: () => void
}

const PGWManagement = ({ toggleOpen }: PGWManagementProps) => {
    return (
        <>
            <DialogContent>
                <SyncDeploymentJobsTab />
            </DialogContent>
            <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                    <Button onClick={toggleOpen}>Cancel</Button>
                </DialogTrigger>
            </DialogActions>
        </>
    )
}

export default PGWManagement
