import {
    Beaker24Regular,
    Book24Regular,
    ClipboardTextLtr24Regular,
    Key24Regular,
    NewsRegular,
    Person24Regular,
    VehicleCar24Regular,
} from '@fluentui/react-icons'
import {
    MenuButton,
    Menu as MenuFluent,
    MenuGroup,
    MenuGroupHeader,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components'
import { permissions, selectViews } from 'slices/authSlice'

import { Fragment } from 'react'
import ROUTES from 'constants/routes'
import { useAppSelector } from 'store'
import { useNavigate } from 'react-router-dom'

interface IMenuProps {
    title: string
    icon: JSX.Element
}

const Menu = ({ title, icon }: IMenuProps) => {
    const navigate = useNavigate()
    const views = useAppSelector(selectViews)
    const {
        vehicleTests: hasVehicleTestPermissions,
        vehicleClassification: hasVehicleClassificationPermissions,
        driverStudiesDriver: hasDriverStudiesDriverPermissions,
        driverStudies: hasDriverStudiesPermissions,
        rivendellRead: hasRivendellReadPermissions,
        rivendellEdit: hasRivendellEditPermissions,
    } = useAppSelector(permissions)

    const navLinkGroups = [
        {
            name: 'Vehicles',
            links: [
                {
                    name: 'Vehicles',
                    url: '/',
                    icon: <VehicleCar24Regular />,
                },
                hasVehicleTestPermissions && {
                    name: 'Vehicle Tests',
                    url: `/${ROUTES.VEHICLE_TESTS}`,
                    icon: <Beaker24Regular />,
                },
                hasVehicleClassificationPermissions && {
                    name: 'Vehicle Classifications',
                    url: `/${ROUTES.VEHICLE_CLASSIFICATIONS}`,
                    icon: <ClipboardTextLtr24Regular />,
                },
            ],
        },
        (hasDriverStudiesDriverPermissions || hasDriverStudiesPermissions) && {
            name: 'Drivers',
            links: [
                hasDriverStudiesDriverPermissions && {
                    name: 'Drivers',
                    url: `/${ROUTES.DRIVERS}`,
                    icon: <Person24Regular />,
                },
                hasDriverStudiesPermissions && {
                    name: 'Driver Studies',
                    url: `/${ROUTES.DRIVER_STUDIES}`,
                    icon: <Book24Regular />,
                },
            ],
        },
        (hasRivendellEditPermissions || hasRivendellReadPermissions) && {
            name: 'Communications',
            links: [
                {
                    name: 'Portal News',
                    url: `/${ROUTES.PORTAL_NEWS}`,
                    icon: <NewsRegular />,
                },
            ],
        },
        views.length > 1 && {
            name: 'Admin',
            links: [
                {
                    name: 'Role/View Picker',
                    url: `/${ROUTES.ROLE_PICKER}`,
                    icon: <Key24Regular />,
                },
            ],
        },
    ]
    return (
        <>
            <MenuFluent>
                <MenuTrigger>
                    <MenuButton
                        data-cy='menu-button'
                        appearance='primary'
                        style={{
                            backgroundColor: '#004578',
                            width: '200px !important',
                            maxWidth: '300px',
                            height: '30px !important',
                            maxHeight: '50px',
                        }}
                        icon={icon}
                    >
                        {title}
                    </MenuButton>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuGroup>
                            {navLinkGroups.map((navGroup, i) => (
                                <Fragment key={i}>
                                    <MenuGroupHeader>
                                        {navGroup.name}
                                    </MenuGroupHeader>
                                    {navGroup.links?.map(navLink => (
                                        <MenuItem
                                            data-cy='menu-item'
                                            key={navLink.name + i}
                                            icon={navLink.icon}
                                            onClick={() =>
                                                navigate(navLink.url)
                                            }
                                        >
                                            {navLink.name}
                                        </MenuItem>
                                    ))}
                                </Fragment>
                            ))}
                        </MenuGroup>
                    </MenuList>
                </MenuPopover>
            </MenuFluent>
        </>
    )
}

export default Menu
