import { Dispatch, SetStateAction } from 'react'
import { Field, Input } from '@fluentui/react-components'

import DriverCheckStates from 'Types/DriverCheckStates'
import isPhoneNumber from 'helpers/isPhoneNumber'
import isValidEmail from 'helpers/isValidEmail'

type DriverFormFieldsProps = {
    driver: DriverObject
    setDriver: Dispatch<SetStateAction<DriverObject>>
    driverCheckState: DriverCheckStates
    driverRequiredCompleted: boolean
    setDriverRequiredCompleted: Dispatch<SetStateAction<boolean>>
}

const DriverFormFields = ({
    driver,
    setDriver,
    driverCheckState,
    driverRequiredCompleted,
    setDriverRequiredCompleted,
}: DriverFormFieldsProps) => {
    const getEmailErrorMessage = (
        email: string | undefined,
        required = true
    ) => {
        if (!driverCheckState.CdsIdVerified) {
            return undefined
        }
        if (required && !email) {
            return 'This field is required'
        }
        if (email) {
            if (!isValidEmail(email)) {
                if (driverRequiredCompleted) {
                    setDriverRequiredCompleted(false)
                }
                return 'Please verify the entered address, it does not seem to be a valid email address.'
            }
        }
        return undefined
    }

    const getPhoneNumberErrorMessage = (phoneNumber: string | undefined) => {
        if (!driverCheckState.CdsIdVerified) {
            return undefined
        }
        if (phoneNumber) {
            if (!isPhoneNumber(phoneNumber)) {
                return 'Please verify the entered phone number, it contains not allowed characters (letters).'
            }
        }
        return undefined
    }

    const getErrorMessage = (input: string | undefined) => {
        if (!driverCheckState.CdsIdVerified) {
            return undefined
        }
        if (!input) {
            return 'This field is required'
        }
        return undefined
    }

    return (
        <>
            <Field
                required
                label='First Name'
                validationMessage={getErrorMessage(driver.firstName)}
            >
                <Input
                    value={driver.firstName ?? ''}
                    onChange={(_ev, data) =>
                        setDriver({ ...driver, firstName: data.value })
                    }
                    disabled={!driverCheckState.CdsIdVerified}
                />
            </Field>
            <Field
                required
                label='Last Name'
                validationMessage={getErrorMessage(driver.lastName)}
            >
                <Input
                    value={driver.lastName ?? ''}
                    onChange={(_ev, data) =>
                        setDriver({ ...driver, lastName: data.value })
                    }
                    disabled={!driverCheckState.CdsIdVerified}
                />
            </Field>
            <Field
                required
                label='Email'
                validationMessage={getEmailErrorMessage(driver.email, false)}
            >
                <Input
                    type='email'
                    value={driver.email ?? ''}
                    onChange={(_ev, data) => {
                        setDriver({ ...driver, email: data.value })
                    }}
                    disabled={!driverCheckState.CdsIdVerified}
                />
            </Field>

            <Field
                required
                label='Preferred Email'
                validationMessage={getErrorMessage(driver.preferredEmail)}
            >
                <Input
                    type='email'
                    value={driver.preferredEmail ?? ''}
                    onChange={(_ev, data) =>
                        setDriver({ ...driver, preferredEmail: data.value })
                    }
                    disabled={!driverCheckState.CdsIdVerified}
                />
            </Field>
            <Field
                required
                label='Phone'
                validationMessage={getPhoneNumberErrorMessage(driver.phone)}
            >
                <Input
                    type='tel'
                    value={driver.phone ?? ''}
                    onChange={(_ev, data) =>
                        setDriver({ ...driver, phone: data.value })
                    }
                    disabled={!driverCheckState.CdsIdVerified}
                />
            </Field>
        </>
    )
}

export default DriverFormFields

DriverFormFields.defaultProps = {
    showFieldDescription: true,
}
