import { Dispatch, SetStateAction } from 'react'

import AddDriverFormCdsId from './AddDriverFormCdsId'
import DriverCheckStates from 'Types/DriverCheckStates'
import DriverFormFields from './DriverFormFields'
import DriverFormOrder from './DriverFormOrder'

type AddDriverFormProps = {
    driver: DriverObject
    setDriver: Dispatch<SetStateAction<DriverObject>>
    driverCheckState: DriverCheckStates
    setDriverCheckState: Dispatch<SetStateAction<DriverCheckStates>>
    driverRequiredCompleted: boolean
    setDriverRequiredCompleted: Dispatch<SetStateAction<boolean>>
}

const AddDriverForm = ({
    driver,
    setDriver,
    driverCheckState,
    setDriverCheckState,
    driverRequiredCompleted,
    setDriverRequiredCompleted,
}: AddDriverFormProps) => (
    <>
        <AddDriverFormCdsId
            driver={driver}
            setDriver={setDriver}
            driverCheckState={driverCheckState}
            setDriverCheckState={setDriverCheckState}
            setDriverRequiredCompleted={setDriverRequiredCompleted}
        />

        <DriverFormFields
            driver={driver}
            setDriver={setDriver}
            driverCheckState={driverCheckState}
            driverRequiredCompleted={driverRequiredCompleted}
            setDriverRequiredCompleted={setDriverRequiredCompleted}
        />

        <DriverFormOrder
            orderToggleTitle='Add Driver with Order'
            driver={driver}
            setDriver={setDriver}
            driverCheckState={driverCheckState}
            setDriverCheckState={setDriverCheckState}
        />
    </>
)

export default AddDriverForm
