import {
    Button,
    Card,
    CardHeader,
    Slot,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
} from '@fluentui/react-components/unstable'

import { Fragment } from 'react'
import { GridDots24Filled } from '@fluentui/react-icons'
import Portal from '../AppIcons/Portal'
import ReleaseInformation from './ReleaseInformation'
import Sharepoint from '../AppIcons/Sharepoint'
import Trillion from '../AppIcons/Trillion'

// import Rivendell from '../AppIcons/Rivendell'

interface IAppItem {
    link: string
    label: string
    icon: Slot<'div', 'img'>
}
interface IAppsPanelProps {
    isOpen: boolean
    toggleOpen: () => void
}

const useStyles = makeStyles({
    drawerBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    card: { ...shorthands.margin('10px') },
    cardHeader: { cursor: 'pointer', display: 'flex' },
})

const AppsPanel = ({ isOpen, toggleOpen }: IAppsPanelProps) => {
    const styles = useStyles()
    const appItems: IAppItem[] = [
        {
            icon: <Trillion />,
            link: 'https://trillion.volvocars.biz/',
            label: 'Trillion',
        },
        // {
        //     icon: <Rivendell />,
        //     link: 'https://rivendell.volvocars.biz/',
        //     label: 'Rivendell',
        // },
        {
            icon: <Portal />,
            link: 'https://codevportal.volvocars.biz/',
            label: 'Codev Driver Portal',
        },
        {
            icon: <Sharepoint />,
            link: 'https://intranet.volvocars.net/sites/codev/SitePages/Home.aspx',
            label: 'CoDev Sharepoint',
        },
    ]

    return (
        <Drawer open={isOpen} onOpenChange={() => toggleOpen()}>
            <DrawerHeader>
                <Button
                    data-cy='button-app-menu-close'
                    icon={<GridDots24Filled />}
                    style={{
                        color: 'grey',
                        border: 'none',
                    }}
                    size='large'
                    onClick={() => toggleOpen()}
                />
                <DrawerHeaderTitle>Apps</DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody className={styles.drawerBody}>
                <div>
                    {appItems.map(item => (
                        <Fragment key={item.label}>
                            <Card
                                data-cy='app-card-item'
                                onClick={() =>
                                    window.open(item.link, '_blank', 'noopener')
                                }
                                className={styles.card}
                            >
                                <CardHeader
                                    image={item.icon}
                                    header={item.label}
                                    className={styles.cardHeader}
                                />
                            </Card>
                        </Fragment>
                    ))}
                </div>
                <ReleaseInformation />
            </DrawerBody>
        </Drawer>
    )
}

export default AppsPanel
