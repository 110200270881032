import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    Tab,
    TabList,
    TabValue,
    Switch,
} from '@fluentui/react-components'

import ModalStatusEnum from 'constants/ModalStatusEnum'
import ModalTypeEnum from 'constants/ModalTypeEnum'
import VehicleGroupListsAdd from './VehicleGroupListsAdd'
import VehicleGroupListsGrid from './VehicleGroupListsGrid'
import { insertSpaces } from 'helpers/stringHelpers'
import { useState } from 'react'

type VehicleGroupListsProps = {
    toggleOpen: () => void
    vehicleGroupType: VehicleGroupListSupportedPages
}

const VehicleGroupLists = ({
    toggleOpen,
    vehicleGroupType,
}: VehicleGroupListsProps) => {
    const [selectedTab, setSelectedTab] = useState<TabValue>(
        'allVehicleGroupLists'
    )
    const [showOnlyMine, setShowOnlyMine] = useState<boolean>(false)

    return (
        <>
            <DialogContent>
                <div style={{ float: 'right', clear: 'both' }}>
                    <Switch
                        label='Show only mine'
                        labelPosition='before'
                        checked={showOnlyMine}
                        onChange={() => setShowOnlyMine(prev => !prev)}
                    />
                </div>

                <TabList
                    selectedValue={selectedTab}
                    onTabSelect={(_ev, data) => setSelectedTab(data.value)}
                >
                    <Tab id='allVehicleGroupLists' value='allVehicleGroupLists'>
                        {insertSpaces(vehicleGroupType)}
                    </Tab>
                    {vehicleGroupType !== 'TagList' && (
                        <Tab
                            id='addVehicleGroupList'
                            value='addVehicleGroupList'
                        >
                            {insertSpaces(vehicleGroupType)}
                        </Tab>
                    )}
                </TabList>
                {selectedTab === 'allVehicleGroupLists' && (
                    <VehicleGroupListsGrid
                        vehicleGroupType={vehicleGroupType}
                        showOnlyMine={showOnlyMine}
                    />
                )}
                {vehicleGroupType !== 'TagList' &&
                    selectedTab === 'addVehicleGroupList' && (
                        <VehicleGroupListsAdd
                            vehicleGroupType={vehicleGroupType}
                            modalStatus={ModalStatusEnum.Manual}
                            modalType={ModalTypeEnum.Default}
                        />
                    )}
            </DialogContent>

            <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                    <Button onClick={toggleOpen}>Cancel</Button>
                </DialogTrigger>
            </DialogActions>
        </>
    )
}

export default VehicleGroupLists
