import {
    Button,
    Input,
    InputOnChangeData,
    makeStyles,
} from '@fluentui/react-components'
import { Dismiss24Regular, Search24Regular } from '@fluentui/react-icons'

import { ChangeEvent } from 'react'

interface ISearchBoxProps {
    id?: string
    value?: string
    placeholder?: string
    disabled?: boolean
    onSearch: () => void
    onChange: (
        ev: ChangeEvent<HTMLInputElement>,
        data: InputOnChangeData
    ) => void
    onClear: () => void
}

const useStyles = makeStyles({
    filledInput: {
        backgroundColor: 'white',
        width: '100%',
    },
})

const SearchBox = ({
    id = 'search',
    value = '',
    placeholder = '',
    disabled = false,
    onSearch,
    onChange,
    onClear,
}: ISearchBoxProps) => {
    const classes = useStyles()

    const ClearButton = () => {
        if (value === '') {
            return null
        }

        return (
            <Button
                appearance='subtle'
                icon={<Dismiss24Regular />}
                onClick={onClear}
            />
        )
    }

    return (
        <Input
            id={id}
            disabled={disabled}
            className={classes.filledInput}
            placeholder={placeholder}
            value={value}
            appearance='filled-lighter'
            contentBefore={
                <Button
                    appearance='subtle'
                    icon={<Search24Regular />}
                    onClick={() => {
                        onSearch()
                    }}
                />
            }
            contentAfter={<ClearButton />}
            onChange={onChange}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    onSearch()
                }
            }}
        />
    )
}

export default SearchBox
