import { Field, Select } from '@fluentui/react-components'

import ActionsMenu from 'components/Ui/Layout/ActionsMenu'
import { Book24Regular } from '@fluentui/react-icons'
import Content from 'components/Ui/Layout/Content'
import CreateDriverStudyDialog from 'components/DriverStudy/CreateDriverStudyDialog'
import DriverStudiesGrid from 'components/DriverStudy/DriverStudiesGrid'
import Header from 'components/Ui/Layout/Header'
import { useState } from 'react'

const DriverStudiesIndex = () => {
    const [filterOption, setFilterOption] = useState('Unarchived')

    return (
        <>
            <Header
                title='Driver Studies'
                icon={<Book24Regular />}
                personalViewType='Spread'
            />

            <ActionsMenu
                items={[
                    {
                        restricted: 'driverStudiesManage',
                        component: <CreateDriverStudyDialog />,
                    },
                ]}
                extraComponents={
                    <Field
                        label='Show only'
                        orientation='horizontal'
                        style={{ minWidth: '270px', alignItems: 'center' }}
                    >
                        <Select
                            data-cy='archive-select'
                            value={filterOption}
                            onChange={(_e, data) => setFilterOption(data.value)}
                        >
                            <option value='Unarchived'>Unarchived</option>
                            <option value='Archived'>Archived</option>
                            <option value='showAll'>Show All</option>
                        </Select>
                    </Field>
                }
            />

            <Content>
                <DriverStudiesGrid filterOption={filterOption} />
            </Content>
        </>
    )
}

export default DriverStudiesIndex
