import HttpRequestHelper from 'components/Axios/HttpRequestHelper'
import { acquireApiAccessToken } from 'authInstance'
import axios from 'axios'

const rivendellApiAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_RIVENDELL_BACKEND_API,
})

rivendellApiAxiosInstance.interceptors.request.use(
    async config => {
        const accessToken = await acquireApiAccessToken()
        config.headers.set('Authorization', `Bearer ${accessToken}`)

        return config
    },
    error => {
        Promise.reject(error)
    }
)

rivendellApiAxiosInstance.interceptors.response.use(response => {
    return response
})

rivendellApiAxiosInstance.interceptors.response.use(
    response => response,
    error => {
        HttpRequestHelper.GetGlobalErrorMessage(
            error.response.status,
            `${error.response.config.baseURL}${error.response.config.url}`,
            error.response.data
        )

        return Promise.reject(error)
    }
)

export default rivendellApiAxiosInstance
