import { GridApi } from 'ag-grid-community'
import qs from 'qs'
import { useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

const useGridFilterUrl = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const oldQuery = useRef('')

    const pushFiltersToHistory = (gridApi: GridApi) => {
        const newQuery = qs.stringify(gridApi.getFilterModel())
        if (oldQuery.current !== newQuery) {
            oldQuery.current = newQuery
            setSearchParams(param => {
                const params = new URLSearchParams(param)
                if (newQuery === '') {
                    params.delete('filters')
                } else {
                    params.set('filters', newQuery)
                }
                return params
            })
        }
    }

    const setFiltersFromHistory = (gridApi: GridApi) => {
        if (
            searchParams.has('filters') &&
            oldQuery.current !== searchParams.get('filters')
        ) {
            oldQuery.current = searchParams.get('filters')
            gridApi.setFilterModel(qs.parse(searchParams.get('filters')))
        }
    }

    return [pushFiltersToHistory, setFiltersFromHistory]
}

export default useGridFilterUrl
