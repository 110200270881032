import {
    Button,
    Combobox,
    DialogActions,
    DialogContent,
    DialogTrigger,
    Field,
    Input,
    Option,
    Select,
    Switch,
    Text,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import { Fragment, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store'

import DateTimePicker from 'components/Ui/DateTimePicker'
import { permissions } from 'slices/authSlice'
import { triggerMessage } from 'slices/messageSlice'
import { useGetDriverStudyTagsQuery } from 'api/driverStudies'
import { useGetEscalationStepsQuery } from 'api/escalationSteps'
import { useGrid } from 'contexts/GridContext'
import { usePatchFactDriverStudyAssignmentsMutation } from 'api/driverStudyParticipation'

interface IBatchEditDriversModalProps {
    toggleOpen: () => void
}
interface IFormData {
    dependencies?: number[] // An array of indices that correspond to the form fields that this field depends on
    tags?: { key: string; text: string }[] // An array of options that can be selected in this dropdown
    options?: { key: string; text: string }[] // An array of options that can be selected in this dropdown
    title: string // The title of the form field
    key: keyof IFactDriverStudyAssignment // The key of the form field
    type: 'dropdown' | 'text' | 'date' | 'bool' | 'tag' // The type of form field
    validate?: (value: string | boolean | Date | number) => string // A function that validates the value of the form field
}

interface IChangeset {
    path: keyof IFactDriverStudyAssignment // The key of the data that is being changed
    value?: string | boolean | Date | number // The new value of the data
}

interface IError {
    path: keyof IFactDriverStudyAssignment
    message: string
}

const useStyles = makeStyles({
    attention: {
        backgroundColor: '#fffbdd',
        ...shorthands.padding('8px'),
        ...shorthands.borderRadius('4px'),
        marginBottom: '8px',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '56px 1fr',
        columnGap: '16px',
        alignItems: 'center',
        width: '97%',
        marginBottom: '16px',
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '4px',
    },
})

const BatchEditDriversModal = ({ toggleOpen }: IBatchEditDriversModalProps) => {
    const classes = useStyles()

    const dispatch = useAppDispatch()

    const { gridRef } = useGrid()
    const selectedRowCount = gridRef.current?.api.getSelectedRows().length ?? 0

    const { data: driverStudyTags } = useGetDriverStudyTagsQuery()
    const tags = useMemo(
        () =>
            driverStudyTags?.map(tag => ({
                key: tag.Id,
                text: tag.Name,
            })) ?? [],
        [driverStudyTags]
    )

    const hasPermission = useAppSelector(permissions).escalationsManage
    const { data: escalationSteps } = useGetEscalationStepsQuery(null, {
        skip: !hasPermission,
    })
    const steps = useMemo(
        () =>
            escalationSteps?.map(option => ({
                key: option.id,
                text: option.name,
            })) ?? [],
        [escalationSteps]
    )

    const [patchFactDriverStudyAssignments] =
        usePatchFactDriverStudyAssignmentsMutation()

    // Create an array of objects to define the form fields
    const formData: IFormData[] = useMemo(
        () =>
            [
                {
                    title: 'Participation',
                    key: 'Participation',
                    type: 'bool',
                    dependencies: [1],
                },
                {
                    title: 'Participation Date',
                    key: 'ParticipationDate',
                    type: 'date',
                    dependencies: [0],
                },
                // Hide tags until more work has been done on the backend
                // {
                //     title: 'Tags',
                //     key: 'Reflect_BridgeSpreadTags',
                //     type: 'tag',
                //     tags: tags,
                // },
                {
                    title: 'Comments',
                    key: 'Comments',
                    type: 'text',
                    validate: value => {
                        if (!value || value === '') {
                            return 'Comments can not be empty'
                        }
                    },
                },
                hasPermission && {
                    title: 'Escalation Status',
                    key: 'EscalationStepId',
                    type: 'dropdown',
                    options: steps,
                },
            ].filter(Boolean) as IFormData[],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [steps]
    )

    // Initialize the toggles state variable with an array of booleans that corresponds to the form fields
    const [toggles, setToggles] = useState(formData.map(() => false))

    // Initialize the changeSets state variable with an array of IChangeset objects that corresponds to the form fields
    const [changeSets, setChangeSets] = useState<IChangeset[]>(
        formData.map(d => ({ path: d.key }))
    )

    // Initialize the errors state variable with an array of IError objects that corresponds to the form fields
    const [errors, setErrors] = useState<IError | unknown>({})

    /**
     * handleCallback is a callback function that updates the changeSets state variable when the value of a form field changes.
     *
     * @param newValue - The new value for the form field.
     * @param path - The path (or "key") of the form field.
     * @param currentIndex - The index of the form field in the formData array.
     * @param dependencies - An optional array of indices that specifies which form fields this field depends on.
     * @param reason - An optional string that specifies the reason for the change.
     */
    const handleCallback = (
        newValue: unknown,
        path: keyof IFactDriverStudyAssignment,
        reason = ''
    ) => {
        const changeSet = []
        const pathsToRemove = []

        // if the reason is clear, push the path to the pathsToRemove array
        // otherwise push the current change to the changeSet
        if (reason === 'clear') {
            pathsToRemove.push(path)
        } else {
            changeSet.push({ path, value: newValue })
        }

        if (typeof errors === 'object' && Object.hasOwn(errors, path)) {
            const newErrors = { ...errors }
            delete newErrors[path]
            setErrors(newErrors)
        }

        // update the changeSet
        setChangeSets(pre =>
            pre.map(p => {
                // if the change is in the changeSet and the path is in the pathsToRemove array
                // set the value to undefined
                if (pathsToRemove.includes(p.path)) {
                    return { ...p, value: undefined }
                }

                const change = changeSet.find(c => c.path === p.path)
                // exception for the ParticipationDate field
                if (change?.path === 'ParticipationDate') {
                    // if the Participation field is not true set the value to undefined
                    if (
                        changeSet.find(c => c.path === 'Participation')
                            ?.value === false
                    ) {
                        return { ...p, value: undefined }
                    }
                    // if the Participation field is checked, set the value to new Date()
                    return { ...p, value: change?.value ?? new Date() }
                }

                return {
                    ...p,
                    value: change?.value ?? p.value,
                }
            })
        )
    }

    /**
     * handleToggle is a callback function that updates the toggles state variable when the user toggles
     * a form field.
     *
     * @param currentIndex - The index of the form field in the formData array.
     * @param path - The path (or "key") of the form field.
     * @param checked - Whether the form field is checked.
     * @param dependencies - An optional array of indices that specifies which form fields this field depends on.
     */
    const handleToggleChange = (
        currentIndex: number,
        path: keyof IFactDriverStudyAssignment,
        checked: boolean,
        dependencies?: number[]
    ) => {
        // Create a copy of the toggles array
        const temp = [...toggles]

        // Update the value of the current toggle
        temp[currentIndex] = checked

        // If there are dependencies and the toggle is being checked,
        // set the dependent toggles to true
        if (dependencies && checked) {
            dependencies.forEach(
                dependencyIndex => (temp[dependencyIndex] = true)
            )
        }

        // If the toggle is being unchecked, remove all dependent
        // toggles and remove the current key from the changeset
        if (!checked) {
            const pathsToRemove = formData
                .map((data, index) => {
                    if (data.dependencies?.includes(currentIndex)) {
                        temp[index] = false
                        return data.key
                    }
                    return null
                })
                .filter(Boolean)

            pathsToRemove.push(path)

            setChangeSets(pre =>
                pre.map(p =>
                    pathsToRemove.includes(p.path)
                        ? { ...p, value: undefined }
                        : p
                )
            )

            setErrors(pre => {
                if (typeof pre === 'object') {
                    const newErrors = { ...pre }
                    pathsToRemove.forEach(p => {
                        if (Object.hasOwn(newErrors, p)) {
                            delete newErrors[p]
                        }
                    })
                    return newErrors
                }
                return pre
            })
        }

        // Update the state with the modified toggles array
        setToggles(temp)
    }

    const handleSubmit = async () => {
        const submitErrors = {}
        const patch: {
            operation: 'Add' | 'Remove' | 'Replace'
            path: keyof IFactDriverStudyAssignment
            value: unknown
        }[] = []

        // loop through the changeSets array
        changeSets.forEach(({ path, value }, index) => {
            //only update the rows if toggles[index] is true
            if (toggles[index]) {
                // check if the field has a validate function
                if (typeof formData[index].validate === 'function') {
                    const error = formData[index].validate(value)

                    if (error) {
                        submitErrors[path] = error
                    }
                }

                // we have to make a exceptions for the Participation
                // and set the value to false if its undefined.
                if (path === 'Participation' && value === undefined) {
                    value = false
                }
                if (path === 'EscalationStepId' && value === undefined) {
                    value = steps[0].key
                }

                patch.push({
                    operation: 'Add',
                    path,
                    value: value,
                })
            }
        })

        // if there are no errors, update the rows
        if (Object.keys(submitErrors).length <= 0) {
            Promise.all(
                gridRef.current?.api.getSelectedRows().map(sr =>
                    patchFactDriverStudyAssignments({
                        id: parseInt(sr.Id, 10),
                        changeSets: patch,
                    })
                )
            )
                .finally(() => {
                    setToggles(pre => pre.map(() => false))
                    setChangeSets(pre =>
                        pre.map(p => ({ ...p, value: undefined }))
                    )
                    setErrors({})
                    toggleOpen()
                    gridRef.current?.api.deselectAll()
                    dispatch(
                        triggerMessage({
                            intent: 'success',
                            title: 'Drivers updated',
                            message: 'Drivers have been updated successfully',
                        })
                    )
                })
                .catch(() => {
                    dispatch(
                        triggerMessage({
                            intent: 'error',
                            title: 'Error updating drivers',
                            message: 'Drivers have not been updated',
                        })
                    )
                })
        } else {
            setErrors(submitErrors)
        }
    }

    return (
        <>
            <DialogContent>
                <div className={classes.attention}>
                    Time chosen will be in <strong>local time</strong>
                    <br />
                    Time will be saved as <strong>UTC time</strong>
                    <br />
                </div>
                <Text>
                    Do you want to set participation for {selectedRowCount}{' '}
                    driver(s) from this study?
                </Text>
                <div className={classes.grid} data-cy='driver-edit-form'>
                    {formData.map(
                        (
                            { title, dependencies, type, key, options },
                            index
                        ) => (
                            <Fragment key={key}>
                                <Field label='Edit'>
                                    <Switch
                                        data-cy={`toggle-${key}-switch`}
                                        checked={toggles[index]}
                                        onChange={ev =>
                                            handleToggleChange(
                                                index,
                                                key,
                                                ev.currentTarget.checked,
                                                dependencies
                                            )
                                        }
                                    />
                                </Field>

                                {type === 'text' ? (
                                    <Field
                                        label={title}
                                        required={
                                            typeof formData[index].validate ===
                                            'function'
                                        }
                                        validationMessage={errors[key]}
                                    >
                                        <Input
                                            data-cy={`${key}-input`}
                                            required={
                                                typeof formData[index]
                                                    .validate === 'function'
                                            }
                                            disabled={!toggles[index]}
                                            value={
                                                changeSets
                                                    .find(s => s.path === key)
                                                    ?.value?.toString() ?? ''
                                            }
                                            onChange={(_, data) =>
                                                handleCallback(data.value, key)
                                            }
                                        />
                                    </Field>
                                ) : type === 'bool' ? (
                                    <Switch
                                        data-cy={`${key}-switch`}
                                        label={title}
                                        labelPosition='above'
                                        disabled={!toggles[index]}
                                        checked={
                                            changeSets.find(s => s.path === key)
                                                ?.value === true
                                        }
                                        onChange={ev =>
                                            handleCallback(
                                                ev.currentTarget.checked,
                                                key
                                            )
                                        }
                                    />
                                ) : type === 'date' ? (
                                    <DateTimePicker
                                        label={title}
                                        disabled={!toggles[index]}
                                        value={
                                            (changeSets.find(
                                                s => s.path === key
                                            )?.value as Date) ?? null
                                        }
                                        onChange={date =>
                                            handleCallback(date, key)
                                        }
                                    />
                                ) : type === 'tag' ? (
                                    <Field label={title}>
                                        <Combobox
                                            placeholder='Select Tags'
                                            disabled={!toggles[index]}
                                            multiselect={true}
                                            onOptionSelect={(_e, data) => {
                                                handleCallback(
                                                    data.selectedOptions,
                                                    key
                                                )
                                            }}
                                        >
                                            {tags.map(tag => (
                                                <Option key={tag.key}>
                                                    {tag.text}
                                                </Option>
                                            ))}
                                        </Combobox>
                                    </Field>
                                ) : type === 'dropdown' ? (
                                    <Field label={title}>
                                        <Select
                                            data-cy={`${key}-select`}
                                            disabled={!toggles[index]}
                                            value={
                                                (changeSets.find(
                                                    s => s.path === key
                                                )?.value as string) ?? ''
                                            }
                                            onChange={(_e, data) =>
                                                handleCallback(data.value, key)
                                            }
                                        >
                                            {options.map(option => (
                                                <option
                                                    key={option.key}
                                                    value={option.key}
                                                >
                                                    {option.text}
                                                </option>
                                            ))}
                                        </Select>
                                    </Field>
                                ) : null}
                            </Fragment>
                        )
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    data-cy='save-study-button'
                    appearance='primary'
                    onClick={handleSubmit}
                    disabled={!(selectedRowCount < 250 && toggles.some(t => t))}
                >
                    Save
                </Button>
                <DialogTrigger disableButtonEnhancement>
                    <Button onClick={toggleOpen} data-cy='close-edit-button'>
                        Cancel
                    </Button>
                </DialogTrigger>
            </DialogActions>
        </>
    )
}

export default BatchEditDriversModal
