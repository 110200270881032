import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    MessageBar,
} from '@fluentui/react-components'

import AxiosStateTypeEnum from 'constants/AxiosStateTypeEnum'
import UpdatePGWCollectionForm from './UpdatePGWCollectionForm'
import { permissions } from 'slices/authSlice'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import sanitizeInput from 'helpers/sanitizeInput'
import { selectLastOdataQuery } from 'slices/gridSlice'
import { toast } from 'react-toastify'
import { useAppSelector } from 'store'
import { useState } from 'react'

type UpdatePGWCollectionProps = {
    toggleOpen: () => void
}

const defaultPGWCollectionObject: IPGWCollection = {
    id: undefined,
    name: undefined,
    description: undefined,
    collectionType: 'CUSTOMER',
    vinsList: undefined,
}

const UpdatePGWCollection = ({ toggleOpen }: UpdatePGWCollectionProps) => {
    const lastODataQuery = useAppSelector(selectLastOdataQuery)
    const hasCodevPermission = useAppSelector(permissions).otaCodev
    const [collection, setCollection] = useState<IPGWCollection>(
        defaultPGWCollectionObject
    )

    const [updateCollectionState, setUpdateCollectionState] =
        useState<AxiosStateTypeEnum>(AxiosStateTypeEnum.Unknown)

    const [
        fetchAllVinsFromFilteredVehicles,
        setFetchAllVinsFromFilteredVehicles,
    ] = useState<boolean>(false)

    const updateCollection = (collectionObject: IPGWCollection) => {
        const sanitizedCollection = sanitizeInput(collectionObject)
        reflectApiAxiosInstance
            .put(`/api/PGWCollection/${collection.id}`, sanitizedCollection)
            .then(() => {
                setUpdateCollectionState(AxiosStateTypeEnum.Success)
            })
            .catch(() => {
                setUpdateCollectionState(AxiosStateTypeEnum.Error)
            })
    }

    const collectAllVins = (
        collectionObject: IPGWCollection,
        index: number,
        vins: Array<string>
    ) => {
        const pageSize = 10000
        let nextQuery = `$top=${pageSize}&$skip=${pageSize * index}&$select=Vin`

        if (hasCodevPermission) {
            nextQuery += `&$filter=CarUsage ne '${'NotSpecified'}'`
        }

        const allMatchesUrl = lastODataQuery
            ?.replace('odata/VehicleViews', 'odata/admin/VehicleViews')
            .replace('$top=1000', nextQuery)

        reflectApiAxiosInstance
            .get(allMatchesUrl)
            .then(response => response?.data?.value)
            .then(data => {
                if (data?.length > 0) {
                    const aggregateVins = [...vins, ...data.map(d => d.Vin)]
                    collectAllVins(collectionObject, index + 1, aggregateVins)
                } else {
                    const aggregateCollection: IPGWCollection = {
                        ...collectionObject,
                        vinsList: vins,
                    }
                    updateCollection(aggregateCollection)
                }
            })
            .catch(error => toast.error(error))
    }

    const onUpdateCollection = () => {
        if (fetchAllVinsFromFilteredVehicles) {
            collectAllVins(collection, 0, [])
        } else {
            updateCollection(collection)
        }
    }

    const tryAgain = () => setUpdateCollectionState(AxiosStateTypeEnum.Unknown)

    return (
        <>
            {updateCollectionState === AxiosStateTypeEnum.Unknown && (
                <>
                    <DialogContent>
                        <UpdatePGWCollectionForm
                            collection={collection}
                            setCollection={setCollection}
                            setFetchAllVinsFromFilteredVehicles={
                                setFetchAllVinsFromFilteredVehicles
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance='primary'
                            id='updateCollectionAction'
                            disabled={
                                !(collection.name && collection.description)
                            }
                            onClick={onUpdateCollection}
                        >
                            Update collection
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={toggleOpen}>Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}
            {updateCollectionState === AxiosStateTypeEnum.Success && (
                <>
                    <DialogContent>
                        <MessageBar intent='success'>
                            Collection updated successfully.
                        </MessageBar>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={toggleOpen}>Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}
            {updateCollectionState === AxiosStateTypeEnum.Error && (
                <>
                    <DialogContent>
                        <MessageBar intent='error'>
                            Failed to Update Collection, please retry. Contact
                            technical support if the error persists.
                        </MessageBar>
                    </DialogContent>
                    <DialogActions>
                        <Button appearance='primary' onClick={tryAgain}>
                            Try again
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={toggleOpen}>Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}
        </>
    )
}

export default UpdatePGWCollection
