import { apiSlice } from './apiSlice'

const listsApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getList: builder.query<string[], string>({
            query: name => `/api/Lists/Values/${name}`,
        }),
    }),
})

export const { useGetListQuery } = listsApi
