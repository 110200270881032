import { dateComparator, dateRenderWithTime } from './dateHelpers'

import { ColDef } from 'ag-grid-community'
import CustomTextListFilter from 'components/AgGrid/Filters/CustomTextListFilter'
import DriverEscalationsCell from 'components/Drivers/DriverEscalationsCell'
import DriverParticipationCell from 'components/Drivers/DriverParticipationCell'

export const columnDefs: ColDef[] = [
    {
        colId: 'checkBoxSelect',
        checkboxSelection: true,
        lockPosition: true,
        lockPinned: true,
        pinned: 'left',
        suppressMenu: true,
        width: 55,
        suppressColumnsToolPanel: true,
    },
    {
        field: 'BaselineVersion',
    },
    {
        field: 'Cdsid',
        filter: CustomTextListFilter,
    },
    {
        field: 'ParticipationCount',
        cellRenderer: DriverParticipationCell,
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'EscalationCount',
        cellRenderer: DriverEscalationsCell,
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'DeliveryCountry',
    },
    {
        field: 'EngineDescription',
    },
    {
        field: 'FactoryComplete',
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: dateComparator,
            maxNumConditions: 1,
            buttons: ['reset', 'apply'],
        },
        cellRenderer: dateRenderWithTime,
    },
    {
        field: 'LastParticipation',
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: dateComparator,
            maxNumConditions: 1,
            buttons: ['reset', 'apply'],
        },
    },
    {
        field: 'Model',
    },
    {
        field: 'ModelYear',
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'Phone',
    },
    {
        field: 'Platform',
    },
    {
        field: 'PreferredEmail',
    },
    {
        field: 'ScheduledDelivery',
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: dateComparator,
            maxNumConditions: 1,
            buttons: ['reset', 'apply'],
        },
        cellRenderer: dateRenderWithTime,
    },
    {
        field: 'VehicleDelivered',
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: dateComparator,
            maxNumConditions: 1,
            buttons: ['reset', 'apply'],
        },
        cellRenderer: dateRenderWithTime,
    },
    {
        field: 'ScheduledReturn',
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: dateComparator,
            maxNumConditions: 1,
            buttons: ['reset', 'apply'],
        },
        cellRenderer: dateRenderWithTime,
    },
    {
        field: 'WorkshopDealer',
    },
    {
        field: 'Vin',
    },
]
