import * as yup from 'yup'

import {
    Button,
    Dropdown,
    Field,
    Input,
    Option,
    Switch,
    Text,
    Textarea,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'

import { DATETIME_FORMAT } from 'helpers/dateHelpers'
import DateTimePicker from 'components/Ui/DateTimePicker'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useGetWorkshopVisitTypesQuery } from 'api/workshop'
import { useMemo } from 'react'

interface IWorkshopVisitFormProps {
    data: IExtendedWorkshop
    onSubmit: (data: IExtendedWorkshop) => void
}

const useStyles = makeStyles({
    driver: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '8px',
        width: '33%',
        marginTop: '16px',
        ...shorthands.padding('16px'),
        ...shorthands.borderRadius('4px'),
        backgroundColor: '#f3f2f1',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: '8px',
        width: '100%',
        marginTop: '16px',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
    field: {
        marginBottom: '16px',
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        gridGap: '32px',
        marginTop: '32px',
    },
})

const initialValues: Partial<IExtendedWorkshop> = {
    WsArrival: null,
    WsInstalled: null,
    WsReady: null,
    WsComment: '',
    WsVisitTypeId: '',
    WsReturnedToDriver: null,
    WsWaiting: false,
    WsInitiatedBy: '',
    PrimaryDriver: '',
}

const WorkshopVisitForm = ({ data, onSubmit }: IWorkshopVisitFormProps) => {
    const classes = useStyles()

    const { data: visitTypes } = useGetWorkshopVisitTypesQuery()

    const { values, errors, setFieldValue, handleSubmit, dirty } = useFormik({
        initialValues: data ?? initialValues,
        enableReinitialize: true,
        validationSchema: yup.object({
            WsArrival: yup.date().nullable(),
            WsInstalled: yup
                .date()
                .nullable()
                .min(
                    yup.ref('WsArrival'),
                    '"Installed" date should be after "Arrival" date'
                ),
            WsReady: yup
                .date()
                .nullable()
                .min(
                    yup.ref('WsInstalled'),
                    '"Ready" date should be after "Installed" date'
                ),
            WsComment: yup.string().nullable(),
            WsVisitType: yup.string(),
            WsReturnedToDriver: yup.string().nullable(),
            WsWaiting: yup.boolean(),
        }),

        onSubmit: (value: IExtendedWorkshop) => onSubmit(value),
        validateOnChange: true,
        validateOnBlur: true,
    })

    const isInstalledDisabled = useMemo(
        () => !values.WsArrival,
        [values.WsArrival]
    )
    const isReadyDisabled = useMemo(
        () => !values.WsInstalled,
        [values.WsInstalled]
    )

    const isSaveDisabled = useMemo(
        () => !dirty || !!(errors.WsInstalled || errors.WsReady),
        [errors.WsInstalled, errors.WsReady, dirty]
    )

    const isReturnedToDriver = useMemo(() => {
        if (!values.WsReturnedToDriver) return false
        return (
            new Date(values.WsReturnedToDriver).getTime() < new Date().getTime()
        )
    }, [values.WsReturnedToDriver])

    const getBookingTimeString = (from, to) => {
        const fromHour = dayjs(from).format('HH:mm')
        const toHour = dayjs(to).format('HH:mm')
        return `${fromHour} - ${toHour}`
    }

    return (
        <>
            <div className={classes.driver}>
                <Text>
                    <b>Driver:</b> {values.PrimaryDriver}
                </Text>
                <Text>
                    <b>Phone:</b> {values.Phone}
                </Text>
                <Text>
                    <b>Email:</b> {values.PreferredEmail}
                </Text>
                <Text>
                    <b>VIN:</b> {values.Vin}
                </Text>
                <Text>
                    <b>Returned to Driver:</b>&nbsp;
                    {values.WsReturnedToDriver
                        ? dayjs(values.WsReturnedToDriver).format(
                              DATETIME_FORMAT
                          )
                        : 'Not returned yet..'}
                </Text>
                <Text>
                    <b>Latest Vehicle Version:</b> {values.LatestVehicleVersion}
                </Text>
                {values.WsInvitationFrom && (
                    <Text>
                        <b>Booked time</b>&nbsp;
                        {getBookingTimeString(
                            data.WsInvitationFrom,
                            data.WsInvitationTo
                        )}
                    </Text>
                )}
            </div>
            <div className={classes.grid}>
                <div className={classes.column}>
                    <DateTimePicker
                        nowTime
                        copyFromClipboard
                        clear
                        label='Arrival'
                        value={values.WsArrival}
                        onChange={newArrivalDate => {
                            setFieldValue('WsArrival', newArrivalDate)
                        }}
                        disabled={!data}
                        max={new Date()}
                    />
                    <DateTimePicker
                        nowTime
                        copyFromClipboard
                        label='Installed'
                        value={values.WsInstalled}
                        onChange={newWsInstalledDate => {
                            setFieldValue('WsInstalled', newWsInstalledDate)
                        }}
                        disabled={!data || isInstalledDisabled}
                        max={new Date()}
                        validationMessage={errors.WsInstalled}
                    />
                    <DateTimePicker
                        nowTime
                        copyFromClipboard
                        label='Ready'
                        value={values.WsReady}
                        onChange={newReadyDate => {
                            setFieldValue('WsReady', newReadyDate)
                        }}
                        disabled={!data || isReadyDisabled}
                        max={new Date()}
                        validationMessage={errors.WsReady}
                    />
                </div>
                <div className={classes.column}>
                    <Field label='Initiated by' className={classes.field}>
                        <Input
                            value={values.WsInitiatedBy ?? ''}
                            disabled={!data}
                            onChange={(_e, d) =>
                                setFieldValue('WsInitiatedBy', d.value)
                            }
                            size='large'
                        />
                    </Field>
                    <Field label='Visit Type' className={classes.field}>
                        <Dropdown
                            inlinePopup
                            value={values.WsVisitTypeId}
                            onOptionSelect={(_e, option) =>
                                setFieldValue(
                                    'WsVisitTypeId',
                                    option.optionValue
                                )
                            }
                            size='large'
                            disabled={!data}
                        >
                            {visitTypes?.map(visitType => (
                                <Option key={visitType} value={visitType}>
                                    {visitType}
                                </Option>
                            ))}
                        </Dropdown>
                    </Field>
                </div>

                <div className={classes.column}>
                    <Field label='Comment'>
                        <Textarea
                            value={values.WsComment ?? ''}
                            onChange={(_e, d) =>
                                setFieldValue('WsComment', d.value)
                            }
                            disabled={!data}
                            size='large'
                            rows={8}
                        />
                    </Field>
                </div>
            </div>
            <div className={classes.actions}>
                <Switch
                    checked={values.WsWaiting ?? false}
                    label='Driver Waiting'
                    disabled={!data}
                    onChange={(_e, target) => {
                        setFieldValue('WsWaiting', target.checked)
                    }}
                />

                <Switch
                    checked={isReturnedToDriver}
                    disabled={!data}
                    label='Returned to driver'
                    onChange={(_e, target) => {
                        const now = new Date()
                        setFieldValue(
                            'WsReturnedToDriver',
                            target.checked ? now.toISOString() : null
                        )
                        if (target.checked) {
                            setFieldValue('WsWaiting', false)
                        }
                    }}
                />
                <Button
                    appearance='primary'
                    disabled={isSaveDisabled}
                    onClick={() => handleSubmit()}
                >
                    Save
                </Button>
            </div>
        </>
    )
}

export default WorkshopVisitForm
