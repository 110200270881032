import { apiSlice } from './apiSlice'

interface IChangeSet {
    operation?: 'Add' | 'Remove' | 'Replace'
    path: string
    value: IExtendedWorkshop | string | number
}

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ['Workshop'],
})

const workshopApi = apiWithTags.injectEndpoints({
    endpoints: builder => ({
        initializeVisit: builder.mutation<
            null,
            {
                vehicleId: string
                testId: number
            }
        >({
            query: body => ({
                url: 'api/FactVehicleTestWorkshops/InitializeVisit',
                method: 'POST',
                body: body,
            }),
        }),
        updateWorkshopVisit: builder.mutation<
            null,
            {
                id: number
                changeSets: IChangeSet[]
            }
        >({
            query: ({ id, changeSets }) => ({
                url: `api/FactVehicleTestWorkshops/${id}/PatchWorkshopVisit/`,
                method: 'PATCH',
                body: changeSets,
            }),
            invalidatesTags: ['Workshop'],
        }),
        // TODO: Maybe we can use the above mutation instead of this one
        oldUpdateWorkshopVisit: builder.mutation<
            IExtendedWorkshop,
            {
                id: number
                field: string
                value: string | boolean | number | { id: number }
            }
        >({
            query: ({ id, field, value }) => ({
                url: `api/FactVehicleTestWorkshops/${id}/PatchWorkshopVisit/`,
                method: 'PATCH',
                body: [
                    {
                        OperationType:
                            typeof value === 'boolean' ||
                            typeof value === 'number' ||
                            (value && value !== '')
                                ? 'Add'
                                : 'Remove',
                        path:
                            field === 'Classification'
                                ? 'VehicleClassification'
                                : field,
                        value:
                            field === 'Classification' &&
                            typeof value === 'object'
                                ? value?.id
                                : typeof value === 'boolean' ||
                                  typeof value === 'number' ||
                                  (value && value !== '')
                                ? value
                                : null,
                    },
                ],
            }),
            invalidatesTags: ['Workshop'],
        }),
        putIExtendedWorkshop: builder.mutation<null, IExtendedWorkshop>({
            query: data => ({
                url: `api/FactVehicleTestWorkshops/${data.Id}/PutWorkshopVisit`,
                method: 'PUT',
                body: {
                    wsComment: data.WsComment,
                    wsWaiting: data.WsWaiting,
                    wsReturnedToDriver: data.WsReturnedToDriver,
                    wsInitiatedBy: data.WsInitiatedBy,
                    wsArrival: data.WsArrival,
                    wsInstalled: data.WsInstalled,
                    wsReady: data.WsReady,
                    wsVisitType: data.WsVisitTypeId,
                    wsNotificationType: data.WsNotificationTypeId,
                    vehicleClassification: data.Classification,
                },
            }),
            invalidatesTags: ['Workshop'],
        }),
        setMissedAppointments: builder.mutation<null, number>({
            query: id =>
                `api/FactVehicleTestWorkshops/${id}/SetMissedAppointments `,
        }),

        getWorkshopViewsById: builder.query<IExtendedWorkshop[], string>({
            query: id =>
                `odata/WorkshopViews?$filter=TestpurposeId eq ${id} and Deleted eq false`,
            transformResponse: (
                response: IODataResponse<IExtendedWorkshop[]>
            ) => response.value,
            providesTags: ['Workshop'],
        }),

        getWorkshopVisitTypes: builder.query<string[], void>({
            query: () => 'api/Lists/Values/WorkshopVisitTypes',
        }),
        getWorkshopNotificationTypes: builder.query<string[], void>({
            query: () => 'api/Lists/Values/WorkshopNotificationTypes',
        }),
    }),
})

export const {
    useInitializeVisitMutation,
    useUpdateWorkshopVisitMutation,
    useOldUpdateWorkshopVisitMutation,
    usePutIExtendedWorkshopMutation,
    useSetMissedAppointmentsMutation,

    useGetWorkshopViewsByIdQuery,

    useGetWorkshopVisitTypesQuery,
    useGetWorkshopNotificationTypesQuery,
} = workshopApi
