enum Permissions {
    batchEdit = 'Reflect.Functions.BatchEdit',
    columnsEdit = 'Reflect.Functions.Columns.Edit',
    contactsManage = 'Reflect.Functions.Contacts.Manage',
    driver = 'Reflect.Functions.Driver',
    driverStudies = 'Reflect.Views.DriverStudies',
    driverStudiesDriver = 'Reflect.Views.DriverStudies.Driver',
    driverStudiesManage = 'Reflect.Functions.DriverStudies.Manage',
    email = 'Reflect.Functions.Communication.Email',
    escalationsManage = 'Reflect.Functions.Escalations.Manage',
    orderDriver = 'Reflect.Functions.OrderDriver',
    otaCodev = 'Reflect.Function.OTA.Codev',
    otaManage = 'Reflect.Functions.OTA.Manage',
    pgwCreate = 'Reflect.Functions.PGW.Create',
    pgwSync = 'Reflect.Functions.PGW.Sync',
    rivendellRead = 'Rivendell.Editor.Read',
    rivendellEdit = 'Rivendell.Editor.Edit',
    searchContacts = 'Reflect.Functions.Search.Contacts',
    sms = 'Reflect.Functions.Communication.SMS',
    tagListManage = 'Reflect.Functions.Taglist.Manage',
    newsListManage = 'Reflect.Functions.Newslist.Manage',
    vehicleClassification = 'Reflect.Functions.Vehicles.VehicleClassification',
    vehicleImport = 'Reflect.Functions.VehicleImport',
    vehicleTests = 'Reflect.Views.Vehicletests',
    vehicleTestsManage = 'Reflect.Functions.Vehicletests.Manage',
    workshop = 'Reflect.Views.Workshop',
    workshopManage = 'Reflect.Functions.Workshop.Manage',
}

export default Permissions
