import {
    Link,
    SkeletonItem,
    Text,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'

import { Fragment } from 'react'
import { dateStringRenderWithTime } from 'helpers/dateHelpers'
import { useGetTestsOfVehiclesByVehicleIdQuery } from 'api/vehicleTests'
import { Open16Regular } from '@fluentui/react-icons'

interface IVehicleTestListProps {
    id: string
}

const useStyles = makeStyles({
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 140px 140px 100px',
        gridGap: '8px',
        ...shorthands.margin('16px'),
        '> span': {
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
    noTests: {
        fontStyle: 'italic',
        gridColumnStart: 1,
        gridColumnEnd: 6,
    },
})

const formatDateTime = (date: string) =>
    date ? dateStringRenderWithTime(date) : 'Not Defined'

const VehicleTestList = ({ id }: IVehicleTestListProps) => {
    const classes = useStyles()
    const { data: vehicleTests, isLoading } =
        useGetTestsOfVehiclesByVehicleIdQuery(id)

    return (
        <div data-cy='vehicle-details-tests-body' className={classes.grid}>
            <Text weight='bold'>Test</Text>
            <Text weight='bold'>Description</Text>
            <Text weight='bold'>Start Date</Text>
            <Text weight='bold'>End Date</Text>
            <Text weight='bold'>Status</Text>

            {isLoading ? (
                [...Array(15)].map((_, i) => <SkeletonItem key={i} />)
            ) : vehicleTests?.length ? (
                vehicleTests?.map(test => (
                    <Fragment key={test.id}>
                        <Text
                            title={test.title?.length > 20 ? test.title : null}
                        >
                            <Link
                                href={`/vehicletests/${test.id}`}
                                target='_blank'
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                    }}
                                >
                                    <div
                                        style={{
                                            overflow: 'hidden',
                                            maxWidth: '90%',
                                        }}
                                    >
                                        <u>{test.title}</u>
                                    </div>
                                    <Open16Regular />
                                </div>
                            </Link>
                        </Text>
                        <Text
                            title={
                                test.description?.length > 20
                                    ? test.description
                                    : null
                            }
                        >
                            {test.description ?? ''}
                        </Text>
                        <Text>{formatDateTime(test.startDate)}</Text>
                        <Text>{formatDateTime(test.endDate)}</Text>
                        <Text>{test.vehicleStatus}</Text>
                    </Fragment>
                ))
            ) : (
                <Text size={400} className={classes.noTests}>
                    This vehicle is not associated with any vehicle tests. If
                    you believe this is a mistake, please reach out to the
                    administrator for assistance.
                </Text>
            )}
        </div>
    )
}

export default VehicleTestList
