import Content from 'components/Ui/Layout/Content'
import VehicleTestGrid from 'components/VehicleTest/VehicleTestGrid'
import VehicleTestHeader from 'components/VehicleTest/VehicleTestHeader'
import { useVehicleTest } from './VehicleTest'

const VehicleTestIndex = () => {
    const { vehicleTest } = useVehicleTest()

    return (
        <>
            <VehicleTestHeader vehicleTest={vehicleTest} />

            <Content>
                <VehicleTestGrid vehicleTest={vehicleTest} />
            </Content>
        </>
    )
}

export default VehicleTestIndex
