import { Link, MessageBar, MessageBarBody } from '@fluentui/react-components'

interface IUploadErrorProps {
    info: string
}

const UploadError = ({ info }: IUploadErrorProps) => {
    return (
        <div>
            <MessageBar intent='error'>
                <MessageBarBody
                    style={{
                        whiteSpace: 'initial',
                        wordWrap: 'break-word',
                    }}
                >
                    Something went wrong while uploading the file. The server
                    responded with the following error:&nbsp;
                    <strong>
                        &quot;
                        {info}
                        &quot;
                    </strong>
                    <br />
                    Make sure you are using the&nbsp;
                    <Link
                        href={`${process.env.PUBLIC_URL}/files/templates/VehicleImportTemplate.xlsx`}
                    >
                        VehicleImportTemplate
                    </Link>
                    .
                    <br />
                    Please try again and contact technical support if the error
                    persists.
                </MessageBarBody>
            </MessageBar>
        </div>
    )
}

export default UploadError
