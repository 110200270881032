import {
    MutableRefObject,
    PropsWithChildren,
    createContext,
    createRef,
    useContext,
    useState,
} from 'react'

import { AgGridReact } from 'ag-grid-react'

interface IGridContext {
    gridRef?: MutableRefObject<AgGridReact>
    setGridRef?: (ref: MutableRefObject<AgGridReact>) => void
}

const gridRef = createRef<AgGridReact>()

const initial: IGridContext = {
    gridRef,
}

export const GridContext = createContext<IGridContext>(initial)

export const GridContextProvider = ({ children }: PropsWithChildren) => {
    const [state, setState] = useState({ gridRef })

    return (
        <GridContext.Provider
            value={{
                ...state,
                setGridRef: (ref: MutableRefObject<AgGridReact>) => {
                    setState(pre => ({ ...pre, gridRef: ref }))
                },
            }}
        >
            {children}
        </GridContext.Provider>
    )
}

export const useGrid = () => useContext(GridContext)
