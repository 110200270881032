import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    SkeletonItem,
    Text,
    makeStyles,
} from '@fluentui/react-components'

import { Person48Regular } from '@fluentui/react-icons'
import { dateStringRenderWithTime } from 'helpers/dateHelpers'
import { permissions } from 'slices/authSlice'
import { useAppSelector } from 'store'
import useDialogStyles from 'components/styles/dialogStyles'
import { useGetDriverStudiesForDriverQuery } from 'api/driverStudyParticipation'
import { useState } from 'react'

interface IParticipationDialogProps {
    data: IDriverStudyDriver
}

const useStyles = makeStyles({
    attention: {
        display: 'block',
        fontStyle: 'italic',
        marginTop: '8px',
        marginBottom: '8px',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr) 80px',
        gridGap: '8px',
        alignItems: 'center',
        marginTop: '16px',
        marginBottom: '24px',
    },
    divider: {
        height: '1px',
        backgroundColor: '#eee',
        gridColumnStart: 1,
        gridColumnEnd: 6,
    },
})

const ParticipationDialog = ({ data }: IParticipationDialogProps) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles()
    const dialogClasses = useDialogStyles()

    const { data: driverStudies, isLoading } =
        useGetDriverStudiesForDriverQuery(data.Id, {
            skip: !open,
        })
    // const [setParticipationForDriver] = useSetParticipationForDriverMutation()

    const hasPermission = useAppSelector(permissions).driverStudiesManage

    const formatDateTime = (date: string) =>
        date ? dateStringRenderWithTime(date) : 'Not Defined'

    const setParticipation = async () => {
        // const onDate = dayjs().toISOString()
        // TODO: This is not the correct id for this action, we need to change it to the correct one
        // the correct on is the id for the driver in the driverstudy assignment table, not the driver id...
        // don't know how to get that id yet... need to figure it out
        // setParticipationForDriver({
        //     participationId: parseInt(data.Id),
        //     onDate,
        // })
        // eslint-disable-next-line no-console
        console.log('setParticipation called')
    }

    return (
        <Dialog open={open} onOpenChange={(_e, d) => setOpen(d.open)}>
            <DialogTrigger>
                <Button
                    data-cy='participation-dialog-button'
                    appearance='transparent'
                    icon={<Person48Regular />}
                />
            </DialogTrigger>
            <DialogSurface className={dialogClasses.dialogSurface}>
                <DialogBody className={dialogClasses.dialogBody}>
                    <DialogTitle>
                        {`Participation information for ${
                            data?.Cdsid || 'this driver'
                        }`}
                    </DialogTitle>
                    <DialogContent>
                        <div
                            data-cy='participation-grid'
                            className={classes.grid}
                        >
                            <Text weight='bold'>Title</Text>
                            <Text weight='bold'>Description</Text>
                            <Text weight='bold'>Participation</Text>
                            <Text weight='bold'>Participation Date</Text>
                            <Text weight='bold'>Model</Text>
                            <div className={classes.divider} />

                            {isLoading &&
                                [...Array(15)].map((_, i) => (
                                    <SkeletonItem key={i} size={16} />
                                ))}
                            {driverStudies?.map(driverStudy => (
                                <>
                                    <Text>{driverStudy.title}</Text>
                                    <Text>{driverStudy.description}</Text>
                                    <Text>
                                        <input
                                            type='checkbox'
                                            checked={driverStudy.participation}
                                            onChange={setParticipation}
                                            disabled={!hasPermission}
                                        />
                                    </Text>
                                    <Text>
                                        {formatDateTime(
                                            driverStudy.participationDate
                                        )}
                                    </Text>
                                    <Text>{driverStudy.model}</Text>
                                </>
                            ))}
                        </div>

                        <Text block>
                            {`Total number of studies assigned to driver: ${driverStudies?.length}`}
                        </Text>
                        <Text block>
                            {`Number of studies assigned to driver with participation: ${
                                driverStudies?.filter(
                                    driverStudy => driverStudy.participation
                                ).length
                            }`}
                        </Text>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger>
                            <Button appearance='primary'>Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default ParticipationDialog
