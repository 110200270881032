import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    SkeletonItem,
    Text,
    makeStyles,
} from '@fluentui/react-components'
import { Edit48Regular, History48Regular } from '@fluentui/react-icons'
import { Fragment, useState } from 'react'

import ROUTES from 'constants/routes'
import { dateStringRenderWithTime } from 'helpers/dateHelpers'
import { permissions } from 'slices/authSlice'
import { useAppSelector } from 'store'
import useDialogStyles from 'components/styles/dialogStyles'
import { useGetDriverEscalationsQuery } from 'api/drivers'
import { useNavigate } from 'react-router-dom'

interface IEscalationListDialogProps {
    data: IDriverStudyDriver
}

const useStyles = makeStyles({
    attention: {
        display: 'block',
        fontStyle: 'italic',
        marginTop: '8px',
        marginBottom: '8px',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr) 50px',
        gridGap: '8px',
        alignItems: 'center',
        marginTop: '16px',
        marginBottom: '24px',
    },
    divider: {
        height: '1px',
        backgroundColor: '#eee',
        gridColumnStart: 1,
        gridColumnEnd: 5,
    },
})

const EscalationListDialog = ({ data }: IEscalationListDialogProps) => {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const classes = useStyles()
    const dialogClasses = useDialogStyles()

    const {
        vehicleTests: hasVehicleTestPermissions,
        driverStudies: hasDriverStudiesPermissions,
    } = useAppSelector(permissions)

    const { data: driverEscalations, isLoading } = useGetDriverEscalationsQuery(
        data.Id,
        {
            skip: !open,
        }
    )

    const formatDateTime = (date: Date) =>
        date ? dateStringRenderWithTime(date) : 'Not Defined'

    const escalationColumns = (
        columns: {
            id: number
            title: string
            status: string
            date: Date
            permission: boolean
            action: () => void
        }[]
    ) => (
        <div className={classes.grid}>
            <Text weight='bold'>Title</Text>
            <Text weight='bold'>Status</Text>
            <Text weight='bold'>Date</Text>
            <Text weight='bold'>&nbsp;</Text>
            <div className={classes.divider} />

            {columns.map(item => (
                <Fragment key={item.id}>
                    <Text data-cy='escalation-title'>{item.title}</Text>
                    <Text data-cy='escalation-status'>{item.status}</Text>
                    <Text>{formatDateTime(item.date)}</Text>
                    {item.permission && (
                        <Button
                            data-cy='escalation-link-button'
                            appearance='transparent'
                            icon={<Edit48Regular />}
                            onClick={() => item.action()}
                            title='Navigate to the escalation'
                        />
                    )}
                    <div className={classes.divider} />
                </Fragment>
            ))}
        </div>
    )

    return (
        <Dialog open={open} onOpenChange={(_e, d) => setOpen(d.open)}>
            <DialogTrigger>
                <Button
                    data-cy='escalation-list-dialog-button'
                    appearance='transparent'
                    icon={<History48Regular />}
                />
            </DialogTrigger>
            <DialogSurface
                className={dialogClasses.dialogSurface}
                data-cy='test-escalation-dialog'
            >
                <DialogBody className={dialogClasses.dialogBody}>
                    <DialogTitle data-cy='test-escalation-title'>
                        Escalations for {driverEscalations?.actorName} (
                        {data?.Cdsid || 'this driver'})
                    </DialogTitle>
                    <DialogContent>
                        <Text size={500}>Vehicle Test Escalations</Text>
                        {isLoading && <SkeletonItem size={16} />}

                        {!driverEscalations?.vehicleTestEscalations.length ? (
                            <Text className={classes.attention}>
                                This driver has not been escalated in any
                                vehicle tests
                            </Text>
                        ) : (
                            escalationColumns(
                                driverEscalations?.vehicleTestEscalations.map(
                                    item => ({
                                        id: item.vehicleTestId,
                                        title: item.vehicleTestTitle,
                                        status: item.escalationStepName,
                                        date: item.insertDate,
                                        permission: hasVehicleTestPermissions,
                                        action: () =>
                                            navigate(
                                                `/${
                                                    ROUTES.VEHICLE_TESTS
                                                }/${String(item.vehicleTestId)}`
                                            ),
                                    })
                                )
                            )
                        )}

                        <Text size={500}>Driver Study Escalations</Text>
                        {isLoading && <SkeletonItem size={16} />}
                        {!driverEscalations?.driverStudyEscalations.length ? (
                            <Text className={classes.attention}>
                                This driver has not been escalated in any driver
                                studies
                            </Text>
                        ) : (
                            escalationColumns(
                                driverEscalations?.driverStudyEscalations.map(
                                    item => ({
                                        id: item.driverStudyId,
                                        title: item.driverStudyTitle,
                                        status: item.escalationStepName,
                                        date: item.insertDate,
                                        permission: hasDriverStudiesPermissions,
                                        action: () =>
                                            navigate(
                                                `/${
                                                    ROUTES.DRIVER_STUDIES
                                                }/${String(item.driverStudyId)}`
                                            ),
                                    })
                                )
                            )
                        )}
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger>
                            <Button appearance='primary'>Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default EscalationListDialog
