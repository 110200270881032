import {
    AddSquareMultiple24Regular,
    Archive24Regular,
    ArrowExport24Regular,
    Book24Regular,
    Dismiss24Filled,
    Filter24Regular,
    Key24Regular,
    Mail24Regular,
    Pen24Regular,
    Person24Regular,
    Phone24Regular,
    Send24Regular,
    VehicleCar24Regular,
    Wifi124Regular,
} from '@fluentui/react-icons'
import {
    Button,
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
} from '@fluentui/react-components'
import {
    useArchiveVehicleTestMutation,
    useEditVehicleTestMutation,
} from 'api/vehicleTests'

import ActionsIActionsMenu from 'components/Ui/Layout/ActionsMenu'
import CreateKeyCardsModal from './CreateKeyCardsModal'
import CreatePGWCollectionModal from './CreatePGWCollectionModal'
import DeleteVehicleModal from './DeleteVehicleModal'
import DriverStudyActions from 'components/Actions/DriverStudyActions'
import EmailActions from 'components/Actions/EmailActions'
import Header from 'components/Ui/Layout/Header'
import { IActionsMenu } from 'Types/ActionsMenu'
import ModalStatusEnum from 'constants/ModalStatusEnum'
import ROUTES from 'constants/routes'
import Restricted from 'components/Restricted'
import SMSActions from 'components/Actions/SMSActions'
import VehicleTestForm from './VehicleTestForm'
import VehiclesBatchEditModal from './VehiclesBatchEditModal'
import { getExportOptions } from 'helpers/excelExportHelpers'
import { toast } from 'react-toastify'
import { triggerMessage } from 'slices/messageSlice'
import { useAppDispatch } from 'store'
import { useGrid } from 'contexts/GridContext'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

interface IVehicleTestHeaderProps {
    vehicleTest: IVehicleTest
}

const VehicleTestHeader = ({ vehicleTest }: IVehicleTestHeaderProps) => {
    const [archiveVehicleTest] = useArchiveVehicleTestMutation()

    const navigate = useNavigate()
    const { gridRef } = useGrid()

    const showArchiveFailureMessage = error => {
        toast.error(error.data)
    }

    const [selectedRows, setSelectedRows] = useState([])
    gridRef.current?.api.addEventListener('selectionChanged', () => {
        setSelectedRows(gridRef.current?.api.getSelectedRows() ?? [])
    })

    const handleArchiveClick = () => {
        archiveVehicleTest(vehicleTest.id).then(response => {
            if ('error' in response) {
                showArchiveFailureMessage(response.error)
            } else {
                toast.success(`Successfully archived ${vehicleTest.title}`)
            }
        })
    }

    const exportToExcel = () =>
        gridRef.current?.api.exportDataAsExcel(
            getExportOptions(
                selectedRows.length,
                gridRef.current?.api.getDisplayedRowCount()
            )
        )

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(null)

    const openDialog = (body: JSX.Element, title: string) => {
        setIsDialogOpen(true)
        setDialogContent({ body, title })
    }

    const dispatch = useAppDispatch()
    const [updateVehicle] = useEditVehicleTestMutation()

    const onEditVehicleTest = async (
        { plannedFinish, plannedStart, ...rest }: IVehicleTest,
        setSubmitting: (isSubmitting: boolean) => void
    ) => {
        const response = await updateVehicle({
            id: vehicleTest.id,
            vehicleTest: {
                ...rest,
                plannedStart: plannedStart
                    ? new Date(plannedStart).toISOString()
                    : null,
                plannedFinish: plannedFinish
                    ? new Date(plannedFinish).toISOString()
                    : null,
            },
        })
        setSubmitting(false)
        if ('data' in response) {
            dispatch(
                triggerMessage({
                    intent: 'success',
                    message: 'Vehicle test updated successfully.',
                })
            )
            setIsDialogOpen(false)
        } else if ('data' in response.error) {
            dispatch(
                triggerMessage({
                    intent: 'error',
                    title: 'Error',
                    message: `${response.error.data}`,
                })
            )
        }
    }

    const menuItems: IActionsMenu[] = [
        {
            label: 'Information',
            icon: <Book24Regular />,
            onClick: () =>
                openDialog(
                    <VehicleTestForm
                        data={vehicleTest}
                        onSave={onEditVehicleTest}
                    />,
                    'Test Information'
                ),
        },
        {
            label: 'Archive Test',
            icon: <Archive24Regular />,
            disabled: vehicleTest.status === 'Deleted' || vehicleTest.archived,
            onClick: () => handleArchiveClick(),
        },
        {
            label: 'Edit',
            icon: <Pen24Regular />,
            disabled: selectedRows.length <= 0,
            onClick: () =>
                openDialog(
                    <VehiclesBatchEditModal
                        testId={vehicleTest.id}
                        toggleOpen={() => setIsDialogOpen(false)}
                    />,
                    `Edit ${selectedRows.length}`
                ),
        },
        {
            label: 'Remove',
            icon: <Dismiss24Filled />,
            disabled: selectedRows.length <= 0,
            onClick: () =>
                openDialog(
                    <DeleteVehicleModal
                        testId={vehicleTest.id}
                        toggleOpen={() => setIsDialogOpen(false)}
                    />,
                    `Remove ${selectedRows.length}`
                ),
        },
        {
            label: 'Create KeyCards',
            icon: <Key24Regular />,
            children: [
                {
                    label: 'Create for Selected Drivers',
                    icon: <AddSquareMultiple24Regular />,
                    disabled: selectedRows.length < 1,
                    onClick: () =>
                        openDialog(
                            <CreateKeyCardsModal
                                vehicleTest={vehicleTest}
                                toggleOpen={() => () => setIsDialogOpen(false)}
                                selectedRows={selectedRows}
                                modalStatus={ModalStatusEnum.Selected}
                            />,
                            'Create keycards for Selected Drivers'
                        ),
                },
                {
                    label: 'Create for Filtered Drivers',
                    icon: <Filter24Regular />,
                    onClick: () =>
                        openDialog(
                            <CreateKeyCardsModal
                                vehicleTest={vehicleTest}
                                toggleOpen={() => () => setIsDialogOpen(false)}
                                selectedRows={selectedRows}
                                modalStatus={ModalStatusEnum.Filtered}
                            />,
                            'Create keycards for Filtered Drivers'
                        ),
                },
            ],
        },
        {
            label: 'Create PGW Collection',
            icon: <Wifi124Regular />,
            restricted: 'pgwCreate',
            disabled: vehicleTest.status === 'Deleted' || vehicleTest.archived,
            onClick: () =>
                openDialog(
                    <CreatePGWCollectionModal
                        toggleOpen={() => setIsDialogOpen(false)}
                    />,
                    'Create PGW Collection'
                ),
        },
        {
            label: 'Export to Excel',
            icon: <ArrowExport24Regular />,
            onClick: () => exportToExcel(),
        },
        {
            label: 'Driver Studies',
            icon: <Book24Regular />,
            restricted: 'driverStudiesManage',
            children: [
                {
                    label: 'Add Selected Drivers',
                    icon: <AddSquareMultiple24Regular />,
                    disabled: selectedRows.length < 1,
                    onClick: () =>
                        openDialog(
                            <DriverStudyActions
                                toggleOpen={() => setIsDialogOpen(false)}
                                modalStatus={ModalStatusEnum.Selected}
                            />,
                            'Add Selected drivers to a study'
                        ),
                },
                {
                    label: 'Add Filtered Drivers',
                    icon: <Filter24Regular />,
                    onClick: () =>
                        openDialog(
                            <DriverStudyActions
                                toggleOpen={() => setIsDialogOpen(false)}
                                modalStatus={ModalStatusEnum.Filtered}
                            />,
                            'Add Filtered drivers to a study'
                        ),
                },
            ],
        },
        {
            label: 'Contact Drivers',
            icon: <Person24Regular />,
            children: [
                {
                    label: 'Send SMS',
                    icon: <Phone24Regular />,
                    restricted: 'sms',
                    children: [
                        {
                            label: 'Send SMS to selected drivers',
                            disabled: !selectedRows.length,
                            icon: <Send24Regular />,
                            onClick: () =>
                                openDialog(
                                    <SMSActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Selected}
                                    />,
                                    'Send SMS Message to selected drivers'
                                ),
                        },
                        {
                            label: 'Send SMS to Filtered drivers',
                            icon: <Filter24Regular />,
                            onClick: () =>
                                openDialog(
                                    <SMSActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Filtered}
                                    />,
                                    'Send SMS Message to filtered drivers'
                                ),
                        },
                    ],
                },
                {
                    label: 'Send Email',
                    icon: <Mail24Regular />,
                    restricted: 'email',
                    children: [
                        {
                            label: 'Send Email to Selected drivers',
                            disabled: !selectedRows.length,
                            icon: <Send24Regular />,
                            onClick: () =>
                                openDialog(
                                    <EmailActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Selected}
                                    />,
                                    'Send Email to Selected drivers'
                                ),
                        },
                        {
                            label: 'Send Email to Filtered drivers',
                            icon: <Filter24Regular />,
                            onClick: () =>
                                openDialog(
                                    <EmailActions
                                        toggleOpen={() =>
                                            setIsDialogOpen(false)
                                        }
                                        modalStatus={ModalStatusEnum.Filtered}
                                    />,
                                    'Send Email to Filtered drivers'
                                ),
                        },
                    ],
                },
            ],
        },
    ]

    if (!vehicleTest) {
        return null
    }

    return (
        <>
            <Header
                title={`Test "${vehicleTest.title}" (${vehicleTest.status})`}
                personalViewType='TestPurpose'
            />

            <ActionsIActionsMenu
                goBack={{
                    label: 'Vehicle Tests',
                    onClick: () => navigate('/vehicletests'),
                }}
                items={menuItems}
                extraComponents={
                    <Restricted to='workshop'>
                        <Button
                            appearance='subtle'
                            icon={<VehicleCar24Regular />}
                            onClick={() => navigate(`${ROUTES.WORKSHOP}`)}
                        >
                            Go to Workshop View
                        </Button>
                    </Restricted>
                }
            />

            <Dialog
                open={isDialogOpen}
                onOpenChange={(_event, data) => setIsDialogOpen(data.open)}
            >
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>{dialogContent?.title}</DialogTitle>
                        {dialogContent?.body}
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    )
}

export default VehicleTestHeader
