/* eslint-disable prettier/prettier */
import {
    ColDef,
    FilterChangedEvent,
    GetRowIdParams,
    GridReadyEvent,
} from 'ag-grid-community'
import {
    defaultColDef,
    getContextMenuItems,
    sideBar,
    statusBar,
} from 'helpers/gridHelper'
import { useAppDispatch, useAppSelector } from 'store'
import { useEffect, useMemo, useRef, useState } from 'react'

import AgGridContainer from 'components/Ui/Layout/AgGridContainer'
import { AgGridReact } from 'ag-grid-react'
import Loader from 'components/Ui/Loader'
import OdataProvider from 'ag-grid-odata'
import PortalNewsActions from './PortalNewsActions'
import { getRivendellOdataApi } from 'helpers/oDataApiHelper'
import { permissions } from 'slices/authSlice'
import { setLastOdataQuery } from 'slices/gridSlice'
import { useGetVehicleGroupListsQuery } from 'api/vehicleGroupList'
import { useGrid } from 'contexts/GridContext'
import useGridFilterUrl from 'hooks/useGridFilterUrl'

interface IPortalNewsGridProps {
    filterOption: string
    isLoading: boolean
}

const PortalNewsGrid = ({ isLoading, filterOption }: IPortalNewsGridProps) => {
    const gridRef = useRef<AgGridReact>(null)
    const dispatch = useAppDispatch()
    const hasPermission = useAppSelector(permissions).rivendellEdit

    const [dataProvider, setDataProvider] = useState<OdataProvider>()
    const [isGridReady, setIsGridReady] = useState(false)
    const [pushFiltersToHistory, setFiltersFromHistory] = useGridFilterUrl()

    const { setGridRef } = useGrid()
    const { data: newsList } = useGetVehicleGroupListsQuery('NewsList')

    const columnDefs: ColDef[] = useMemo(() => {
        return [
            {
                field: 'Title',
            },
            { field: 'Introduction' },
            {
                field: 'Text',
                headerName: 'Body text',
            },
            { field: 'PublishDate' },
            {
                field: 'UnPublishDate',
                headerName: 'Unpublish Date',
            },
            { field: 'VirtualStatus', headerName: 'Status' },
            {
                field: 'Created',
                headerName: 'Created Date',
                sort: 'desc',
            },
            {
                field: 'CreatedBy',
            },
            {
                field: 'Rivendell_BridgeNewsCdbMarketTypes',
                headerName: 'Markets',
                valueGetter: params => {
                    return params?.data?.Rivendell_BridgeNewsCdbMarketTypes?.map(
                        market => market.CdbMarketTypeId
                    ).join(', ')
                },
            },
            {
                field: 'Rivendell_BridgeNewsVehicleGroups',
                headerName: 'News Lists',
                valueGetter: params => {
                    const bridgeNewsGroups =
                        params?.data?.Rivendell_BridgeNewsVehicleGroups

                    if (
                        Array.isArray(bridgeNewsGroups) &&
                        bridgeNewsGroups.length > 0
                    ) {
                        return bridgeNewsGroups
                            .map(group => {
                                const matchingNews = newsList.find(
                                    news => news.id === group.VehicleGroupId
                                )
                                return matchingNews ? matchingNews.name : ''
                            })
                            .filter(name => name)
                            .join(', ')
                    }
                    return ''
                },
            },
            {
                field: 'Modified',
            },
            {
                field: 'ModifiedBy',
            },
            {
                field: 'ImageUrl',
            },
            {
                field: 'ThumbnailUrl',
            },
            hasPermission && {
                cellRenderer: PortalNewsActions,
                maxWidth: 120,
                filter: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: true,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                sortable: false,
            },
        ].filter(Boolean) as ColDef[]
    }, [hasPermission, newsList])

    const onGridReady = (params: GridReadyEvent) => {
        setIsGridReady(true)

        const portalNewsOdataProvider = new OdataProvider({
            callApi: (query: string) =>
                getRivendellOdataApi<IPortalNews[]>(
                    `odata/PortalNews${query}`
                ).then(response => {
                    dispatch(setLastOdataQuery(`odata/PortalNews${query}`))
                    return {
                        '@odata.count': response?.data?.value?.length,
                        value: response?.data?.value,
                    }
                }),
        })

        setDataProvider(portalNewsOdataProvider)
        params.api.setServerSideDatasource(portalNewsOdataProvider)
        setGridRef(gridRef)
        setFiltersFromHistory(params.api)
    }

    const onFilterChanged = (event: FilterChangedEvent) => {
        pushFiltersToHistory(event.api)
    }

    useEffect(() => {
        if (isGridReady && gridRef.current && dataProvider) {
            dataProvider.beforeRequest = query => {
                switch (filterOption) {
                    case 'published':
                        query.filter = ['VirtualStatus eq \'Published\'']
                        break
                    case 'unpublished':
                        query.filter = [
                            '(VirtualStatus eq \'Ended\' or VirtualStatus eq \'Draft\')',
                        ]
                        break
                    default:
                        query.filter = []
                        query.expand = [
                            'Rivendell_BridgeNewsCdbMarketTypes, Rivendell_BridgeNewsVehicleGroups',
                        ]
                        break
                }
            }

            gridRef.current?.api.refreshServerSide()
        }
    }, [dataProvider, isGridReady, filterOption])

    if (isLoading) {
        return <Loader text='Fetching portal news...' />
    }

    return (
        <AgGridContainer>
            <AgGridReact
                columnDefs={columnDefs}
                cacheBlockSize={100}
                rowModelType='serverSide'
                defaultColDef={defaultColDef}
                enableRangeSelection
                getContextMenuItems={getContextMenuItems}
                onFilterChanged={onFilterChanged}
                getRowId={(params: GetRowIdParams) =>
                    `${params.data?.Id?.toString()}-${Math.random().toString()}`
                }
                onGridReady={onGridReady}
                ref={gridRef}
                rowSelection='multiple'
                sideBar={sideBar}
                statusBar={statusBar}
                suppressServerSideInfiniteScroll
            />
        </AgGridContainer>
    )
}

export default PortalNewsGrid
