import {
    AlertOn24Regular,
    Delete48Regular,
    History48Regular,
} from '@fluentui/react-icons'
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Select,
    Text,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import { Fragment, useEffect, useMemo, useState } from 'react'
import {
    useAddVehicleTestEscalationMutation,
    useDeleteVehicleTestEscalationMutation,
    useGetVehicleTestEscalationHistoryQuery,
} from 'api/vehicleTests'
import { useAppDispatch, useAppSelector } from 'store'
import {
    useDeleteDriverStudyEscalationMutation,
    useGetDriverStudyEscalationHistoryQuery,
    usePostDriverStudyEscalationMutation,
} from 'api/driverStudyParticipation'

import Restricted from '../Restricted'
import { dateStringRenderWithTime } from 'helpers/dateHelpers'
import { permissions } from 'slices/authSlice'
import { triggerMessage } from 'slices/messageSlice'
import useDialogStyles from 'components/styles/dialogStyles'
import { useGetEscalationStepsQuery } from 'api/escalationSteps'

interface IEscalateDialogProps {
    id: number
    data: {
        ActorId: string
        FirstName: string
        LastName: string
        Cdsid: string
    }
    type: 'DriverStudy' | 'VehicleTest'
    escalated?: boolean
}

const useStyles = makeStyles({
    count: {
        display: 'flex',
        ...shorthands.margin('16px', 0),
        ...shorthands.borderBottom('1px', 'solid', '#ccc'),
        paddingBottom: '16px',
    },
    selectRow: {
        marginTop: '8px',
        display: 'flex',
        alignItems: 'center',
        gridGap: '8px',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '200px 1fr 60px',
        gridGap: '8px',
        marginTop: '16px',
    },
})

const EscalateDialog = ({
    id,
    data,
    type,
    escalated,
}: IEscalateDialogProps) => {
    const [open, setOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState('')

    const classes = useStyles()
    const dialogClasses = useDialogStyles()
    const dispatch = useAppDispatch()

    const hasPermission = useAppSelector(permissions).escalationsManage

    const { data: escalationSteps } = useGetEscalationStepsQuery()
    const options = useMemo(
        () =>
            escalationSteps?.map(step => {
                return {
                    key: step.id,
                    text: step.name,
                }
            }) ?? [],
        [escalationSteps]
    )

    useEffect(() => {
        if (escalationSteps?.length) {
            setSelectedItem(escalationSteps[0].id.toString())
        }
    }, [escalationSteps])

    const { data: driverStudiesEscalationHistory } =
        useGetDriverStudyEscalationHistoryQuery(
            { id, actorId: data.ActorId },
            { skip: !(type === 'DriverStudy' && open) }
        )
    const [postDriverStudyEscalation] = usePostDriverStudyEscalationMutation()
    const [deleteDriverStudyEscalation] =
        useDeleteDriverStudyEscalationMutation()

    const { data: vehicleTestsEscalationHistory } =
        useGetVehicleTestEscalationHistoryQuery(
            { id, actorId: data.ActorId },
            { skip: !(type === 'VehicleTest' && open) }
        )
    const [addVehicleTestEscalation] = useAddVehicleTestEscalationMutation()
    const [deleteVehicleTestEscalation] =
        useDeleteVehicleTestEscalationMutation()

    const history = useMemo(() => {
        if (type === 'DriverStudy') {
            return driverStudiesEscalationHistory ?? []
        } else {
            return vehicleTestsEscalationHistory ?? []
        }
    }, [driverStudiesEscalationHistory, vehicleTestsEscalationHistory, type])

    const escalateDriver = async (): Promise<void> => {
        const escalationId = parseInt(selectedItem, 10)
        if (isNaN(escalationId)) return

        let response

        if (type === 'DriverStudy') {
            response = await postDriverStudyEscalation({
                driverStudyId: id,
                actorId: data.ActorId,
                escalationStepId: escalationId,
            })
        } else {
            response = await addVehicleTestEscalation({
                vehicleTestId: id,
                actorId: data.ActorId,
                escalationStepId: escalationId,
            })
        }

        if ('data' in response) {
            dispatch(
                triggerMessage({
                    title: 'Escalation Successful',
                    message: 'The driver has been escalated successfully.',
                    intent: 'success',
                })
            )
        }
    }

    const removeEscalation = async (escalationId: number) => {
        let response

        if (type === 'DriverStudy') {
            response = await deleteDriverStudyEscalation(escalationId)
        } else {
            response = await deleteVehicleTestEscalation(escalationId)
        }

        if ('data' in response) {
            dispatch(
                triggerMessage({
                    title: 'Escalation Successful',
                    message: 'The driver has been escalated successfully.',
                    intent: 'success',
                })
            )
        }
    }

    const formatDateTime = (date: string) =>
        date ? dateStringRenderWithTime(date) : 'Not Defined'

    return (
        <Dialog open={open} onOpenChange={(_e, d) => setOpen(d.open)}>
            <DialogTrigger disableButtonEnhancement>
                <Button
                    data-cy='open-escalation-dialog-button'
                    icon={
                        escalated ? <History48Regular /> : <AlertOn24Regular />
                    }
                    title={escalated ? 'Escalation History' : 'Escalate'}
                    appearance='transparent'
                />
            </DialogTrigger>
            <DialogSurface
                className={dialogClasses.dialogSurface}
                data-cy='escalation-dialog'
            >
                <DialogBody className={dialogClasses.dialogBody}>
                    <DialogTitle data-cy='escalation-title'>
                        {data.FirstName && data.LastName && data.Cdsid
                            ? `Escalation History for ${data.FirstName} ${data.LastName} (${data.Cdsid})`
                            : 'Escalation History'}
                    </DialogTitle>

                    <DialogContent>
                        {history.length > 0 && (
                            <>
                                <div
                                    className={classes.grid}
                                    data-cy='escalation-history-grid'
                                >
                                    <Text weight='bold'>Date</Text>
                                    <Text weight='bold'>Escalation Step</Text>
                                    {hasPermission && (
                                        <Text weight='bold'>Delete</Text>
                                    )}
                                    {history.map(item => (
                                        <Fragment key={item.id}>
                                            <Text>
                                                {formatDateTime(
                                                    item.insertDate
                                                )}
                                            </Text>
                                            <Text>
                                                {item.escalationStepName}
                                            </Text>
                                            {hasPermission && (
                                                <Button
                                                    data-cy='escalation-remove-button'
                                                    icon={<Delete48Regular />}
                                                    title='Delete'
                                                    onClick={() =>
                                                        removeEscalation(
                                                            item.id
                                                        )
                                                    }
                                                />
                                            )}
                                        </Fragment>
                                    ))}
                                </div>
                                <Text className={classes.count}>
                                    Escalation Count: {history.length}
                                </Text>
                            </>
                        )}

                        <Restricted to='escalationsManage'>
                            <Text>Need to Escalate?</Text>
                            <div
                                className={classes.selectRow}
                                data-cy='escalation-select'
                            >
                                <Select
                                    value={selectedItem}
                                    onChange={(_e, d) =>
                                        setSelectedItem(d.value)
                                    }
                                    disabled={!options.length}
                                >
                                    {options.map(option => (
                                        <option
                                            key={option.key}
                                            value={option.key}
                                        >
                                            {option.text}
                                        </option>
                                    ))}
                                </Select>
                                <Button
                                    data-cy='escalate-button'
                                    appearance='primary'
                                    onClick={escalateDriver}
                                    disabled={!options.length || !selectedItem}
                                >
                                    Escalate
                                </Button>
                            </div>
                        </Restricted>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button data-cy='close-escalation-dialog-button'>
                                Close
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default EscalateDialog
