import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTrigger,
    Field,
    Input,
    MenuItem,
} from '@fluentui/react-components'
import { useAppDispatch, useAppSelector } from 'store'
import {
    useGetPersonalViewsQuery,
    usePostPersonalViewsMutation,
    usePutPersonalViewsMutation,
} from 'api/personalViews'
import { useMemo, useState } from 'react'

import { SaveEdit24Regular } from '@fluentui/react-icons'
import { SerializedError } from '@reduxjs/toolkit'
import { selectUser } from 'slices/authSlice'
import { triggerMessage } from 'slices/messageSlice'
import { useGrid } from 'contexts/GridContext'
import { useSearchParams } from 'react-router-dom'

interface ISaveDialogProps {
    intent: 'add' | 'update' | undefined
    onSuccessfulSave: (view: IPersonalView) => void
    personalViews?: IPersonalView[]
    viewType: string
    viewId?: number
    viewName?: string
}

const SaveDialog = ({
    intent,
    onSuccessfulSave,
    viewType,
    viewId,
    viewName,
}: ISaveDialogProps) => {
    const [open, setOpen] = useState(false)
    const [inputValue, setInputValue] = useState(
        intent === 'update' ? viewName : ''
    )

    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)

    const [, setSearchParams] = useSearchParams()
    const { gridRef } = useGrid()

    const scope = useMemo(
        () => (viewType === 'Reflect' ? user.view : viewType),
        [viewType, user.view]
    )

    const { data: personalViews } = useGetPersonalViewsQuery(viewType)

    const [addPersonalView, { isLoading: addingView }] =
        usePostPersonalViewsMutation()
    const [updatePersonalView, { isLoading: updatingView }] =
        usePutPersonalViewsMutation()

    const onAddPersonalView = async () => {
        const reqObject: Partial<IPersonalView> = {
            name: inputValue,
            viewType: viewType,
            viewJson: JSON.stringify({
                columnState: gridRef.current?.columnApi.getColumnState(),
                filterModel: gridRef.current?.api.getFilterModel(),
            }),
            activeScope: scope,
        }

        const response =
            intent === 'update'
                ? await updatePersonalView({
                      id: viewId,
                      view: reqObject,
                  })
                : await addPersonalView(reqObject)

        if ('data' in response) {
            onSuccessfulSave(response.data)
            setSearchParams(param => {
                const params = new URLSearchParams(param)
                params.set('view', response.data.id.toString())
                return params
            })
            dispatch(
                triggerMessage({
                    title: 'Personal View Saved',
                    message: 'The personal view has been saved successfully',
                    intent: 'success',
                })
            )
        } else {
            dispatch(
                triggerMessage({
                    title: 'Error',
                    message:
                        (response.error as SerializedError).message ??
                        'An error occurred',
                    intent: 'error',
                })
            )
        }
        setOpen(false)
    }

    const isValidName = useMemo(() => {
        if (personalViews && inputValue !== viewName) {
            return !personalViews.some(
                pv => pv.name.toLowerCase() === inputValue.toLowerCase()
            )
        }
        return true
    }, [personalViews, inputValue, viewName])

    return (
        <Dialog open={open} onOpenChange={(_e, data) => setOpen(data.open)}>
            <DialogTrigger>
                <MenuItem
                    icon={<SaveEdit24Regular />}
                    data-cy='personal-views-save-open-dialog-button'
                >
                    {intent === 'update' ? 'Update View' : 'Save as new view'}
                </MenuItem>
            </DialogTrigger>
            <DialogSurface>
                <DialogBody data-cy='personal-views-save-dialog'>
                    <DialogContent>
                        {intent === 'update' && (
                            <i>You are changing the current view</i>
                        )}
                        <Field
                            required
                            label='Personal View Name'
                            validationMessage={
                                !isValidName ? 'This name is already taken' : ''
                            }
                        >
                            <Input
                                data-cy='personal-views-save-name-input'
                                value={inputValue}
                                onChange={(_ev, data) => {
                                    setInputValue(data.value)
                                }}
                                disabled={addingView || updatingView}
                            />
                        </Field>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance='primary'
                            onClick={onAddPersonalView}
                            data-cy='personal-views-save-button'
                            disabled={
                                inputValue === '' ||
                                !isValidName ||
                                addingView ||
                                updatingView
                            }
                        >
                            {intent === 'update' ? 'Apply' : 'Create'}
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button disabled={addingView || updatingView}>
                                Cancel
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default SaveDialog
