import {
    ColDef,
    GetContextMenuItemsParams,
    SideBarDef,
} from 'ag-grid-community'

import CustomFilterStatePanel from 'components/AgGrid/Toolbars/CustomFilterStatePanel'
import GridHasFilterMessage from 'components/AgGrid/StatusBarRenderers/GridHasFilterMessage'
import OdataProvider from 'ag-grid-odata'
import RowCounter from 'components/AgGrid/StatusBarRenderers/RowCounter'

export class WorkshopOdataProvider extends OdataProvider {
    odataOperator = {
        ...this.odataOperator,
        blanks: (col: string) => `${col} eq null`,
        inWithNullAndUrlEncode: (col: string, values: string[]) =>
            `${col} in (${values
                .map(x =>
                    x !== null
                        ? `'${this.encode(encodeURIComponent(x))}'`
                        : `${x}`
                )
                .join()})`,
        guidFilter: (col: string, value: string) => `${col} ${value}`,
        notBlank: (col: string) => `${col} ne null`,
        blank: (col: string) => `${col} eq null`,
    }
}

export const getBooleanCustomFilters = (fileds: string[]) =>
    fileds.reduce(
        (filters, field) => ({
            ...filters,
            [field]: (
                colName: string,
                col: { values: string[] },
                _isCaseSensitive: boolean,
                provider: WorkshopOdataProvider
            ) =>
                col.values && col.values.length > 0
                    ? provider.odataOperator.equals(colName, col.values)
                    : false,
        }),
        {}
    )

export const getListCustomFilters = (fileds: string[]) =>
    fileds.reduce(
        (filters, field) => ({
            ...filters,
            [field]: (
                colName: string,
                col: { values: string[] },
                _isCaseSensitive: boolean,
                provider: WorkshopOdataProvider
            ) =>
                col.values && col.values.length > 0
                    ? provider.odataOperator.inWithNullAndUrlEncode(
                          colName,
                          col.values
                      )
                    : false,
        }),
        {}
    )

export const getCsvFilters = (fileds: string[]) =>
    fileds.reduce(
        (filters, field) => ({
            ...filters,
            [field]: (
                colName: string,
                col: { value: string },
                _isCaseSensitive: boolean,
                provider: WorkshopOdataProvider
            ) =>
                col.value
                    ? provider.odataOperator.inWithNullAndUrlEncode(
                          colName,
                          col.value?.split(',').map(s => s.trim())
                      )
                    : false,
        }),
        {}
    )

export const getGuidCustomFilters = (fields: string[]) => {
    return fields.reduce(
        (filters, field) => ({
            ...filters,
            [field]: (
                colName: string,
                col: unknown,
                _isCaseSensitive: boolean,
                provider: WorkshopOdataProvider
            ) => provider.odataOperator.guidFilter(colName, col),
        }),
        {}
    )
}

export const textFilterOptions = [
    'contains',
    'notContains',
    'equals',
    'notEqual',
    'startsWith',
    'endsWith',
    'blank',
    'notBlank',
]

export const numberFilterOptions = [
    'equals',
    'notEqual',
    'lessThan',
    'lessThanOrEqual',
    'greaterThan',
    'greaterThanOrEqual',
    'inRange',
]

export const defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
        buttons: ['reset', 'apply'],
        filterOptions: textFilterOptions,
    },
}

export const sideBar: SideBarDef = {
    defaultToolPanel: undefined,
    toolPanels: [
        {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressSideButtons: true,
                suppressColumnFilter: false,
                suppressColumnSelectAll: false,
                suppressColumnExpandAll: false,
            },
        },
        {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
                suppressExpandAll: true,
                suppressFilterSearch: false,
            },
        },
        {
            id: 'customFilterState',
            labelDefault: 'Active Filters',
            labelKey: 'customFilterState',
            iconKey: 'filter',
            toolPanel: CustomFilterStatePanel,
            toolPanelParams: { hideRestoreButton: true },
        },
    ],
}

export const statusBar = {
    statusPanels: [
        {
            statusPanel: RowCounter,
            align: 'left',
        },
        {
            statusPanel: 'agSelectedRowCountComponent',
            align: 'left',
        },
        {
            statusPanel: GridHasFilterMessage,
            align: 'right',
        },
    ],
}

/**
 * Built In Menu Items
 * The following is a list of all the default built in menu items with the rules about when they are shown.
 *
 ** autoSizeAll: Auto-size all columns. Not shown by default.
 ** expandAll: When set, it's only shown if grouping by at least one column. Not shown by default.
 ** contractAll: Collapse all groups. When set, it's only shown if grouping by at least one column. Not shown by default.
 ** copy: Copy selected value to clipboard. Shown by default.
 ** copyWithHeaders: Copy selected value to clipboard with headers. Shown by default.
 ** copyWithGroupHeaders: Copy selected value to clipboard with headers and header groups. Shown by default.
 ** paste: Always disabled (see note in clipboard section). Always disabled. Shown by default.
 ** resetColumns: Reset all columns. Not shown by default.
 ** export: Export sub menu (containing csvExport and excelExport). Shown by default.
 ** csvExport: Export to CSV using all default export values. Shown by default.
 ** excelExport: Export to Excel (.xlsx) using all default export values. Shown by default.
 ** chartRange: Chart a range of selected cells. Only shown if charting is enabled.
 */
export const getContextMenuItems = (params: GetContextMenuItemsParams) => {
    const selectedRows = params.api.getSelectedRows()
    return [
        'copy',
        'copyWithHeaders',
        selectedRows.length > 0 && {
            name: 'Copy selected rows',
            icon: '<span class="ag-icon ag-icon-copy" role="presentation"></span>',
            tooltip: 'Copy selected rows to clipboard',
            action: () => {
                params.api.copySelectedRowsToClipboard()
            },
        },
        selectedRows.length > 0 && {
            name: 'Copy selected rows with headers',
            icon: '<span class="ag-icon ag-icon-copy" role="presentation"></span>',
            tooltip: 'Copy selected rows to clipboard with headers',
            action: () => {
                params.api.copySelectedRowsToClipboard({ includeHeaders: true })
            },
        },
    ].filter(Boolean)
}
