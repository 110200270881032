import { ChangeEvent, useState } from 'react'

import { InputOnChangeData } from '@fluentui/react-components'
import SearchBox from 'components/Ui/SearchBox'
import { useSearchParams } from 'react-router-dom'

interface ISearchProps {
    title?: string
    placeholder?: string
}

const Search = ({ placeholder }: ISearchProps) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchValue, setSearchValue] = useState(
        searchParams.get('search') || ''
    )

    const onClear = () => {
        setSearchValue('')
        setSearchParams(param => {
            param.delete('search')
            return param
        })
    }

    const onChange = (
        _ev: ChangeEvent<HTMLInputElement>,
        data: InputOnChangeData
    ) => {
        setSearchValue(data.value)

        if (data.value === '') {
            setSearchParams(param => {
                param.delete('search')
                return param
            })
        }
    }

    const onSearch = () => {
        if (searchValue !== '') {
            setSearchParams(param => {
                param.set('search', searchValue)
                return param
            })
        }
    }

    return (
        <SearchBox
            placeholder={placeholder}
            value={searchValue}
            onChange={onChange}
            onSearch={onSearch}
            onClear={onClear}
        />
    )
}

export default Search
