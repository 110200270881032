import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    MessageBar,
} from '@fluentui/react-components'
import { useEffect, useState } from 'react'

import AddDriverForm from './AddDriverForm'
import AxiosStateTypeEnum from 'constants/AxiosStateTypeEnum'
import DriverCheckStates from 'Types/DriverCheckStates'
import manageDriversHelpers from 'helpers/manageDriversHelpers'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import sanitizeInput from 'helpers/sanitizeInput'

type AddDriverProps = {
    toggleOpen: () => void
}

const defaultDriverObject: DriverObject = {
    cdsid: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    preferredEmail: undefined,
    phone: undefined,
    doin: undefined,
    consentCategory: undefined,
}

const DriverCheckStateDefault: DriverCheckStates = {
    CdsIdVerified: false,
    CdsIdNotFound: false,
    DriverWithCdsIdExists: false,
    SeveralDriverMatches: false,
    CheckDriverExistsFailed: false,
    InvalidEmail: false,
    DriverFound: AxiosStateTypeEnum.Unknown,
    IncludeOrder: false,
}

const AddDriver = ({ toggleOpen }: AddDriverProps) => {
    const [driver, setDriver] = useState<DriverObject>(defaultDriverObject)
    const [dialogSubText, setDialogSubText] = useState('')

    const [driverCheckState, setDriverCheckState] = useState<DriverCheckStates>(
        DriverCheckStateDefault
    )

    const [driverRequiredCompleted, setDriverRequiredCompleted] =
        useState<boolean>(false)

    const [addDriverState, setAddDriverState] = useState<AxiosStateTypeEnum>(
        AxiosStateTypeEnum.Unknown
    )

    useEffect(() => {
        if (
            driver.cdsid &&
            driver.preferredEmail &&
            driver.email &&
            driver.firstName &&
            driver.lastName
        )
            setDriverRequiredCompleted(true)

        if (
            !driver.cdsid ||
            !driver.preferredEmail ||
            !driver.email ||
            !driver.firstName ||
            !driver.lastName
        )
            setDriverRequiredCompleted(false)
    }, [driver])

    const onAddDriver = () => {
        const sanitizedDriver = sanitizeInput(driver)
        reflectApiAxiosInstance
            .post('/api/Drivers', sanitizedDriver)
            .then(() => {
                setAddDriverState(AxiosStateTypeEnum.Success)
            })
            .catch(() => {
                setAddDriverState(AxiosStateTypeEnum.Error)
            })
    }

    useEffect(() => {
        setDialogSubText(
            addDriverState === AxiosStateTypeEnum.Success
                ? ''
                : 'Search for a Volvo user or manually enter the CDSID'
        )
    }, [addDriverState])

    const tryAgain = () => setAddDriverState(AxiosStateTypeEnum.Unknown)

    return (
        <>
            {addDriverState === AxiosStateTypeEnum.Unknown && (
                <>
                    <DialogContent>
                        <div style={{ marginBottom: '10px' }}>
                            {dialogSubText}
                        </div>

                        <AddDriverForm
                            driver={driver}
                            setDriver={setDriver}
                            driverCheckState={driverCheckState}
                            setDriverCheckState={setDriverCheckState}
                            driverRequiredCompleted={driverRequiredCompleted}
                            setDriverRequiredCompleted={
                                setDriverRequiredCompleted
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance='primary'
                            onClick={onAddDriver}
                            disabled={manageDriversHelpers.CheckAddUpdateDriverDisabled(
                                driver,
                                driverCheckState,
                                driverRequiredCompleted
                            )}
                            id='addDriverAction'
                        >
                            Add driver
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={toggleOpen}>Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}
            {addDriverState === AxiosStateTypeEnum.Success && (
                <>
                    <MessageBar intent='success'>
                        Driver added successfully.
                    </MessageBar>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={toggleOpen}>Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}
            {addDriverState === AxiosStateTypeEnum.Error && (
                <>
                    <MessageBar intent='error'>
                        Failed to add driver, please retry. Contact technical
                        support if the error persists.
                    </MessageBar>
                    <DialogActions>
                        <Button appearance='primary' onClick={tryAgain}>
                            Try again
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={toggleOpen}>Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}
        </>
    )
}

export default AddDriver
