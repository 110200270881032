import {
    SkeletonItem,
    Text,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'

import { dateStringRenderWithTime } from 'helpers/dateHelpers'
import { useGetVehicleDataSourcesQuery } from 'api/vehicleDetails'

interface IDataSourcesHistoryProps {
    id: string
}

const useStyles = makeStyles({
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '8px',
        ...shorthands.margin('16px'),
        '> span': {
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
    noData: {
        fontStyle: 'italic',
        gridColumnStart: 1,
        gridColumnEnd: 3,
    },
})

const formatDateTime = (date: string) =>
    date ? dateStringRenderWithTime(date) : 'Not Defined'

const DataSourcesHistory = ({ id }: IDataSourcesHistoryProps) => {
    const classes = useStyles()
    const { data: dataSources, isLoading } = useGetVehicleDataSourcesQuery(id)

    return (
        <div data-cy='vehicle-details-history-body' className={classes.grid}>
            <Text weight='bold'>Source Type</Text>
            <Text weight='bold'>Date</Text>
            {isLoading ? (
                [...Array(15)].map((_, i) => <SkeletonItem key={i} />)
            ) : dataSources?.length ? (
                dataSources?.map(source => (
                    <>
                        <Text title={source.dataSourceTypeId}>
                            {source.dataSourceTypeId}
                        </Text>
                        <Text>{formatDateTime(source.updatedDate)}</Text>
                    </>
                ))
            ) : (
                <Text size={400} className={classes.noData}>
                    It looks like this vehicle does not have any insert/update
                    history. If you think this is an error, please contact the
                    administrator.
                </Text>
            )}
        </div>
    )
}

export default DataSourcesHistory
