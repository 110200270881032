/**
 * Truncate a string to length and adds ... (as default)
 *
 * @param  {string} string
 * @param  {number} length
 * @param  {} end='...'
 */
export const truncate = (string: string, length: number, end = '...') =>
    string.length < length
        ? string
        : string.substring(0, length - end.length) + end

export const insertSpaces = (str: string) => {
    str = str.replace(/([a-z])([A-Z])/g, '$1 $2')
    str = str.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    return str
}

export const camelToReadable = (camelCaseString: string) => {
    const camelCase = camelCaseString
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .split(' ')

    let readable = ''

    camelCase.forEach((word: string) => {
        readable = readable + word.charAt(0).toUpperCase() + word.slice(1) + ' '
    })
    return readable
}
