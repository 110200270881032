import { PublicClientApplication } from '@azure/msal-browser'
import parseJwt from 'helpers/parseJwt'

export const API_SCOPE = [process.env.REACT_APP_REFLECT_BACKEND_API_SCOPE ?? '']

export const msalInstance = new PublicClientApplication({
    auth: {
        authority: process.env.REACT_APP_AUTH_AUTHORITY,
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
        redirectUri: '/',
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
    },
})

export const acquireGraphAccessToken = async () => {
    const account = msalInstance.getAllAccounts()[0]

    if (!account) {
        /**
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        throw Error('No active account! Verify a user has been signed in.')
    }
    const request = {
        scopes: [
            'User.Read.All',
            'User.ReadBasic.All',
            'User.Read',
            'People.Read',
        ],
        account,
    }

    const authResult = await msalInstance.acquireTokenSilent(request)

    return authResult.accessToken
}

export const acquireApiAccessToken = async () => {
    const account = msalInstance.getAllAccounts()[0]

    if (!account) {
        /**
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        throw Error('No active account! Verify a user has been signed in.')
    }
    const request = { scopes: API_SCOPE, account }
    const authResult = await msalInstance.acquireTokenSilent(request)

    return authResult.accessToken
}

export const acquireApiAccessTokenRoles = async () => {
    const account = msalInstance.getAllAccounts()[0]

    if (!account) {
        /**
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        throw Error('No active account! Verify a user has been signed in.')
    }
    const request = { scopes: API_SCOPE, account }
    const authResult = await msalInstance.acquireTokenSilent(request)

    const parsedAccessToken = parseJwt(authResult.accessToken)

    const apiAccessTokenRoles = parsedAccessToken.roles
    const preferredUsername = parsedAccessToken.preferred_username.split('@')[0]

    return { apiAccessTokenRoles, preferredUsername }
}

export const signOutClickHandler = () => {
    const account = msalInstance.getAllAccounts()[0]
    const logoutRequest = {
        account,
        postLogoutRedirectUri: 'https://www.volvocars.com/',
    }
    msalInstance.logoutRedirect(logoutRequest)
}
