import { Button, Tooltip } from '@fluentui/react-components'

import { Add48Regular } from '@fluentui/react-icons'
import { ICellRendererParams } from 'ag-grid-community'
import { toast } from 'react-toastify'
import { useInitializeVisitMutation } from 'api/workshop'

const CreateWorkshopVisit = ({ data, api }: ICellRendererParams<IWorkshop>) => {
    const now = new Date().toISOString()

    const [initializeVisit] = useInitializeVisitMutation()

    const createVisit = async () => {
        try {
            initializeVisit({
                vehicleId: data.VehicleId,
                testId: data.TestPurposeId,
            }).then(() => api.refreshServerSide())
        } catch {
            toast.error(
                'There was an error processing this workshop visit, please try again or contact support.'
            )
        } finally {
            toast.success('Successfully created a new workshop visit!')
        }
    }

    return (
        <Tooltip
            content='Do you want to create a new workshop visit for this
            vehicle? The new visit will be added to the end of the
            grid. Your previous visit will no longer be visible.'
            relationship='description'
        >
            <Button
                icon={<Add48Regular />}
                disabled={
                    data.WsReturnedToDriver > now ||
                    data.WsReturnedToDriver === null
                }
                onClick={createVisit}
            >
                {data.WsReturnedToDriver > now ||
                data.WsReturnedToDriver === null
                    ? 'You need to set "Returned to Driver" before creating a new visit'
                    : 'Create a new workshop visit'}
            </Button>
        </Tooltip>
    )
}

export default CreateWorkshopVisit
