import { Text, makeStyles } from '@fluentui/react-components'

import { WarningRegular } from '@fluentui/react-icons'

interface IAuthLoading {
    text?: string
}

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    icon: {
        height: '128px',
        width: '128px',
    },
})

const Error = ({ text = '' }: IAuthLoading) => {
    const classes = useStyles()
    return (
        <div className={classes.wrapper}>
            <WarningRegular className={classes.icon} />
            <Text size={900}>
                {text ||
                    'There was an error loading the page. Please try again.'}
            </Text>
        </div>
    )
}

export default Error
