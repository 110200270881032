import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    MessageBar,
    Spinner,
} from '@fluentui/react-components'

import AxiosStateTypeEnum from 'constants/AxiosStateTypeEnum'
import { CSVLink } from 'react-csv'
import OTAReportForm from './OTAReportForm'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import { selectLastOdataQuery } from 'slices/gridSlice'
import { useAppSelector } from 'store'
import { useGrid } from 'contexts/GridContext'
import { useState } from 'react'

type OTAReportProps = {
    toggleOpen: () => void
}

const OTAReport = ({ toggleOpen }: OTAReportProps) => {
    const { gridRef } = useGrid()

    const [collection, setCollection] = useState<OTAReportObject>({
        filename: '',
        vinsList:
            gridRef.current?.api.getSelectedRows().map(sr => sr.Vin) || [],
    })
    const [csvDataState, setCsvDataState] = useState<AxiosStateTypeEnum>(
        AxiosStateTypeEnum.Unknown
    )
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [csvData, setCsvData] = useState([])
    const [isCollectingData, setIsCollectingData] = useState(false)

    const lastODataQuery = useAppSelector(selectLastOdataQuery)

    const formatRow = d => ({
        VIN: d.Vin,
        BaselineVersion: d.BaselineVersion,
        Blacklist: d.Blacklist,
        BlacklistComment: d.BlacklistComment,
        ExclusionLists: d.ExclusionLists,
        EngineDescription: d.EngineDescription,
        Model: d.Model,
        ModelCode: d.ModelCode,
        StructureWeek: d.StructureWeek,
        FirstPDS: d.FirstPds,
    })

    const collectAllVins = async () => {
        const param = new URLSearchParams(
            lastODataQuery?.replace(
                'odata/VehicleViews',
                'odata/admin/VehicleViews'
            )
        )
        param.delete('$skip')
        param.delete('$top')
        param.delete('$select')
        param.append(
            '$select',
            // eslint-disable-next-line max-len
            'Vin,BaselineVersion,Blacklist,BlacklistComment,ExclusionLists,EngineDescription,Model,ModelCode,StructureWeek,FirstPds'
        )
        param.set('$filter', encodeURIComponent(param.get('$filter')))
        reflectApiAxiosInstance
            .get(decodeURIComponent(param.toString()))
            .then(response => response && response.data && response.data.value)
            .then(data => {
                const vinsList = data.map(d => formatRow(d))
                setCsvDataState(AxiosStateTypeEnum.Success)
                setCsvData(vinsList)
            })
            .catch(error => {
                setCsvDataState(AxiosStateTypeEnum.Error)
                setErrorMessage(error.message)
            })
            .finally(() => setIsCollectingData(false))
    }

    const onDownloadReport = () => {
        setIsCollectingData(true)
        collectAllVins()
    }

    const getErrorText = () => {
        if (csvDataState === AxiosStateTypeEnum.Error)
            return `Failed to download report, please retry. Contact technical support if the error persists. ${errorMessage}`
        return ''
    }

    const tryAgain = () => setCsvDataState(AxiosStateTypeEnum.Unknown)

    return (
        <>
            {csvDataState === AxiosStateTypeEnum.Unknown && (
                <>
                    <DialogContent>
                        <OTAReportForm
                            collection={collection}
                            setCollection={setCollection}
                        />
                    </DialogContent>

                    {isCollectingData && <Spinner />}

                    <DialogActions>
                        <Button
                            appearance='primary'
                            disabled={isCollectingData}
                            id='downloadReport'
                            onClick={onDownloadReport}
                        >
                            Generate Report
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                disabled={isCollectingData}
                                onClick={toggleOpen}
                            >
                                Cancel
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}
            {csvDataState === AxiosStateTypeEnum.Success &&
                csvData?.length &&
                csvData.length > 0 && (
                    <>
                        <MessageBar intent='success'>
                            Report created.
                        </MessageBar>
                        <CSVLink
                            data={csvData}
                            target='_blank'
                            filename={collection.filename}
                            separator=';'
                        >
                            Download
                        </CSVLink>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button onClick={toggleOpen}>Close</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </>
                )}
            {csvDataState === AxiosStateTypeEnum.Error && (
                <>
                    <MessageBar intent='error'>{getErrorText()}</MessageBar>

                    <DialogActions>
                        <Button appearance='primary' onClick={tryAgain}>
                            Try again
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={toggleOpen}>Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}
        </>
    )
}

export default OTAReport
