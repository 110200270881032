import {
    Button,
    ButtonProps,
    DialogTrigger,
    InfoLabel,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components'

import { IActionsMenu } from 'Types/ActionsMenu'
import { Link } from 'react-router-dom'

const ActionsMenuItems = ({ item }: { item: IActionsMenu }) => {
    const getButtonProps = (_item: IActionsMenu): ButtonProps => ({
        appearance: _item.appearance || 'subtle',
        icon: _item.icon,
        disabled: _item.disabled,
        size: 'small',
    })

    const renderButton = (_item: IActionsMenu) => {
        if (_item.info) {
            return (
                <InfoLabel info={_item.info()}>
                    <Button {...getButtonProps(_item)} onClick={_item.onClick}>
                        {_item.label}
                    </Button>
                </InfoLabel>
            )
        }

        return (
            <DialogTrigger disableButtonEnhancement>
                <Button
                    {...getButtonProps(_item)}
                    onClick={_item.onClick}
                    data-cy={_item.label.replace(/\s/g, '_').toLowerCase()}
                >
                    {_item.label}
                </Button>
            </DialogTrigger>
        )
    }

    const renderMenu = (_item: IActionsMenu) => {
        return (
            <Menu key={_item.label}>
                <MenuTrigger>
                    <MenuButton
                        {...getButtonProps(_item)}
                        data-cy={_item.label.replace(/\s/g, '_').toLowerCase()}
                    >
                        {_item.label}
                    </MenuButton>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {_item.children?.map(i =>
                            i.children?.length > 0 ? (
                                renderMenu(i)
                            ) : (
                                <MenuItem key={i.label}>
                                    {renderButton(i)}
                                </MenuItem>
                            )
                        )}
                    </MenuList>
                </MenuPopover>
            </Menu>
        )
    }

    return item.children?.length > 0 ? (
        renderMenu(item)
    ) : item.link ? (
        <Link to={item.link}>{renderButton(item)}</Link>
    ) : (
        renderButton(item)
    )
}

export default ActionsMenuItems
