import { apiSlice } from './apiSlice'
import { lowerCaseKeys } from 'helpers/objectManipulation'

interface IChangeSet {
    operation: 'Add' | 'Remove' | 'Replace'
    path: keyof IDriverStudy
    value: unknown
}

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ['DriverStudies', 'DriverStudyEscalations', 'DriverStudy'],
})

const driverStudiesApi = apiWithTags.injectEndpoints({
    endpoints: builder => ({
        getDriverStudies: builder.query<IDriverStudy[], string | void>({
            query: (options = '') =>
                `odata/DriverStudies?filter=IsDeleted ne true ${options}`,
            transformResponse: (response: IODataResponse<DriverStudyOdata[]>) =>
                lowerCaseKeys(response.value) as unknown as IDriverStudy[],
            providesTags: ['DriverStudies'],
        }),
        getDriverStudyById: builder.query<IDriverStudy, string>({
            query: id => `odata/DriverStudies?$filter=Id eq ${id}`,
            transformResponse: (response: IODataResponse<DriverStudyOdata[]>) =>
                lowerCaseKeys(response.value[0]) as unknown as IDriverStudy,
            providesTags: ['DriverStudy'],
        }),
        archiveDriverStudy: builder.mutation<void, number>({
            query: id => ({
                url: `api/DriverStudies/${id}/Archive`,
                method: 'POST',
            }),
            invalidatesTags: ['DriverStudies', 'DriverStudy'],
        }),
        unArchiveDriverStudy: builder.mutation<void, number>({
            query: id => ({
                url: `api/DriverStudies/${id}/UnArchive`,
                method: 'POST',
            }),
            invalidatesTags: ['DriverStudies', 'DriverStudy'],
        }),
        addDriverStudy: builder.mutation<IDriverStudy, IDriverStudy>({
            query: driverStudy => ({
                url: 'api/DriverStudies',
                method: 'POST',
                body: driverStudy,
            }),
            invalidatesTags: ['DriverStudies'],
        }),
        editDriverStudies: builder.mutation<
            void,
            { id: string; changeSets: IChangeSet[] }
        >({
            query: ({ id, changeSets }) => ({
                url: `api/DriverStudies/${id}`,
                method: 'PATCH',
                body: changeSets,
            }),
            invalidatesTags: ['DriverStudies', 'DriverStudy'],
        }),
        deleteDriverStudy: builder.mutation<void, number>({
            query: id => ({
                url: `api/DriverStudies/${id}/Delete`,
                method: 'POST',
            }),
            invalidatesTags: ['DriverStudies'],
        }),

        getStudyTypes: builder.query<IStudyType[], void>({
            query: () => 'api/StudyTypes',
        }),
        getDriverStudyTags: builder.query<DriverStudyTag[], string | void>({
            query: (options = '') => `/odata/DriverStudyTags${options}`,
            transformResponse: (response: IODataResponse<DriverStudyTag[]>) =>
                lowerCaseKeys(response.value) as unknown as DriverStudyTag[],
        }),
    }),
})

export const {
    useGetDriverStudiesQuery,
    useGetDriverStudyByIdQuery,
    useArchiveDriverStudyMutation,
    useUnArchiveDriverStudyMutation,
    useAddDriverStudyMutation,
    useEditDriverStudiesMutation,
    useDeleteDriverStudyMutation,

    useGetStudyTypesQuery,
    useGetDriverStudyTagsQuery,
} = driverStudiesApi
