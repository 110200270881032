import { apiSlice } from './apiSlice'

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ['VehicleDetails', 'extendVehicleOrder'],
})

const vehicleDetailsApi = apiWithTags.injectEndpoints({
    endpoints: builder => ({
        updateVehicleDetails: builder.mutation<
            void,
            {
                vehicleId: string
                attribute: VehicleDetails['id']
                data: VehicleDetails['value']
            }
        >({
            query: ({ vehicleId, attribute, data }) => ({
                url: `/api/Vehicles/${vehicleId}/Attributes/${attribute}`,
                method: 'PUT',
                body: { data },
            }),
            invalidatesTags: ['VehicleDetails'],
        }),
        getVehicleDataSources: builder.query<IVehicleDataSource[], string>({
            query: vehicleId => `api/Vehicles/${vehicleId}/DataSources`,
        }),
    }),
})

export const {
    useUpdateVehicleDetailsMutation,
    useGetVehicleDataSourcesQuery,
} = vehicleDetailsApi
