import { Button, makeStyles } from '@fluentui/react-components'

import { ClipboardTextEdit24Regular } from '@fluentui/react-icons'
import DriverStudyArchiveAction from './DriverStudyArchiveAction'
import { ICellRendererParams } from 'ag-grid-community'
import ROUTES from 'constants/routes'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridGap: '8px',
    },
})

const DriverStudyActionsColumn = (prop: ICellRendererParams<IDriverStudy>) => {
    const navigate = useNavigate()
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Button
                data-cy='driver-study-details-button'
                appearance='transparent'
                icon={<ClipboardTextEdit24Regular />}
                title='Open driver study page'
                onClick={() => {
                    navigate(`/${ROUTES.DRIVER_STUDIES}/${prop.data.id}`)
                }}
            />
            <DriverStudyArchiveAction {...prop} />
        </div>
    )
}

export default DriverStudyActionsColumn
