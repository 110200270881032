import { Text, makeStyles, shorthands } from '@fluentui/react-components'

import EscalateDialog from 'components/Escalations/EscalateDialog'
import { ICellRendererParams } from 'ag-grid-community'
import { useGetLatestDriverStudyEscalationsQuery } from 'api/driverStudyParticipation'
import { useGetLatestVehicleTestEscalationQuery } from 'api/vehicleTests'
import { useMemo } from 'react'

interface IEscalationStatusProps extends ICellRendererParams {
    id?: number
    type: 'DriverStudy' | 'VehicleTest'
}

const useStyles = makeStyles({
    root: {
        width: '170px',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    escalation: {
        display: 'inline-block',
        width: '138px',
        whiteSpace: 'nowrap',
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
    },
})

const EscalationStatus = ({ id, type, data }: IEscalationStatusProps) => {
    const classes = useStyles()

    const { data: testEscalations, isLoading: testLoading } =
        useGetLatestVehicleTestEscalationQuery(id)
    const { data: studyEscalations, isLoading: studyLoading } =
        useGetLatestDriverStudyEscalationsQuery(id)

    const latestEscalations = useMemo(() => {
        if (type === 'DriverStudy') {
            return studyEscalations?.find(
                escalation => escalation.actorId === data.ActorId
            )
        } else {
            return testEscalations?.find(
                escalation => escalation.actorId === data.ActorId
            )
        }
    }, [data.ActorId, studyEscalations, testEscalations, type])

    if (data.ActorId == null) {
        return (
            <Text italic title='No driver to escalate'>
                No Driver
            </Text>
        )
    }

    if (testLoading || studyLoading) {
        return <Text>Loading...</Text>
    }

    return (
        <div className={classes.root}>
            <Text data-cy='escalation-status' className={classes.escalation}>
                {latestEscalations
                    ? latestEscalations.escalationStepName
                    : 'Not Escalated'}
            </Text>
            <EscalateDialog
                id={id}
                data={data}
                type={type}
                escalated={!!latestEscalations}
            />
        </div>
    )
}

export default EscalationStatus
