import { apiSlice } from './apiSlice'

const releaseInformationApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReleaseInformation: builder.query<string, void>({
            query: () => 'api/BuildInfo/api/build',
            transformResponse: (response: IBuildInfo) => response.buildNumber,
        }),
    }),
})

export const { useGetReleaseInformationQuery } = releaseInformationApi
