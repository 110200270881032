import {
    Button,
    MessageBar,
    MessageBarActions,
    MessageBarBody,
} from '@fluentui/react-components'

import { DismissRegular } from '@fluentui/react-icons'
import { useState } from 'react'

interface IIncludeVolvoBilProps {
    includeVolvoBilData: boolean
}

const IncludeVolvoBil = ({ includeVolvoBilData }: IIncludeVolvoBilProps) => {
    const [isMessageBarVisible, setIsMessageBarVisible] = useState(true)

    const handleDismissClick = () => {
        setIsMessageBarVisible(false)
    }

    return (
        <div style={{ marginBottom: '10px' }}>
            {isMessageBarVisible && (
                <MessageBar>
                    <MessageBarBody>
                        Volvo Bil data is&nbsp;
                        {includeVolvoBilData ? 'included' : 'excluded'}.
                    </MessageBarBody>
                    <MessageBarActions
                        containerAction={
                            <Button
                                aria-label='dismiss'
                                appearance='transparent'
                                icon={<DismissRegular />}
                                onClick={handleDismissClick}
                            />
                        }
                    />
                </MessageBar>
            )}
        </div>
    )
}

export default IncludeVolvoBil
