import {
    useGetAmericaVehicleSchemaQuery,
    useGetChinaVehicleSchemaQuery,
    useGetCodevVehicleViewsSchemaQuery,
    useGetOtaVehicleSchemaQuery,
    useGetReadVehicleSchemaQuery,
    useGetSuperVehicleSchemaQuery,
} from 'api/schemas'

import ViewsEnum from 'constants/views'
import { selectUser } from 'slices/authSlice'
import { useAppSelector } from 'store'
import { useMemo } from 'react'

const useSchemas = () => {
    const user = useAppSelector(selectUser)

    const { data: superVehicleSchema, isLoading: svsLoading } =
        useGetSuperVehicleSchemaQuery(null, {
            skip: user.view !== ViewsEnum.super,
        })
    const { data: otaVehicleSchema, isLoading: ovsLoading } =
        useGetOtaVehicleSchemaQuery(null, {
            skip: user.view !== ViewsEnum.ota,
        })
    const { data: codevVehicleSchema, isLoading: cvsLoading } =
        useGetCodevVehicleViewsSchemaQuery(null, {
            skip: user.view !== ViewsEnum.codev,
        })
    const { data: vehicleSchema, isLoading: vsLoading } =
        useGetReadVehicleSchemaQuery(null, {
            skip: user.view !== ViewsEnum.vehicle,
        })
    const { data: chinaVehicleSchema, isLoading: csLoading } =
        useGetChinaVehicleSchemaQuery(null, {
            skip: user.view !== ViewsEnum.china,
        })
    const { data: americaVehicleSchema, isLoading: avsLoading } =
        useGetAmericaVehicleSchemaQuery(null, {
            skip: user.view !== ViewsEnum.america,
        })

    const schema = useMemo(() => {
        switch (user.view) {
            case ViewsEnum.super:
                return superVehicleSchema
            case ViewsEnum.ota:
                return otaVehicleSchema
            case ViewsEnum.codev:
                return codevVehicleSchema
            case ViewsEnum.vehicle:
                return vehicleSchema
            case ViewsEnum.china:
                return chinaVehicleSchema
            case ViewsEnum.america:
                return americaVehicleSchema
            default:
                return []
        }
    }, [
        user.view,
        superVehicleSchema,
        otaVehicleSchema,
        codevVehicleSchema,
        vehicleSchema,
        chinaVehicleSchema,
        americaVehicleSchema,
    ])

    return {
        schema: schema ?? [],
        isLoading:
            svsLoading ||
            ovsLoading ||
            cvsLoading ||
            vsLoading ||
            csLoading ||
            avsLoading,
    }
}

export default useSchemas
