import {
    ChangeEvent,
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'
import { Divider, makeStyles } from '@fluentui/react-components'
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community'

const useStyles = makeStyles({
    flex: {
        display: 'flex',
    },
})

const CustomTextListFilter = forwardRef(
    (
        {
            api,
            colDef,
            column,
            columnApi,
            context,
            filterChangedCallback,
            valueGetter,
        }: IFilterParams,
        ref
    ) => {
        const [filterText, _setFilterText] = useState<string>('')
        const filterTextRef = useRef<string>('')
        const classes = useStyles()

        const setFilterText = (value: string) => {
            _setFilterText(value)
            filterTextRef.current = value
        }

        const refresh = () => {
            filterChangedCallback()
            api.deselectAll()
        }

        useImperativeHandle(ref, () => {
            return {
                filterBySelection(data: string) {
                    setFilterText(data)
                    setTimeout(refresh, 0)
                },

                doesFilterPass({ node }: IDoesFilterPassParams) {
                    let passed = false

                    if (filterTextRef.current) {
                        filterTextRef.current
                            .replaceAll(' ', '')
                            .split(',')
                            .forEach(filterWord => {
                                const value = valueGetter({
                                    api,
                                    colDef,
                                    column,
                                    columnApi,
                                    context,
                                    data: node.data,
                                    getValue: field => node.data[field],
                                    node,
                                })

                                if (value?.toString() === filterWord) {
                                    passed = true
                                }
                            })
                    }

                    return passed
                },

                isFilterActive() {
                    return filterTextRef.current !== ''
                },

                getModel() {
                    if (!this.isFilterActive()) {
                        return null
                    }

                    return { value: filterTextRef.current }
                },

                setModel(model: { value: string }) {
                    setFilterText(model?.value || '')
                },
            }
        })

        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
            setFilterText(event.target.value)
        }

        const onApplyClicked = () => filterChangedCallback()

        const onResetClicked = () => {
            setFilterText('')
            filterChangedCallback()
        }

        return (
            <>
                <div className='ag-filter-body-wrapper ag-simple-filter-body-wrapper'>
                    This filter searches for a list of comma separated strings.
                    <input
                        style={{
                            margin: '8px 0',
                            paddingLeft: 6,
                            height: 20,
                            border: 'solid #babfc 0.5px',
                            borderRadius: 3,
                        }}
                        type='text'
                        value={filterText ?? ''}
                        onChange={onChange}
                        placeholder='Search...'
                    />
                </div>
                <Divider />
                <div className={'ag-filter-apply-panel ' + classes.flex}>
                    <button
                        className='ag-standard-button ag-filter-apply-panel-button'
                        onClick={onResetClicked}
                    >
                        Reset
                    </button>
                    <button
                        className='ag-standard-button ag-filter-apply-panel-button'
                        onClick={onApplyClicked}
                    >
                        Apply
                    </button>
                </div>
            </>
        )
    }
)

CustomTextListFilter.displayName = 'Text List Filter'

export default CustomTextListFilter
