import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    Dropdown,
    Field,
    Input,
    Option,
    Switch,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import {
    useGetWorkshopNotificationTypesQuery,
    useGetWorkshopVisitTypesQuery,
    useUpdateWorkshopVisitMutation,
} from 'api/workshop'
import { useMemo, useState } from 'react'

import DateTimePicker from 'components/Ui/DateTimePicker'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { useGrid } from 'contexts/GridContext'

interface IFormData {
    options?: string[]
    title: string
    key: string
    type: 'dropdown' | 'text' | 'date'
}

interface IChangeSet {
    operation?: 'Add' | 'Remove' | 'Replace'
    path: string
    value: IExtendedWorkshop | string | number
}

const useStyles = makeStyles({
    input: {
        width: '100%',
    },
    info: {
        marginBottom: '16px',
    },
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '60px 1fr',
        gridGap: '8px',
        marginBottom: '12px',
    },
    attention: {
        backgroundColor: '#fffbdd',
        ...shorthands.padding('8px'),
        ...shorthands.borderRadius('4px'),
        marginBottom: '8px',
    },
})

interface IWorkshopBatchEditModalProps {
    toggleOpen: () => void
    selectedRows: IWorkshop[]
}

const WorkshopBatchEditModal = ({
    toggleOpen,
    selectedRows,
}: IWorkshopBatchEditModalProps) => {
    const classes = useStyles()

    const { data: visitTypes } = useGetWorkshopVisitTypesQuery()
    const { data: notificationTypes } = useGetWorkshopNotificationTypesQuery()

    const [updateWorkshopVisit] = useUpdateWorkshopVisitMutation()

    const { gridRef } = useGrid()

    const formData: IFormData[] = useMemo(
        () => [
            {
                title: 'Waiting',
                key: 'WsWaiting',
                type: 'dropdown',
                options: ['true', 'false'],
            },
            {
                title: 'Comment',
                key: 'WsComment',
                type: 'text',
            },
            {
                title: 'Initiated By',
                key: 'WsInitiatedBy',
                type: 'text',
            },
            {
                title: 'Notification Type',
                key: 'WsNotificationTypeId',
                options: notificationTypes,
                type: 'dropdown',
            },
            {
                title: 'Visit Type',
                key: 'WsVisitTypeId',
                options: visitTypes,
                type: 'dropdown',
            },
            {
                title: 'Arrival',
                key: 'WsArrival',
                type: 'date',
            },
            {
                title: 'Installed',
                key: 'WsInstalled',
                type: 'date',
            },
            {
                title: 'Invitation From',
                key: 'WsInvitationFrom',
                type: 'date',
            },
            {
                title: 'Invitation To',
                key: 'WsInvitationTo',
                type: 'date',
            },
            {
                title: 'Ready',
                key: 'WsReady',
                type: 'date',
            },
            {
                title: 'Returned to Driver',
                key: 'WsReturnedToDriver',
                type: 'date',
            },
        ],
        [notificationTypes, visitTypes]
    )

    const [toggles, setToggles] = useState(formData.map(() => false))

    const [validation, setValidation] = useState(formData.map(() => undefined))

    const [changeSets, setChangeSets] = useState<IChangeSet[]>(
        formData.map(d => ({ path: d.key, value: null }))
    )

    const onSave = async () => {
        const responses = []

        gridRef.current?.api.getSelectedRows().forEach(({ Id }) => {
            const patchObject = changeSets
                .map(({ path, value }, index) => {
                    if (toggles[index]) {
                        if (typeof value == 'object') {
                            return {
                                operation: 'Add',
                                path,
                                value: value !== null ? value.Id : null,
                            } as IChangeSet
                        } else if (typeof value == 'string') {
                            return {
                                operation: 'Add',
                                path,
                                value,
                            } as IChangeSet
                        }
                    }

                    return null
                })
                .filter(po => po !== null)
            responses.push(
                updateWorkshopVisit({
                    id: Id,
                    changeSets: patchObject,
                })
            )
        })
        Promise.all(responses).then(() => {
            gridRef.current?.api.refreshServerSide({ purge: true })
            gridRef.current?.api.deselectAll()
            toast.success('Successfully updated the grid!')
            toggleOpen()
        })
    }

    const handleCallback = (
        newValue: string,
        path: string,
        currentIndex: number
    ) => {
        const changeSet = []

        changeSet.push({ path, value: newValue })

        setValidation(formData.map(() => undefined))

        const arrival = changeSets
            .find(p => p.path === 'WsArrival')
            .value?.toString()

        const installed = changeSets
            .find(p => p.path === 'WsInstalled')
            .value?.toString()

        const invitationFrom = changeSets
            .find(p => p.path === 'WsInvitationFrom')
            .value?.toString()

        const invitationTo = changeSets
            .find(p => p.path === 'WsInvitationTo')
            .value?.toString()

        if (path === 'WsInstalled' && new Date(arrival) > new Date(newValue)) {
            setValidation(pre =>
                pre.map((value, index) =>
                    index === currentIndex
                        ? 'You cannot set the Installed time before the Arrival time.'
                        : value
                )
            )
        }
        if (
            path === 'WsReady' &&
            new Date(installed || arrival) > new Date(newValue)
        ) {
            setValidation(pre =>
                pre.map((value, index) =>
                    index === currentIndex
                        ? 'You cannot set the Ready time before the Installed and Arrival time.'
                        : value
                )
            )
        }
        if (
            path === 'WsInvitationTo' &&
            new Date(invitationFrom) > new Date(newValue)
        ) {
            setValidation(pre =>
                pre.map((value, index) =>
                    index === currentIndex
                        ? 'You cannot set the Invitation To time before the Invitation From time.'
                        : value
                )
            )
        }
        if (
            path === 'WsInvitationFrom' &&
            new Date(invitationTo) < new Date(newValue)
        ) {
            setValidation(pre =>
                pre.map((value, index) =>
                    index === currentIndex
                        ? 'You cannot set the Invitation From time after the Invitation To time.'
                        : value
                )
            )
        }
        setChangeSets(pre => {
            return pre.map(p => {
                const change = changeSet.find(c => {
                    return c.path === p.path
                })
                return {
                    ...p,
                    value: change?.value ?? p.value,
                }
            })
        })
    }

    const handleToggleChange = (
        currentIndex: number,
        path: string,
        checked: boolean
    ) => {
        handleCallback('', path, currentIndex)
        const temp = [...toggles]
        temp[currentIndex] = checked

        setToggles(temp)

        if (checked) {
            setChangeSets(
                changeSets.map(cs => {
                    return cs.path === path &&
                        formData.find(d => d.key === path)?.type === 'date'
                        ? { path, value: dayjs(new Date()).toString() }
                        : cs
                })
            )
        }
    }

    return (
        <>
            <DialogContent>
                <div className={classes.attention}>
                    Time chosen will be in <strong>local time</strong>
                    <br />
                    Time will be saved as <strong>UTC time</strong>
                    <br />
                    {selectedRows.length > 250
                        ? 'You can batch edit a maximum of 250 vehicles at a time.'
                        : null}
                </div>
                <div className={classes.info}>
                    All selected vehicles will be updated with the values you
                    specify below. All fields are optional and you can choose
                    which fields to update by switching the toggle to Yes. If
                    you leave a field blank, it will remove the data from the
                    selected field for all selected vehicles.
                </div>

                {formData.map(({ title, key, type, options }, index) => (
                    <div key={key} className={classes.wrapper}>
                        <Field label='Edit'>
                            <Switch
                                checked={toggles[index]}
                                onChange={(_, data) =>
                                    handleToggleChange(index, key, data.checked)
                                }
                            />
                        </Field>
                        {type === 'text' ? (
                            <Field label={title}>
                                <Input
                                    className={classes.input}
                                    disabled={!toggles[index]}
                                    value={
                                        changeSets
                                            .find(s => s.path === key)
                                            ?.value?.toString() ?? ''
                                    }
                                    onChange={(_ev, data) =>
                                        handleCallback(data.value, key, index)
                                    }
                                />
                            </Field>
                        ) : type === 'dropdown' ? (
                            <Field label={title}>
                                <Dropdown
                                    inlinePopup
                                    className={classes.input}
                                    disabled={!toggles[index]}
                                    onOptionSelect={(_ev, data) => {
                                        handleCallback(
                                            data.optionValue,
                                            key,
                                            index
                                        )
                                    }}
                                >
                                    {options?.map(option => (
                                        <Option key={option} value={option}>
                                            {option}
                                        </Option>
                                    ))}
                                </Dropdown>
                            </Field>
                        ) : type === 'date' ? (
                            <DateTimePicker
                                nowTime
                                clear
                                label={title}
                                disabled={!toggles[index]}
                                onChange={d =>
                                    handleCallback(
                                        d?.toString() ?? '',
                                        key,
                                        index
                                    )
                                }
                                value={
                                    (changeSets.find(s => s.path === key)
                                        ?.value as string) || null
                                }
                                max={new Date()}
                                validationMessage={validation[index]}
                            />
                        ) : null}
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button
                    appearance='primary'
                    onClick={onSave}
                    disabled={
                        !(selectedRows.length < 250 && toggles.some(t => t))
                    }
                >
                    Save
                </Button>
                <DialogTrigger>
                    <Button data-cy='close-edit-button'>Cancel</Button>
                </DialogTrigger>
            </DialogActions>
        </>
    )
}

export default WorkshopBatchEditModal
