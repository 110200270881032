import { ColDef, ICellRendererParams } from 'ag-grid-community'

export const selectColumnDef: ColDef = {
    lockPosition: true,
    lockPinned: true,
    pinned: 'left',
    width: 55,
    checkboxSelection: true,
    suppressMenu: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
}

export const VehicleColumnDefs = (vehicleStatuses: string[]): Array<ColDef> => [
    { field: 'Id' },
    { field: 'Vin' },
    {
        field: 'VehicleStatus',
        filter: 'agSetColumnFilter',
        filterParams: {
            values: vehicleStatuses,
        },
    },
    { field: 'Model' },
    { field: 'ModelYear' },
]

export const vehicleGridRowClassRules = {
    // apply yellowish color to undefined vehicle status
    'ag-grid-row-warning': function (props: ICellRendererParams) {
        const { data } = props

        return (
            data &&
            ((Object.hasOwn(data, 'vehicleStatus') &&
                data.vehicleStatus === 'undefined') ||
                (Object.hasOwn(data, 'VehicleStatus') &&
                    props.data.VehicleStatus === 'undefined'))
        )
    },

    // apply red color to ended vehicle status
    'ag-grid-row-error': function (props: ICellRendererParams) {
        const { data } = props

        return (
            data &&
            ((Object.hasOwn(data, 'vehicleStatus') &&
                data.vehicleStatus === 'Ended') ||
                (Object.hasOwn(data, 'VehicleStatus') &&
                    props.data.VehicleStatus === 'Ended'))
        )
    },
}

export const vehicleStatusString = ({
    startDate,
    endDate,
}: {
    startDate: string
    endDate: string
}) => {
    const nowDate = new Date()

    if (endDate && new Date(endDate) < nowDate) return 'Finished'

    if (startDate) return new Date(startDate) > nowDate ? 'Planned' : 'Ongoing'

    return 'Created'
}
