import { ICellRendererParams } from 'ag-grid-community'
import { Switch } from '@fluentui/react-components'

const WorkshopToggleCellRenderer = (props: ICellRendererParams<IWorkshop>) => {
    return (
        <Switch
            checked={props.data.WsWaiting}
            onChange={(_ev, data) => props.setValue(data.checked)}
            label={props.data.WsWaiting ? 'Driver waiting' : null}
        />
    )
}

export default WorkshopToggleCellRenderer
