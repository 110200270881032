import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    MessageBar,
} from '@fluentui/react-components'
import { useEffect, useRef, useState } from 'react'

import { TextBulletListSquareEdit24Regular } from '@fluentui/react-icons'
import { ISetFilter } from 'ag-grid-enterprise'
import { apiUtil, useEditVehicleGroupListsMutation } from 'api/vehicleGroupList'
import { acquireApiAccessToken } from 'authInstance'
import ModalStatusEnum from 'constants/ModalStatusEnum'
import ModalTypeEnum from 'constants/ModalTypeEnum'
import { useGrid } from 'contexts/GridContext'
import { FilePond as FilePondType } from 'filepond'
import consoleErrorData from 'helpers/consoleErrorData'
import { insertSpaces } from 'helpers/stringHelpers'
import { FilePond } from 'react-filepond'
import { triggerMessage } from 'slices/messageSlice'
import { useAppDispatch } from 'store'
import VehicleGroupListsModalForm from './VehicleGroupListsForm'

type VehicleGroupListEditModalProps = {
    data: { id: string; name: string; vins: string[] }
    vehicleGroupType: VehicleGroupListSupportedPages
}

const VehicleGroupListEditModal = ({
    data,
    vehicleGroupType,
}: VehicleGroupListEditModalProps) => {
    const filePondInstance = useRef<FilePond & { _pond: FilePondType }>(null)
    const [vinAddType, setVinAddType] = useState<string>('vinField')

    const [accessToken, setAccessToken] = useState<string>()
    const [isOpen, setIsOpen] = useState(false)

    const getAccessToken = async () => {
        setAccessToken(await acquireApiAccessToken())
    }

    const { gridRef } = useGrid()
    const dispatch = useAppDispatch()

    const [vehicleGroupList, setVehicleGroupList] = useState({
        ...data,
        operation: 'Add',
    })

    useEffect(() => {
        setVehicleGroupList(pre => ({
            ...pre,
            ...data,
        }))
    }, [data])

    const [editVehicleGroupLists, { isError, isLoading, isSuccess }] =
        useEditVehicleGroupListsMutation()

    const updateGridFilter = (filterName: string, newValue) => {
        // Refresh the grid
        gridRef.current?.api.refreshServerSide()

        // Update the filter options
        const filterInstance =
            gridRef.current?.api.getFilterInstance<ISetFilter>(filterName)
        const values = filterInstance?.getFilterValues()
        filterInstance?.setFilterValues([
            ...values.filter(item => item !== data?.name),
            newValue,
        ])

        // Update the filter model, this doesn't work for some reason
        // it only seems to clear the filter model... might be something
        // to look into in the future... or not
        const model = filterInstance?.getModel()
        filterInstance?.setModel({
            ...model,
            values: [...values.filter(item => item !== data?.name), newValue],
        })
        gridRef.current?.api.onFilterChanged()
    }

    const updateVehicleGroupList = async () => {
        if (vinAddType === 'vinField') {
            const putVehicleGroupList: IVehicleGroup = {
                name: vehicleGroupList.name,
                vins:
                    vehicleGroupList.vins &&
                    vehicleGroupList.vins.filter(Boolean).length > 0
                        ? vehicleGroupList.vins?.filter(Boolean)
                        : [],
                operation: vehicleGroupList.operation,
            }

            await editVehicleGroupLists({
                vehicleGroupType,
                vehicleGroupListId: data?.id,
                putVehicleGroupList,
            })

            updateGridFilter(`${vehicleGroupType}s`, vehicleGroupList.name)
        }
        if (
            vinAddType === 'vinExcel' &&
            vehicleGroupList.name &&
            vehicleGroupList.id &&
            vehicleGroupList.operation
        ) {
            filePondInstance.current?._pond.setOptions({
                server: {
                    url: `${process.env.REACT_APP_REFLECT_BACKEND_API}`,
                    process: {
                        url: `api/VehicleGroupList/${vehicleGroupType}/GroupListReadFile/${
                            vehicleGroupList.id
                        }/${encodeURIComponent(
                            vehicleGroupList.name
                        )}/${vehicleGroupList.operation.toUpperCase()}`,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        method: 'PUT',
                        ondata: (formData: FormData) => formData,
                        withCredentials: false,
                        onerror: response => {
                            consoleErrorData(response)
                            return response.data
                        },
                    },
                    revert: null,
                    restore: null,
                    load: null,
                    fetch: null,
                },
            })
            await filePondInstance.current
                ?.processFiles()
                .then(response => {
                    const serverResponse = JSON.parse(response[0].serverId)

                    dispatch(
                        triggerMessage({
                            title: 'Success',
                            message: `${serverResponse.vehicleCount} vehicles added to the group: ${vehicleGroupList.name}!`,
                            intent: 'success',
                        })
                    )
                    dispatch(
                        apiUtil.invalidateTags([
                            'VehicleGroupLists',
                            'VehicleGroupListsFilters',
                        ])
                    )
                    updateGridFilter(
                        `${vehicleGroupType}s`,
                        vehicleGroupList.name
                    )
                })
                .catch(e => {
                    dispatch(
                        triggerMessage({
                            title: 'Error',
                            message: `Could not add vehicle(s) to taglist: ${vehicleGroupList.name}.
                         Please check the file or contact support, statuscode: ${e.error.code}.`,
                            intent: 'error',
                        })
                    )
                })
        }
        setIsOpen(false)
    }

    return (
        <Dialog open={isOpen} onOpenChange={(e, d) => setIsOpen(d.open)}>
            <DialogTrigger>
                <Button
                    icon={<TextBulletListSquareEdit24Regular />}
                    title='Edit'
                    appearance='transparent'
                    aria-label={`Open ${insertSpaces(vehicleGroupType)}`}
                />
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>{`Edit ${insertSpaces(vehicleGroupType)} "${
                        data?.name
                    }"`}</DialogTitle>
                    <DialogContent>
                        {isError && (
                            <MessageBar intent='error'>
                                Failed to update
                                {insertSpaces(vehicleGroupType)}. Please retry,
                                contact technical support if the error persists.
                            </MessageBar>
                        )}
                        {isSuccess && (
                            <MessageBar intent='success'>
                                {insertSpaces(vehicleGroupType)} updated!
                            </MessageBar>
                        )}
                        <VehicleGroupListsModalForm
                            editExisting
                            vehicleGroupList={vehicleGroupList}
                            setVehicleGroupList={setVehicleGroupList}
                            filePondInstance={filePondInstance}
                            getAccessToken={getAccessToken}
                            setVinAddType={setVinAddType}
                            vinAddType={vinAddType}
                            vehicleGroupType={vehicleGroupType}
                            modalType={ModalTypeEnum.EditModal}
                            modalStatus={ModalStatusEnum.Manual}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => updateVehicleGroupList()}
                            appearance='primary'
                            disabled={isLoading || !vehicleGroupList.name}
                        >
                            Save
                        </Button>

                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={() => setIsOpen(false)}>
                                Cancel
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default VehicleGroupListEditModal
