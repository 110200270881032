import dayjs from 'dayjs'
import isoExtends from 'dayjs/plugin/isoWeek'

const isoWeekString = (isoWeek: number) =>
    isoWeek <= 9 ? `0${isoWeek}` : isoWeek

const createYearWeekFilterString = (date: Date, readable = false) => {
    dayjs.extend(isoExtends)

    const useDate = new Date(date)
    const isoWeek = dayjs(useDate).isoWeek()
    const isoYear = dayjs(useDate).isoWeekYear()
    return readable
        ? `Y${isoYear} W${isoWeekString(isoWeek)}`
        : `${isoYear}${isoWeekString(isoWeek)}`
}

export default createYearWeekFilterString
