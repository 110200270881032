import {
    Button,
    Text,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react'

import ColumnType from 'constants/ColumnTypeEnum'
import FilterModelEnum from 'constants/FilterModelEnum'
import { FilterVariantEnum } from 'components/AgGrid/Filters/CustomStructureWeekFilter'
import { IToolPanelParams } from 'ag-grid-community'
import { SubtractCircle24Regular } from '@fluentui/react-icons'
import createYearWeekFilterString from 'helpers/createYearWeekFilterString'
import dayjs from 'dayjs'

interface IFilterModel {
    filterVariant: FilterVariantEnum
    type: string
    filter: string
    structureWeekIn: string[]
    structureWeekFrom: Date
    structureWeekTo: Date
    realType: FilterModelEnum
    dateFrom: string
    dateTo: string
    operator: string
    condition1: { [key: string]: string }
    condition2: { [key: string]: string }
}

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gridRowGap: '8px',
        ...shorthands.padding('8px'),
    },
    button: {
        minHeight: '32px',
        width: '100%',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
})

const getShortLocalDateString = (dateString: string, subDaysNumber = 0) => {
    return dayjs(dateString).subtract(subDaysNumber, 'day').format('YYYY-MM-DD')
}

const getSetFilterString = (values: Array<string>) => {
    const valuesArray = values
    if (valuesArray.length <= 3) {
        return ` (${values.join(', ')})`
    }
    const newValuesArray = valuesArray.slice(0, 3)
    return ` (${newValuesArray.join(', ')} +${
        valuesArray.length - newValuesArray.length
    } more)`
}

const getStructureWeekFilterString = (filter: IFilterModel) => {
    let structureWeekDateReturnString = ` ( ${filter.type}`
    if (filter.filterVariant === FilterVariantEnum.SelectStructureWeeks) {
        return getSetFilterString(filter.structureWeekIn)
    }
    structureWeekDateReturnString += ` ${createYearWeekFilterString(
        filter.structureWeekFrom,
        true
    )}`
    if (filter.structureWeekTo) {
        structureWeekDateReturnString += ` to ${createYearWeekFilterString(
            filter.structureWeekTo,
            true
        )}`
    }
    structureWeekDateReturnString += ')'
    return structureWeekDateReturnString
}

const getDateFilterString = (filter: IFilterModel) => {
    let dateReturnString = ` (${filter.realType}: `
    dateReturnString += ` ${getShortLocalDateString(filter.dateFrom)}`
    if (filter.dateTo && filter.realType !== FilterModelEnum.Equals) {
        dateReturnString += ` to ${getShortLocalDateString(filter.dateTo, 0)}`
    }
    dateReturnString += ')'
    return dateReturnString
}

const getConditionString = (type: string, filter: unknown) => {
    if (type === 'blanks') {
        return type
    }
    return `${type}: "${filter}"`
}

const getTextFilterString = (filter: IFilterModel) => {
    if (filter.operator) {
        if (filter.operator === 'OR' || filter.operator === 'AND') {
            return ` (${getConditionString(
                filter.condition1.type,
                filter.condition1.filter
            )}
                ${' '}
                ${filter.operator}
                ${' '}
                ${getConditionString(
                    filter.condition2.type,
                    filter.condition2.filter
                )})`
        }
    }
    if (filter.type === 'blanks') {
        return ` (${filter.type})`
    }
    return ` (${filter.type}: ${filter.filter})`
}

const getFilterValues = (filterKey: string, filterModel: IFilterModel) => {
    const filter = filterModel[filterKey]
    switch (filter.filterType) {
        case ColumnType.Text:
            return getTextFilterString(filter)
        case ColumnType.Set:
            return getSetFilterString(filter.values)
        case 'set':
            return getSetFilterString(filter.values)
        case ColumnType.StructureWeek:
            return getStructureWeekFilterString(filter)
        case ColumnType.Date:
            return getDateFilterString(filter)
        default:
            return null
    }
}

type CustomFilterStatePanelParams = IToolPanelParams & {
    hideRestoreButton: boolean
    viewFilters: string | undefined
    setViewFiltersRestore: Dispatch<SetStateAction<boolean>>
}

const CustomFilterStatePanel = ({ api }: CustomFilterStatePanelParams) => {
    const [filterModel, setFilterModel] = useState<IFilterModel>()

    const classes = useStyles()

    const getFilterModel = useCallback(() => {
        const currentFilterModel = api.getFilterModel() as IFilterModel
        setFilterModel(currentFilterModel)
    }, [api])

    useEffect(() => {
        api.addEventListener('modelUpdated', getFilterModel)
        return () => api.removeEventListener('modelUpdated', getFilterModel)
    }, [api, getFilterModel])

    const clearAllFilters = () => {
        api.setFilterModel(null)
    }

    const removeFilterFromModel = (filterKey: string) => {
        const newFilterModel = filterModel
        if (newFilterModel && newFilterModel[filterKey]) {
            delete newFilterModel[filterKey]
            api.setFilterModel(newFilterModel)
        }
    }

    return (
        <div className={classes.wrapper}>
            <Button
                appearance='primary'
                onClick={clearAllFilters}
                disabled={
                    filterModel ? Object.entries(filterModel).length <= 0 : true
                }
            >
                Clear all filters
            </Button>

            {filterModel && Object.entries(filterModel).length > 0 && (
                <>
                    <Text block>
                        <strong>Reset filter for column:</strong>
                    </Text>

                    {filterModel &&
                        Object.keys(filterModel).map((filterColumn, i) => (
                            <Button
                                key={i}
                                icon={<SubtractCircle24Regular />}
                                onClick={() =>
                                    removeFilterFromModel(filterColumn)
                                }
                            >{`${filterColumn}${
                                getFilterValues(filterColumn, filterModel) ?? ''
                            }`}</Button>
                        ))}
                </>
            )}
        </div>
    )
}

export default CustomFilterStatePanel
