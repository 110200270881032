import { Dispatch, SetStateAction, useState } from 'react'
import {
    Field,
    Input,
    Switch,
    Textarea,
    makeStyles,
} from '@fluentui/react-components'

import PGWCollectionTypeDropdown from './PGWCollectionTypeDropdown'
import { selectRowCount } from 'slices/gridSlice'
import { useAppSelector } from 'store'

type PGWCollectionFormFieldsProps = {
    collection: IPGWCollection
    setCollection: Dispatch<SetStateAction<IPGWCollection>>
    setFetchAllVinsFromFilteredVehicles?: Dispatch<SetStateAction<boolean>>
    disableFields?: boolean
    hideVinField?: boolean
}

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gridGap: '8px',
    },
    inner: {
        display: 'flex',
        gridGap: '16px',
    },
})

const PGWCollectionFormFields = ({
    collection,
    setCollection,
    setFetchAllVinsFromFilteredVehicles = () => null,
    disableFields,
    hideVinField = false,
}: PGWCollectionFormFieldsProps) => {
    const rowCount = useAppSelector(selectRowCount)
    const [addAllFilteredVehicles, setAddAllFilteredVehicles] =
        useState<boolean>(false)

    const classes = useStyles()

    const getErrorMessage = (string?: string) =>
        string === '' ? 'This field is required' : null

    const getSelectionInfoText = () =>
        rowCount !== undefined
            ? `Add all filtered vehicles (${rowCount}).`
            : null

    return (
        <div className={classes.wrapper}>
            <div className={classes.inner}>
                <Field
                    required
                    label='Name'
                    validationMessage={
                        disableFields
                            ? undefined
                            : getErrorMessage(collection.name)
                    }
                >
                    <Input
                        value={collection.name ?? ''}
                        onChange={(_e, data) => {
                            setCollection(pre => ({
                                ...pre,
                                name: data.value,
                            }))
                        }}
                        disabled={disableFields}
                    />
                </Field>

                <PGWCollectionTypeDropdown
                    selectedItemKey={collection.collectionType ?? ''}
                    setSelectedItemKey={selectedItemKey => {
                        setCollection(pre => ({
                            ...pre,
                            collectionType: selectedItemKey,
                        }))
                    }}
                    disabled={disableFields}
                />
            </div>

            <Field
                required
                label='Description'
                validationMessage={
                    disableFields
                        ? undefined
                        : getErrorMessage(collection.description)
                }
            >
                <Input
                    value={collection.description ?? ''}
                    onChange={(_e, data) => {
                        setCollection(pre => ({
                            ...pre,
                            description: data.value,
                        }))
                    }}
                    disabled={disableFields}
                />
            </Field>

            {!hideVinField && (
                <>
                    <Switch
                        label={getSelectionInfoText()}
                        checked={addAllFilteredVehicles}
                        onChange={(_, data) => {
                            setAddAllFilteredVehicles(data.checked)
                            setFetchAllVinsFromFilteredVehicles(data.checked)
                        }}
                    />
                    {addAllFilteredVehicles && rowCount >= 10000 && (
                        <span className='ms-TextField-description description-165'>
                            WARNING! This operation will take a long time to
                            complete
                        </span>
                    )}
                    <Field label='Selected VINs (change selection in main grid)'>
                        <Textarea
                            placeholder='VIN1, VIN2, VIN3'
                            value={collection.vinsList?.join(', ') ?? ''}
                            rows={10}
                            onChange={(_, data) => {
                                setCollection(pre => ({
                                    ...pre,
                                    vinsList:
                                        data.value
                                            ?.split(',')
                                            .map(vin => vin.trim()) || [],
                                }))
                            }}
                            readOnly
                            disabled={disableFields || addAllFilteredVehicles}
                        />
                    </Field>
                </>
            )}
        </div>
    )
}

export default PGWCollectionFormFields
