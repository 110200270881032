import {
    SkeletonItem,
    Text,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'

import { Fragment } from 'react'
import { dateStringRenderWithTime } from 'helpers/dateHelpers'
import { useGetVehicleGroupListsQuery } from 'api/vehicleGroupList'

interface ITagList {
    id: string
    createdBy: string
    insertDate: string
    name: string
    updateDate: string
    vehicleCount: number
}

interface IVehicleTagListsProps {
    data: IVehicleInfoOdata
}

const useStyles = makeStyles({
    grid: {
        display: 'grid',
        gridTemplateColumns: '0.5fr 200px 180px 100px',
        gridGap: '10px',
        ...shorthands.margin('16px'),
        '> span': {
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
    noLists: {
        fontStyle: 'italic',
        gridColumnStart: 1,
        gridColumnEnd: 6,
    },
})

const formatDateTime = (date: string) =>
    date ? dateStringRenderWithTime(date) : 'Not Defined'

const VehicleTagLists = ({ data }: IVehicleTagListsProps) => {
    const classes = useStyles()

    const { data: tagLists, isLoading } =
        useGetVehicleGroupListsQuery('TagList')

    const vehicleTagLists = tagLists
        ?.filter(t => data.TagLists?.split(',').includes(t.name))
        .map(t => t as unknown as ITagList)

    return (
        <div data-cy='vehicle-details-tagLists-body' className={classes.grid}>
            <Text weight='bold'>Name</Text>
            <Text weight='bold'>Created</Text>
            <Text weight='bold'>Vehicles included</Text>
            <Text weight='bold'>Created By</Text>

            {isLoading ? (
                [...Array(15)].map((_, i) => <SkeletonItem key={i} />)
            ) : vehicleTagLists?.length ? (
                vehicleTagLists?.map(t => (
                    <Fragment key={t.id}>
                        <Text title={'Name'}>{t.name}</Text>
                        <Text title={'Created'}>
                            {formatDateTime(t.insertDate) ?? ''}
                        </Text>
                        <Text>{t.vehicleCount}</Text>
                        <Text>{t.createdBy}</Text>
                    </Fragment>
                ))
            ) : (
                <Text size={400} className={classes.noLists}>
                    This vehicle does not appear to be associated with any tag
                    lists. If you believe this is a mistake, please reach out to
                    the administrator for assistance.
                </Text>
            )}
        </div>
    )
}

export default VehicleTagLists
