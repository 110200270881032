import {
    Combobox,
    ComboboxProps,
    Field,
    Option,
    Text,
} from '@fluentui/react-components'
import { useMemo, useState } from 'react'

import { useGetActorsQuery } from 'api/drivers'

interface IApiDropdownProps {
    label: string
    onSelect: (selected: IActor) => void
    placeholder: string
    required?: boolean
    hint?: string
}

const Actors = ({
    label,
    onSelect,
    placeholder,
    required = false,
    hint,
}: IApiDropdownProps) => {
    const { data: rawData } = useGetActorsQuery(
        '?sort=firstName,asc&filter=cdsid ne null'
    )
    const actors = useMemo(() => {
        const uniqueActors: IActor[] = []
        const cdsids: string[] = []
        rawData?.forEach((actor: IActor) => {
            if (!cdsids.includes(actor.cdsid)) {
                uniqueActors.push(actor)
                cdsids.push(actor.cdsid)
            }
        })
        return uniqueActors
    }, [rawData])

    const [customSearch, setCustomSearch] = useState<string | undefined>()
    const [matchingOptions, setMatchingOptions] = useState<IActor[]>([])

    const onChange: ComboboxProps['onChange'] = event => {
        const value = event.target.value

        setCustomSearch(value)
        if (value.length >= 3) {
            const matches =
                actors?.filter(
                    actor =>
                        actor.firstName
                            ?.toLowerCase()
                            .indexOf(value.toLowerCase()) === 0 ||
                        actor.lastName
                            ?.toLowerCase()
                            .indexOf(value.toLowerCase()) === 0 ||
                        actor.cdsid
                            ?.toLowerCase()
                            .indexOf(value.toLowerCase()) === 0 ||
                        actor.email
                            ?.toLowerCase()
                            .indexOf(value.toLowerCase()) === 0 ||
                        actor.preferredEmail
                            ?.toLowerCase()
                            .indexOf(value.toLowerCase()) === 0
                ) ?? []
            setMatchingOptions(matches)
        } else {
            setMatchingOptions([])
        }
    }

    const onOptionSelect: ComboboxProps['onOptionSelect'] = (_ev, data) => {
        const matchingActor = actors.find(
            actor => actor.cdsid === data.optionValue
        )

        if (matchingActor) {
            onSelect(matchingActor)
        }
    }

    return (
        <Field label={label} required={required} hint={hint}>
            <Combobox
                clearable
                placeholder={placeholder}
                onChange={onChange}
                onOptionSelect={onOptionSelect}
            >
                {!matchingOptions.length ? (
                    <Option key='empty' text={customSearch}>
                        {customSearch?.length >= 3 ? (
                            <span>
                                No Driver found containing &quot;{customSearch}
                                &quot;
                            </span>
                        ) : (
                            <span>Enter at least 3 characters</span>
                        )}
                    </Option>
                ) : null}
                {matchingOptions.map(option => (
                    <Option
                        key={option.cdsid}
                        value={option.cdsid}
                        text={option.cdsid}
                    >
                        <Text>
                            {`${option.firstName} ${option.lastName}`}
                            <br />
                            <b>CDSID</b> {option.cdsid} <br />
                            <b>Email</b> {option.email}
                            <br />
                            <b>Preferred Email</b> {option.preferredEmail}
                            <br />
                            <b>Phone</b> {option.phone}
                        </Text>
                    </Option>
                ))}
            </Combobox>
        </Field>
    )
}

export default Actors
