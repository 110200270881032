import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    makeStyles,
} from '@fluentui/react-components'
import { CheckmarkCircleRegular, Pen24Regular } from '@fluentui/react-icons'
import { useEffect, useState } from 'react'

import AxiosStateTypeEnum from 'constants/AxiosStateTypeEnum'
import BatchEditVehicleImport from './BatchEditImportVehicles'
import CancelImport from './CancelImport'
import FileUploader from './FileUploader'
import ImportFeedback from './ImportFeedback'
import ImportVehicleData from './ImportVehicleData'
import IncludeVolvoBil from './IncludeVolvoBil'
import UploadError from './UploadError'
import { capitalizeKeysInObject } from 'helpers/objectManipulation'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'

type ImportVehicleProps = {
    toggleOpen: () => void
    open: boolean
}

const useStyles = makeStyles({
    dialog: {
        display: 'flex',
    },
    dialogActions: {
        marginLeft: 'auto',
        marginRight: '5px',
    },
    button: {
        marginTop: '8px',
        marginLeft: '8px',
    },
})

const ImportVehicle = ({ toggleOpen, open }: ImportVehicleProps) => {
    const classes = useStyles()

    const [dialogWidth, setDialogWidth] = useState('600px')

    const [uploadedFiles, setUploadedFiles] = useState<VehicleDataResponse[]>()
    const [uploadFileError, setUploadFileError] = useState(false)
    const [uploadStatus, setUploadStatus] = useState<AxiosStateTypeEnum>(
        AxiosStateTypeEnum.Unknown
    )

    const [includeVolvoBilData, setIncludeVolvoBilData] = useState(false)
    const [showCancelDialog, setShowCancelDialog] = useState(false)

    const [readyVehicles, setReadyVehicles] = useState<ImportVehicle[]>([])
    const [vehicleImportList, setVehicleImportList] = useState<ImportVehicle[]>(
        []
    )

    const [uploadingVehicles, setUploadingVehicles] = useState(false)
    const [backendErrorResponse, setBackendErrorResponse] = useState<string>()
    const [carUsage, setCarUsage] = useState<string>()
    const [consentType, setConsentType] = useState<string>()

    const [isBatchEditOpen, setIsBatchEditOpen] = useState(false)

    const hideCancelDialog = () => setShowCancelDialog(false)

    const hideAllDialogs = () => {
        setShowCancelDialog(false)
        toggleOpen()
    }

    const onCancelVehicleImport = event => {
        event.preventDefault()
        if (uploadedFiles?.length > 0) {
            setShowCancelDialog(true)
        } else {
            toggleOpen()
        }
    }

    const uploadVehicles = () => {
        setUploadingVehicles(true)
        const vehicleArray: VehicleData[] = []

        readyVehicles.forEach(importVehicle => {
            const vehicleData = capitalizeKeysInObject(importVehicle.data)

            if (!importVehicle.data.vin) {
                vehicleData.Vin = importVehicle.vin
                vehicleArray.push(vehicleData)
            } else {
                vehicleArray.push(vehicleData)
            }
        })

        reflectApiAxiosInstance
            .post(
                `/api/VehicleImport/VehicleInformation?includeVolvoBilData=${includeVolvoBilData}`,
                vehicleArray
            )
            .then(() => {
                setUploadStatus(AxiosStateTypeEnum.Success)
            })
            .catch(error => {
                setUploadStatus(AxiosStateTypeEnum.Error)
                // eslint-disable-next-line no-console
                console.error(error)
            })
            .finally(() => {
                setUploadingVehicles(false)
            })
    }

    const onClickTryAgain = () => {
        setUploadedFiles(undefined)
        setUploadFileError(false)
        setUploadStatus(AxiosStateTypeEnum.Unknown)
        setReadyVehicles([])
    }

    useEffect(() => {
        if (
            carUsage &&
            vehicleImportList?.some(
                vehicle => vehicle.data.carUsage !== carUsage
            )
        ) {
            setVehicleImportList(
                vehicleImportList.map(vehicle => ({
                    ...vehicle,
                    data: {
                        ...vehicle.data,
                        carUsage: String(carUsage),
                    },
                }))
            )
        }
    }, [carUsage, vehicleImportList])

    useEffect(() => {
        if (
            consentType &&
            vehicleImportList?.some(
                vehicle => vehicle.data.consentType !== consentType
            )
        ) {
            setVehicleImportList(
                vehicleImportList.map(vehicle => ({
                    ...vehicle,
                    data: {
                        ...vehicle.data,
                        consentType: String(consentType),
                    },
                }))
            )
        }
    }, [consentType, vehicleImportList])

    useEffect(() => {
        const calculateMaxWidth = () => {
            if (
                uploadedFiles &&
                uploadedFiles.length > 0 &&
                uploadStatus != AxiosStateTypeEnum.Success
            ) {
                return '1100px'
            } else {
                return '600px'
            }
        }

        setDialogWidth(calculateMaxWidth())
    }, [uploadStatus, uploadedFiles])

    const setAllToReady = () => {
        setReadyVehicles(vehicleImportList)
    }

    useEffect(() => {
        if (!isBatchEditOpen) {
            setCarUsage('')
            setConsentType('')
        }
    }, [isBatchEditOpen])

    return (
        <>
            <Dialog open={open} onOpenChange={toggleOpen}>
                <DialogSurface
                    style={{
                        width: '100%',
                        maxWidth: dialogWidth,
                    }}
                >
                    <DialogBody>
                        <DialogTitle>Import Vehicles</DialogTitle>
                        <DialogContent>
                            {uploadStatus === AxiosStateTypeEnum.Unknown &&
                                (uploadedFiles ? (
                                    <>
                                        {uploadedFiles.length > 0 && (
                                            <>
                                                <IncludeVolvoBil
                                                    includeVolvoBilData={
                                                        includeVolvoBilData
                                                    }
                                                />
                                                <div className={classes.dialog}>
                                                    <Button
                                                        onClick={setAllToReady}
                                                        icon={
                                                            <CheckmarkCircleRegular />
                                                        }
                                                        style={{
                                                            marginTop: '8px',
                                                        }}
                                                    >
                                                        Set all to ready
                                                    </Button>
                                                    <Button
                                                        icon={<Pen24Regular />}
                                                        className={
                                                            classes.button
                                                        }
                                                        disabled={
                                                            uploadedFiles.length <
                                                            2
                                                        }
                                                        onClick={() =>
                                                            setIsBatchEditOpen(
                                                                prevState =>
                                                                    !prevState
                                                            )
                                                        }
                                                    >
                                                        Batch Edit
                                                    </Button>
                                                </div>
                                                {isBatchEditOpen && (
                                                    <BatchEditVehicleImport
                                                        isBatchEditOpen={
                                                            isBatchEditOpen
                                                        }
                                                        setIsBatchEditOpen={
                                                            setIsBatchEditOpen
                                                        }
                                                        carUsage={carUsage}
                                                        setCarUsage={
                                                            setCarUsage
                                                        }
                                                        consentType={
                                                            consentType
                                                        }
                                                        setConsentType={
                                                            setConsentType
                                                        }
                                                    />
                                                )}
                                                {uploadedFiles.map(vehicle => (
                                                    <ImportVehicleData
                                                        uploadedFiles={
                                                            uploadedFiles
                                                        }
                                                        key={vehicle.excel.vin}
                                                        vehicle={vehicle}
                                                        readyVehicles={
                                                            readyVehicles
                                                        }
                                                        setReadyVehicles={
                                                            setReadyVehicles
                                                        }
                                                        setVehicleImportList={
                                                            setVehicleImportList
                                                        }
                                                        carUsage={carUsage}
                                                        consentType={
                                                            consentType
                                                        }
                                                        includeVolvoBilData={
                                                            includeVolvoBilData
                                                        }
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {uploadFileError && (
                                            <UploadError
                                                info={backendErrorResponse}
                                            />
                                        )}

                                        <FileUploader
                                            setUploadedFiles={setUploadedFiles}
                                            setUploadFileError={
                                                setUploadFileError
                                            }
                                            includeVolvoBilData={
                                                includeVolvoBilData
                                            }
                                            setIncludeVolvoBilData={
                                                setIncludeVolvoBilData
                                            }
                                            backendErrorResponse={
                                                backendErrorResponse
                                            }
                                            setBackendErrorResponse={
                                                setBackendErrorResponse
                                            }
                                        />
                                    </>
                                ))}

                            <ImportFeedback
                                uploadStatus={uploadStatus}
                                toggleOpen={toggleOpen}
                                onClickTryAgain={onClickTryAgain}
                            />

                            {uploadStatus === AxiosStateTypeEnum.Unknown &&
                                (uploadingVehicles ? (
                                    <DialogActions>
                                        <Button
                                            className={classes.dialogActions}
                                            disabled
                                            appearance='primary'
                                        >
                                            Importing vehicles...
                                        </Button>
                                        <DialogTrigger disableButtonEnhancement>
                                            <Button disabled>Cancel</Button>
                                        </DialogTrigger>
                                    </DialogActions>
                                ) : (
                                    <DialogActions>
                                        <div className={classes.dialogActions}>
                                            {uploadedFiles?.length > 0 && (
                                                <Button
                                                    disabled={
                                                        readyVehicles.length < 1
                                                    }
                                                    onClick={uploadVehicles}
                                                    appearance='primary'
                                                    style={{
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    Import{' '}
                                                    {readyVehicles.length}{' '}
                                                    vehicle(s)
                                                </Button>
                                            )}
                                            <DialogTrigger
                                                disableButtonEnhancement
                                            >
                                                <Button
                                                    onClick={
                                                        onCancelVehicleImport
                                                    }
                                                    style={{
                                                        marginLeft: 'auto',
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </DialogTrigger>
                                        </div>
                                    </DialogActions>
                                ))}
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            <CancelImport
                showCancelDialog={showCancelDialog}
                hideAllDialogs={hideAllDialogs}
                hideCancelDialog={hideCancelDialog}
                setShowCancelDialog={setShowCancelDialog}
            />
        </>
    )
}

export default ImportVehicle
