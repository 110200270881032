import { Text, makeStyles, shorthands } from '@fluentui/react-components'

import { ICellRendererParams } from 'ag-grid-community'
import { TextAsteriskRegular } from '@fluentui/react-icons'

const useStyles = makeStyles({
    icon: {
        paddingTop: '2px',
        color: 'DimGrey',
        ...shorthands.margin('0', '5px'),
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
    },
})

const VehicleTestsVisitWorkshopCellRenderer = ({
    value,
}: ICellRendererParams) => {
    const classes = useStyles()
    return value ? (
        <div className={classes.wrapper}>
            <TextAsteriskRegular className={classes.icon} />
            <Text>Requires visit</Text>
        </div>
    ) : null
}

export default VehicleTestsVisitWorkshopCellRenderer
