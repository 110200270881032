import { ICellRendererParams } from 'ag-grid-community'
import dayjs from 'dayjs'
import { useSetParticipationForDriverMutation } from 'api/driverStudyParticipation'
import { Button, Spinner } from '@fluentui/react-components'
import { Play48Regular } from '@fluentui/react-icons'
import { useAppDispatch } from 'store'
import { triggerMessage } from 'slices/messageSlice'

type DriverActionsColumnProps = ICellRendererParams & {
    data: IFactDriverStudyAssignment
    disabled: boolean
}

const DriverActionsColumn = ({ data, disabled }: DriverActionsColumnProps) => {
    const [setParticipation, { isLoading }] =
        useSetParticipationForDriverMutation()
    const dispatch = useAppDispatch()

    const handleClick = async () => {
        const onDate = dayjs().toISOString()

        await setParticipation({
            participationId: data.Id,
            onDate,
        })

        dispatch(
            triggerMessage({
                title: 'Drivers updated',
                message: 'Participation set for driver',
                intent: 'success',
            })
        )
    }

    return (
        <Button
            data-cy='set-participation-button'
            title='Set Participation'
            appearance='transparent'
            onClick={handleClick}
            disabled={disabled || data.Participation}
            icon={isLoading ? <Spinner /> : <Play48Regular />}
        />
    )
}

export default DriverActionsColumn
