import { Text } from '@fluentui/react-components'
import { releaseName } from 'utils/envUtils'
import { useGetReleaseInformationQuery } from 'api/releaseInformation'

const ReleaseInformation = () => {
    const { data } = useGetReleaseInformationQuery()

    return (
        <div>
            <h3>Release Information</h3>
            <Text italic block>
                BE: {data?.split('_')[1]}
            </Text>
            <Text italic block>
                FE: {releaseName}
            </Text>
        </div>
    )
}

export default ReleaseInformation
