import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
    Field,
    MessageBar,
    Radio,
    RadioGroup,
    Switch,
    makeStyles,
} from '@fluentui/react-components'

import AxiosStateTypeEnum from 'constants/AxiosStateTypeEnum'
import ConsentCategory from 'components/ApiDropdowns/ConsentCategory'
import DriverCheckStates from 'Types/DriverCheckStates'
import SearchBox from 'components/Ui/SearchBox'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import sanitizeInput from 'helpers/sanitizeInput'

type DriverFormOrderProps = {
    orderToggleTitle: string
    orderToggleDefaultState?: boolean
    driver: DriverObject
    setDriver: Dispatch<SetStateAction<DriverObject>>
    driverCheckState: DriverCheckStates
    setDriverCheckState: Dispatch<SetStateAction<DriverCheckStates>>
}

type OrderSearchResult = {
    Doin: number
    ConsentCategory: string
    OrderDate: string
    WorkshopDealer: string
    Vehicles: {
        Vin: string
        RegistrationNo: string
    }[]
}

type OrderSearch = {
    numberOfsearchResults: number
    searchResults: Array<OrderSearchResult>
}

const useStyles = makeStyles({
    switch: {
        marginTop: '1rem',
        marginLeft: '-0.5rem',
    },
})

const DriverFormOrder = ({
    orderToggleTitle,
    orderToggleDefaultState = true,
    driver,
    setDriver,
    driverCheckState,
    setDriverCheckState,
}: DriverFormOrderProps) => {
    const classes = useStyles()
    const [orderSearch, setOrderSearch] = useState<OrderSearch>()
    const [searchOrderState, setSearchOrderState] =
        useState<AxiosStateTypeEnum>(AxiosStateTypeEnum.Unknown)
    const [withDoin, setWithDoin] = useState<boolean | undefined>(
        orderToggleDefaultState
    )
    const [orderSearchString, setOrderSearchString] = useState<string>()

    const [consentCategory, setConsentCategory] = useState<string>()

    useEffect(() => {
        if (consentCategory && driver.consentCategory !== consentCategory) {
            setDriver({
                ...driver,
                consentCategory,
            })
        }
    }, [consentCategory, driver, setDriver])

    useEffect(() => {
        if (
            driverCheckState.CdsIdVerified &&
            withDoin &&
            !driverCheckState.IncludeOrder
        ) {
            setDriverCheckState({
                ...driverCheckState,
                IncludeOrder: true,
            })
        }
        if (
            driverCheckState.CdsIdVerified &&
            !withDoin &&
            driverCheckState.IncludeOrder
        ) {
            setDriverCheckState({
                ...driverCheckState,
                IncludeOrder: false,
            })
        }
    }, [driverCheckState, setDriverCheckState, withDoin])

    useEffect(() => {
        if (!withDoin && driver.doin) {
            setDriver({
                ...driver,
                doin: undefined,
                consentCategory: undefined,
            })
        }
    }, [driver, setDriver, withDoin])

    const findOrderId = (searchString: string) => {
        setSearchOrderState(AxiosStateTypeEnum.Loading)
        setDriver({
            ...driver,
            doin: undefined,
            consentCategory: undefined,
        })
        reflectApiAxiosInstance
            .get(
                // eslint-disable-next-line max-len
                `/odata/Orders?$count=true&$top=3&$expand=Vehicles&$filter=(Doin eq ${searchString} or Vehicles/any(p: p/Vin eq '${searchString}')) and ActorId eq null`
            )
            .then(response => {
                setSearchOrderState(AxiosStateTypeEnum.Success)
                setOrderSearch({
                    ...orderSearch,
                    numberOfsearchResults: response.data['@odata.count'],
                    searchResults: response.data.value,
                })
            })
            .catch(() => {
                setSearchOrderState(AxiosStateTypeEnum.Error)
            })
    }

    const onClearOrderSearch = () => {
        setSearchOrderState(AxiosStateTypeEnum.Unknown)
        setDriver({
            ...driver,
            doin: undefined,
            consentCategory: undefined,
        })
        setOrderSearch({
            ...orderSearch,
            numberOfsearchResults: 0,
            searchResults: [],
        })
    }

    const getOrderOptionsString = (searchResult: OrderSearchResult) => {
        const doin = searchResult.Doin ? searchResult.Doin : 'No DOIN found'
        const vin =
            searchResult.Vehicles && searchResult.Vehicles[0]?.Vin
                ? searchResult.Vehicles[0]?.Vin
                : 'No VIN found'
        const regNo =
            searchResult.Vehicles && searchResult.Vehicles[0]?.RegistrationNo
                ? searchResult.Vehicles[0].RegistrationNo
                : 'No registration number found'
        return `Doin: ${doin}, VIN: ${vin}, RegNo: ${regNo}`
    }

    const getOrderOptions = () => {
        const orderOptions: { key: string; text: string }[] = []
        if (
            orderSearch?.searchResults &&
            orderSearch?.searchResults.length > 0
        ) {
            orderSearch?.searchResults.forEach(searchResult => {
                orderOptions.push({
                    key: searchResult.Doin.toString(),
                    text: getOrderOptionsString(sanitizeInput(searchResult)),
                })
            })
        }
        return orderOptions
    }

    useEffect(() => {
        if (!withDoin && driver.doin) {
            setDriver({
                ...driver,
                doin: undefined,
                consentCategory: undefined,
            })
        }
    }, [driver, setDriver, withDoin])

    return (
        <>
            <Switch
                label={orderToggleTitle}
                checked={withDoin}
                onChange={(_ev, data) => setWithDoin(data.checked)}
                className={classes.switch}
            />
            {withDoin && (
                <>
                    <Field>
                        <SearchBox
                            placeholder='Search order by DOIN or VIN'
                            onSearch={() => findOrderId(orderSearchString)}
                            value={orderSearchString}
                            onChange={(_ev, data) => {
                                setOrderSearchString(data.value)
                            }}
                            disabled={!driverCheckState.CdsIdVerified}
                            onClear={onClearOrderSearch}
                        />
                    </Field>
                    {!driverCheckState.CdsIdVerified && (
                        <span className='ms-TextField-description description-165'>
                            Please select a user or enter the CDSID first
                        </span>
                    )}
                    {searchOrderState === AxiosStateTypeEnum.Loading && (
                        <MessageBar intent='info'>
                            Searching for orders...
                        </MessageBar>
                    )}
                    {searchOrderState === AxiosStateTypeEnum.Success && (
                        <MessageBar
                            intent={
                                orderSearch &&
                                orderSearch.numberOfsearchResults > 3
                                    ? 'warning'
                                    : 'success'
                            }
                        >
                            {orderSearch &&
                                orderSearch.numberOfsearchResults <= 3 &&
                                `${orderSearch.numberOfsearchResults} order(s) matched your search`}
                            {orderSearch &&
                                orderSearch.numberOfsearchResults > 3 &&
                                `${orderSearch.numberOfsearchResults} orders matched your search, the first 3 are displayed below.
                                    Please narrow your search by using more letters from the VIN/POId.`}
                        </MessageBar>
                    )}
                    {searchOrderState === AxiosStateTypeEnum.Unknown &&
                        driver.cdsid &&
                        !driver.doin &&
                        driverCheckState.IncludeOrder && (
                            <MessageBar intent='error'>
                                You need to search for and select a DOIN.
                            </MessageBar>
                        )}
                    {searchOrderState === AxiosStateTypeEnum.Success &&
                        orderSearch &&
                        orderSearch.searchResults &&
                        orderSearch.searchResults.length > 0 && (
                            <Field label='Select order'>
                                <RadioGroup
                                    value={driver.doin?.toString()}
                                    onChange={(_ev, data) => {
                                        setDriver({
                                            ...driver,
                                            doin: +data.value,
                                        })
                                        const selectedOrder =
                                            orderSearch?.searchResults.find(
                                                searchResult =>
                                                    searchResult.Doin.toString() ===
                                                    data.value
                                            )
                                        setConsentCategory(
                                            selectedOrder?.ConsentCategory
                                        )
                                    }}
                                >
                                    {getOrderOptions().map(option => (
                                        <Radio
                                            key={option.key}
                                            value={option.key}
                                        />
                                    ))}
                                </RadioGroup>
                            </Field>
                        )}
                    <ConsentCategory
                        selectedItem={driver.consentCategory}
                        setSelectedItem={selectedItem =>
                            setConsentCategory(selectedItem)
                        }
                        disabled={!driver.doin}
                    />
                    {driver.doin &&
                        (driver.consentCategory === 'Unknown' ||
                            !driver.consentCategory) && (
                            <MessageBar intent='error'>
                                Consent category can not be Unknown. Please set
                                a category.
                            </MessageBar>
                        )}
                </>
            )}
        </>
    )
}

export default DriverFormOrder

DriverFormOrder.defaultProps = {
    orderToggleDefaultState: true,
}
