/* eslint-disable check-file/no-index */
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'react-toastify/dist/ReactToastify.css'
import './index.scss'

import { FluentProvider, teamsLightTheme } from '@fluentui/react-components'
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react'

import App from 'App'
import Error from 'components/Ui/Error'
import { InteractionType } from '@azure/msal-browser'
import { LicenseManager } from 'ag-grid-enterprise'
import Loader from 'components/Ui/Loader'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { msalInstance } from 'authInstance'

const container = document.getElementById('app')
const root = createRoot(container)

LicenseManager.setLicenseKey(`${process.env.REACT_APP_AGGRID_LICENSE}`)

// Load the app and all the third party libraries:
// MsalProvider - is used to provide the authentication context to the app
// MsalAuthenticationTemplate - is used to handle the authentication flow
// FluentProvider - is used to provide the Fluent theme to the app
root.render(
    <StrictMode>
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                errorComponent={() => <Error text='Authentication failed' />}
                loadingComponent={() => <Loader text='Authenticating...' />}
            >
                <FluentProvider theme={teamsLightTheme}>
                    <App />
                </FluentProvider>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    </StrictMode>
)
