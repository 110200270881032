import { Outlet, useOutletContext, useParams } from 'react-router-dom'

import Loader from 'components/Ui/Loader'
import { useGetVehicleTestByIdQuery } from 'api/vehicleTests'
import NoMatch from 'pages/NoMatch'

const VehicleTest = () => {
    const { id } = useParams()
    const { data: vehicleTest, isLoading } = useGetVehicleTestByIdQuery(id)

    if (!vehicleTest) {
        return <NoMatch />
    }
    if (isLoading) {
        return <Loader text='Loading vehicle test...' />
    }

    return <Outlet context={{ vehicleTest }} />
}

type ContextType = {
    vehicleTest: IVehicleTest | null
}

export const useVehicleTest = () => {
    return useOutletContext<ContextType>()
}

export default VehicleTest
