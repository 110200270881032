import DOMPurify from 'dompurify'

/**
 * Recieve dirty input (string, array, etc), sanitize it using DOMPurify and return the sanitized <thing>
 * @param dirtyInput the string, array or similar.
 * @param domPurifyConfig config for DOMPurify, true for sensible default, false or undefined for DOMPurify default config
 * @returns santized <T>
 */
const sanitizeInput = <T>(
    dirtyInput: T,
    domPurifyConfig: DOMPurify.Config | boolean = true
): T => {
    const sanitizeObject = (dirtyObject: T): T =>
        Object.entries(dirtyObject).reduce(
            (attrs, entry) => ({
                ...attrs,
                [entry[0]]: sanitizeInput(entry[1]),
            }),
            {} as T
        )

    const sanitizeString = (dirtyString: string) => {
        const config = domPurifyConfig
            ? typeof domPurifyConfig === 'boolean'
                ? { ALLOWED_TAGS: ['#text'], KEEP_CONTENT: true }
                : domPurifyConfig
            : {}

        const sanitizedString = DOMPurify.sanitize(dirtyString, config)
        return sanitizedString
    }

    const sanitizeArray = (dirtyArray: Array<unknown>) => {
        const sanitizedArray = dirtyArray

        sanitizedArray.forEach((value, index) => {
            if (Array.isArray(value)) {
                sanitizedArray[index] = sanitizeArray(value)
            }
            if (typeof value === 'string') {
                sanitizedArray[index] = sanitizeString(value)
            }
            if (typeof value === 'object' && value !== null) {
                sanitizedArray[index] = sanitizeObject(value as T)
            }
        })
        return sanitizedArray
    }

    if (dirtyInput === null || typeof dirtyInput === 'undefined') {
        return dirtyInput
    }

    if (typeof dirtyInput === 'string') {
        const sanitizedString = sanitizeString(dirtyInput)
        return sanitizedString as unknown as T
    }
    if (Array.isArray(dirtyInput)) {
        const sanitizedArray = sanitizeArray(dirtyInput)
        return sanitizedArray as unknown as T
    }
    if (typeof dirtyInput === 'object') {
        const sanitizedObject = sanitizeObject(dirtyInput)
        return sanitizedObject
    }
    return dirtyInput
}

export default sanitizeInput
