enum AxiosStateTypeEnum {
    Unknown = 'Unknown',
    Success = 'Success',
    Error = 'Error',
    ErrorUnknown = 'ErrorUnknown',
    Loading = 'Loading',
    NotFound = 'NoMatch',
    Adding = 'Adding',
    Updating = 'Updating',
    Deleting = 'Deleting',
    NotAllowed = 'NotAllowed',
    Exists = 'Exists',
}

export default AxiosStateTypeEnum
