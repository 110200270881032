import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    makeStyles,
} from '@fluentui/react-components'

interface ICancelImportProps {
    showCancelDialog: boolean
    hideAllDialogs: () => void
    hideCancelDialog: () => void
    setShowCancelDialog: (value: boolean) => void
}

const useStyles = makeStyles({
    dialog: {
        width: '450px',
    },
})

const CancelImport = ({
    showCancelDialog,
    hideAllDialogs,
    hideCancelDialog,
    setShowCancelDialog,
}: ICancelImportProps) => {
    const classes = useStyles()

    return (
        <div>
            <Dialog
                open={showCancelDialog}
                onOpenChange={(_event, data) => setShowCancelDialog(data.open)}
            >
                <DialogSurface className={classes.dialog}>
                    <DialogBody>
                        <DialogTitle>
                            Are you sure you want to cancel importing vehicles?
                        </DialogTitle>
                        <DialogActions>
                            <Button
                                appearance='primary'
                                onClick={hideAllDialogs}
                            >
                                Yes
                            </Button>
                            <DialogTrigger disableButtonEnhancement>
                                <Button onClick={hideCancelDialog}>No</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    )
}

export default CancelImport
