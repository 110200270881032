import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
} from '@fluentui/react-components'

import { triggerMessage } from 'slices/messageSlice'
import { useAppDispatch } from 'store'
import { useDeleteFactDriverStudyAssignmentsMutation } from 'api/driverStudyParticipation'
import { useGrid } from 'contexts/GridContext'

interface IDeleteDriversModalProps {
    toggleOpen: () => void
}

const DeleteDriversModal = ({ toggleOpen }: IDeleteDriversModalProps) => {
    const dispatch = useAppDispatch()
    const { gridRef } = useGrid()
    const selectedRowCount = gridRef.current?.api.getSelectedRows().length ?? 0

    const [deleteDriverStudyAssignments, { isLoading }] =
        useDeleteFactDriverStudyAssignmentsMutation()

    const onDelete = async () => {
        Promise.all(
            gridRef.current?.api
                .getSelectedRows()
                .map(sr => deleteDriverStudyAssignments(sr.Id))
        )
            .finally(() => {
                toggleOpen()
                gridRef.current?.api.deselectAll()
                dispatch(
                    triggerMessage({
                        title: 'Drivers deleted',
                        message: 'Drivers have been deleted',
                        intent: 'success',
                    })
                )
            })
            .catch(() => {
                dispatch(
                    triggerMessage({
                        title: 'Error deleting drivers',
                        message: 'Drivers have not been deleted',
                        intent: 'error',
                    })
                )
            })
    }

    return (
        <>
            <DialogContent>
                Are you certain you wish to delete {selectedRowCount} driver(s)
                from this study?
            </DialogContent>
            <DialogActions>
                <Button
                    data-cy='remove-driver-confirm-button'
                    appearance='primary'
                    onClick={onDelete}
                    disabled={isLoading}
                >
                    Delete
                </Button>
                <DialogTrigger disableButtonEnhancement>
                    <Button>Cancel</Button>
                </DialogTrigger>
            </DialogActions>
        </>
    )
}

export default DeleteDriversModal
