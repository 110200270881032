/*
    This file contains the keys for the BridgeInfo and VehicleInfo tables.
    These keys are used to get the data from the BridgeInfo and VehicleInfo tables.
*/
export const BRIDGE_INFO_KEYS: Array<keyof IBridgeInfoOdata> = [
    'CreatedBy',
    'CreatedOn',
    'Deleted',
    'EndDate',
    'TestStatus',
    // 'Id',
    'MissedAppointments',
    'StartDate',
    'TestPurposeId',
    'VehicleClassification',
    // 'VehicleId',
    'WsActorId',
    'WsArrival',
    'WsComment',
    'WsInitiatedBy',
    'WsInstalled',
    'WsInvitationFrom',
    'WsInvitationTo',
    'WsNotificationTypeId',
    'WsReady',
    'WsReturnedToDriver',
    'WsVisitTypeId',
    'WsWaiting',
]

export const VEHICLE_INFO_KEYS: Array<keyof IVehicleInfoOdata> = [
    'ActorId',
    'BaselineVersion',
    'Blacklist',
    'BlacklistComment',
    'CarCategory',
    'CarDistribution',
    'CarUsage',
    'CdbMarket',
    'Cdsid',
    'Color',
    'ColorCode',
    'Comments',
    'ConsentCategory',
    'CustomDelivered',
    'CustomReturned',
    'CustomerCity',
    'CustomerCountry',
    'DataSource',
    'DeliveryCountry',
    'Email',
    'EmployeeId',
    'EngineCode',
    'EngineDescription',
    'EquipmentId',
    'EquipmentType',
    'ExclusionLists',
    'FactoryComplete',
    'FeedbackTools',
    'FirstName',
    'FirstPds',
    'FirstUpdateDone',
    'FleetName',
    'FuelType',
    'Fyon',
    'GearboxCode',
    'GearboxDescription',
    'Id',
    'InclusionList',
    'IsArchived',
    'LastName',
    'LatestVehicleVersion',
    'MarketingCode',
    'Model',
    'ModelCode',
    'ModelYear',
    'OrderDate',
    'OtaVersion',
    'PgwCalculationStatus',
    'PgwDeploymentJobName',
    'PgwDeploymentJobNumber',
    'PgwDeploymentJobUuId',
    'PgwVehicleCollectionName',
    'PgwVehicleCollectionUuId',
    'Phone',
    'PieMarketCode',
    'Platform',
    'PreferredEmail',
    'Project',
    'RegistrationNo',
    'ResourceGroup',
    'Responsible',
    'SalesVersion',
    'SalesVersionDescription',
    'ScheduledDelivery',
    'ScheduledReturn',
    'SourceId',
    'SssVersion',
    'SteeringCode',
    'SteeringDescription',
    'StructureWeek',
    'TagLists',
    'VehicleTestsFinished',
    'VehicleTestsOngoing',
    'VehicleTestsPlanned',
    'UpholsteryCode',
    'UpholsteryDescription',
    'VariantCode',
    'VehicleDelivered',
    'VehicleReturned',
    'VehicleStatus',
    'VehicleType',
    // 'VehicleVehicleGroups', This does not work!
    'VidaClientId',
    'Vin',
    'WcuId',
    'Window',
    'WorkshopDealer',
]
