import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
} from '@fluentui/react-components'

import { useGrid } from 'contexts/GridContext'
import { useRemoveVehiclesFromTestMutation } from 'api/vehicleTests'

interface IDeleteVehicleModal {
    testId: number
    toggleOpen: () => void
}

const DeleteVehicleModal = ({ testId, toggleOpen }: IDeleteVehicleModal) => {
    const { gridRef } = useGrid()
    const vehicleIds = gridRef.current?.api
        .getSelectedNodes()
        .map(sr => String(sr.data.Id))
    const selectedRowCount = gridRef.current?.api.getSelectedRows().length

    const [removeVehicle, { isLoading }] = useRemoveVehiclesFromTestMutation()

    const onDelete = () =>
        removeVehicle({
            id: testId,
            vehicleIds,
        }).then(() => {
            gridRef.current?.api.refreshServerSide()
            gridRef.current?.api.deselectAll()
            toggleOpen()
        })

    return (
        <>
            <DialogContent>
                Are you certain you wish to delete {selectedRowCount} vehicle(s)
                from this test?
            </DialogContent>
            <DialogActions>
                <Button
                    appearance='primary'
                    onClick={onDelete}
                    disabled={isLoading}
                >
                    Delete
                </Button>
                <DialogTrigger disableButtonEnhancement>
                    <Button>Cancel</Button>
                </DialogTrigger>
            </DialogActions>
        </>
    )
}

export default DeleteVehicleModal
