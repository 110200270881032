import {
    Link,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'

const useStyles = makeStyles({
    messageBar: {
        backgroundColor: 'lightyellow',
        ...shorthands.padding('0.5rem'),
    },
    container: {
        marginBottom: '1rem',
        backgroundColor: 'lightyellow',
    },
})

const UploadInformation = () => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <MessageBar shape='rounded' className={classes.messageBar}>
                <MessageBarBody>
                    <MessageBarTitle>Please note</MessageBarTitle>
                    that the&nbsp;
                    <Link
                        href={`${process.env.PUBLIC_URL}/files/templates/VehicleImportTemplate.xlsx`}
                    >
                        VehicleImportTemplate
                    </Link>
                    &nbsp;must be used upon import. <br /> Otherwise your import
                    will fail or have unintended consequences.
                </MessageBarBody>
            </MessageBar>
        </div>
    )
}

export default UploadInformation
