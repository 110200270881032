import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import Loader from 'components/Ui/Loader'
import VehicleGroupListDeleteModal from './VehicleGroupListDelete'
import VehicleGroupListEditModal from './VehicleGroupListEdit'
import { useGetVehicleGroupListsFiltersQuery } from 'api/vehicleGroupList'
import { dateComparator, dateRenderWithTime } from 'helpers/dateHelpers'
import { useAppSelector } from 'store'
import { selectUser } from 'slices/authSlice'
import { useEffect, useRef } from 'react'

type VehicleGroupListsModalListTabProps = {
    vehicleGroupType: VehicleGroupListSupportedPages
    showOnlyMine: boolean
}

const VehicleGroupListsGrid = ({
    vehicleGroupType,
    showOnlyMine,
}: VehicleGroupListsModalListTabProps) => {
    const { data: filters, isLoading } =
        useGetVehicleGroupListsFiltersQuery(vehicleGroupType)
    const gridRef = useRef<AgGridReact>(null)
    const user = useAppSelector(selectUser)

    useEffect(() => {
        if (gridRef.current?.api) {
            const gridApi = gridRef.current.api
            const filterModel = {
                createdBy: {
                    type: 'equals',
                    filter: user.name,
                },
            }
            showOnlyMine
                ? gridApi.setFilterModel(filterModel)
                : gridApi.setFilterModel(null)
        }
    }, [showOnlyMine, user.name])

    if (isLoading) {
        return <Loader />
    }

    const columnDefs: ColDef[] = [
        {
            field: 'insertDate',
            headerName: 'Created date',
            cellRenderer: dateRenderWithTime,
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: dateComparator,
                maxNumConditions: 1,
                buttons: ['reset', 'apply'],
            },
        },
        {
            field: 'name',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            sort: 'asc',
        },
        {
            field: 'vehicleCount',
            headerName: 'Vehicles',
        },
        {
            field: 'createdBy',
            headerName: 'Created by',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
        },
        {
            headerName: '',
            field: 'id',
            colId: 'editModalOpener',
            lockPosition: true,
            lockPinned: true,
            pinned: 'right',
            suppressMenu: true,
            width: 70,
            suppressColumnsToolPanel: true,
            cellRenderer: VehicleGroupListEditModal,
            cellRendererParams: {
                vehicleGroupType,
            },
        },
        {
            headerName: '',
            field: 'id',
            colId: 'deleteModalOpener',
            lockPosition: true,
            lockPinned: true,
            pinned: 'right',
            suppressMenu: true,
            width: 70,
            suppressColumnsToolPanel: true,
            cellRenderer: VehicleGroupListDeleteModal,
            cellRendererParams: {
                vehicleGroupType,
            },
        },
    ]

    return (
        <>
            <div style={{ height: '600px' }} className='ag-theme-alpine'>
                <AgGridReact
                    defaultColDef={{
                        flex: 1,
                        sortable: true,
                        resizable: true,
                    }}
                    ref={gridRef}
                    rowData={filters}
                    columnDefs={columnDefs}
                    sideBar={{
                        defaultToolPanel: 'filters',
                        toolPanels: [
                            {
                                id: 'filters',
                                labelDefault: 'Filters',
                                labelKey: 'filters',
                                iconKey: 'filter',
                                toolPanel: 'agFiltersToolPanel',
                                toolPanelParams: {
                                    suppressExpandAll: true,
                                    suppressFilterSearch: false,
                                },
                            },
                        ],
                    }}
                />
            </div>
        </>
    )
}

export default VehicleGroupListsGrid
