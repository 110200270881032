/* eslint-disable max-len */

import { makeStyles } from '@fluentui/react-components'

const useStyles = makeStyles({
    svg: {
        height: '50px',
        width: '50px',
    },
})

const SharePoint = () => {
    const classes = useStyles()
    return (
        <svg
            id='Layer_1'
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 128 123.65'
            className={classes.svg}
        >
            <defs>
                <linearGradient
                    id='linear-gradient'
                    x1='116.55'
                    y1='3802.23'
                    x2='254.9'
                    y2='4041.85'
                    gradientTransform='translate(-14.33 -1013.07) scale(.27)'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop offset='0' stopColor='#058f92' />
                    <stop offset='.5' stopColor='#038489' />
                    <stop offset='1' stopColor='#026d71' />
                </linearGradient>
            </defs>
            <ellipse
                className='sp-6'
                fill='#036c70'
                strokeWidth={0}
                cx='65.31'
                cy='37.92'
                rx='31.35'
                ry='31.88'
            />
            <ellipse
                className='sp-4'
                fill='#1a9ba1'
                strokeWidth={0}
                cx='91.43'
                cy='67.14'
                rx='28.74'
                ry='29.22'
            />
            <ellipse
                className='sp-3'
                fill='#37c6d0'
                strokeWidth={0}
                cx='69.23'
                cy='95.03'
                rx='22.21'
                ry='22.58'
            />
            <g>
                <path
                    className='sp-7'
                    opacity={0.1}
                    strokeWidth={0}
                    d='m70.53,34.81v56.69c-.01,1.97-1.19,3.74-2.98,4.49-.57.25-1.18.37-1.8.37h-18.71c-.03-.45-.03-.88-.03-1.33,0-.44,0-.89.05-1.33.48-8.49,5.62-15.98,13.3-19.37v-4.94c-17.08-2.75-28.74-19.06-26.03-36.43.02-.12.04-.24.06-.36.13-.9.31-1.78.55-2.66h30.8c2.64.01,4.77,2.18,4.78,4.86h0Z'
                />
                <path
                    className='sp-2'
                    opacity={0.2}
                    strokeWidth={0}
                    d='m63.14,32.6h-28.74c-2.9,17.34,8.57,33.79,25.62,36.74.52.09,1.03.17,1.55.23-8.1,3.91-14,15-14.5,24.13-.04.44-.06.88-.05,1.33,0,.45,0,.88.03,1.33.05.89.16,1.78.34,2.66h15.75c1.94-.01,3.68-1.21,4.42-3.03.24-.58.37-1.2.37-1.83v-56.69c0-2.68-2.14-4.85-4.78-4.86h0Z'
                />
                <path
                    className='sp-2'
                    opacity={0.2}
                    strokeWidth={0}
                    d='m63.14,32.6h-28.74c-2.9,17.34,8.57,33.79,25.62,36.74.35.06.7.11,1.05.16-7.84,4.19-13.51,15.25-14,24.2h16.07c2.63-.02,4.76-2.19,4.78-4.86v-51.38c-.01-2.68-2.14-4.85-4.78-4.86Z'
                />
                <path
                    className='sp-2'
                    opacity={0.2}
                    strokeWidth={0}
                    d='m60.53,32.6h-26.13c-2.74,16.37,7.35,32.13,23.17,36.21-5.99,6.96-9.67,15.67-10.5,24.89h13.46c2.64-.01,4.77-2.18,4.78-4.86v-51.38c0-2.68-2.14-4.86-4.78-4.86h0Z'
                />
                <path
                    className='sp-1'
                    strokeWidth={0}
                    fill='url(#linear-gradient)'
                    d='m12.62,32.6h47.9c2.64,0,4.79,2.18,4.79,4.87v48.71c0,2.69-2.14,4.87-4.79,4.87H12.62c-2.64,0-4.79-2.18-4.79-4.87v-48.71c0-2.69,2.14-4.87,4.79-4.87Z'
                />
                <path
                    className='sp-5'
                    fill='#fff'
                    strokeWidth={0}
                    d='m29.22,61.23c-1.12-.76-2.06-1.76-2.74-2.95-.66-1.24-.99-2.62-.95-4.03-.06-1.9.57-3.76,1.77-5.22,1.26-1.46,2.89-2.54,4.72-3.13,2.08-.7,4.26-1.04,6.45-1.01,2.88-.11,5.75.3,8.49,1.21v6.11c-1.19-.73-2.49-1.27-3.84-1.59-1.47-.37-2.98-.55-4.49-.55-1.6-.06-3.18.28-4.62.99-1.11.49-1.83,1.59-1.83,2.82,0,.74.28,1.46.78,2,.6.63,1.31,1.15,2.09,1.52.87.44,2.18,1.03,3.92,1.76.19.06.38.14.56.23,1.71.68,3.37,1.51,4.94,2.48,1.19.75,2.19,1.77,2.92,2.99.75,1.38,1.11,2.94,1.05,4.52.09,1.95-.5,3.88-1.66,5.44-1.16,1.43-2.7,2.49-4.44,3.04-2.05.65-4.19.97-6.33.94-1.93,0-3.85-.15-5.75-.48-1.6-.27-3.17-.73-4.66-1.39v-6.44c1.42,1.03,3.02,1.81,4.7,2.28,1.68.53,3.43.82,5.19.84,1.63.1,3.26-.25,4.7-1.01,1.01-.58,1.63-1.68,1.6-2.87,0-.82-.31-1.61-.89-2.19-.71-.71-1.54-1.3-2.45-1.73-1.05-.53-2.58-1.23-4.62-2.1-1.62-.66-3.17-1.48-4.62-2.46Z'
                />
            </g>
        </svg>
    )
}

export default SharePoint
