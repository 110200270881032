import { ProcessCellForExportParams } from 'ag-grid-community'

const getExportFileName = (
    selectedRowsCount: number | undefined,
    filteredVehiclesCount: number | undefined
) => {
    const dateTimeNow = new Intl.DateTimeFormat('sv-SE', {
        dateStyle: 'short',
        timeStyle: 'short',
    })
        .format(new Date())
        .replace(/\s/g, '_')
        .replace(/:|-/g, '')

    let exportFileName = 'Reflect-VehicleExport'
    if (
        selectedRowsCount ||
        (filteredVehiclesCount && filteredVehiclesCount > 0)
    )
        exportFileName += '-'

    if (selectedRowsCount) exportFileName += `${selectedRowsCount}`

    if (
        !selectedRowsCount &&
        filteredVehiclesCount &&
        filteredVehiclesCount > 0
    )
        exportFileName += `${filteredVehiclesCount}`

    if (
        selectedRowsCount ||
        (filteredVehiclesCount && filteredVehiclesCount > 0)
    )
        exportFileName += '_vehicles'

    if (dateTimeNow) exportFileName += `-${dateTimeNow}`

    return exportFileName
}

export const getExportOptions = (
    selectedRowsCount: number,
    rowCount: number
) => ({
    fileName: getExportFileName(selectedRowsCount, rowCount),
    sheetName: 'Vehicles',
    processCellCallback: (params: ProcessCellForExportParams): string => {
        if (typeof params.value === 'string') {
            let value = params.value
            const firstChar = value.charAt(0)
            if (firstChar === '=' || firstChar === '+' || firstChar === '-') {
                value = `'${value}` // prevents formula to be evaluated
            }
            return value
        }
        return params.value
    },
})
