import { Slide, ToastContainer } from 'react-toastify'

import { GridContextProvider } from 'contexts/GridContext'
import Messages from 'components/Ui/Messages'
import { Outlet } from 'react-router'

const Layout = () => {
    return (
        <GridContextProvider>
            <Outlet />

            <Messages />
            <ToastContainer
                position='bottom-center'
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                transition={Slide}
                style={{ boxShadow: 'none' }}
            />
        </GridContextProvider>
    )
}

export default Layout
