import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    Switch,
} from '@fluentui/react-components'
import {
    useAddVehicleTestMutation,
    useAddVehiclesToTestMutation,
} from 'api/vehicleTests'

import Loader from 'components/Ui/Loader'
import ROUTES from 'constants/routes'
import Restricted from 'components/Restricted'
import VehicleTestForm from './VehicleTestForm'
import VehicleTestSelect from 'components/VehicleTest/VehicleTestSelect'
import { useGrid } from 'contexts/GridContext'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { triggerMessage } from 'slices/messageSlice'
import { useAppDispatch } from 'store'

type AddSelectedVehiclesToVehicleTestProps = {
    onClose: () => void
}

const AddSelectedVehiclesToVehicleTest = ({
    onClose,
}: AddSelectedVehiclesToVehicleTestProps) => {
    const [isCreateNewVehicleTest, setIsCreateNewVehicleTest] = useState(false)

    const navigate = useNavigate()
    const { gridRef } = useGrid()
    const vehicleIds = gridRef.current?.api
        .getSelectedNodes()
        .map(sr => sr.data.Id)
    const selectedRowCount = gridRef.current?.api.getSelectedRows().length

    const [selectedVehicleTestId, setSelectedVehicleTestId] = useState<number>()
    const dispatch = useAppDispatch()

    const [addVehicles, { isLoading }] = useAddVehiclesToTestMutation()
    const [addVehicleTest] = useAddVehicleTestMutation()

    const callAddVehiclesToVehicleTestApi = async () =>
        await addVehicles({
            id: selectedVehicleTestId,
            vehicleIds,
        })

    const onSave = () => callAddVehiclesToVehicleTestApi().then(() => onClose())

    const onSaveAndVehicleTest = () =>
        callAddVehiclesToVehicleTestApi().then(() =>
            navigate(
                `/${ROUTES.VEHICLE_TESTS}/${String(selectedVehicleTestId)}`
            )
        )

    const onCreateVehicleTest = async (
        { plannedFinish, plannedStart, ...rest }: IVehicleTest,
        setSubmitting: (isSubmitting: boolean) => void
    ) => {
        const response = await addVehicleTest({
            ...rest,
            plannedStart: plannedStart
                ? new Date(plannedStart).toISOString()
                : null,
            plannedFinish: plannedFinish
                ? new Date(plannedFinish).toISOString()
                : null,
        })
        setSubmitting(false)
        if ('data' in response) {
            await addVehicles({
                id: response.data.id,
                vehicleIds,
            })
            dispatch(
                triggerMessage({
                    intent: 'success',
                    message: 'Vehicle test created successfully.',
                })
            )
            navigate(`/${ROUTES.VEHICLE_TESTS}/${String(response.data.id)}`)
        } else if ('data' in response.error) {
            dispatch(
                triggerMessage({
                    intent: 'error',
                    title: 'Error',
                    message: `${response.error.data}`,
                })
            )
        }
    }

    return (
        <>
            {isLoading ? (
                <Loader text='Getting things ready...' />
            ) : (
                <>
                    <DialogContent>
                        <span>
                            {`Adding ${selectedRowCount} vehicles to test.`}
                        </span>
                        <Restricted to='vehicleTestsManage'>
                            <Switch
                                label='Create a new test?'
                                checked={isCreateNewVehicleTest}
                                onChange={() =>
                                    setIsCreateNewVehicleTest(prev => !prev)
                                }
                            />
                        </Restricted>

                        {isCreateNewVehicleTest ? (
                            <VehicleTestForm onSave={onCreateVehicleTest} />
                        ) : (
                            <VehicleTestSelect
                                onSelect={(vehicleTestId: number) => {
                                    setSelectedVehicleTestId(vehicleTestId)
                                }}
                            />
                        )}
                    </DialogContent>
                    {!isCreateNewVehicleTest && (
                        <DialogActions fluid>
                            <Button
                                appearance='primary'
                                onClick={onSaveAndVehicleTest}
                                disabled={!selectedVehicleTestId}
                            >
                                Save and go to vehicle test page
                            </Button>
                            <Button
                                appearance='primary'
                                onClick={onSave}
                                disabled={!selectedVehicleTestId}
                            >
                                Save
                            </Button>
                            <DialogTrigger disableButtonEnhancement>
                                <Button onClick={onClose}>Cancel</Button>
                            </DialogTrigger>
                        </DialogActions>
                    )}
                </>
            )}
        </>
    )
}

export default AddSelectedVehiclesToVehicleTest
