import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    Switch,
} from '@fluentui/react-components'
import { selectLastOdataQuery, selectRowCount } from 'slices/gridSlice'
import {
    useAddVehicleTestMutation,
    useAddVehiclesToTestMutation,
} from 'api/vehicleTests'

import Loader from 'components/Ui/Loader'
import ROUTES from 'constants/routes'
import VehicleTestForm from './VehicleTestForm'
import VehicleTestSelect from 'components/VehicleTest/VehicleTestSelect'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import { useAppDispatch, useAppSelector } from 'store'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { triggerMessage } from 'slices/messageSlice'

type AddFilteredVehiclesToVehicleTestProps = {
    onClose: () => void
}

const AddFilteredVehiclesToVehicleTest = ({
    onClose,
}: AddFilteredVehiclesToVehicleTestProps) => {
    const [isShowForm, setShowForm] = useState(false)
    const [selectedId, setSelectedId] = useState<number>()
    const navigate = useNavigate()
    const lastODataQuery = useAppSelector(selectLastOdataQuery)
    const rowCount = useAppSelector(selectRowCount)

    const [addVehicles, { isLoading }] = useAddVehiclesToTestMutation()
    const [addVehicleTest] = useAddVehicleTestMutation()
    const dispatch = useAppDispatch()

    const collectAllVehicleIds = async () => {
        const param = new URLSearchParams(lastODataQuery)
        param.delete('$skip')
        param.delete('$top')
        param.delete('$select')
        param.append('$select', 'Id')

        const { data } = await reflectApiAxiosInstance.get(
            decodeURIComponent(param.toString())
        )

        return data.value.map(({ Id }) => Id)
    }

    const callAddVehiclesToVehicleTestApi = async () => {
        const vehicleIds = await collectAllVehicleIds()

        addVehicles({ id: selectedId, vehicleIds })
    }

    const onSave = () => {
        callAddVehiclesToVehicleTestApi().then(() => onClose())
    }

    const onSaveAndVehicleTest = () => {
        callAddVehiclesToVehicleTestApi().then(() =>
            navigate(`/${ROUTES.VEHICLE_TESTS}/${String(selectedId)}`)
        )
    }

    const handleSubmit = async (
        { plannedFinish, plannedStart, ...rest }: IVehicleTest,
        setSubmitting: (isSubmitting: boolean) => void
    ) => {
        const response = await addVehicleTest({
            ...rest,
            plannedStart: plannedStart
                ? new Date(plannedStart).toISOString()
                : null,
            plannedFinish: plannedFinish
                ? new Date(plannedFinish).toISOString()
                : null,
        })
        setSubmitting(false)
        if ('data' in response) {
            const vehicleIds = await collectAllVehicleIds()
            await addVehicles({ id: response.data.id, vehicleIds })
            dispatch(
                triggerMessage({
                    intent: 'success',
                    message: 'Vehicle test created successfully.',
                })
            )
            navigate(`/${ROUTES.VEHICLE_TESTS}/${String(response.data.id)}`)
        } else if ('data' in response.error) {
            dispatch(
                triggerMessage({
                    intent: 'error',
                    title: 'Error',
                    message: `${response.error.data}`,
                })
            )
        }
    }

    return (
        <>
            {isLoading ? (
                <Loader text='Getting things ready...' />
            ) : (
                <>
                    <DialogContent>
                        <span>{`Adding ${rowCount} filtered vehicles to test.`}</span>
                        <Switch
                            label='Create a new test?'
                            checked={isShowForm}
                            onChange={(_ev, data) => setShowForm(data.checked)}
                        />
                        {isShowForm ? (
                            <VehicleTestForm onSave={handleSubmit} />
                        ) : (
                            <>
                                <VehicleTestSelect
                                    onSelect={(vehicleTestId: number) => {
                                        setSelectedId(vehicleTestId)
                                    }}
                                    selectedId={selectedId}
                                />
                                {rowCount >= 10000 && (
                                    <span className='ms-TextField-description description-165'>
                                        WARNING! This operation will take a long
                                        time to complete
                                    </span>
                                )}
                            </>
                        )}
                    </DialogContent>
                    {!isShowForm && (
                        <DialogActions fluid>
                            <Button
                                appearance='primary'
                                onClick={onSaveAndVehicleTest}
                                disabled={!selectedId || isLoading}
                            >
                                Save and go to vehicle test page
                            </Button>
                            <Button
                                appearance='primary'
                                onClick={onSave}
                                disabled={!selectedId || isLoading}
                            >
                                Save
                            </Button>
                            <DialogTrigger disableButtonEnhancement>
                                <Button onClick={onClose}>Cancel</Button>
                            </DialogTrigger>
                        </DialogActions>
                    )}
                </>
            )}
        </>
    )
}

export default AddFilteredVehiclesToVehicleTest
