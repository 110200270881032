import { Dispatch, SetStateAction } from 'react'

import Actors from 'components/ApiDropdowns/Actors'
import DriverCheckStates from 'Types/DriverCheckStates'
import DriverFormFields from './DriverFormFields'
import DriverFormOrder from './DriverFormOrder'

type UpdateDriverFormProps = {
    driver: DriverObject
    setDriver: Dispatch<SetStateAction<DriverObject>>
    driverCheckState: DriverCheckStates
    setDriverCheckState: Dispatch<SetStateAction<DriverCheckStates>>
    driverRequiredCompleted: boolean
    setDriverRequiredCompleted: Dispatch<SetStateAction<boolean>>
}

const UpdateDriverForm = ({
    driver,
    setDriver,
    driverCheckState,
    setDriverCheckState,
    driverRequiredCompleted,
    setDriverRequiredCompleted,
}: UpdateDriverFormProps) => {
    const handleDriverChange = (actor: IActor) => {
        if (actor) {
            setDriverCheckState({
                ...driverCheckState,
                SeveralDriverMatches: false,
                CheckDriverExistsFailed: false,
                CdsIdNotFound: false,
                CdsIdVerified: true,
            })
            setDriverRequiredCompleted(true)
        } else {
            setDriverCheckState({
                ...driverCheckState,
                SeveralDriverMatches: false,
                CheckDriverExistsFailed: false,
                CdsIdNotFound: false,
                CdsIdVerified: true,
            })
            setDriverRequiredCompleted(false)
        }
        setDriver(actor)
    }
    return (
        <>
            <Actors
                label='Select a driver'
                placeholder='Search for a driver... (at least 3 characters)'
                hint='You can search for name, surname, email, preferred email or cdsid'
                onSelect={selected => handleDriverChange(selected)}
            />

            <DriverFormFields
                driver={driver}
                setDriver={setDriver}
                driverCheckState={driverCheckState}
                driverRequiredCompleted={driverRequiredCompleted}
                setDriverRequiredCompleted={setDriverRequiredCompleted}
                showFieldDescription={false}
            />

            <DriverFormOrder
                orderToggleTitle='Update Driver with new Order'
                orderToggleDefaultState={false}
                driver={driver}
                setDriver={setDriver}
                driverCheckState={driverCheckState}
                setDriverCheckState={setDriverCheckState}
            />
        </>
    )
}

export default UpdateDriverForm
