import { ColDef, FilterChangedEvent, GridReadyEvent } from 'ag-grid-community'
import { dateComparator, dateRenderWithTime } from 'helpers/dateHelpers'
import {
    defaultColDef,
    getContextMenuItems,
    sideBar,
    statusBar,
} from 'helpers/gridHelper'

import AgGridContainer from 'components/Ui/Layout/AgGridContainer'
import { AgGridReact } from 'ag-grid-react'
import DeleteVehicleClassification from './DeleteVehicleClassification'
import Loader from 'components/Ui/Loader'
import { useGetVehicleClassificationsQuery } from 'api/vehicleTests'
import useGridFilterUrl from 'hooks/useGridFilterUrl'
import { useMemo } from 'react'

const VehicleClassificationsGrid = () => {
    const { data: vehicleClassifications, isLoading } =
        useGetVehicleClassificationsQuery()

    const [pushFiltersToHistory, setFiltersFromHistory] = useGridFilterUrl()

    const columnDefs: ColDef[] = useMemo(() => {
        return [
            {
                field: 'title',
                flex: 1,
            },
            {
                field: 'createdDate',
                filter: 'agDateColumnFilter',
                cellRenderer: dateRenderWithTime,
                filterParams: {
                    comparator: dateComparator,
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                field: 'createdBy',
            },
            {
                field: 'updatedDate',
                filter: 'agDateColumnFilter',
                cellRenderer: dateRenderWithTime,
                filterParams: {
                    comparator: dateComparator,
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                field: 'updatedBy',
            },
            {
                cellRenderer: DeleteVehicleClassification,
                maxWidth: 50,
                filter: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: true,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                sortable: false,
            },
        ]
    }, [])

    const onGridReady = (params: GridReadyEvent) => {
        setFiltersFromHistory(params.api)
    }

    const onFilterChanged = (event: FilterChangedEvent) => {
        pushFiltersToHistory(event.api)
    }

    if (isLoading) {
        return <Loader text='Fetching classifications...' />
    }

    return (
        <AgGridContainer>
            <AgGridReact<IVehicleClassification>
                className='cy-vehicle-classification-grid'
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                enableRangeSelection
                getContextMenuItems={getContextMenuItems}
                onFilterChanged={onFilterChanged}
                onGridReady={onGridReady}
                rowData={vehicleClassifications}
                rowSelection='multiple'
                sideBar={sideBar}
                statusBar={statusBar}
            />
        </AgGridContainer>
    )
}

export default VehicleClassificationsGrid
