import DriverCheckStates from 'Types/DriverCheckStates'

const manageDriversHelpers = {
    CheckAddUpdateDriverDisabled: (
        driver: DriverObject,
        driverCheckState: DriverCheckStates,
        driverRequiredCompleted: boolean
    ) => {
        if (
            !driverCheckState.CdsIdVerified ||
            driverCheckState.CdsIdNotFound ||
            driverCheckState.CheckDriverExistsFailed ||
            driverCheckState.DriverWithCdsIdExists ||
            driverCheckState.SeveralDriverMatches
        ) {
            return true
        }
        if (driverCheckState.IncludeOrder && !driver.doin) {
            return true
        }
        if (
            driver.doin &&
            (driver.consentCategory === 'Unknown' || !driver.consentCategory)
        ) {
            return true
        }
        if (!driverRequiredCompleted) {
            return true
        }
        return false
    },
}

export default manageDriversHelpers
