import {
    Button,
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    MessageBarGroup,
    MessageBarTitle,
    makeStyles,
} from '@fluentui/react-components'
import { removeMessage, selectMessages } from 'slices/messageSlice'

import { DismissRegular } from '@fluentui/react-icons'
import { useAppSelector } from 'store'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
    messageBarGroup: {
        position: 'fixed',
        bottom: '8px',
        right: '12px',
        left: '12px',
        zIndex: 9999999,
        display: 'flex',
        flexDirection: 'column',
        gridGap: '8px',
    },
    durationBar: {
        width: '100%',
        height: '2px',
        backgroundColor: '#0078d4',
        animationName: {
            '0%': { width: '100%' },
            '100%': { width: '0%' },
        },
        animationDuration: '10s',
        animationTimingFunction: 'linear',
        animationIterationCount: 1,
        position: 'absolute',
        bottom: 0,
        left: 0,
    },
})

const Messages = () => {
    const classes = useStyles()
    const messages = useAppSelector(selectMessages)
    const dispatch = useDispatch()

    return (
        <MessageBarGroup animate='both' className={classes.messageBarGroup}>
            {messages.map(({ intent, id, title, message }) => (
                <MessageBar
                    key={`${intent}-${id}`}
                    intent={intent}
                    data-cy={`message-bar-${intent}`}
                >
                    <MessageBarBody>
                        <MessageBarTitle>{title}</MessageBarTitle>
                        {message}
                        <div className={classes.durationBar} />
                    </MessageBarBody>
                    <MessageBarActions
                        containerAction={
                            <Button
                                onClick={() => dispatch(removeMessage(id))}
                                aria-label='dismiss'
                                appearance='transparent'
                                icon={<DismissRegular />}
                            />
                        }
                    />
                </MessageBar>
            ))}
        </MessageBarGroup>
    )
}

export default Messages
