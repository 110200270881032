import { PropsWithChildren } from 'react'
import { makeStyles } from '@fluentui/react-components'

const useStyles = makeStyles({
    wrapper: {
        height: 'calc(100vh - 130px)', // 60px for header + 28px breadcrumb + 40 actionsmenu + 2px for separator
    },
})

const Content = ({ children }: PropsWithChildren<unknown>) => {
    const classes = useStyles()
    return <div className={classes.wrapper}>{children}</div>
}

export default Content
