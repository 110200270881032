import { RootState } from 'store'
import { createSlice } from '@reduxjs/toolkit'

interface IGridState {
    lastODataQuery: string
    rowCount: number
}

const initialState: IGridState = {
    lastODataQuery: '',
    rowCount: 0,
}

const gridSlice = createSlice({
    name: 'grid',
    initialState,
    reducers: {
        setLastOdataQuery: (state, action) => {
            state.lastODataQuery = action.payload
        },
        setRowCount: (state, action) => {
            state.rowCount = action.payload
        },
    },
})

export const { setLastOdataQuery, setRowCount } = gridSlice.actions

export const selectLastOdataQuery = (state: RootState) =>
    state.grid.lastODataQuery
export const selectRowCount = (state: RootState) => state.grid.rowCount

export default gridSlice.reducer
