interface IViraLink {
    viraNumber?: string
}

const ViraLink = ({ viraNumber = '' }: IViraLink) => (
    <a
        href={'https://jira-vira.volvocars.biz/browse/' + viraNumber}
        target='_blank'
        rel='noreferrer'
    >
        {viraNumber}
    </a>
)

export default ViraLink
