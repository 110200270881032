import {
    Combobox,
    ComboboxProps,
    Field,
    Option,
    Text,
} from '@fluentui/react-components'

import { useGetCollectionsQuery } from 'api/pgwCollection'
import { useState } from 'react'

interface IApiDropdownProps {
    label: string
    onSelect: (selected: IPGWCollection) => void
    placeholder?: string
    required?: boolean
    hint?: string
}

const PGWCollection = ({
    label,
    onSelect,
    placeholder,
    required = false,
    hint,
}: IApiDropdownProps) => {
    const { data: collections } = useGetCollectionsQuery()

    const [customSearch, setCustomSearch] = useState<string | undefined>()
    const [matchingOptions, setMatchingOptions] = useState<IPGWCollection[]>([])

    const onChange: ComboboxProps['onChange'] = event => {
        const value = event.target.value

        setCustomSearch(value)
        if (value.length >= 3) {
            const matches =
                collections?.filter(
                    c =>
                        c.name?.toLowerCase().indexOf(value.toLowerCase()) === 0
                ) ?? []
            setMatchingOptions(matches)
        } else {
            setMatchingOptions([])
        }
    }

    const onOptionSelect: ComboboxProps['onOptionSelect'] = (_ev, data) => {
        const matching = collections.find(
            actor => actor.id === data.optionValue
        )

        if (matching) {
            onSelect(matching)
        }
    }

    return (
        <Field label={label} required={required} hint={hint}>
            <Combobox
                clearable
                placeholder={placeholder}
                onChange={onChange}
                onOptionSelect={onOptionSelect}
            >
                {!matchingOptions.length ? (
                    <Option key='empty' text={customSearch}>
                        {customSearch?.length >= 3 ? (
                            <span>
                                No PGW Collection found containing &quot;
                                {customSearch}
                                &quot;
                            </span>
                        ) : (
                            <span>Enter at least 3 characters</span>
                        )}
                    </Option>
                ) : null}
                {matchingOptions.map(option => (
                    <Option
                        key={option.id}
                        value={option.id}
                        text={option.name}
                    >
                        <Text>
                            {option.name}
                            <br />
                            <b>Collection Type</b> {option.collectionType}
                        </Text>
                    </Option>
                ))}
            </Combobox>
        </Field>
    )
}

export default PGWCollection
