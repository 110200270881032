export default {
    DRIVER_STUDIES: 'driverstudies',
    DRIVERS: 'drivers',
    PORTAL_NEWS: 'portalnews',
    ROLE_PICKER: 'rolepicker',
    VEHICLE_CLASSIFICATIONS: 'vehicleclassifications',
    VEHICLE_TESTS: 'vehicletests',
    WORKSHOP: 'workshop',
    VEHICLE_DETAILS: 'vehicledetails',
}
