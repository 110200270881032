import { ColDef, FilterChangedEvent, GridReadyEvent } from 'ag-grid-community'
import { dateComparator, dateRenderWithTime } from 'helpers/dateHelpers'
import {
    defaultColDef,
    getContextMenuItems,
    sideBar,
    statusBar,
} from 'helpers/gridHelper'
import {
    useGetDriverStudiesQuery,
    useGetStudyTypesQuery,
} from 'api/driverStudies'
import { useMemo, useRef } from 'react'

import AgGridContainer from 'components/Ui/Layout/AgGridContainer'
import { AgGridReact } from 'ag-grid-react'
import DriverStudyActionsColumn from 'components/DriverStudy/DriverStudyActionsColumn'
import Loader from 'components/Ui/Loader'
import { permissions } from 'slices/authSlice'
import { useAppSelector } from 'store'
import { useGrid } from 'contexts/GridContext'
import useGridFilterUrl from 'hooks/useGridFilterUrl'

interface IDriverStudiesGridProps {
    filterOption: string
}

const DriverStudiesGrid = ({ filterOption }: IDriverStudiesGridProps) => {
    const [pushFiltersToHistory, setFiltersFromHistory] = useGridFilterUrl()
    const gridRef = useRef()
    const { setGridRef } = useGrid()

    const hasPermission = useAppSelector(permissions).driverStudiesManage

    const { data: driverStudies, isLoading } = useGetDriverStudiesQuery()
    const { data: studyTypes } = useGetStudyTypesQuery()
    const rowData = useMemo(() => {
        return driverStudies
            ?.map(s => ({
                ...s,
                studyType: studyTypes?.find(type => type.id === s.studyTypeId)
                    ?.name,
            }))
            .filter(study => {
                if (filterOption === 'Archived') {
                    return study.isArchived
                } else if (filterOption === 'Unarchived') {
                    return !study.isArchived
                }
                return true
            })
    }, [driverStudies, filterOption, studyTypes])

    const columnDefs: ColDef[] = useMemo(() => {
        return [
            {
                field: 'id',
                filter: 'agNumberColumnFilter',
                width: 80,
            },
            {
                field: 'title',
                filter: false,
            },
            { field: 'description', flex: 1, minWidth: 200 },
            {
                field: 'studyType',
                headerName: 'Study Type',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: studyTypes?.map(t => t.name),
                },
            },
            { field: 'stakeholder' },
            {
                field: 'status',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: ['Ended', 'Ongoing', 'Created'],
                },
            },
            {
                field: 'startDate',
                cellRenderer: dateRenderWithTime,
                filter: 'agDateColumnFilter',
                filterParams: {
                    comparator: dateComparator,
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                field: 'endDate',
                cellRenderer: dateRenderWithTime,
                filter: 'agDateColumnFilter',
                filterParams: {
                    comparator: dateComparator,
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                field: 'msFormId',
            },
            {
                field: 'isArchived',
                width: 120,
                filter: 'agSetColumnFilter',
            },
            hasPermission && {
                cellRenderer: DriverStudyActionsColumn,
                maxWidth: 100,
                filter: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: true,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                sortable: false,
            },
        ].filter(Boolean) as ColDef[]
    }, [hasPermission, studyTypes])

    const onGridReady = (params: GridReadyEvent) => {
        setFiltersFromHistory(params.api)
        setGridRef(gridRef)
    }

    const onFilterChanged = (event: FilterChangedEvent) => {
        pushFiltersToHistory(event.api)
    }

    if (isLoading) {
        return <Loader text='Fetching driver studies...' />
    }

    return (
        <AgGridContainer>
            <AgGridReact
                className='cy-driver-studies-grid'
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                enableRangeSelection
                getContextMenuItems={getContextMenuItems}
                onFilterChanged={onFilterChanged}
                onGridReady={onGridReady}
                ref={gridRef}
                rowData={rowData}
                rowSelection='multiple'
                sideBar={sideBar}
                statusBar={statusBar}
            />
        </AgGridContainer>
    )
}

export default DriverStudiesGrid
