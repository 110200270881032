import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    MessageBar,
} from '@fluentui/react-components'
import { selectLastOdataQuery, selectRowCount } from 'slices/gridSlice'
import { useMemo, useState } from 'react'

import DriverStudyForm from 'components/DriverStudy/DriverStudyForm'
import DriverStudySelect from 'components/DriverStudy/DriverStudySelect'
import ModalStatusEnum from 'constants/ModalStatusEnum'
import ROUTES from 'constants/routes'
import dayjs from 'dayjs'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import { useAddDriverStudyMutation } from 'api/driverStudies'
import { useAddFactDriverStudyAssignmentsBatchMutation } from 'api/driverStudyParticipation'
import { useAppSelector } from 'store'
import { useGrid } from 'contexts/GridContext'
import { useNavigate } from 'react-router-dom'

type DriverStudyActionsProps = {
    toggleOpen: () => void
    modalStatus: ModalStatusEnum
}

const DriverStudyActions = ({
    toggleOpen,
    modalStatus,
}: DriverStudyActionsProps) => {
    const [isShowForm, setShowForm] = useState(false)
    const [selectedId, setSelectedId] = useState<number>()

    const navigate = useNavigate()

    const [addDriverStudy] = useAddDriverStudyMutation()
    const [createBatch, { isLoading }] =
        useAddFactDriverStudyAssignmentsBatchMutation()

    const lastODataQuery = useAppSelector(selectLastOdataQuery)
    const rowCount = useAppSelector(selectRowCount)

    const { gridRef } = useGrid()
    const selectedActorIds = useMemo(
        () =>
            gridRef.current?.api
                .getSelectedRows()
                .map(sr => ({
                    actorId: sr.ActorId,
                    vehicleId: sr.Id,
                }))
                .filter(o => !!o.actorId),
        [gridRef]
    )

    const collectActorIds = async () => {
        const param = new URLSearchParams(lastODataQuery)
        param.delete('$skip')
        param.delete('$top')
        param.delete('$select')
        param.append('$select', 'ActorId, Id')

        const { data } = await reflectApiAxiosInstance.get(
            decodeURIComponent(param.toString())
        )

        return data.value
            .map(({ ActorId, Id }) => ({
                actorId: ActorId,
                vehicleId: Id,
            }))
            .filter(o => !!o.actorId)
    }

    const onAddDrivers = async (driverStudyId: number) => {
        if (modalStatus === ModalStatusEnum.Selected) {
            await createBatch({
                driverStudyId,
                items: selectedActorIds,
            })
        } else if (modalStatus === ModalStatusEnum.Filtered) {
            const items = await collectActorIds()
            await createBatch({
                driverStudyId,
                items,
            })
        }
    }

    const onSave = () => {
        onAddDrivers(selectedId).then(() => toggleOpen())
    }

    const onSaveAndNavigate = () => {
        onAddDrivers(selectedId).then(() =>
            navigate(`/${ROUTES.DRIVER_STUDIES}/${String(selectedId)}`)
        )
    }

    const handleSubmit = async (
        { startDate, endDate, ...rest }: IDriverStudy,
        setSubmitting: (isSubmitting: boolean, inEditMode: boolean) => void
    ) => {
        setSubmitting(true, false)
        const response = await addDriverStudy({
            ...rest,
            startDate: startDate ? dayjs(startDate).toISOString() : null,
            endDate: endDate ? dayjs(endDate).toISOString() : null,
        })

        if ('data' in response) {
            setSubmitting(false, false)
            setShowForm(false)
            setSelectedId(response.data.id)
        }
    }

    const count = selectedActorIds.length || rowCount

    return (
        <>
            <DialogContent>
                {count > 1000 && (
                    <MessageBar intent='warning'>
                        Warning! This action can take a very long time!
                    </MessageBar>
                )}
                <div
                    style={{ marginTop: '6px', marginBottom: '8px' }}
                >{`Adding drivers from ${count} vehicles`}</div>

                {isShowForm ? (
                    <DriverStudyForm onSave={handleSubmit} />
                ) : (
                    <DriverStudySelect
                        onSelect={(vehicleTestId: number) => {
                            setSelectedId(vehicleTestId)
                        }}
                        selectedId={selectedId}
                    />
                )}
            </DialogContent>
            {!isShowForm && (
                <DialogActions fluid>
                    <Button
                        onClick={() => setShowForm(prev => !prev)}
                        appearance='primary'
                    >
                        Create new study
                    </Button>
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <MenuButton appearance='primary'>Save</MenuButton>
                        </MenuTrigger>

                        <MenuPopover>
                            <MenuList>
                                <MenuItem
                                    onClick={onSave}
                                    disabled={!selectedId || isLoading}
                                >
                                    Save
                                </MenuItem>
                                <MenuItem
                                    onClick={onSaveAndNavigate}
                                    disabled={!selectedId || isLoading}
                                >
                                    Save and go to test
                                </MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>

                    <DialogTrigger disableButtonEnhancement>
                        <Button data-cy='cancel-button' onClick={toggleOpen}>
                            Cancel
                        </Button>
                    </DialogTrigger>
                </DialogActions>
            )}
        </>
    )
}

export default DriverStudyActions
