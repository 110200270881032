import { apiSlice } from './apiSlice'

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ['VehicleGroupLists', 'VehicleGroupListsFilters'],
})

const vehicleGroupListApi = apiWithTags.injectEndpoints({
    endpoints: builder => ({
        getVehicleGroupLists: builder.query<
            IVehicleGroup[],
            VehicleGroupListSupportedPages
        >({
            query: vehicleGroupType =>
                `api/VehicleGroupList/${vehicleGroupType}`,
            providesTags: ['VehicleGroupLists'],
        }),
        getVehicleGroupsByVehicleId: builder.query<
            IVehicleGroup[],
            {
                vehicleGroupType: VehicleGroupListSupportedPages
                vehicleId: string
            }
        >({
            query: ({ vehicleGroupType, vehicleId }) =>
                `/api/VehicleGroupList/${vehicleGroupType}/GetVehicleGroupsByVehicleId/${vehicleId}`,
            providesTags: ['VehicleGroupLists'],
        }),

        createVehicleGroupLists: builder.mutation<
            { vehicleCount: number },
            {
                vehicleGroupType: VehicleGroupListSupportedPages
                postVehicleGroupList: IVehicleGroup
            }
        >({
            query: ({ vehicleGroupType, postVehicleGroupList }) => ({
                url: `/api/VehicleGroupList/${vehicleGroupType}`,
                method: 'POST',
                body: postVehicleGroupList,
            }),
            invalidatesTags: ['VehicleGroupLists', 'VehicleGroupListsFilters'],
        }),
        addVehicleToVehicleGroup: builder.mutation<
            null,
            {
                vehicleGroupType: VehicleGroupListSupportedPages
                sanitizedVehicleGroups: {
                    vehicleId: string
                    groupId?: unknown
                }
            }
        >({
            query: ({ vehicleGroupType, sanitizedVehicleGroups }) => ({
                url: `/api/VehicleGroupList/${vehicleGroupType}/AddVehicleToVehicleGroup`,
                method: 'PUT',
                body: sanitizedVehicleGroups,
            }),
            invalidatesTags: ['VehicleGroupLists', 'VehicleGroupListsFilters'],
        }),
        removeVehicleFromVehicleGroup: builder.mutation<
            null,
            {
                vehicleGroupType: VehicleGroupListSupportedPages
                deleteVehicleGroup: {
                    vehicleId: string
                    groupId: string
                }
            }
        >({
            query: ({ vehicleGroupType, deleteVehicleGroup }) => ({
                url: `/api/VehicleGroupList/${vehicleGroupType}/RemoveVehicleFromVehicleGroup`,
                method: 'PUT',
                body: deleteVehicleGroup,
            }),
            invalidatesTags: ['VehicleGroupLists', 'VehicleGroupListsFilters'],
        }),
        deleteVehicleGroupLists: builder.mutation<
            null,
            {
                vehicleGroupType: VehicleGroupListSupportedPages
                vehicleGroupListId: string
            }
        >({
            query: ({ vehicleGroupType, vehicleGroupListId }) => ({
                url: `/api/VehicleGroupList/${vehicleGroupType}/${vehicleGroupListId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['VehicleGroupLists', 'VehicleGroupListsFilters'],
        }),
        editVehicleGroupLists: builder.mutation<
            IVehicleGroup,
            {
                vehicleGroupType: VehicleGroupListSupportedPages
                vehicleGroupListId: string
                putVehicleGroupList: IVehicleGroup
            }
        >({
            query: ({
                vehicleGroupType,
                vehicleGroupListId,
                putVehicleGroupList,
            }) => ({
                url: `/api/VehicleGroupList/${vehicleGroupType}/${vehicleGroupListId}`,
                method: 'PUT',
                body: putVehicleGroupList,
            }),
            invalidatesTags: ['VehicleGroupLists', 'VehicleGroupListsFilters'],
        }),

        getVehicleGroupListsFilters: builder.query<
            VehicleGroupListListType[],
            VehicleGroupListSupportedPages
        >({
            query: vehicleGroupType =>
                `api/VehicleGroupList/${vehicleGroupType}/GetVehicleGroupListsFilters`,
            providesTags: ['VehicleGroupListsFilters'],
        }),
    }),
})

export const {
    useGetVehicleGroupListsQuery,
    useGetVehicleGroupsByVehicleIdQuery,
    useCreateVehicleGroupListsMutation,
    useAddVehicleToVehicleGroupMutation,
    useRemoveVehicleFromVehicleGroupMutation,
    useDeleteVehicleGroupListsMutation,
    useEditVehicleGroupListsMutation,
    useGetVehicleGroupListsFiltersQuery,
    util: apiUtil,
} = vehicleGroupListApi
