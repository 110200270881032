import {
    Button,
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components'

import { Add24Regular } from '@fluentui/react-icons'
import DriverStudyForm from './DriverStudyForm'
import ROUTES from 'constants/routes'
import dayjs from 'dayjs'
import { useAddDriverStudyMutation } from 'api/driverStudies'
import { useNavigate } from 'react-router'
import { useState } from 'react'

const CreateDriverStudyDialog = () => {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const [addDriverStudy] = useAddDriverStudyMutation()

    const createStudy = async ({
        startDate,
        endDate,
        ...rest
    }: IDriverStudy) => {
        const response = await addDriverStudy({
            ...rest,
            startDate: startDate ? dayjs(startDate).toISOString() : null,
            endDate: endDate ? dayjs(endDate).toISOString() : null,
        })

        if ('data' in response) {
            navigate(`/${ROUTES.DRIVER_STUDIES}/${String(response.data.id)}`)
        }
    }

    return (
        <Dialog open={open} onOpenChange={(_e, d) => setOpen(d.open)}>
            <DialogTrigger>
                <Button
                    data-cy='add-driver-study-button'
                    appearance='subtle'
                    icon={<Add24Regular />}
                    size='small'
                >
                    Create new study
                </Button>
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Create new study</DialogTitle>

                    <DriverStudyForm onSave={createStudy} />
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default CreateDriverStudyDialog
