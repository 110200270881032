import {
    ColDef,
    ColGroupDef,
    GetContextMenuItemsParams,
} from 'ag-grid-community'
import {
    dateComparator,
    dateRenderWithAdjustedCETtoUTC,
    dateRenderWithTime,
} from 'helpers/dateHelpers'

import ColumnType from 'constants/ColumnTypeEnum'
import CustomFilterStatePanel from 'components/AgGrid/Toolbars/CustomFilterStatePanel'
import ResultGridHelper from 'components/Search/ResultGrid/ResultGridHelper'
import RowActions from 'components/AgGrid/CellRenderers/RowActions'
import { getContextMenuItems } from 'helpers/gridHelper'
import qs from 'qs'
import routes from 'constants/routes'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

export const textFilterOptions = [
    'contains',
    'notContains',
    'equals',
    'notEqual',
    'startsWith',
    'endsWith',
    'blank',
]

export default (schema: SearchViewColumn[], isLoading: boolean) => {
    const navigate = useNavigate()

    const sideBar = useMemo(
        () => ({
            defaultToolPanel: undefined,
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressSideButtons: true,
                        suppressColumnFilter: false,
                        suppressColumnSelectAll: false,
                        suppressColumnExpandAll: false,
                    },
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    toolPanelParams: {
                        suppressExpandAll: true,
                        suppressFilterSearch: false,
                    },
                },
                {
                    id: 'customFilterState',
                    labelDefault: 'Active Filters',
                    labelKey: 'customFilterState',
                    iconKey: 'filter',
                    toolPanel: CustomFilterStatePanel,
                },
            ],
        }),
        []
    )

    const columnDefs: Array<ColDef | ColGroupDef> = useMemo(() => {
        const columns: Array<ColDef | ColGroupDef> = [
            {
                colId: 'checkBoxSelect',
                checkboxSelection: true,
                lockPosition: true,
                lockPinned: true,
                pinned: 'left',
                suppressMenu: true,
                width: 55,
                suppressColumnsToolPanel: true,
            },
        ]

        if (isLoading) {
            return columns
        }

        // schema is none-mutable, so we can't sort it without creating a new array
        ;[...schema]
            .sort(
                (colA, colB) =>
                    colA.gridColumnSortOrder - colB.gridColumnSortOrder
            )
            .forEach(column => {
                let colFilter = column.columnType
                let values = column.setFilterValues
                const nonNullableColumns = [
                    'CdbMarket',
                    'IsArchived',
                    'CarDistribution',
                    'CarCategory',
                    'EquipmentType',
                    'CarUsage',
                    'VehicleStatus',
                    'Blacklist',
                    'FeedbackTools',
                ]

                if (
                    column.colId === 'ModelCode' ||
                    column.colId === 'Vin' ||
                    column.colId === 'Cdsid'
                ) {
                    colFilter = ColumnType.ModelCode
                }

                if (column.colId === 'StructureWeek') {
                    colFilter = ColumnType.StructureWeek
                }

                if (
                    column.columnType === 'setfilter' &&
                    column.setFilterValues
                ) {
                    values = [null, ...values]
                }

                if (nonNullableColumns.includes(column.colId)) {
                    values = column.setFilterValues
                }

                if (
                    column.colId === 'VidaClientId' ||
                    column.colId === 'SteeringCode'
                ) {
                    colFilter = ColumnType.Number

                    columns.push({
                        resizable: true,
                        field: column.colId,
                        headerName: column.displayName
                            .replace(/([A-Z])/g, ' $1')
                            .trim(),
                        hide: column.hide ?? undefined,
                        filter: 'agNumberColumnFilter',
                        filterParams: {
                            buttons: ['reset', 'apply'],
                        },
                        sortable: true,
                        unSortIcon: true,
                        tooltipField: column.colId,
                        pinned: column.pinned,
                        sort: column.sort,
                        sortIndex: column.sortIndex,
                    })

                    return
                }

                // TODO: [DQ-Time] Data in backend suffers from data-quality issues.
                // this is a quickfix for those issues, more info in dateRenderWithAdjustedCETtoUTC func
                if (column.columnType === ColumnType.Date) {
                    columns.push({
                        resizable: true,
                        field: column.colId,
                        hide: column.hide ?? undefined,
                        filter: 'agDateColumnFilter',
                        filterParams: {
                            comparator: dateComparator,
                            maxNumConditions: 1,
                            buttons: ['reset', 'apply'],
                        },
                        headerName: column.displayName
                            .replace(/([A-Z])/g, ' $1')
                            .trim(),
                        cellRenderer:
                            column.colId === 'LatestVehicleVersion' ||
                            column.colId === 'FirstPds'
                                ? dateRenderWithAdjustedCETtoUTC
                                : dateRenderWithTime,
                        sortable: true,
                        unSortIcon: true,
                        tooltipField: column.colId,
                        pinned: column.pinned,
                        sort: column.sort,
                        sortIndex: column.sortIndex,
                    })

                    return
                }

                columns.push({
                    resizable: true,
                    field: column.colId,
                    headerName: column.displayName
                        .replace(/([A-Z])/g, ' $1')
                        .trim(),
                    hide: column.hide ?? undefined,
                    menuTabs: ['generalMenuTab'],
                    filter: ResultGridHelper.GetFilterType(colFilter),
                    filterParams: {
                        filterOptions:
                            !column.columnType ||
                            column.columnType === ColumnType.Text
                                ? textFilterOptions
                                : undefined,
                        values: values,
                        buttons: ['reset', 'apply'],
                        suppressSorting: column.suppressSorting,
                    },
                    sortable: true,
                    unSortIcon: true,
                    tooltipField: column.colId,
                    pinned: column.pinned,
                    sort: column.sort,
                    sortIndex: column.sortIndex,
                })
            })

        columns.push({
            colId: 'modalOpener',
            lockPosition: true,
            lockPinned: true,
            pinned: 'right',
            suppressMenu: true,
            width: 70,
            suppressColumnsToolPanel: true,
            cellRenderer: RowActions,
        })
        return columns
    }, [schema, isLoading])

    const getContextMenu = (params: GetContextMenuItemsParams) => {
        const selectedRows = params.api.getSelectedRows()
        return [
            ...getContextMenuItems(params),
            selectedRows.length > 0 && 'separator',
            selectedRows.length > 0 && {
                name: 'Filter by selection',
                icon: '<span class="ag-icon ag-icon-filter" role="presentation"></span>',
                tooltip: 'Filter by the selected rows Vins.',
                action: () => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    params.api.getFilterInstance('Vin', (ctx: any) => {
                        const selectedVin = params.api
                            .getSelectedRows()
                            .map(row => row.Vin)
                            .join(',')
                        ctx.filterBySelection(selectedVin)
                    })
                },
                disabled: !params.api.getSelectedRows().length,
            },
            selectedRows.length > 0 && {
                name: 'Share selection',
                icon: '<span class="ag-icon ag-icon-copy" role="presentation"></span>',
                tooltip:
                    'Will copy the url with the selected id filter to the clipboard.',
                action: () => {
                    const filters = {
                        Vin: {
                            value: selectedRows.map(row => row.Vin).join(','),
                        },
                    }
                    const search = qs.stringify(filters)
                    const url = `${window.location.origin}/?filters=${search}`
                    navigator.clipboard.writeText(url)
                },
            },
            selectedRows.length > 0 && {
                name: 'Show driver(s) for selected vehicle(s)',
                icon: '<span class="ag-icon ag-icon-right" role="presentation"></span>',
                tooltip: 'Will trigger page navigation with active filter.',
                action: () => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const sr = params.api.getSelectedRows() as any[]
                    if (sr && sr.length > 0) {
                        navigate(
                            `${routes.DRIVERS}?filters=Cdsid%5Bvalue%5D=${sr
                                .map(r => r.Cdsid)
                                .join(',')}`
                        )
                    } else if (params.node?.data?.Cdsid) {
                        navigate(
                            `${routes.DRIVERS}?filters=Cdsid%5Bvalue%5D=${params.node?.data?.Cdsid}`
                        )
                    }
                },
            },
        ].filter(Boolean)
    }

    const excelStyles = useMemo(
        () => [
            {
                id: 'header',
                font: {
                    bold: true,
                },
            },
        ],
        []
    )

    return { sideBar, columnDefs, getContextMenu, excelStyles }
}
