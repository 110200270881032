import Base from 'components/ApiDropdowns/Base'

interface IApiDropdownProps {
    disabled?: boolean
    selectedItem: string
    setSelectedItem: (value: string) => void
    noLabel?: boolean
    showHint?: boolean
}

const CarUsages = ({
    selectedItem,
    setSelectedItem,
    disabled,
    noLabel = false,
    showHint,
}: IApiDropdownProps) => (
    <Base
        label={noLabel ? null : 'Car usage'}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        apiListName='CarUsages'
        disabled={disabled}
        hint={
            showHint
                ? 'Changing this value might have unexpected consequences'
                : ''
        }
    />
)

export default CarUsages
