import Content from 'components/Ui/Layout/Content'
import { Outlet } from 'react-router-dom'
import WorkshopHeader from 'components/Workshop/WorkshopHeader'
import { useVehicleTest } from './VehicleTest'

const Workshop = () => {
    const { vehicleTest } = useVehicleTest()

    return (
        <>
            <WorkshopHeader vehicleTest={vehicleTest} />

            <Content>
                <Outlet context={{ vehicleTest }} />
            </Content>
        </>
    )
}

export default Workshop
