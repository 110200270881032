enum ColumnType {
    Text = 'text',
    Date = 'date',
    Number = 'number',
    Set = 'setfilter',
    StructureWeek = 'structureWeek',
    CustomList = 'customList',
    Guid = 'guid',
    ModelCode = 'modelCode',
}

export default ColumnType
