import { Dispatch, SetStateAction } from 'react'
import { Field, Input, MessageBar } from '@fluentui/react-components'

import DriverCheckStates from 'Types/DriverCheckStates'
import { debounce } from 'ts-debounce'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import reflectAxiosGraphInstance from 'components/Axios/ReflectGraphAxios'

type AddDriverFormCdsIdProps = {
    driver: DriverObject
    setDriver: Dispatch<SetStateAction<DriverObject>>
    driverCheckState: DriverCheckStates
    setDriverCheckState: Dispatch<SetStateAction<DriverCheckStates>>
    setDriverRequiredCompleted: Dispatch<SetStateAction<boolean>>
}

const checkIfDriverExists = (
    cdsId: string,
    driverCheckState: DriverCheckStates,
    setDriverCheckState: (value: SetStateAction<DriverCheckStates>) => void
) => {
    reflectApiAxiosInstance
        .get(`/odata/Actors?$filter=CDSID eq '${cdsId}'`)
        .then(response => {
            if (response.data.value) {
                if (response.data.value.length === 1) {
                    setDriverCheckState({
                        ...driverCheckState,
                        DriverWithCdsIdExists: true,
                        SeveralDriverMatches: false,
                        CheckDriverExistsFailed: false,
                        CdsIdNotFound: false,
                        CdsIdVerified: true,
                    })
                }
                if (response.data.value.length > 1) {
                    setDriverCheckState({
                        ...driverCheckState,
                        DriverWithCdsIdExists: false,
                        SeveralDriverMatches: true,
                        CheckDriverExistsFailed: false,
                        CdsIdNotFound: false,
                        CdsIdVerified: true,
                    })
                }
                if (response.data.value.length < 1) {
                    setDriverCheckState({
                        ...driverCheckState,
                        DriverWithCdsIdExists: false,
                        SeveralDriverMatches: false,
                        CheckDriverExistsFailed: false,
                        CdsIdNotFound: false,
                        CdsIdVerified: true,
                    })
                }
            }
        })
        .catch(() => {
            setDriverCheckState({
                ...driverCheckState,
                CheckDriverExistsFailed: true,
            })
        })
}

const findAdUserbyCdsId = (
    cdsid: string,
    currentDriver: DriverObject,
    setDriver: Dispatch<SetStateAction<DriverObject>>,
    driverCheckState: DriverCheckStates,
    setDriverCheckState: (value: SetStateAction<DriverCheckStates>) => void
) => {
    reflectAxiosGraphInstance
        .get(`/users/${cdsid}@volvocars.com`)
        .then(response => {
            if (response.data) {
                setDriver({
                    ...currentDriver,
                    cdsid: cdsid.toUpperCase(),
                    firstName: response.data.givenName ?? undefined,
                    lastName: response.data.surname ?? undefined,
                    email: response.data.userPrincipalName ?? undefined,
                    preferredEmail:
                        response.data.mail ??
                        response.data.userPrincipalName ??
                        undefined,
                    phone:
                        response.data.businessPhones &&
                        response.data.businessPhones.length > 0 &&
                        response.data.businessPhones[0]
                            ? response.data.businessPhones[0]
                            : undefined,
                })
                checkIfDriverExists(
                    cdsid,
                    driverCheckState,
                    setDriverCheckState
                )
            }
        })
        .catch(() => {
            setDriverCheckState({
                ...driverCheckState,
                CdsIdNotFound: true,
                CdsIdVerified: false,
            })
        })
}

const AddDriverFormCdsId = ({
    driver,
    setDriver,
    driverCheckState,
    setDriverCheckState,
}: AddDriverFormCdsIdProps) => {
    const debouncedFindAdUserbyCdsId = debounce(findAdUserbyCdsId, 500)

    return (
        <>
            <Field
                required
                label='CDSID'
                validationMessage={
                    driver?.cdsid ? undefined : 'This field is required'
                }
            >
                <Input
                    placeholder='CDSID'
                    value={driver.cdsid ?? ''}
                    onChange={(_ev, data) => {
                        setDriver({ ...driver, cdsid: data.value })
                        if (data.value != null && data.value !== '') {
                            setDriverCheckState({
                                ...driverCheckState,
                                DriverWithCdsIdExists: false,
                                SeveralDriverMatches: false,
                            })
                            debouncedFindAdUserbyCdsId(
                                data.value,
                                driver,
                                setDriver,
                                driverCheckState,
                                setDriverCheckState
                            )
                        }
                        if (data.value == null || data.value === '') {
                            setDriverCheckState({
                                ...driverCheckState,
                                CdsIdVerified: false,
                            })
                        }
                    }}
                />
            </Field>

            {driverCheckState.CheckDriverExistsFailed && (
                <MessageBar intent='error'>
                    An error occurred while checking if the driver exists.
                    Please try again and contact support if the error persists.
                </MessageBar>
            )}
            {driverCheckState.CdsIdNotFound && (
                <MessageBar intent='error'>
                    The CDSID does not match any Volvo user. Please verify the
                    CDSID and try again.
                </MessageBar>
            )}
            {driverCheckState.DriverWithCdsIdExists && (
                <MessageBar intent='error'>
                    A driver with this CDSID already exists. You cannot add a
                    CDSID that already exists as a driver.
                </MessageBar>
            )}
            {driverCheckState.SeveralDriverMatches && (
                <MessageBar intent='error'>
                    More than one (1) drivers matches the CDSID. Please verify
                    the CDSID.
                </MessageBar>
            )}
        </>
    )
}

export default AddDriverFormCdsId
