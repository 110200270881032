import { Field, Select } from '@fluentui/react-components'

import { NewsRegular } from '@fluentui/react-icons'
import { useGetPortalNewsQuery } from 'api/portalNews'
import PortalNewsDialog from 'components/PortalNews/PortalNewsDialog'
import PortalNewsGrid from 'components/PortalNews/PortalNewsGrid'
import ActionsMenu from 'components/Ui/Layout/ActionsMenu'
import Content from 'components/Ui/Layout/Content'
import Header from 'components/Ui/Layout/Header'
import { useState } from 'react'

const PortalNewsIndex = () => {
    const { isLoading } = useGetPortalNewsQuery()
    const [filterOption, setFilterOption] = useState('showAll')

    return (
        <>
            <Header title='Portal News' icon={<NewsRegular />} />

            <ActionsMenu
                items={[
                    {
                        restricted: 'rivendellEdit',
                        component: (
                            <PortalNewsDialog formTitle={'Create News Item'} />
                        ),
                    },
                ]}
                extraComponents={
                    <Field
                        label='Show only'
                        orientation='horizontal'
                        style={{ minWidth: '270px', alignItems: 'center' }}
                    >
                        <Select
                            value={filterOption}
                            onChange={(_e, data) => setFilterOption(data.value)}
                        >
                            <option value='unpublished'>Unpublished</option>
                            <option value='published'>Published</option>
                            <option value='showAll'>Show All</option>
                        </Select>
                    </Field>
                }
            />

            <Content>
                <PortalNewsGrid
                    isLoading={isLoading}
                    filterOption={filterOption}
                />
            </Content>
        </>
    )
}

export default PortalNewsIndex
