import { Button } from '@fluentui/react-components'
import { ClipboardTextEdit24Regular } from '@fluentui/react-icons'
import { ICellRendererParams } from 'ag-grid-community'
import { useNavigate } from 'react-router-dom'

const OpenVehicleTestButton = ({
    data,
}: ICellRendererParams<IVehicleTestOdata>) => {
    const navigate = useNavigate()

    return (
        <Button
            data-cy='open-vehicle-test-button'
            icon={<ClipboardTextEdit24Regular />}
            appearance='transparent'
            onClick={() => {
                navigate(`${data.Id}`)
            }}
            title='Open vehicle test page'
            aria-label='Open vehicle test page'
        />
    )
}

export default OpenVehicleTestButton
