import {
    Field,
    Input,
    Select,
    Textarea,
    makeStyles,
} from '@fluentui/react-components'

import CarCategories from 'components/ApiDropdowns/CarCategories'
import CarUsages from 'components/ApiDropdowns/CarUsages'
import ConsentCategory from 'components/ApiDropdowns/ConsentCategory'
import DateTimePicker from 'components/Ui/DateTimePicker'
import MarketCodes from 'components/ApiDropdowns/MarketCodes'

type VehicleEditFieldTypeProps = {
    column: string
    value: unknown
    disabled?: boolean
    onChangeTextField: (newValue: unknown, column: string) => void
}

const useStyles = makeStyles({
    input: {
        marginTop: '8px',
        width: '100%',
    },
})

const VehicleEditFieldType = ({
    column,
    value,
    disabled = false,
    onChangeTextField,
}: VehicleEditFieldTypeProps) => {
    const classes = useStyles()

    switch (column) {
        case 'CarCategory':
            return (
                <CarCategories
                    selectedItem={value as string}
                    setSelectedItem={selectedItem => {
                        onChangeTextField(selectedItem, column)
                    }}
                    disabled={disabled}
                />
            )
        case 'CarUsage':
            return (
                <CarUsages
                    selectedItem={value as string}
                    setSelectedItem={selectedItem => {
                        onChangeTextField(selectedItem, column)
                    }}
                    disabled={disabled}
                />
            )
        case 'ConsentCategory':
            return (
                <ConsentCategory
                    selectedItem={value as string}
                    setSelectedItem={selectedItem => {
                        onChangeTextField(selectedItem, column)
                    }}
                    disabled={disabled}
                />
            )
        case 'CdbMarket':
            return (
                <MarketCodes
                    selectedItem={value as string}
                    setSelectedItem={selectedItem => {
                        onChangeTextField(selectedItem, column)
                    }}
                    disabled={disabled}
                />
            )
        case 'FeedbackTools':
            return (
                <Field label={column}>
                    <Input
                        value={(value as string) ?? ''}
                        className={classes.input}
                        disabled={disabled}
                    />
                </Field>
            )
        case 'IsArchived':
            return (
                <Field label={column}>
                    <Select
                        onChange={(_e, data) => {
                            if (data.value) {
                                onChangeTextField(data.value, column)
                            }
                        }}
                        value={
                            value ? String(value).toLocaleLowerCase() : 'false'
                        }
                        className={classes.input}
                        disabled={disabled}
                    >
                        <option value='false'>False</option>
                        <option value='true'>True</option>
                    </Select>
                </Field>
            )
        case 'VehicleDelivered':
        case 'VehicleReturned':
        case 'CustomDelivered':
        case 'CustomReturned':
            return (
                <DateTimePicker
                    clear
                    nowTime
                    label={column}
                    value={value ? new Date(value as string) : null}
                    onChange={date => {
                        onChangeTextField(date, column)
                    }}
                    disabled={disabled}
                />
            )
        case 'Comments':
            return (
                <Field label={column}>
                    <Textarea
                        value={(value as string) ?? ''}
                        onChange={(_e, data) => {
                            onChangeTextField(data.value, column)
                        }}
                        className={classes.input}
                        disabled={disabled}
                    />
                </Field>
            )
        default:
            return (
                <Field label={column}>
                    <Input
                        value={(value as string) ?? ''}
                        onChange={(_e, data) => {
                            onChangeTextField(data.value, column)
                        }}
                        className={classes.input}
                        disabled={disabled}
                    />
                </Field>
            )
    }
}

export default VehicleEditFieldType
