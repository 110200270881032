export const capitalizeString = (value: string) =>
    value.charAt(0).toUpperCase() + value.slice(1)

/**
 * Capitalize all keys in an object
 *
 * @param obj
 * @param deep
 * @returns
 */
export const capitalizeKeysInObject = <T>(obj: T, deep = false): T =>
    Object.keys(obj).reduce((acc, key) => {
        acc[capitalizeString(key)] =
            deep && typeof obj[key] === 'object'
                ? capitalizeKeysInObject(obj[key])
                : obj[key]
        return acc
    }, {} as T)

/**
 * Recursively lowercases the keys of an object and returns a new object
 * with the same values but with the keys lowercased (e.g. Id -> id)
 *
 * @param  {T} obj
 * @returns UncapitalizedObjectKeys
 */
export const lowerCaseKeys = <T extends object>(
    obj: T
): UncapitalizedObjectKeys<T> => {
    if (Array.isArray(obj)) {
        return obj.map(o =>
            lowerCaseKeys(o)
        ) as unknown as UncapitalizedObjectKeys<T>
    } else if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj).reduce(
            (r, [k, v]) => ({
                ...r,
                [`${k.substring(0, 1).toLowerCase()}${k.substring(1)}`]:
                    lowerCaseKeys(v),
            }),
            {}
        ) as UncapitalizedObjectKeys<T>
    } else {
        return obj as UncapitalizedObjectKeys<T>
    }
}

/**
 * Returns a new array with only unique objects based on the given key
 * @param  {T[]} arr
 * @returns T[]
 * @example
 * const arr = [{ id: 1, name: 'John' }, { id: 2, name: 'Jane' }, { id: 1, name: 'John' }]
 * const uniqueArr = uniqueArray(arr, 'id')
 * // uniqueArr = [{ id: 1, name: 'John' }, { id: 2, name: 'Jane' }]
 */
export const uniqueArray = <T extends object>(arr: T[], key: keyof T): T[] =>
    arr.filter((v, i, a) => a.findIndex(t => t[key] === v[key]) === i)
