import {
    Button,
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components'

import { Add24Regular } from '@fluentui/react-icons'
import ROUTES from 'constants/routes'
import VehicleTestForm from './VehicleTestForm'
import dayjs from 'dayjs'
import { triggerMessage } from 'slices/messageSlice'
import { useAddVehicleTestMutation } from 'api/vehicleTests'
import { useAppDispatch } from 'store'
import { useNavigate } from 'react-router'
import { useState } from 'react'

const CreateVehicleTestDialog = () => {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const [addVehicleTest] = useAddVehicleTestMutation()
    const dispatch = useAppDispatch()

    const saveVehicleTest = async (
        { plannedFinish, plannedStart, ...rest }: IVehicleTest,
        setSubmitting: (isSubmitting: boolean) => void
    ) => {
        const response = await addVehicleTest({
            ...rest,
            plannedStart: plannedStart
                ? dayjs(plannedStart).toISOString()
                : null,
            plannedFinish: plannedFinish
                ? dayjs(plannedFinish).toISOString()
                : null,
        })
        setSubmitting(false)
        if ('data' in response) {
            dispatch(
                triggerMessage({
                    intent: 'success',
                    message: 'Vehicle test created successfully.',
                })
            )
            navigate(`/${ROUTES.VEHICLE_TESTS}/${String(response.data.id)}`)
        } else if ('data' in response.error) {
            dispatch(
                triggerMessage({
                    intent: 'error',
                    title: 'Error',
                    message: `${response.error.data}`,
                })
            )
        }
    }

    return (
        <Dialog open={open} onOpenChange={(_e, d) => setOpen(d.open)}>
            <DialogTrigger>
                <Button
                    data-cy='add-vehicle-test-button'
                    appearance='subtle'
                    icon={<Add24Regular />}
                    size='small'
                >
                    Create new test
                </Button>
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Create new test</DialogTitle>

                    <VehicleTestForm onSave={saveVehicleTest} />
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default CreateVehicleTestDialog
