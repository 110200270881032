import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    Select,
    Switch,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import {
    useAddBatchVehicleTestEscalationMutation,
    useGetVehicleClassificationsQuery,
    useStartVehicleTestsMutation,
    useStopVehicleTestsMutation,
} from 'api/vehicleTests'
import { useMemo, useState } from 'react'

import DateTimePicker from 'components/Ui/DateTimePicker'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { useGetEscalationStepsQuery } from 'api/escalationSteps'
import { useGrid } from 'contexts/GridContext'
import { useUpdateWorkshopVisitMutation } from 'api/workshop'

interface IVehiclesBatchEditModalProps {
    testId: number
    toggleOpen: () => void
}

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        gridGap: '8px',
        marginBottom: '8px',
    },
    attention: {
        backgroundColor: '#fffbdd',
        ...shorthands.padding('8px'),
        ...shorthands.borderRadius('4px'),
        marginBottom: '8px',
    },
})

const VehiclesBatchEditModal = ({
    testId,
    toggleOpen,
}: IVehiclesBatchEditModalProps) => {
    const [enableStartDate, setEnableStartDate] = useState(false)
    const [startDate, setStartDate] = useState('')

    const [enableEndDate, setEnableEndDate] = useState(false)
    const [endDate, setEndDate] = useState('')

    const [enableEscalate, setEnableEscalate] = useState(false)
    const { data: escalationSteps } = useGetEscalationStepsQuery()
    const steps = useMemo(
        () =>
            escalationSteps?.map(option => ({
                key: option.id,
                text: option.name,
            })) ?? [],
        [escalationSteps]
    )
    const [selectedEscalationStep, setSelectedEscalationStep] =
        useState<string>(steps[0]?.key.toString())

    const [enableVehicleClassification, setEnableVehicleClassification] =
        useState(false)
    const { data: vehicleClassifications } = useGetVehicleClassificationsQuery()
    const options = useMemo(
        () =>
            vehicleClassifications?.map(option => ({
                key: option.id,
                text: option.title,
            })) ?? [],
        [vehicleClassifications]
    )
    const [selectedVehicleClassification, setSelectedVehicleClassification] =
        useState<string>(options[0]?.key.toString())

    const classes = useStyles()

    const [isLatestVehicleVersionEndDate, setIsLatestVehicleVersionEndDate] =
        useState(false)
    const [
        isLatestVehicleVersionStartDate,
        setIsLatestVehicleVersionStartDate,
    ] = useState(false)

    const [startVehicleTest] = useStartVehicleTestsMutation()
    const [stopVehicleTest] = useStopVehicleTestsMutation()
    const [addBatchVehicleTestEscalation] =
        useAddBatchVehicleTestEscalationMutation()
    const [updateWorkshopVisit] = useUpdateWorkshopVisitMutation()

    const { gridRef } = useGrid()
    const selectedRows = gridRef.current?.api.getSelectedRows() ?? []

    const onEdit = async () => {
        try {
            if (enableStartDate) {
                await startVehicleTest(
                    selectedRows.map(sr => ({
                        participationId: sr.BridgeInfoId,
                        onDate: startDate,
                    }))
                )
            }

            if (enableEndDate) {
                await stopVehicleTest(
                    selectedRows.map(sr => ({
                        participationId: sr.BridgeInfoId,
                        onDate: endDate,
                    }))
                )
            }

            if (isLatestVehicleVersionStartDate) {
                await startVehicleTest(
                    selectedRows.map(sr => ({
                        participationId: sr.BridgeInfoId,
                        onDate: sr.LatestVehicleVersion,
                    }))
                )
            }

            if (isLatestVehicleVersionEndDate) {
                await stopVehicleTest(
                    selectedRows.map(sr => ({
                        participationId: sr.BridgeInfoId,
                        onDate: sr.LatestVehicleVersion,
                    }))
                )
            }

            if (enableEscalate) {
                const actorIds = selectedRows
                    .map(sr => sr.ActorId)
                    .filter(Boolean)

                if (actorIds.length) {
                    await addBatchVehicleTestEscalation({
                        vehicleTestId: testId,
                        actorIds,
                        escalationStepId: parseInt(selectedEscalationStep, 10),
                    })
                }
            }
            if (enableVehicleClassification) {
                selectedRows.forEach(sr => {
                    updateWorkshopVisit({
                        id: sr.BridgeInfoId,
                        changeSets: [
                            {
                                operation: 'Add',
                                path: 'VehicleClassification',
                                value: selectedVehicleClassification,
                            },
                        ],
                    })
                })
            }
        } catch (error) {
            toast.error(error)
        } finally {
            gridRef.current?.api.deselectAll()
            gridRef.current?.api.refreshServerSide({ purge: true })
            toggleOpen()
        }
    }

    return (
        <>
            <DialogContent>
                <div className={classes.attention}>
                    Time chosen will be in <strong>local time</strong>
                    <br />
                    Time will be saved as <strong>UTC time</strong>
                    <br />
                </div>
                <div className={classes.wrapper}>
                    <Switch
                        label='Start Date'
                        checked={enableStartDate}
                        onChange={(_ev, data) => {
                            setEnableStartDate(data.checked)
                            if (!data.checked) {
                                setStartDate(null)
                            } else {
                                setIsLatestVehicleVersionStartDate(false)
                                setStartDate(dayjs(new Date()).toString())
                            }
                        }}
                    />
                    <Switch
                        label='Use LatestVehicleVersion'
                        checked={isLatestVehicleVersionStartDate}
                        onChange={(_ev, data) => {
                            setIsLatestVehicleVersionStartDate(data.checked)
                            if (data.checked) {
                                setEnableStartDate(false)
                            }
                        }}
                    />
                </div>
                <div className={classes.wrapper}>
                    <DateTimePicker
                        clear
                        nowTime
                        copyFromClipboard
                        label='Start Date'
                        value={startDate}
                        onChange={newStartdate => {
                            setStartDate(newStartdate)
                        }}
                        disabled={!enableStartDate}
                    />
                </div>
                <div className={classes.wrapper}>
                    <Switch
                        label='End Date'
                        checked={enableEndDate}
                        onChange={(_ev, data) => {
                            setEnableEndDate(data.checked)
                            if (!data.checked) {
                                setEndDate(null)
                            } else {
                                setIsLatestVehicleVersionEndDate(false)
                                setEndDate(dayjs(new Date()).toString())
                            }
                        }}
                    />
                    <Switch
                        label='Use LatestVehicleVersion'
                        checked={isLatestVehicleVersionEndDate}
                        onChange={(_ev, data) => {
                            setIsLatestVehicleVersionEndDate(data.checked)
                            if (data.checked) {
                                setEnableEndDate(false)
                            }
                        }}
                    />
                </div>
                <div className={classes.wrapper}>
                    <DateTimePicker
                        clear
                        nowTime
                        copyFromClipboard
                        label='End Date'
                        value={endDate}
                        onChange={newEndDate => {
                            setEndDate(newEndDate)
                        }}
                        disabled={!enableEndDate}
                    />
                </div>
                <div className={classes.wrapper}>
                    <Switch
                        label='Escalation Status'
                        checked={enableEscalate}
                        onChange={(_ev, data) => {
                            setEnableEscalate(data.checked)
                        }}
                    />
                    <Select
                        onChange={(_event, option) =>
                            setSelectedEscalationStep(option.value)
                        }
                        value={selectedEscalationStep}
                        disabled={!enableEscalate}
                    >
                        {steps.map(option => (
                            <option key={option.key} value={String(option.key)}>
                                {option.text}
                            </option>
                        ))}
                    </Select>
                </div>
                <div className={classes.wrapper}>
                    <Switch
                        label='Vehicle classification'
                        checked={enableVehicleClassification}
                        onChange={(_ev, data) => {
                            setEnableVehicleClassification(data.checked)
                        }}
                    />

                    <Select
                        onChange={(_event, option) =>
                            setSelectedVehicleClassification(option.value)
                        }
                        value={selectedVehicleClassification}
                        disabled={!enableVehicleClassification}
                    >
                        {options.map(option => (
                            <option key={option.key} value={String(option.key)}>
                                {option.text}
                            </option>
                        ))}
                    </Select>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    appearance='primary'
                    onClick={onEdit}
                    disabled={
                        !(
                            enableStartDate ||
                            enableEndDate ||
                            isLatestVehicleVersionStartDate ||
                            isLatestVehicleVersionEndDate ||
                            enableEscalate ||
                            enableVehicleClassification
                        )
                    }
                >
                    Save
                </Button>
                <DialogTrigger>
                    <Button>Cancel</Button>
                </DialogTrigger>
            </DialogActions>
        </>
    )
}

export default VehiclesBatchEditModal
