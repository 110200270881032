import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    Field,
    Spinner,
    Text,
    Textarea,
    makeStyles,
} from '@fluentui/react-components'
import { useAppDispatch, useAppSelector } from 'store'
import { useEffect, useState } from 'react'

import Loader from 'components/Ui/Loader'
import ModalStatusEnum from 'constants/ModalStatusEnum'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import { selectLastOdataQuery } from 'slices/gridSlice'
import { triggerMessage } from 'slices/messageSlice'
import { useGrid } from 'contexts/GridContext'
import { useSendSmsMutation } from 'api/messaging'

interface ISMSActionsProps {
    toggleOpen: () => void
    modalStatus: ModalStatusEnum
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gridGap: '8px',
    },
})

const SMSActions = ({ toggleOpen, modalStatus }: ISMSActionsProps) => {
    const [text, setText] = useState('')
    const classes = useStyles()

    const [sendSms, { isLoading: senndingSMS }] = useSendSmsMutation()
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)

    const { gridRef } = useGrid()
    const selectedActorIds =
        gridRef.current?.api
            .getSelectedRows()
            .map(sr => sr.ActorId)
            .filter(id => !!id) || []

    const [filteredActorIds, setFilteredActorIds] = useState<string[]>([])
    const lastODataQuery = useAppSelector(selectLastOdataQuery)
    useEffect(() => {
        const collectActorIds = async () => {
            setLoading(true)

            const param = new URLSearchParams(lastODataQuery)
            param.delete('$skip')
            param.delete('$top')
            param.delete('$select')
            param.append('$select', 'ActorId')

            const { data } = await reflectApiAxiosInstance.get(
                decodeURIComponent(param.toString())
            )

            setFilteredActorIds(data.value.map(({ ActorId }) => ActorId))

            setLoading(false)
        }

        if (
            lastODataQuery &&
            modalStatus === ModalStatusEnum.Filtered &&
            filteredActorIds.length === 0
        ) {
            collectActorIds()
        }
    }, [filteredActorIds.length, lastODataQuery, loading, modalStatus])

    const driversCount =
        modalStatus === ModalStatusEnum.Selected
            ? selectedActorIds.length
            : filteredActorIds.length

    const onSend = async () => {
        const response = await sendSms({
            actorIds:
                modalStatus === ModalStatusEnum.Selected
                    ? selectedActorIds
                    : filteredActorIds,
            message: text,
        })

        if ('data' in response) {
            const failedNumbers = response.data.failedNumberValidations
            if (failedNumbers.length) {
                dispatch(
                    triggerMessage({
                        intent: 'error',
                        title: 'Error sending SMS',
                        message: `Failed to send SMS to these numbers: ${failedNumbers.join(
                            ' , '
                        )}`,
                    })
                )
            } else {
                dispatch(
                    triggerMessage({
                        intent: 'success',
                        title: 'SMS sent',
                        message: 'SMS has been sent successfully.',
                    })
                )
                setText('')
                toggleOpen()
            }
        }
    }

    return (
        <>
            <DialogContent>
                {loading ? (
                    <Loader text='Fetching filtered data...' />
                ) : (
                    <div className={classes.container}>
                        <Field label='Message'>
                            <Textarea
                                rows={5}
                                value={text ?? ''}
                                onChange={(_ev, data) =>
                                    setText(data?.value || '')
                                }
                                resize='vertical'
                            />
                        </Field>

                        <Text>
                            Sending SMS to <b>{driversCount} driver(s).</b>
                        </Text>
                    </div>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    appearance='primary'
                    onClick={onSend}
                    disabled={
                        loading || !text || driversCount <= 0 || senndingSMS
                    }
                >
                    {senndingSMS ? <Spinner /> : 'Send SMS'}
                </Button>
                <DialogTrigger disableButtonEnhancement>
                    <Button onClick={() => toggleOpen()}>Cancel</Button>
                </DialogTrigger>
            </DialogActions>
        </>
    )
}

export default SMSActions
