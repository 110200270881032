import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { RootState } from 'store'

type Message = {
    title?: string
    message?: string
    intent: 'success' | 'warning' | 'error' | 'info'
    id?: string
}

interface IMessageBarState {
    messages: Message[]
}

const initialState: IMessageBarState = {
    messages: [],
}

const messageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        addMessage: (state, action) => {
            state.messages = [...state.messages, action.payload]
        },
        removeMessage: (state, action) => {
            state.messages = state.messages.filter(
                message => message.id !== action.payload
            )
        },
    },
})

export const { addMessage, removeMessage } = messageSlice.actions

export const triggerMessage = createAsyncThunk(
    'message/triggerMessage',
    async (message: Message, { dispatch }) => {
        const id = Math.random().toString(36).substring(2, 9)
        dispatch(addMessage({ ...message, id }))
        setTimeout(() => dispatch(removeMessage(id)), 10000)
    }
)

export const selectMessages = (state: RootState) => state.message.messages

export default messageSlice.reducer
