import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    MessageBar,
    Spinner,
} from '@fluentui/react-components'
import { useEffect, useState } from 'react'

import AxiosStateTypeEnum from 'constants/AxiosStateTypeEnum'
import PGWCollectionFormFields from './PGWCollectionFormFields'
import { permissions } from 'slices/authSlice'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import sanitizeInput from 'helpers/sanitizeInput'
import { selectLastOdataQuery } from 'slices/gridSlice'
import { toast } from 'react-toastify'
import { useAppSelector } from 'store'
import { useGrid } from 'contexts/GridContext'

type AddPGWCollectionProps = {
    toggleOpen: () => void
}

const defaultPGWCollectionObject: IPGWCollection = {
    id: undefined,
    name: undefined,
    description: undefined,
    collectionType: 'CUSTOMER',
    vinsList: undefined,
}

const AddPGWCollection = ({ toggleOpen }: AddPGWCollectionProps) => {
    const hasCodevPermission = useAppSelector(permissions).otaCodev
    const [collection, setCollection] = useState<IPGWCollection>(
        defaultPGWCollectionObject
    )
    const [addCollectionState, setAddCollectionsState] =
        useState<AxiosStateTypeEnum>(AxiosStateTypeEnum.Unknown)
    const [
        fetchAllVinsFromFilteredVehicles,
        setFetchAllVinsFromFilteredVehicles,
    ] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false)

    const { gridRef } = useGrid()
    const lastODataQuery = useAppSelector(selectLastOdataQuery)

    const [dialogSubText, setDialogSubText] = useState('')

    useEffect(() => {
        collection.vinsList =
            (gridRef.current?.api
                .getSelectedRows()
                .map(sr => sr.Vin || '') as string[]) || []
    }, [collection, gridRef])

    const createCollection = (collectionObject: IPGWCollection) => {
        const sanitizedCollection = sanitizeInput(collectionObject)

        reflectApiAxiosInstance
            .post('/api/PGWCollection', sanitizedCollection)
            .then(() => {
                setAddCollectionsState(AxiosStateTypeEnum.Success)
            })
            .catch(() => {
                setAddCollectionsState(AxiosStateTypeEnum.Error)
            })
            .finally(() => setIsLoading(false))
    }

    const collectAllVins = async (collectionObject: IPGWCollection) => {
        const param = new URLSearchParams(
            lastODataQuery?.replace(
                'odata/VehicleViews',
                'odata/admin/VehicleViews'
            )
        )
        param.delete('$skip')
        param.delete('$top')
        param.delete('$select')
        param.append('$select', 'Vin')

        if (hasCodevPermission) {
            param.append('$filter', `CarUsage ne '${'NotSpecified'}'`)
        }

        reflectApiAxiosInstance
            .get(decodeURIComponent(param.toString()))
            .then(response => response?.data?.value)
            .then(data => {
                const aggregateCollection: IPGWCollection = {
                    ...collectionObject,
                    vinsList: data.map(d => d.Vin),
                }

                createCollection(aggregateCollection)
            })
            .catch(e => {
                toast.error(e)
                setIsLoading(false)
            })
    }

    const onAddCollection = () => {
        if (fetchAllVinsFromFilteredVehicles) {
            setIsLoading(true)

            collectAllVins(collection)
        } else {
            createCollection(collection)
        }
    }

    useEffect(() => {
        setDialogSubText(
            addCollectionState === AxiosStateTypeEnum.Success
                ? ''
                : 'Enter collection details'
        )
    }, [addCollectionState])

    const tryAgain = () => setAddCollectionsState(AxiosStateTypeEnum.Unknown)

    return (
        <>
            {isLoading && <Spinner />}
            {addCollectionState === AxiosStateTypeEnum.Unknown && (
                <>
                    <DialogContent>
                        {dialogSubText}
                        <PGWCollectionFormFields
                            collection={collection}
                            setCollection={setCollection}
                            setFetchAllVinsFromFilteredVehicles={
                                setFetchAllVinsFromFilteredVehicles
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance='primary'
                            id='addCollectionAction'
                            disabled={
                                isLoading ||
                                !(collection.name && collection.description)
                            }
                            onClick={onAddCollection}
                        >
                            Add Collection
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button disabled={isLoading} onClick={toggleOpen}>
                                Cancel
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}

            {addCollectionState === AxiosStateTypeEnum.Success && (
                <>
                    <DialogContent>
                        <MessageBar intent='success'>
                            Collection added successfully.
                        </MessageBar>
                    </DialogContent>

                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button disabled={isLoading} onClick={toggleOpen}>
                                Close
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}

            {addCollectionState === AxiosStateTypeEnum.Error && (
                <>
                    <DialogContent>
                        <MessageBar intent='error'>
                            Failed to add collection, please retry. Contact
                            technical support if the error persists.
                        </MessageBar>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance='primary'
                            disabled={isLoading}
                            onClick={tryAgain}
                        >
                            Try again
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button disabled={isLoading} onClick={toggleOpen}>
                                Cancel
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}
        </>
    )
}

export default AddPGWCollection
