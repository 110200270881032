import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Field,
    Input,
    makeStyles,
} from '@fluentui/react-components'

import { Add24Regular } from '@fluentui/react-icons'
import { toast } from 'react-toastify'
import { useAddVehicleClassificationsMutation } from 'api/vehicleTests'
import { useState } from 'react'

const useStyles = makeStyles({
    input: {
        width: '100%',
        marginBottom: '8px',
    },
})

const AddClassificationModal = () => {
    const [open, setOpen] = useState<boolean>(false)
    const [title, setTitle] = useState<string>()
    const classes = useStyles()

    const [addVehicleClassifications, { isLoading }] =
        useAddVehicleClassificationsMutation()

    const handleSubmit = async () => {
        const response = await addVehicleClassifications(title)

        if ('data' in response && response.data) {
            setTitle(null)
            setOpen(false)
        } else {
            toast.error('Something went wrong, please try again.')
        }
    }

    return (
        <Dialog open={open} onOpenChange={(_e, data) => setOpen(data.open)}>
            <DialogTrigger disableButtonEnhancement>
                <Button
                    data-cy='add-classification-button'
                    appearance='subtle'
                    icon={<Add24Regular />}
                    size='small'
                >
                    Add Classification
                </Button>
            </DialogTrigger>
            <DialogSurface>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Add Classification</DialogTitle>
                        <DialogContent>
                            <Field required label='Title'>
                                <Input
                                    data-cy='vehicle-classification-name-input'
                                    className={classes.input}
                                    name='Title'
                                    value={title ?? ''}
                                    onChange={(_e, data) =>
                                        setTitle(data.value)
                                    }
                                    disabled={isLoading}
                                />
                            </Field>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                data-cy='save-classification-button'
                                appearance='primary'
                                onClick={handleSubmit}
                                disabled={isLoading}
                            >
                                Save
                            </Button>
                            <DialogTrigger disableButtonEnhancement>
                                <Button>Cancel</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    )
}

export default AddClassificationModal
