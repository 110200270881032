/**
 * Check if a string is an uuid
 * @param string the string to check
 * @returns boolean
 */
const isUuid = (string: string): boolean => {
    const uuidPattern = /^[a-f\d]{8}-(?:[a-f\d]{4}-){3}[a-f\d]{12}$/i
    return typeof string === 'string' && uuidPattern.test(string)
}

export default isUuid
