import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

import { acquireApiAccessToken } from 'authInstance'

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_REFLECT_BACKEND_API,
        prepareHeaders: async headers => {
            const token = await acquireApiAccessToken()
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),
    endpoints: () => ({}),
})

export const rivenDellApiSlice = createApi({
    reducerPath: 'rivendellApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_RIVENDELL_BACKEND_API,
        prepareHeaders: async headers => {
            const token = await acquireApiAccessToken()
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),
    endpoints: () => ({}),
})
