import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    MessageBar,
} from '@fluentui/react-components'

import { Delete24Regular } from '@fluentui/react-icons'
import { insertSpaces } from 'helpers/stringHelpers'
import { useDeleteVehicleGroupListsMutation } from 'api/vehicleGroupList'
import { useGrid } from 'contexts/GridContext'
import { useState } from 'react'

type VehicleGroupListDeleteModalProps = {
    data: { id: string; name: string }
    vehicleGroupType: VehicleGroupListSupportedPages
}

const VehicleGroupListDeleteModal = ({
    data,
    vehicleGroupType,
}: VehicleGroupListDeleteModalProps) => {
    const { gridRef } = useGrid()
    const [isOpen, setIsOpen] = useState(false)

    const [deleteVehicleGroupLists, { isUninitialized, isLoading, isError }] =
        useDeleteVehicleGroupListsMutation()

    const updateGridFilter = (filterName: string) => {
        gridRef.current?.api.refreshServerSide()

        // Update the filter options, the name of the filter is the plural of the vehicleGroupType
        // and for some reason (!) we have to use 'getFilterInstance' twice otherwise, it won't work
        gridRef.current?.api.getFilterInstance(filterName, () => {
            gridRef.current?.api.getFilterInstance(filterName, filter => {
                return filter.setModel({
                    allOptions: [
                        ...(filter.getModel()
                            ? filter
                                  .getModel()
                                  .allOptions.filter(
                                      (option: string) => option !== data?.name
                                  )
                                  .map(item =>
                                      item === 'null' ? '(Blancks)' : item
                                  )
                            : []),
                    ],
                })
            })
        })
    }

    const deleteVehicleGroupList = async () => {
        await deleteVehicleGroupLists({
            vehicleGroupType,
            vehicleGroupListId: data?.id,
        }).then(() => {
            updateGridFilter(`${vehicleGroupType}s`)
            setIsOpen(false)
        })
    }

    return (
        <>
            <Dialog open={isOpen} onOpenChange={(e, d) => setIsOpen(d.open)}>
                <DialogTrigger>
                    <Button
                        icon={<Delete24Regular />}
                        title='Delete'
                        appearance='transparent'
                        aria-label={`Delete ${insertSpaces(vehicleGroupType)}`}
                    />
                </DialogTrigger>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>{`Delete ${insertSpaces(
                            vehicleGroupType
                        )} ${data?.name}`}</DialogTitle>
                        <DialogContent>
                            {isUninitialized && (
                                <div>
                                    <p>
                                        This will delete the
                                        {insertSpaces(vehicleGroupType)}
                                        {data?.name}.
                                    </p>
                                    <p>
                                        This action cannot be undone. Are you
                                        sure you want to proceed and delete the
                                        {insertSpaces(vehicleGroupType)}?
                                    </p>
                                </div>
                            )}
                            {isLoading && (
                                <MessageBar intent='info'>
                                    Deleting {insertSpaces(vehicleGroupType)}...
                                </MessageBar>
                            )}
                            {isError && (
                                <MessageBar intent='error'>
                                    Failed to delete{' '}
                                    {insertSpaces(vehicleGroupType)}. Please
                                    retry, contact technical support if the
                                    error persists.
                                </MessageBar>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => deleteVehicleGroupList()}
                                appearance='primary'
                            >
                                Confirm
                            </Button>

                            <DialogTrigger disableButtonEnhancement>
                                <Button onClick={() => setIsOpen(false)}>
                                    Cancel
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    )
}

export default VehicleGroupListDeleteModal
