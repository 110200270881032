import { ICellRendererParams } from 'ag-grid-community'

const DateRenderer = ({ value }: ICellRendererParams) => {
    if (value) {
        return new Date(value).toLocaleDateString()
    }

    return ''
}

export default DateRenderer
