import {
    Avatar,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components'
import { msalInstance, signOutClickHandler } from 'authInstance'

import { LockClosedKeyFilled } from '@fluentui/react-icons'
import { clearState } from 'helpers/localStorage'

const UserMenu = () => {
    const handleLogout = async () => {
        clearState()
        signOutClickHandler()
    }

    const account = msalInstance.getAllAccounts()[0]

    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <Avatar
                    data-cy='user-panel-button'
                    color='colorful'
                    name={account?.name || ''}
                />
            </MenuTrigger>

            <MenuPopover>
                <MenuList data-cy='user-panel'>
                    <MenuItem
                        icon={<LockClosedKeyFilled />}
                        onClick={handleLogout}
                    >
                        Sign out
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    )
}

export default UserMenu
