import { ClearFormatting24Regular, Open24Regular } from '@fluentui/react-icons'
import DeploymentJobActionButton, {
    DeploymentJobActionButtonProps,
} from './CellRenderers/DeploymentJobActionButton'
import { useEffect, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import { toast } from 'react-toastify'

type FlatDeploymentJob = {
    deploymentJobUuId: string
    deploymentJobNumber: number
    deploymentJobName: string
    vehicleCollectionName: string
}

const mapRowData = (deploymentjobs: Array<PGWDeploymentJobResponseType>) => {
    const flattenedData: Array<FlatDeploymentJob | undefined> =
        deploymentjobs.flatMap(data =>
            data.vehicleCollections?.map(vehicleCollection => ({
                deploymentJobUuId: data.deploymentJobUuId,
                deploymentJobName: data.deploymentJobName,
                deploymentJobNumber: data.deploymentJobId,
                vehicleCollectionName: vehicleCollection.name,
            }))
        )
    return flattenedData
}

const SyncDeploymentJobsTab = () => {
    const [rowData, setRowData] = useState<
        Array<FlatDeploymentJob | undefined>
    >([])
    const [deploymentjobsUpdated, setBaselinesUpdated] =
        useState<boolean>(false)

    const onGridReady = () => {
        if (rowData && rowData.length === 0) {
            reflectApiAxiosInstance
                .get('/api/PGWCollection/deploymentjobs')
                .then(response => {
                    const deploymentJobs: Array<PGWDeploymentJobResponseType> =
                        response.data
                    setRowData(mapRowData(deploymentJobs))
                })
                .catch(error => toast.error(error))
        }
    }

    const [syncDeploymentJobInfo, dispatchSyncDeploymentJob] = useState<
        { name: string; id: string } | undefined
    >()
    const [clearDeploymentJobInfo, dispatchClearDeploymentJob] = useState<
        { name: string; id: string } | undefined
    >()

    const columnDefs: ColDef[] = [
        {
            field: 'deploymentJobName',
            rowGroup: true,
            hide: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
        },
        {
            field: 'vehicleCollectionName',
        },
        {
            field: 'id',
            headerName: 'Sync',
            maxWidth: 70,
            minWidth: 70,
            initialWidth: 70,
            width: 66,
            colId: 'editModalOpener',
            lockPosition: true,
            lockPinned: true,
            pinned: 'right',
            suppressMenu: true,
            suppressColumnsToolPanel: true,
            cellRenderer: DeploymentJobActionButton,
            cellRendererParams: (params: DeploymentJobActionButtonProps) => ({
                params,
                deploymentJobUuId:
                    params.node.group && params.node.level === 0
                        ? params.node.allLeafChildren[0].data.deploymentJobUuId
                        : undefined,
                deploymentJobName:
                    params.node.group && params.node.level === 0
                        ? params.node.allLeafChildren[0].data.deploymentJobName
                        : undefined,
                dispatchAction: dispatchSyncDeploymentJob,
                icon: <Open24Regular />,
                label: 'Click to re-sync vehicles from PGW',
                rootGroup: params.node.group && params.node.level === 0,
            }),
        },
        {
            headerName: 'Clear',
            maxWidth: 70,
            minWidth: 70,
            initialWidth: 70,
            width: 66,
            field: 'id',
            colId: 'editModalOpener',
            lockPosition: true,
            lockPinned: true,
            pinned: 'right',
            suppressMenu: true,
            suppressColumnsToolPanel: true,
            cellRenderer: DeploymentJobActionButton,
            cellRendererParams: (params: DeploymentJobActionButtonProps) => ({
                params,
                deploymentJobUuId:
                    params.node.group && params.node.level === 0
                        ? params.node.allLeafChildren[0].data.deploymentJobUuId
                        : undefined,
                deploymentJobName:
                    params.node.group && params.node.level === 0
                        ? params.node.allLeafChildren[0].data.deploymentJobName
                        : undefined,
                dispatchAction: dispatchClearDeploymentJob,
                icon: <ClearFormatting24Regular />,
                label: 'Click to clear all Reflect-vehicles mapped to this deployment job NEW',
                rootGroup: params.node.group && params.node.level === 0,
            }),
        },
    ]

    useEffect(() => {
        if (syncDeploymentJobInfo && syncDeploymentJobInfo.id) {
            const postExclusionList: PGWDeploymentJobActionType = {
                deploymentJobName: syncDeploymentJobInfo.name,
                deploymentJobId: syncDeploymentJobInfo.id,
                skipSync: false,
            }

            reflectApiAxiosInstance
                .post('/api/PGWCollection/deploymentjobs', postExclusionList)
                .catch(error => toast.error(error))
        }
    }, [syncDeploymentJobInfo])

    useEffect(() => {
        if (clearDeploymentJobInfo && clearDeploymentJobInfo.id) {
            const postExclusionList: PGWDeploymentJobActionType = {
                deploymentJobName: clearDeploymentJobInfo.name,
                deploymentJobId: clearDeploymentJobInfo.id,
                skipSync: true,
            }

            reflectApiAxiosInstance
                .post('/api/PGWCollection/deploymentjobs', postExclusionList)
                .catch(error => toast.error(error))
        }
    }, [clearDeploymentJobInfo])

    useEffect(() => {
        if (deploymentjobsUpdated) {
            setBaselinesUpdated(false)

            reflectApiAxiosInstance
                .get('/api/PGWCollection/deploymentjobs')
                .then(response => {
                    const deploymentJobs: Array<PGWDeploymentJobResponseType> =
                        response.data
                    setRowData(mapRowData(deploymentJobs))
                })
                .catch(error => toast.error(error))
        }
    }, [deploymentjobsUpdated])

    return (
        <div style={{ height: '600px' }} className='ag-theme-alpine'>
            <AgGridReact
                defaultColDef={{
                    flex: 1,
                    minWidth: 100,
                    sortable: true,
                    resizable: true,
                }}
                animateRows
                onGridReady={onGridReady}
                rowData={rowData}
                columnDefs={columnDefs}
                groupDisplayType='multipleColumns'
                sideBar={{
                    defaultToolPanel: 'filters',
                    toolPanels: [
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel',
                            toolPanelParams: {
                                suppressExpandAll: true,
                                suppressFilterSearch: false,
                            },
                        },
                    ],
                }}
            />
        </div>
    )
}

export default SyncDeploymentJobsTab
