/* eslint-disable max-len */

/**
 * Check if a string is formatted as an email address.
 * @param email the string to check
 * @returns boolean
 */
const isValidEmail = (email: string): boolean => {
    /**
     * RegExp check for emails:
     * - Allows one letter TLD's according to spec
     * - Sets allowed characters
     * - Correctly limits the lenght of the different parts of an email address (except local part)
     * - Allows TLD's with numbers, unless they begin with a number (not allowed in spec)
     * - Limits each set after @ to 64 characters according to spec
     * - Tested against examples here: https://stackoverflow.com/a/38787343/6477949
     * - Allows for local@[IPv4-address]
     * - Disallows beginning with .
     * - Handles a lot more rules...
     *
     * Current limitations:
     * - Does not handle maximum number of chars before @
     * - Does not handle local@[IPv6-address]
     * - Does not handle if part before @ ends with .
     */
    const re =
        /^((?!\.)(?!.*\.\.)(([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~'-]+(\.[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~'-]+)*)|(".+")))@(((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]{0,61}\.)+([a-zA-Z][a-zA-Z0-9]{0,61}))))$/
    return re.test(String(email).toLowerCase())
}

export default isValidEmail
