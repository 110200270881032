import 'react-quill/dist/quill.snow.css'

import {
    Avatar,
    Button,
    DialogActions,
    DialogContent,
    Divider,
    Field,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import { Dispatch, SetStateAction, useState } from 'react'

import ConfirmActionDialog from './ConfirmActionDialog'
import { toast } from 'react-toastify'
import { useEditNewsItemMutation } from 'api/portalNews'
import { useGrid } from 'contexts/GridContext'

const useStyles = makeStyles({
    image: {
        width: '130px',
        height: '130px',
        ...shorthands.margin('5px'),
    },
    thumbnail: {
        width: '80px',
        height: '80px',
        ...shorthands.margin('5px'),
    },
    preview: {
        display: 'flex',
        width: '540px',
        height: '200px',
        ...shorthands.overflow('hidden'),
        justifyContent: 'space-between',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('15px'),
        width: '100%',
        gridColumnStart: 1,
        gridColumnEnd: 4,

        '& > div:last-of-type': {
            marginTop: '1rem',
            alignSelf: 'flex-end',
        },
    },
})
interface IPreviewPublishNews {
    selected: number
    setSelected: Dispatch<SetStateAction<number>>
    newsItem: IPortalNews
    setOpen: Dispatch<SetStateAction<boolean>>
    selectedNewsList: IVehicleGroup[]
    selectedCountries: string[]
    publishDate: string
    unPublishDate: string
}

const PreviewPublishNews = ({
    newsItem,
    selected,
    setSelected,
    setOpen,
    selectedNewsList,
    selectedCountries,
    publishDate,
    unPublishDate,
}: IPreviewPublishNews) => {
    const classes = useStyles()
    const { gridRef } = useGrid()
    const [showCancelDialog, setShowCancelDialog] = useState(false)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [editNewsItem, { isLoading }] = useEditNewsItemMutation()

    const totalVehicleCount = selectedNewsList
        .filter(vc => vc?.vehicleCount > 0)
        .reduce((acc, vc) => acc + (vc?.vehicleCount || 0), 0)

    const onPublish = async () => {
        try {
            await editNewsItem({
                Id: selected,
                Title: newsItem.Title,
                Text: newsItem.Text,
                Introduction: newsItem.Introduction,
                ImageUrl: newsItem.ImageUrl,
                ThumbnailUrl: newsItem.ThumbnailUrl,
                PublishDate: publishDate,
                UnPublishDate: unPublishDate,
                Countries: selectedCountries,
                NewsLists: selectedNewsList.map(
                    item => item.id
                ) as unknown as IVehicleGroup[],
            })
        } catch (error) {
            toast.error(error)
        } finally {
            gridRef.current?.api.refreshServerSide({ purge: true })
            toast.success('Successfully published news item')
            setSelected(-1)
            setOpen(false)
        }
    }

    const onCancel = async () => {
        setShowCancelDialog(false)
        setOpen(false)
    }

    return (
        <>
            <DialogContent className={classes.root}>
                <div style={{ display: 'flex' }}>
                    <span style={{ fontWeight: 'bold' }}>Preview</span>
                    <span style={{ marginLeft: 'auto' }}>
                        Recipients: {totalVehicleCount}
                    </span>
                </div>
                <Field>
                    <Divider />
                    <div className={classes.preview}>
                        <>
                            <div
                                key={newsItem?.Id}
                                style={{
                                    maxWidth: '300px',
                                    whiteSpace: 'initial',
                                    wordWrap: 'break-word',
                                }}
                            >
                                <h2>{newsItem?.Title}</h2>
                                <span>{newsItem?.Introduction}</span>
                                <span>{newsItem?.Text}</span>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Avatar
                                    shape='square'
                                    className={classes.image}
                                    image={{ src: newsItem?.ImageUrl || '' }}
                                />
                                <Avatar
                                    shape='square'
                                    className={classes.thumbnail}
                                    image={{
                                        src: newsItem?.ThumbnailUrl || '',
                                    }}
                                />
                            </div>
                        </>
                    </div>
                </Field>
                <DialogActions fluid>
                    <Button
                        appearance='primary'
                        onClick={() => setShowConfirmDialog(true)}
                        disabled={
                            (!selectedCountries.length &&
                                !selectedNewsList.length) ||
                            !publishDate ||
                            isLoading
                        }
                    >
                        {isLoading ? 'Loading...' : 'Publish'}
                    </Button>
                    {showConfirmDialog && (
                        <ConfirmActionDialog
                            setIsOpen={setShowConfirmDialog}
                            isOpen={showConfirmDialog}
                            callback={onPublish}
                            action='publish'
                            recipients={totalVehicleCount}
                            isLoading={isLoading}
                            countryCount={selectedCountries.length}
                        />
                    )}
                    <Button onClick={() => setShowCancelDialog(true)}>
                        Cancel
                    </Button>
                    {showCancelDialog && (
                        <ConfirmActionDialog
                            setIsOpen={setShowCancelDialog}
                            isOpen={showCancelDialog}
                            callback={onCancel}
                            action='cancel the publishing of'
                        />
                    )}
                </DialogActions>
            </DialogContent>
        </>
    )
}

export default PreviewPublishNews
