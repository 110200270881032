import {
    FilterChangedEvent,
    GetContextMenuItemsParams,
    GridReadyEvent,
} from 'ag-grid-community'
import {
    defaultColDef,
    getContextMenuItems,
    getCsvFilters,
    sideBar,
    statusBar,
} from 'helpers/gridHelper'

import ActionsMenu from 'components/Ui/Layout/ActionsMenu'
import AgGridContainer from 'components/Ui/Layout/AgGridContainer'
import { AgGridReact } from 'ag-grid-react'
import Content from 'components/Ui/Layout/Content'
import Header from 'components/Ui/Layout/Header'
import { Person24Regular } from '@fluentui/react-icons'
import { VehicleTestOdataProvider } from 'helpers/vehicleTestsGridHelper'
import { columnDefs } from 'helpers/driverGridHelper'
import { getOdataApi } from 'helpers/oDataApiHelper'
import qs from 'qs'
import routes from 'constants/routes'
import { useGrid } from 'contexts/GridContext'
import useGridFilterUrl from 'hooks/useGridFilterUrl'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'

const Drivers = () => {
    const [pushFiltersToHistory, setFiltersFromHistory] = useGridFilterUrl()
    const navigate = useNavigate()
    const gridRef = useRef()
    const { setGridRef } = useGrid()

    const onGridReady = (params: GridReadyEvent) => {
        params.api.setServerSideDatasource(
            new VehicleTestOdataProvider({
                callApi: async options =>
                    getOdataApi<IDriverStudyDriver[]>(
                        `/odata/DriverStudyDriverViews${options}`
                    ).then(response => response.data),
                customFilters: { ...getCsvFilters(['Cdsid']) },
            })
        )
        setFiltersFromHistory(params.api)
        setGridRef(gridRef)
    }

    const onFilterChanged = (event: FilterChangedEvent) => {
        pushFiltersToHistory(event.api)
    }

    const contextMenu = (params: GetContextMenuItemsParams) => {
        const selectedRows = params.api.getSelectedRows()
        return [
            ...getContextMenuItems(params),
            selectedRows.length > 0 && 'separator',
            selectedRows.length > 0 && {
                name: 'Filter by selection',
                icon: '<span class="ag-icon ag-icon-filter" role="presentation"></span>',
                tooltip: 'Filter by the selected rows Vins.',
                action: () => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    params.api.getFilterInstance('Cdsid', (ctx: any) => {
                        const selected = selectedRows
                            .map(row => row.Cdsid)
                            .join(',')
                        ctx.filterBySelection(selected)
                    })
                },
                disabled: !params.api.getSelectedRows().length,
            },
            selectedRows.length > 0 && {
                name: 'Share selection',
                icon: '<span class="ag-icon ag-icon-copy" role="presentation"></span>',
                tooltip:
                    'Will copy the url with the selected id filter to the clipboard.',
                action: () => {
                    const filters = {
                        Cdsid: {
                            value: selectedRows.map(row => row.Cdsid).join(','),
                        },
                    }
                    const search = qs.stringify(filters)
                    const url = `${window.location.origin}/${routes.DRIVERS}?filters=${search}`
                    navigator.clipboard.writeText(url)
                },
            },
            selectedRows.length > 0 && {
                name: 'Show vehicles(s) for selected driver(s)',
                action: () => {
                    navigate(
                        `/?filters=Cdsid%5Bvalue%5D=${selectedRows
                            .map(r => r.Cdsid)
                            .join(',')}`
                    )
                },
            },
        ].filter(Boolean)
    }

    return (
        <>
            <Header
                title='Drivers'
                icon={<Person24Regular />}
                personalViewType='Driver'
            />
            <ActionsMenu />
            <Content>
                <AgGridContainer>
                    <AgGridReact
                        className='cy-drivers-grid'
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        enableRangeSelection
                        getContextMenuItems={contextMenu}
                        onFilterChanged={onFilterChanged}
                        onGridReady={onGridReady}
                        ref={gridRef}
                        rowModelType='serverSide'
                        rowSelection='multiple'
                        sideBar={sideBar}
                        statusBar={statusBar}
                    />
                </AgGridContainer>
            </Content>
        </>
    )
}

export default Drivers
