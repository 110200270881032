import OdataProvider from 'ag-grid-odata'

export class ExtendedOdataProvider extends OdataProvider {
    odataOperator = {
        ...this.odataOperator,
        blank: (col: string) => `${col} eq null`,
        blanks: (col: string) => `${col} eq null`,
        vehicleGroupsFilter: ({
            values,
            groupTypeId,
        }: {
            values: string[]
            groupTypeId: string
        }) => {
            if (!values.length) return null

            const logic = ' OR '

            const filters = []
            if (values.includes(null)) {
                filters.push(
                    `(not VehicleVehicleGroups/any(c:c/VehicleGroup/GroupTypeId eq ${groupTypeId}))`
                )
            }
            if (values.filter(Boolean).length > 0) {
                filters.push(
                    `(VehicleVehicleGroups/any(c:c/VehicleGroup/GroupTypeId eq ${groupTypeId} and (${values
                        .filter((value: string) => value !== null)
                        .map(
                            (value: string) =>
                                `c/VehicleGroup/Name eq '${this.encode(
                                    encodeURIComponent(value)
                                )}'`
                        )
                        .join(logic)})))`
                )
            }

            return `(${filters.join(logic)})`
        },
        vehicleTestsFilter: ({
            values,
            status,
        }: {
            values: string[]
            status: string
        }) => {
            if (!values.length) return null

            const logic = ' OR '

            const filters = []
            if (values.includes(null)) {
                filters.push(
                    `(not VehicleTestWorkshops/any(c:c/TestPurpose/Status eq '${status}'))`
                )
            }
            if (values.filter(Boolean).length > 0) {
                filters.push(
                    `(VehicleTestWorkshops/any(c:c/TestPurpose/Status eq '${status}' and c/Deleted eq false and (${values
                        .filter((value: string) => value !== null)
                        .map(
                            (value: string) =>
                                `c/TestPurpose/Title eq '${this.encode(
                                    encodeURIComponent(value)
                                )}'`
                        )
                        .join(logic)})))`
                )
            }

            return `(${filters.join(logic)})`
        },
        driverStudiesFilter: ({
            values,
            status,
        }: {
            values: string[]
            status: string
        }) => {
            if (!values.length) return null

            const logic = ' OR '

            const filters = []
            if (values.includes(null)) {
                filters.push(
                    `(not DriverStudiesAssignments/any(c:c/Reflect_Spread/Status eq '${status}'))`
                )
            }
            if (values.filter(Boolean).length > 0) {
                filters.push(
                    `(DriverStudiesAssignments/any(c:c/Reflect_Spread/Status eq '${status}' and (${values
                        .filter((value: string) => value !== null)
                        .map(
                            (value: string) =>
                                `c/Reflect_Spread/Title eq '${this.encode(
                                    encodeURIComponent(value)
                                )}'`
                        )
                        .join(logic)})))`
                )
            }

            return `(${filters.join(logic)})`
        },
        consentCategoryFilter: (values: string[]) =>
            `BridgeActorVehicles/any(c:c/ActorTypeId eq 'PrimaryDriver' and c/ConsentCategoryTypeId in (${values
                .map(value => `'${value}'`)
                .join(',')}))`,
        feedbackToolsFilter: (values: string[]) =>
            `BridgeActorVehicles/any(c:c/Actor/BridgeActorFeedbackTools/any(x:x/FeedbackToolTypeId in (${values
                .map(value => `'${value}'`)
                .join(',')})))`,
        guidFilter: (col: string, value: string) => `${col} ${value}`,
        inCollection: (col: string, values: string[]) =>
            values
                .map(
                    (value, index) =>
                        `${col} in ('${this.encode(
                            encodeURIComponent(value)
                        )}')${index + 1 < values.length ? ' OR ' : ''}`
                )
                .join(''),
        inWithNull: (col: string, values: string[]) =>
            `${col} in (${values
                .map(x => (x !== null ? `'${this.encode(x)}'` : `${x}`))
                .join()})`,
        inWithNullAndUrlEncode: (col: string, values: string[]) =>
            `${col} in (${values
                .map(x =>
                    x !== null
                        ? `'${this.encode(encodeURIComponent(x))}'`
                        : `${x}`
                )
                .join()})`,
        inWithUrlEncode: (col: string, values: string[]) =>
            `${col} in (${values
                .map(x => `${this.encode(encodeURIComponent(x))}`)
                .join()})`,
        csvFilter: (col: string, values: string) =>
            `(${values
                .replace(/\s/g, '')
                .split(',')
                .filter(Boolean)
                .map(v => `${col} eq '${v}'`)
                .join(' or ')})`,
        notBlank: (col: string) => `${col} ne null`,
    }
}
