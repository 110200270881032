import { apiSlice } from './apiSlice'
import { lowerCaseKeys } from 'helpers/objectManipulation'

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: [
        'VehicleTests',
        'VehicleTest',
        'vehicleClassifications',
        'VehicleTestEscalations',
    ],
})

const vehicleTestApi = apiWithTags.injectEndpoints({
    endpoints: builder => ({
        getVehicleTests: builder.query<IVehicleTest[], string | void>({
            query: (options = '') => `odata/VehicleTests${options}`,
            transformResponse: (
                response: IODataResponse<IVehicleTestOdata[]>
            ) => lowerCaseKeys(response.value) as unknown as IVehicleTest[],
            providesTags: ['VehicleTests'],
        }),
        getVehicleTestById: builder.query<IVehicleTest, string>({
            query: id =>
                `odata/VehicleTests?$filter=Deleted eq false and Id eq ${id}`,
            transformResponse: (
                response: IODataResponse<IVehicleTestOdata[]>
            ) => lowerCaseKeys(response.value[0]) as unknown as IVehicleTest,
        }),
        getTestsOfVehiclesByVehicleId: builder.query<
            IVehicleTest[] | { vehicleStatus: string }[],
            string
        >({
            query: id =>
                `odata/BridgeVehicle?filter=vehicleInfo/Id
                 eq ${id} and bridgeInfo/Deleted eq false 
                 and bridgeInfo/TestPurpose/Deleted eq false and bridgeInfo/TestPurpose/Canceled eq false
                 & compute=bridgeInfo/TestPurpose/Id as Id,
                   bridgeInfo/TestPurpose/Title as Title,
                   bridgeInfo/TestPurpose/Description as Description,
                   bridgeInfo/StartDate as StartDate,
                   bridgeInfo/EndDate as EndDate,
                   bridgeInfo/TestPurpose/Status as Status,bridgeInfo/TestStatus as VehicleStatus
                 &select=Id,Title,Description,StartDate,EndDate,Status,VehicleStatus`,
            transformResponse: (
                response: IODataResponse<IVehicleTestOdata[]>
            ) => lowerCaseKeys(response.value) as unknown as IVehicleTest[],
        }),
        archiveVehicleTest: builder.mutation<void, number>({
            query: id => ({
                url: `api/VehicleTests/${id}/Archive`,
                method: 'POST',
            }),
            invalidatesTags: ['VehicleTests'],
        }),
        unArchiveVehicleTest: builder.mutation<void, number>({
            query: id => ({
                url: `api/VehicleTests/${id}/UnArchive`,
                method: 'POST',
            }),
            invalidatesTags: ['VehicleTests'],
        }),
        addVehicleTest: builder.mutation<IVehicleTest, IVehicleTest>({
            query: vehicleTest => ({
                url: 'api/VehicleTests',
                method: 'POST',
                body: vehicleTest,
            }),
            invalidatesTags: ['VehicleTests'],
        }),
        editVehicleTest: builder.mutation<
            IVehicleTest,
            { id: number; vehicleTest: IVehicleTest }
        >({
            query: req => ({
                url: `api/VehicleTests/${req.id}`,
                method: 'PUT',
                body: req.vehicleTest,
            }),
            invalidatesTags: ['VehicleTests'],
        }),
        deleteVehicleTest: builder.mutation<void, number>({
            query: id => ({
                url: `api/VehicleTests/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['VehicleTests'],
        }),

        addVehiclesToTest: builder.mutation<
            void,
            { id: number; vehicleIds: string[] }
        >({
            query: ({ id, vehicleIds }) => ({
                url: `api/VehicleTests/${id}/AddVehicles`,
                method: 'POST',
                body: vehicleIds,
            }),
            invalidatesTags: ['VehicleTests'],
        }),
        removeVehiclesFromTest: builder.mutation<
            void,
            { id: number; vehicleIds: string[] }
        >({
            query: ({ id, vehicleIds }) => ({
                url: `api/VehicleTests/${id}/RemoveVehicles`,
                method: 'POST',
                body: vehicleIds,
            }),
            invalidatesTags: ['VehicleTests'],
        }),

        startVehicleTests: builder.mutation<
            void,
            { participationId: number; onDate?: string }[]
        >({
            query: data => ({
                url: 'api/VehicleTests/SetStartTest',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['VehicleTests'],
        }),
        stopVehicleTests: builder.mutation<
            void,
            { participationId: number; onDate?: string }[]
        >({
            query: data => ({
                url: 'api/VehicleTests/SetStopTest',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['VehicleTests'],
        }),

        getVehicleTestTypes: builder.query<ITestType[], void>({
            query: () => 'api/VehicleTests/TestTypes',
        }),

        getVehicleClassifications: builder.query<
            IVehicleClassification[],
            void
        >({
            query: () =>
                'api/VehicleTestVehicleClassifications?$filter=isDeleted ne true',
            providesTags: ['vehicleClassifications'],
        }),
        addVehicleClassifications: builder.mutation<
            IVehicleClassification[],
            string
        >({
            query: title => ({
                url: 'api/VehicleTestVehicleClassifications',
                method: 'POST',
                body: { title },
            }),
            invalidatesTags: ['vehicleClassifications'],
        }),
        deleteVehicleClassification: builder.mutation<void, number>({
            query: id => ({
                url: `api/VehicleTestVehicleClassifications/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['vehicleClassifications'],
        }),

        getVehicleTestEscalationHistory: builder.query<
            IVehicleTestEscalation[],
            { id: number; actorId: string }
        >({
            query: ({ id, actorId }) =>
                `api/VehicleTestEscalations/${id}/${actorId}/history`,
            providesTags: ['VehicleTestEscalations'],
        }),
        getLatestVehicleTestEscalation: builder.query<
            IVehicleTestEscalation[],
            number
        >({
            query: id => `api/VehicleTestEscalations/${id}/latest`,
            providesTags: ['VehicleTestEscalations'],
        }),
        addVehicleTestEscalation: builder.mutation<
            IVehicleTestEscalation,
            {
                vehicleTestId: number
                actorId: string
                escalationStepId: number
            }
        >({
            query: body => ({
                url: 'api/VehicleTestEscalations/add',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['VehicleTestEscalations'],
        }),
        addBatchVehicleTestEscalation: builder.mutation<
            IVehicleTestEscalation[],
            {
                vehicleTestId: number
                actorIds: string[]
                escalationStepId: number
            }
        >({
            query: body => ({
                url: 'api/VehicleTestEscalations/addbatch',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['VehicleTestEscalations'],
        }),
        deleteVehicleTestEscalation: builder.mutation<void, number>({
            query: escalationId => ({
                url: `api/VehicleTestEscalations/${escalationId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['VehicleTestEscalations'],
        }),
    }),
})

export const {
    useGetVehicleTestsQuery,
    useGetVehicleTestByIdQuery,
    useGetTestsOfVehiclesByVehicleIdQuery,
    useArchiveVehicleTestMutation,
    useUnArchiveVehicleTestMutation,
    useAddVehicleTestMutation,
    useEditVehicleTestMutation,
    useDeleteVehicleTestMutation,

    useAddVehiclesToTestMutation,
    useRemoveVehiclesFromTestMutation,

    useStartVehicleTestsMutation,
    useStopVehicleTestsMutation,

    useGetVehicleTestTypesQuery,

    useGetVehicleClassificationsQuery,
    useAddVehicleClassificationsMutation,
    useDeleteVehicleClassificationMutation,

    useGetVehicleTestEscalationHistoryQuery,
    useGetLatestVehicleTestEscalationQuery,
    useAddVehicleTestEscalationMutation,
    useAddBatchVehicleTestEscalationMutation,
    useDeleteVehicleTestEscalationMutation,
} = vehicleTestApi
