import { Divider, Input, Select, makeStyles } from '@fluentui/react-components'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { IFilterParams } from 'ag-grid-community'
import isUuid from 'helpers/isUuid'
import { toast } from 'react-toastify'

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
    },
})

const CustomGuidFilter = forwardRef((props: IFilterParams, ref) => {
    const [filterType, setFilterType] = useState('equals')
    const [filter, setFilter] = useState('')
    const options =
        props.colDef.field === 'Id'
            ? [
                  { key: 'equals', text: 'Equals' },
                  { key: 'notEquals', text: 'Not Equals' },
              ]
            : [
                  { key: 'equals', text: 'Equals' },
                  { key: 'notEquals', text: 'Not Equals' },
                  { key: 'blank', text: '(Blanks)' },
              ]

    const classes = useStyles()

    const filterQuery = useRef('')

    const setFilterQuery = (operator: string, filterParam: string) => {
        const equals = operator === 'equals'
        const blanks = operator === 'blank'

        filterQuery.current = blanks
            ? 'eq null'
            : filterParam
            ? equals
                ? `eq ${filterParam}`
                : `ne ${filterParam}`
            : ''
    }

    useImperativeHandle(ref, () => ({
        isFilterActive() {
            return filterQuery.current
                ? isUuid(filterQuery.current.split(' ')[1])
                : false
        },

        getModel() {
            return filterQuery.current
        },

        setModel(model: string) {
            if (model) {
                const params = model.split(' ')
                const activeFilterType =
                    params[0] === 'eq' ? 'equals' : 'notEquals'
                setFilterType(activeFilterType)
                setFilterQuery(activeFilterType, params[1])
            } else {
                setFilter('')
                setFilterType('equals')
                setFilterQuery('equals', '')
            }
        },
    }))

    const onApplyClicked = () => {
        if (filterType === 'blank') {
            setFilterQuery(filterType, filter)
            props.filterChangedCallback()
            return
        }

        if (isUuid(filter)) {
            setFilterQuery(filterType, filter)
            props.filterChangedCallback()
        } else {
            toast.error(`It's not a valid format for "${props.colDef.field}"`)
        }
    }

    const onResetClicked = () => {
        setFilterQuery('equals', '')
        setFilter('')
        setFilterType('equals')
        props.filterChangedCallback()
    }

    return (
        <>
            <div className='ag-filter-body-wrapper ag-simple-filter-body-wrapper'>
                <Select
                    value={filterType}
                    onChange={(_ev, data) => setFilterType(data.value)}
                >
                    {options.map(item => (
                        <option key={item.key} value={item.key}>
                            {item.text}
                        </option>
                    ))}
                </Select>
                <Input
                    value={filter ?? ''}
                    placeholder='Filter...'
                    onChange={(_ev, data) => setFilter(data.value)}
                    pattern='/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i'
                />
            </div>
            <Divider />
            <div className={'ag-filter-apply-panel ' + classes.wrapper}>
                <button
                    className='ag-standard-button ag-filter-apply-panel-button'
                    onClick={onResetClicked}
                >
                    Reset
                </button>
                <button
                    className='ag-standard-button ag-filter-apply-panel-button'
                    onClick={onApplyClicked}
                >
                    Apply
                </button>
            </div>
        </>
    )
})

CustomGuidFilter.displayName = 'Guid Filter'

export default CustomGuidFilter
