import { AxiosResponse } from 'axios'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import rivendellApiAxiosInstance from 'components/Axios/RivendellApiAxios'

export const getOdataApi = <T>(
    url: string
): Promise<AxiosResponse<IODataResponse<T>>> => reflectApiAxiosInstance.get(url)

export const getRivendellOdataApi = <T>(
    url: string
): Promise<AxiosResponse<IODataResponse<T>>> =>
    rivendellApiAxiosInstance.get(url)
