import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
} from '@fluentui/react-components'
import { useAppDispatch, useAppSelector } from 'store'
import { useEffect, useState } from 'react'

import PGWCollectionFormFields from 'components/Actions/PGW/PGWCollectionFormFields'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import sanitizeInput from 'helpers/sanitizeInput'
import { selectLastOdataQuery } from 'slices/gridSlice'
import { triggerMessage } from 'slices/messageSlice'
import { useGrid } from 'contexts/GridContext'

interface ICreatePGWCollectionModal {
    toggleOpen: () => void
}

const defaultPGWCollectionObject: IPGWCollection = {
    id: undefined,
    name: undefined,
    description: undefined,
    collectionType: 'CUSTOMER',
    vinsList: undefined,
}

const CreatePGWCollectionModal = ({
    toggleOpen,
}: ICreatePGWCollectionModal) => {
    const [collection, setCollection] = useState<IPGWCollection>(
        defaultPGWCollectionObject
    )

    const [loading, setLoading] = useState(false)

    const { gridRef } = useGrid()
    const dispatch = useAppDispatch()

    useEffect(() => {
        collection.vinsList = gridRef.current?.api
            .getSelectedRows()
            .map(sr => sr.Vin || '') as string[]
    }, [collection, gridRef])

    const lastODataQuery = useAppSelector(selectLastOdataQuery)

    const createPGWCollection = async (collectionObject: IPGWCollection) => {
        const sanitizedCollection = sanitizeInput(collectionObject)

        const param = new URLSearchParams(lastODataQuery)
        param.delete('$skip')
        param.delete('$top')
        param.delete('$select')
        param.append('$select', 'Vin')

        const { data } = await reflectApiAxiosInstance.get(
            decodeURIComponent(param.toString())
        )

        if (sanitizedCollection.vinsList.length === 0) {
            sanitizedCollection.vinsList = data.value.map(v => v.Vin)
        }

        return await reflectApiAxiosInstance.post(
            '/api/PGWCollection',
            sanitizedCollection
        )
    }

    const createCollection = () => {
        setLoading(true)

        createPGWCollection(collection)
            .then(() => {
                dispatch(
                    triggerMessage({
                        message: 'Successfully created collection',
                        intent: 'success',
                    })
                )
            })
            .catch(() => {
                dispatch(
                    triggerMessage({
                        message: 'Failed to create collection',
                        intent: 'error',
                    })
                )
            })
            .finally(() => {
                setLoading(false)
                toggleOpen()
            })
    }

    return (
        <>
            <DialogContent>
                <PGWCollectionFormFields
                    hideVinField
                    collection={collection}
                    setCollection={setCollection}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    appearance='primary'
                    disabled={
                        loading || !(collection.name && collection.description)
                    }
                    onClick={createCollection}
                >
                    Add Collection
                </Button>
                <DialogTrigger disableButtonEnhancement>
                    <Button onClick={() => toggleOpen()}>Cancel</Button>
                </DialogTrigger>
            </DialogActions>
        </>
    )
}

export default CreatePGWCollectionModal
