import 'filepond/dist/filepond.min.css'

import { Checkbox, makeStyles, shorthands } from '@fluentui/react-components'
import { FilePond, registerPlugin } from 'react-filepond'
import { FilePondErrorDescription, FilePondFile } from 'filepond'
import { useEffect, useRef, useState } from 'react'

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import HttpRequestHelper from 'components/Axios/HttpRequestHelper'
import UploadInformation from './UploadInformation'
import { acquireApiAccessToken } from 'authInstance'
import consoleErrorData from 'helpers/consoleErrorData'

type FileUploaderProps = {
    setUploadedFiles: (response: VehicleDataResponse[]) => void
    setUploadFileError: (error: boolean) => void
    includeVolvoBilData: boolean
    setIncludeVolvoBilData: (include: boolean) => void
    backendErrorResponse: string
    setBackendErrorResponse: (response: string) => void
}

const useStyles = makeStyles({
    message: {
        display: 'flex',
        maxWidth: 'auto',
        whiteSpace: 'initial',
        wordWrap: 'break-word',
        marginBottom: '10px',
        ...shorthands.padding('10px'),
        backgroundColor: '#fcf1d2',
    },
    checkbox: {
        display: 'flex',
        justifyContent: 'flex-end',
        ...shorthands.margin('5px'),
    },
})

const FileUploader = ({
    setUploadedFiles,
    setUploadFileError,
    includeVolvoBilData,
    setIncludeVolvoBilData,
    backendErrorResponse,
    setBackendErrorResponse,
}: FileUploaderProps) => {
    const filePondInstance = useRef<FilePond>(null)
    const classes = useStyles()
    const [accessToken, setAccessToken] = useState<string>()

    registerPlugin(FilePondPluginFileValidateType)

    const onError = (
        error: FilePondErrorDescription,
        file?: FilePondFile,
        status?: string
    ) => {
        consoleErrorData({
            error,
            file,
            status,
        })
        HttpRequestHelper.GetGlobalErrorMessage(error.code)
        setUploadFileError(true)
    }

    const onProcessFile = (
        error: FilePondErrorDescription | null,
        file: FilePondFile
    ) => {
        if (!error && file.serverId) {
            const response = JSON.parse(file.serverId)
            setUploadedFiles(response)
        }
    }

    const getAccessToken = async () => {
        setAccessToken(await acquireApiAccessToken())
    }

    useEffect(() => {
        getAccessToken()
    }, [])

    return (
        <>
            {backendErrorResponse && (
                <div className={classes.message}>{backendErrorResponse}</div>
            )}
            <UploadInformation />
            <FilePond
                ref={filePondInstance}
                name='files'
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                acceptedFileTypes={[
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                ]}
                allowMultiple={false}
                allowReplace
                allowRevert={false}
                instantUpload={false}
                server={{
                    url: `${process.env.REACT_APP_REFLECT_BACKEND_API}`,
                    process: {
                        url: `/api/VehicleImport/ReadFile?includeVolvoBilData=${includeVolvoBilData}`,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        method: 'POST',
                        ondata: formData => formData,
                        withCredentials: false,
                        onload: response => response,
                        onerror: response => {
                            setBackendErrorResponse(response)
                            return response.data
                        },
                    },
                    revert: null,
                    restore: null,
                    load: null,
                    fetch: null,
                }}
                onerror={onError}
                onprocessfile={onProcessFile}
                onaddfile={getAccessToken}
            />

            <Checkbox
                label='Include Volvo Bil Data?'
                checked={includeVolvoBilData}
                onChange={event => {
                    setIncludeVolvoBilData(event.target.checked)
                }}
                className={classes.checkbox}
            />
        </>
    )
}

export default FileUploader
