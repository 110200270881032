import { Add24Regular, EditRegular } from '@fluentui/react-icons'
import {
    Button,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTrigger,
} from '@fluentui/react-components'

import PortalNewsForm from './PortalNewsForm'
import { useState } from 'react'

const PortalNewsDialog = ({
    formTitle,
    data,
}: {
    data?: IPortalNews
    formTitle: string
}) => {
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(-1)

    return (
        <Dialog
            modalType='alert'
            open={open}
            onOpenChange={(_e, d) => setOpen(d.open)}
        >
            <DialogTrigger>
                {formTitle === 'Edit News Item' ? (
                    <Button
                        appearance='subtle'
                        icon={<EditRegular />}
                        title='Edit News Item'
                        size='small'
                        onClick={() => {
                            setSelected(data.Id)
                        }}
                    />
                ) : (
                    <Button
                        appearance='subtle'
                        icon={<Add24Regular />}
                        size='small'
                    >
                        Create New Portal News Item
                    </Button>
                )}
            </DialogTrigger>

            <DialogSurface>
                <DialogBody>
                    <DialogContent>
                        <PortalNewsForm
                            setOpen={setOpen}
                            formTitle={formTitle}
                            open={open}
                            data={data}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default PortalNewsDialog
