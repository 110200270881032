import { getBooleanCustomFilters, getCsvFilters } from './gridHelper'

import OdataProvider from 'ag-grid-odata'
import { booleanColumns } from 'constants/vehicleTest'

export class VehicleTestOdataProvider extends OdataProvider {
    odataOperator = {
        ...this.odataOperator,
        blanks: (col: string) => `${col} eq null`,
        inWithNullAndUrlEncode: (col: string, values: string[]) =>
            `${col} in (${values
                .map(x =>
                    x !== null
                        ? `'${this.encode(encodeURIComponent(x))}'`
                        : `${x}`
                )
                .join()})`,
        guidFilter: (col: string, value: string) => `${col} ${value}`,
        notBlank: (col: string) => `${col} ne null`,
        blank: (col: string) => `${col} eq null`,
        vehicleInfoVin: (values: string[]) =>
            `vehicleInfo/Vin in (${values
                .map(x =>
                    x !== null
                        ? `'${this.encode(encodeURIComponent(x))}'`
                        : `${x}`
                )
                .join()})`,
    }
}

export const VehicleTestCustomFilters = {
    ...getBooleanCustomFilters(booleanColumns),
    ...getCsvFilters(['RegistrationNo', 'Cdsid']),
    ['VehicleInfo.Vin']: (
        _colName: string,
        col: { value: string },
        _isCaseSensitive: boolean,
        provider: VehicleTestOdataProvider
    ) =>
        col.value?.length
            ? provider.odataOperator.vehicleInfoVin(
                  col.value?.split(',').map(s => s.trim())
              )
            : false,
    ['VehicleInfo.RegistrationNo']: (
        _colName: string,
        col: { value: string },
        _isCaseSensitive: boolean,
        provider: VehicleTestOdataProvider
    ) =>
        col.value?.length
            ? provider.odataOperator.inWithNullAndUrlEncode(
                  'vehicleInfo/RegistrationNo',
                  col.value?.split(',').map(s => s.trim())
              )
            : false,
    ['VehicleInfo.TagLists']: (
        _colName: string,
        col: { values: string },
        _isCaseSensitive: boolean,
        provider: VehicleTestOdataProvider
    ) =>
        col.values?.length
            ? provider.odataOperator.inWithNullAndUrlEncode(
                  'vehicleInfo/TagLists',
                  col.values
              )
            : false,
    ['VehicleInfo.Cdsid']: (
        _colName: string,
        col: { value: string },
        _isCaseSensitive: boolean,
        provider: VehicleTestOdataProvider
    ) =>
        col.value?.length
            ? provider.odataOperator.inWithNullAndUrlEncode(
                  'vehicleInfo/Cdsid',
                  col.value?.split(',').map(s => s.trim())
              )
            : false,
    ['BridgeInfo.VehicleClassification']: (
        _colName: string,
        col: { values: string },
        _isCaseSensitive: boolean,
        provider: VehicleTestOdataProvider
    ) =>
        col.values?.length
            ? provider.odataOperator.inWithNullAndUrlEncode(
                  'bridgeInfo/VehicleClassification',
                  col.values
              )
            : false,
    ['VehicleInfo.VehicleStatus']: (
        _colName: string,
        col: { values: string },
        _isCaseSensitive: boolean,
        provider: VehicleTestOdataProvider
    ) =>
        col.values?.length
            ? provider.odataOperator.inWithNullAndUrlEncode(
                  'vehicleInfo/VehicleStatus',
                  col.values
              )
            : false,
    RequiresWorkshopVisit: (
        colName: string,
        col: { values: string[] },
        _isCaseSensitive: boolean,
        provider: VehicleTestOdataProvider
    ) =>
        col.values?.length
            ? provider.odataOperator.equals(colName, col.values)
            : false,
    ['BridgeInfo.TestStatus']: (
        _colName: string,
        col: { values: string[] },
        _isCaseSensitive: boolean,
        provider: VehicleTestOdataProvider
    ) =>
        col.values?.length
            ? provider.odataOperator.inWithNullAndUrlEncode(
                  'bridgeInfo/testStatus',
                  col.values
              )
            : false,
}
