import Base from 'components/ApiDropdowns/Base'

interface IApiDropdownProps {
    disabled?: boolean
    selectedItem: string
    setSelectedItem: (value: string) => void
    noLabel?: boolean
}

const ConsentCategory = ({
    selectedItem,
    setSelectedItem,
    disabled,
    noLabel,
}: IApiDropdownProps) => (
    <Base
        label={noLabel ? null : 'Consent type'}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        apiListName='ConsentCategory'
        disabled={disabled}
    />
)

export default ConsentCategory
