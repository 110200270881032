import { Button, Input, makeStyles } from '@fluentui/react-components'
import {
    GridDots24Filled,
    Question24Regular,
    Search24Regular,
} from '@fluentui/react-icons'

import AppsPanel from './AppsPanel'
import Breadcrumbs from '../Breadcrumbs'
import Menu from 'components/Ui/Layout/Menu'
import PersonalViews from 'components/PersonalViews/PVMenu'
import ReflectLogo from '../Logo/ReflectLogo'
import Search from 'components/Search/Header/Search'
import UserMenu from '../UserMenu'
import { useGrid } from 'contexts/GridContext'
import { useState } from 'react'

interface IHeader {
    title?: string
    icon?: JSX.Element
    personalViewType?: PersonalViewType
    isMainGrid?: boolean
    isWorkshopGrid?: boolean
}

const useStyles = makeStyles({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        height: '60px',
        backgroundColor: '#0078D4',
    },
    start: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'start',
    },
    center: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    end: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'end',
        paddingRight: '16px',
    },
    filledInput: {
        backgroundColor: 'white',
        width: '100%',
    },
})

const Header = ({
    title,
    personalViewType,
    isMainGrid,
    isWorkshopGrid,
    icon,
}: IHeader) => {
    const [isAppsDrawerOpen, setAppsDrawerOpen] = useState(false)
    const classes = useStyles()
    const { gridRef } = useGrid()

    return (
        <>
            <Breadcrumbs />
            <div className={classes.wrapper}>
                <div className={classes.start}>
                    <Button
                        data-cy='button-app-menu-open'
                        icon={<GridDots24Filled />}
                        style={{
                            color: 'white',
                            backgroundColor: 'transparent',
                            border: 'none',
                            marginLeft: '10px',
                        }}
                        size='large'
                        onClick={() => setAppsDrawerOpen(true)}
                    />
                    <ReflectLogo />
                    <Menu title={title} icon={icon} />
                </div>

                <div className={classes.center}>
                    {isMainGrid ? (
                        <Search placeholder='Search for Vins, Registration No, Cdsid or Vehicle Id' />
                    ) : isWorkshopGrid ? (
                        <Search placeholder='Search for Registration No' />
                    ) : (
                        <Input
                            disabled
                            className={classes.filledInput}
                            appearance='filled-darker'
                            contentBefore={<Search24Regular />}
                            placeholder='You cannot search this grid'
                        />
                    )}
                </div>

                <div className={classes.end}>
                    {personalViewType && gridRef.current && (
                        <PersonalViews viewType={personalViewType} />
                    )}
                    <Button
                        icon={<Question24Regular />}
                        style={{
                            color: 'white',
                            backgroundColor: 'transparent',
                            border: 'none',
                        }}
                        onClick={() =>
                            window.open(
                                // eslint-disable-next-line max-len
                                'https://volvocars.service-now.com/sp?id=kb_article&sys_id=ae39228a9382fd1031aaf4258aba1080&sysparm_article=KB0043153',
                                '_blank',
                                'noopener'
                            )
                        }
                    />
                    <UserMenu />
                </div>
                {isAppsDrawerOpen && (
                    <AppsPanel
                        isOpen={isAppsDrawerOpen}
                        toggleOpen={() => setAppsDrawerOpen(false)}
                    />
                )}
            </div>
        </>
    )
}

export default Header
