/* eslint-disable @typescript-eslint/no-shadow */

import { Input } from '@fluentui/react-components'

interface IVehicleDataFieldsProps {
    vehicle: VehicleDataResponse
    name: string
    currentVehicle: VehicleData
    setCurrentVehicle: React.Dispatch<React.SetStateAction<VehicleData>>
    isVolvoBilField: (field: string) => boolean
}

const VehicleDataFields = ({
    vehicle,
    name,
    currentVehicle,
    setCurrentVehicle,
    isVolvoBilField,
}: IVehicleDataFieldsProps) => {
    const renderDataField = (data, name, currentVehicle, setCurrentVehicle) => {
        return Object.entries(data).map(
            ([key, value]) =>
                key === name && (
                    <div key={key} style={{ overflow: 'hidden' }}>
                        {key !== 'vin' && !isVolvoBilField(key) && (
                            <>
                                <Input
                                    defaultValue={String(value)}
                                    disabled={!value}
                                    readOnly
                                    onClick={() =>
                                        setCurrentVehicle(prev => ({
                                            ...prev,
                                            [name]:
                                                currentVehicle[name] === value
                                                    ? null
                                                    : String(value),
                                        }))
                                    }
                                    value={value ? String(value) : ''}
                                />
                            </>
                        )}
                    </div>
                )
        )
    }

    const vehicleDataFields = [vehicle.excel, vehicle.reflect, vehicle.volvoBil]

    return (
        <>
            {vehicleDataFields.map(
                (dataField, index) =>
                    dataField && (
                        <div key={index}>
                            {renderDataField(
                                dataField,
                                name,
                                currentVehicle,
                                setCurrentVehicle
                            )}
                        </div>
                    )
            )}
        </>
    )
}

export default VehicleDataFields
