import {
    ClockDismiss24Regular,
    Play24Regular,
    RecordStop24Filled,
    Warning24Regular,
} from '@fluentui/react-icons'
import {
    useStartVehicleTestsMutation,
    useStopVehicleTestsMutation,
} from 'api/vehicleTests'

import {
    Button,
    Popover,
    PopoverSurface,
    PopoverTrigger,
} from '@fluentui/react-components'
import { ICellRendererParams } from 'ag-grid-community'
import { useMemo } from 'react'
import { useSetMissedAppointmentsMutation } from 'api/workshop'
import { vehicleStatusString } from 'helpers/vehicleGridHelper'

const VehicleActions = ({
    data,
    disabled,
    node,
    testTitle,
}: ICellRendererParams<
    IBridgeInfoOdata &
        IVehicleInfoOdata & {
            BridgeInfoId: number
            TestStatus: string
        }
> & {
    disabled: boolean
    testTitle: string
}) => {
    const {
        StartDate,
        EndDate,
        MissedAppointments,
        BridgeInfoId,
        VehicleStatus,
        VehicleTestsOngoing,
        VehicleTestsPlanned,
        TestStatus,
        Vin,
    } = data

    const started = useMemo(
        () =>
            StartDate ? new Date(StartDate.slice(0, -1)) < new Date() : false,
        [StartDate]
    )

    const hasDates = useMemo(
        () => (StartDate && EndDate ? true : false),
        [StartDate, EndDate]
    )

    const isEnded = useMemo(
        () => (VehicleStatus === 'Ended' ? true : false),
        [VehicleStatus]
    )

    const [setMissedAppointment] = useSetMissedAppointmentsMutation()
    const [startVehicleTest] = useStartVehicleTestsMutation()
    const [stopVehicleTest] = useStopVehicleTestsMutation()

    const startTest = () => {
        const onDate = new Date().toISOString()

        startVehicleTest([{ participationId: BridgeInfoId, onDate }]).then(
            () => {
                node.updateData({
                    ...data,
                    StartDate: onDate,
                    TestStatus: vehicleStatusString({
                        startDate: onDate,
                        endDate: EndDate,
                    }),
                })
            }
        )
    }

    const endTest = () => {
        const onDate = new Date().toISOString()

        stopVehicleTest([{ participationId: BridgeInfoId, onDate }]).then(
            () => {
                node.updateData({
                    ...data,
                    EndDate: onDate,
                    TestStatus: vehicleStatusString({
                        startDate: StartDate,
                        endDate: onDate,
                    }),
                })
            }
        )
    }

    const handleClick = () => {
        node.updateData({
            ...data,
            MissedAppointments: MissedAppointments + 1,
        })
        setMissedAppointment(BridgeInfoId)
    }

    const otherVehicleTests = useMemo(
        () =>
            [
                ...(VehicleTestsOngoing?.split(',').filter(
                    tp => tp != testTitle
                ) ?? []),
                ...(VehicleTestsPlanned?.split(',').filter(
                    tp => tp != testTitle
                ) ?? []),
            ].map(x => `'${x}'`),
        [VehicleTestsOngoing, VehicleTestsPlanned, testTitle]
    )

    return (
        <>
            {started && (
                <Button
                    title='End Test'
                    aria-label='End Test'
                    onClick={endTest}
                    disabled={
                        disabled ||
                        hasDates ||
                        (isEnded && TestStatus != 'Ongoing')
                    }
                    appearance='transparent'
                    icon={
                        <RecordStop24Filled
                            primaryFill={
                                disabled ||
                                hasDates ||
                                (isEnded && TestStatus != 'Ongoing')
                                    ? '#ebebeb'
                                    : '#0078D4'
                            }
                        />
                    }
                />
            )}
            {!started && (
                <>
                    <Button
                        title='Start Test'
                        aria-label='Start Test'
                        appearance='transparent'
                        onClick={startTest}
                        disabled={disabled || hasDates || isEnded}
                        icon={
                            <Play24Regular
                                primaryFill={
                                    disabled || hasDates || isEnded
                                        ? '#ebebeb'
                                        : '#0078D4'
                                }
                            />
                        }
                    />
                    <Button
                        title='Missed appointment'
                        aria-label='Missed appointment'
                        onClick={handleClick}
                        disabled={disabled || hasDates || isEnded}
                        icon={
                            <ClockDismiss24Regular
                                primaryFill={
                                    disabled || hasDates || isEnded
                                        ? '#ebebeb'
                                        : '#0078D4'
                                }
                            />
                        }
                        appearance='transparent'
                    />
                </>
            )}
            {otherVehicleTests.length > 0 && (
                <Popover
                    appearance='brand'
                    openOnHover={true}
                    withArrow={true}
                    mouseLeaveDelay={0}
                >
                    <PopoverTrigger disableButtonEnhancement>
                        <Button
                            appearance='transparent'
                            icon={<Warning24Regular primaryFill='red' />}
                        />
                    </PopoverTrigger>
                    <PopoverSurface>
                        Vehicle with Vin &apos;{Vin}&apos; is already in Test
                        <br />
                        Purpose {otherVehicleTests.join(', ')}. Make sure that
                        <br />
                        these tests will not affect each other.
                    </PopoverSurface>
                </Popover>
            )}
        </>
    )
}

export default VehicleActions
