import {
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip,
} from '@fluentui/react-components'
import {
    useDeleteNewsItemMutation,
    usePostCreateNewsItemMutation,
} from 'api/portalNews'

import ConfirmActionDialog from './ConfirmActionDialog'
import { MoreHorizontalRegular } from '@fluentui/react-icons'
import { toast } from 'react-toastify'
import { useGrid } from 'contexts/GridContext'
import { useState } from 'react'

const NewsItemActions = ({ data }: { data: IPortalNews }) => {
    const { gridRef } = useGrid()

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [duplicateModalOpen, setDuplicateModalOpen] = useState(false)

    const [deleteNewsItem] = useDeleteNewsItemMutation()
    const [createNewsItem] = usePostCreateNewsItemMutation()

    const onDelete = async () => {
        try {
            await deleteNewsItem(data.Id)
        } catch (error) {
            toast.error(error)
        } finally {
            toast.success('Successfully deleted news item!')
            setDeleteModalOpen(false)
        }
        gridRef.current?.api.refreshServerSide({ purge: true })
    }

    const onDuplicate = async () => {
        try {
            await createNewsItem({
                Title: data.Title,
                Introduction: data.Introduction,
                Text: data.Text,
                PublishDate: data.PublishDate,
                UnPublishDate: data.UnPublishDate,
                ImageUrl: data.ImageUrl,
                ThumbnailUrl: data.ThumbnailUrl,
                Countries: data.Countries,
                NewsLists: data.NewsLists,
            })
        } catch (error) {
            toast.error(error)
        } finally {
            toast.success('Successfully duplicated news item!')
            setDuplicateModalOpen(false)
        }
        gridRef.current?.api.refreshServerSide({ purge: true })
    }

    return (
        <div>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <Tooltip content='More actions' relationship='label'>
                        <MenuButton
                            icon={<MoreHorizontalRegular />}
                            appearance='transparent'
                            size='small'
                        />
                    </Tooltip>
                </MenuTrigger>

                <MenuPopover>
                    <MenuList>
                        <MenuItem onClick={() => setDuplicateModalOpen(true)}>
                            Duplicate
                        </MenuItem>
                        <MenuItem onClick={() => setDeleteModalOpen(true)}>
                            Delete
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>

            {duplicateModalOpen && (
                <ConfirmActionDialog
                    setIsOpen={setDuplicateModalOpen}
                    isOpen={duplicateModalOpen}
                    callback={onDuplicate}
                    action='duplicate'
                />
            )}
            {deleteModalOpen && (
                <ConfirmActionDialog
                    setIsOpen={setDeleteModalOpen}
                    isOpen={deleteModalOpen}
                    callback={onDelete}
                    action='delete'
                />
            )}
        </div>
    )
}

export default NewsItemActions
