import { Button, makeStyles, shorthands } from '@fluentui/react-components'

import ActionsMenuItems from './ActionsMenuItems'
import { ArrowLeft24Regular } from '@fluentui/react-icons'
import { IActionsMenu } from 'Types/ActionsMenu'
import React from 'react'
import Restricted from 'components/Restricted'

interface IActionsMenuProps {
    items?: IActionsMenu[]
    extraComponents?: React.ReactNode
    goBack?: IGoBack
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        height: '40px',
        backgroundColor: 'white',
        alignItems: 'center',
        ...shorthands.margin('0', '8px'),
        justifyContent: 'space-between',
    },
    buttons: { display: 'flex', ...shorthands.gap('8px') },
    separator: { height: '2px', backgroundColor: '#ededed' },
})

const ActionsMenu = ({ items, extraComponents, goBack }: IActionsMenuProps) => {
    const classes = useStyles()

    const actionMenuItem = (item: IActionsMenu) => {
        return item.component ? (
            <Restricted key={item.restricted} to={item.restricted}>
                {item.component}
            </Restricted>
        ) : item.restricted ? (
            <Restricted key={item.label} to={item.restricted}>
                <ActionsMenuItems item={item} />
            </Restricted>
        ) : (
            <ActionsMenuItems key={item.label} item={item} />
        )
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.buttons}>
                    {goBack && (
                        <Button
                            appearance='subtle'
                            onClick={goBack.onClick}
                            icon={<ArrowLeft24Regular />}
                            size='small'
                        >
                            Go back to {goBack.label}
                        </Button>
                    )}
                    {items?.map(actionMenuItem)}
                </div>
                <div>{extraComponents}</div>
            </div>
            <div className={classes.separator} />
        </>
    )
}

export default ActionsMenu
