import { Spinner, makeStyles } from '@fluentui/react-components'

interface IAuthLoading {
    text?: string
}

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 9999,
    },
})

const Loader = ({ text = '' }: IAuthLoading) => {
    const classes = useStyles()

    return (
        <div className={classes.wrapper}>
            <Spinner label={text} labelPosition='after' />
        </div>
    )
}

export default Loader
