import 'filepond/dist/filepond.min.css'

import {
    Field,
    Input,
    Link,
    Radio,
    RadioGroup,
    Tab,
    TabList,
    Textarea,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import { FilePondErrorDescription, FilePondFile } from 'filepond'
import { Dispatch, RefObject, SetStateAction } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'

import HttpRequestHelper from 'components/Axios/HttpRequestHelper'
import ModalStatusEnum from 'constants/ModalStatusEnum'
import ModalTypeEnum from 'constants/ModalTypeEnum'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import consoleErrorData from 'helpers/consoleErrorData'
import { insertSpaces } from 'helpers/stringHelpers'

type VehicleGroupListsFormProps = {
    vehicleGroupList:
        | IVehicleGroup
        | (IVehicleGroup & { removeVins: Array<string> })
    setVehicleGroupList: Dispatch<SetStateAction<IVehicleGroup>>
    editExisting?: boolean
    filePondInstance: RefObject<FilePond>
    getAccessToken: () => Promise<void>
    setVinAddType: Dispatch<SetStateAction<string>>
    vinAddType: string
    setVehicleGroupListsUpdated?: () => void
    setVehicleGroupListAddedVehicles?: (
        value: SetStateAction<number | undefined>
    ) => void
    vehicleGroupType: VehicleGroupListSupportedPages
    modalType?: ModalTypeEnum
    modalStatus?: ModalStatusEnum
    setVinList?: Dispatch<SetStateAction<string[]>>
    vinList?: string[]
    setGroupListName?: Dispatch<SetStateAction<string>>
    groupListName?: string
    setAddByExternalFilePond?: (value: SetStateAction<boolean>) => void
}

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gridGap: '8px',
    },
    warning: {
        ...shorthands.padding('16px', '32px'),
        backgroundColor: '#fff1a8',
        marginBottom: '16px',
    },
})

const VehicleGroupListsForm = ({
    vehicleGroupList,
    setVehicleGroupList,
    editExisting = false,
    filePondInstance,
    getAccessToken,
    setVinAddType,
    vinAddType,
    setVehicleGroupListsUpdated,
    setVehicleGroupListAddedVehicles,
    vehicleGroupType,

    modalType,
    modalStatus,
    setVinList,
    vinList,
    setGroupListName,
    groupListName,
    setAddByExternalFilePond,
}: VehicleGroupListsFormProps) => {
    registerPlugin(FilePondPluginFileValidateType)
    const classes = useStyles()

    const onError = (
        error: FilePondErrorDescription,
        file: FilePondFile | undefined,
        status: unknown
    ) => {
        consoleErrorData({
            error,
            file,
            status,
        })
        HttpRequestHelper.GetGlobalErrorMessage(error.code)
    }

    const onProcessFile = (
        error: FilePondErrorDescription | null,
        file: FilePondFile
    ) => {
        if (!error && file.serverId) {
            if (setVehicleGroupListsUpdated) {
                setVehicleGroupListsUpdated()
            }
            if (setVehicleGroupListAddedVehicles) {
                setVehicleGroupListAddedVehicles(
                    JSON.parse(file.serverId).vehicleCount
                )
            }
        }
    }

    const value =
        modalType === ModalTypeEnum.QuickModal
            ? groupListName
            : vehicleGroupList.name
    const onChange = (_ev, data) => {
        if (modalType === ModalTypeEnum.QuickModal) {
            setGroupListName?.(data.value)
        } else {
            setVehicleGroupList({
                ...vehicleGroupList,
                name: data?.value,
            })
        }
    }

    return (
        <div className={classes.wrapper}>
            <Input
                placeholder={`${insertSpaces(vehicleGroupType)} name`}
                value={value ?? ''}
                onChange={onChange}
            />

            {modalType === ModalTypeEnum.EditModal && editExisting && (
                <RadioGroup
                    required
                    value={vehicleGroupList.operation}
                    onChange={(_ev, data) =>
                        setVehicleGroupList({
                            ...vehicleGroupList,
                            operation: data.value,
                        })
                    }
                >
                    <Radio value='add' label='Add VINs to VehicleGroup list' />
                    <Radio
                        value='remove'
                        label='Remove VINs from VehicleGroup list'
                    />
                </RadioGroup>
            )}
            {modalStatus === ModalStatusEnum.Manual && (
                <TabList
                    selectedValue={vinAddType}
                    onTabSelect={(_ev, data) => {
                        setVinAddType(data.value as string)
                        setAddByExternalFilePond?.(data.value === 'vinExcel')
                    }}
                >
                    <Tab value='vinField'>VINs as text</Tab>
                    <Tab value='vinExcel'>VINs in Excel</Tab>
                </TabList>
            )}
            {modalStatus === ModalStatusEnum.Manual &&
                vinAddType === 'vinField' && (
                    <Field label='VINs (comma separated)'>
                        <Textarea
                            placeholder='VIN1, VIN2, VIN3'
                            value={vinList?.join(', ')}
                            onChange={(_ev, data) => {
                                //can set string with newlines or commas
                                const cleanedVinList = data.value
                                    .split(/[\n,]+/)
                                    .filter(item => item && item.trim() !== '')
                                setVehicleGroupList({
                                    ...vehicleGroupList,
                                    vins: cleanedVinList,
                                })
                                setVinList?.(cleanedVinList)
                            }}
                        />
                    </Field>
                )}

            {modalStatus === ModalStatusEnum.Manual &&
                vinAddType === 'vinExcel' && (
                    <>
                        <FilePond
                            ref={filePondInstance}
                            name='files'
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            acceptedFileTypes={[
                                'application/vnd.ms-excel',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            ]}
                            allowMultiple={false}
                            allowReplace
                            allowRevert={false}
                            instantUpload={false}
                            allowProcess={false}
                            onerror={onError}
                            onprocessfile={onProcessFile}
                            onaddfile={() => getAccessToken()}
                        />

                        <div className={classes.warning}>
                            Please note that when importing vehicles you must
                            use the
                            <Link
                                href={`${process.env.PUBLIC_URL}/files/templates/VehicleGroupListVinTemplate.xlsx`}
                            >
                                &nbsp;VehicleGroupListVinTemplate&nbsp;
                            </Link>
                            otherwise <strong>your import will fail</strong> or
                            may have unintended consequences.
                        </div>
                    </>
                )}
        </div>
    )
}

VehicleGroupListsForm.defaultProps = {
    editExisting: false,
    setVehicleGroupListsUpdated: undefined,
    setVehicleGroupListAddedVehicles: undefined,
}

export default VehicleGroupListsForm
