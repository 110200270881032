import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    MessageBar,
} from '@fluentui/react-components'
import { useEffect, useState } from 'react'

import AxiosStateTypeEnum from 'constants/AxiosStateTypeEnum'
import DriverCheckStates from 'Types/DriverCheckStates'
import UpdateDriverForm from './UpdateDriverForm'
import manageDriversHelpers from 'helpers/manageDriversHelpers'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import sanitizeInput from 'helpers/sanitizeInput'

type UpdateDriverProps = {
    toggleOpen: () => void
}

const DriverCheckStateDefault: DriverCheckStates = {
    CdsIdVerified: false,
    CdsIdNotFound: false,
    DriverWithCdsIdExists: false,
    SeveralDriverMatches: false,
    CheckDriverExistsFailed: false,
    InvalidEmail: false,
    DriverFound: AxiosStateTypeEnum.Unknown,
    IncludeOrder: false,
}

const defaultDriverObject: DriverObject = {
    cdsid: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    preferredEmail: undefined,
    phone: undefined,
    doin: undefined,
    consentCategory: undefined,
}

const UpdateDriver = ({ toggleOpen }: UpdateDriverProps) => {
    const [driver, setDriver] = useState<DriverObject>(defaultDriverObject)
    const [driverCheckState, setDriverCheckState] = useState<DriverCheckStates>(
        DriverCheckStateDefault
    )
    const [driverRequiredCompleted, setDriverRequiredCompleted] =
        useState<boolean>(false)
    const [updateDriverState, setUpdateDriverState] =
        useState<AxiosStateTypeEnum>(AxiosStateTypeEnum.Unknown)

    useEffect(() => {
        if (
            driver &&
            driver.cdsid &&
            driver.preferredEmail &&
            driver.email &&
            driver.firstName &&
            driver.lastName
        )
            setDriverRequiredCompleted(true)

        if (
            driver &&
            (!driver.cdsid ||
                !driver.preferredEmail ||
                !driver.email ||
                !driver.firstName ||
                !driver.lastName)
        )
            setDriverRequiredCompleted(false)
    }, [driver])

    const onUpdateDriver = () => {
        const sanitizedDriver = sanitizeInput(driver)
        reflectApiAxiosInstance
            .post('/api/Drivers', sanitizedDriver)
            .then(() => {
                setUpdateDriverState(AxiosStateTypeEnum.Success)
            })
            .catch(() => {
                setUpdateDriverState(AxiosStateTypeEnum.Error)
            })
    }

    const tryAgain = () => setUpdateDriverState(AxiosStateTypeEnum.Unknown)

    return (
        <>
            {updateDriverState === AxiosStateTypeEnum.Unknown && (
                <>
                    <DialogContent>
                        <UpdateDriverForm
                            driver={driver}
                            setDriver={setDriver}
                            driverCheckState={driverCheckState}
                            setDriverCheckState={setDriverCheckState}
                            driverRequiredCompleted={driverRequiredCompleted}
                            setDriverRequiredCompleted={
                                setDriverRequiredCompleted
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance='primary'
                            id='updateDriverAction'
                            disabled={manageDriversHelpers.CheckAddUpdateDriverDisabled(
                                driver,
                                driverCheckState,
                                driverRequiredCompleted
                            )}
                            onClick={onUpdateDriver}
                        >
                            Update driver
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={toggleOpen}>Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}
            {updateDriverState === AxiosStateTypeEnum.Success && (
                <>
                    <MessageBar intent='success'>
                        Driver updated successfully.
                    </MessageBar>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={toggleOpen}>Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}
            {updateDriverState === AxiosStateTypeEnum.Error && (
                <>
                    <MessageBar intent='error'>
                        Failed to update driver, please retry. Contact technical
                        support if the error persists.
                    </MessageBar>
                    <DialogActions>
                        <Button appearance='primary' onClick={tryAgain}>
                            Try again
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={toggleOpen}>Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </>
            )}
        </>
    )
}

export default UpdateDriver
