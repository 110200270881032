import { Combobox, Field, Option } from '@fluentui/react-components'
import { useEffect, useState } from 'react'

import reflectAxiosGraphInstance from 'components/Axios/ReflectGraphAxios'
import { toast } from 'react-toastify'
import useDebounce from 'hooks/useDebounce'

interface ICdsidSelectProps {
    label?: string
    defaultValue: string
    onSelect: (value: string) => void
    disabled?: boolean
    required?: boolean
}

const CdsidSelect = ({
    onSelect,
    label,
    defaultValue,
    disabled = false,
    required = false,
}: ICdsidSelectProps) => {
    const [options, setOptions] = useState([])
    const [value, setValue] = useState<string>('')
    const debouncedValue = useDebounce<string>(value, 500)

    const fetch = (cdsid: string, callback: (result: string[]) => void) => {
        reflectAxiosGraphInstance
            .get(
                `/users?$top=10&$filter=startsWith(userPrincipalName, '${cdsid}')`
            )
            .then(response => {
                callback(
                    response.data.value
                        .map((v: { userPrincipalName: string }) =>
                            v.userPrincipalName
                                .split('@')[0]
                                .replace(/\W/g, ' ')
                        )
                        .sort((a: string, b: string) => b.localeCompare(a))
                        .map((name: string) => ({
                            key: name,
                            text: name ?? '(Blanks)',
                        }))
                )
            })
            .catch(error => {
                toast(error)
                callback([])
            })
    }

    const handleInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setValue(ev.target.value)
    }

    useEffect(() => {
        if (debouncedValue) {
            fetch(debouncedValue, results => {
                setOptions(results)
            })
        } else {
            setOptions([])
        }
    }, [debouncedValue])

    return (
        <Field required={required} label={label}>
            <Combobox
                defaultValue={defaultValue}
                disabled={disabled}
                onOptionSelect={(_event, data) => {
                    onSelect(data.optionText)
                }}
                onInput={handleInput}
                placeholder='Enter a Cdsid'
                data-cy='combobox-cdsid'
            >
                {options.map((option, index) => (
                    <Option key={index} id={option.key} value={option.text}>
                        {option.text}
                    </Option>
                ))}
            </Combobox>
        </Field>
    )
}

export default CdsidSelect
