import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    AccordionToggleEventHandler,
    Checkbox,
    Input,
    Label,
    MessageBar,
    MessageBarBody,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import CarUsages from 'components/ApiDropdowns/CarUsages'
import ConsentCategory from 'components/ApiDropdowns/ConsentCategory'
import VehicleDataFields from './VehicleDataFields'
import { camelToReadable } from 'helpers/stringHelpers'

interface IImportVehicleDataProps {
    vehicle: VehicleDataResponse
    readyVehicles: ImportVehicle[]
    setReadyVehicles: Dispatch<SetStateAction<ImportVehicle[]>>
    setVehicleImportList: Dispatch<SetStateAction<ImportVehicle[]>>
    carUsage: string
    consentType: string
    includeVolvoBilData: boolean
    uploadedFiles: VehicleDataResponse[]
}

const useStyles = makeStyles({
    header: {
        backgroundColor: '#f1f0ef',
        marginTop: '8px',
        marginBottom: '8px',
    },
    panel: {
        display: 'flex',
        justifyContent: 'center',
        ...shorthands.padding('10px'),
        marginBottom: '10px',
    },
    container: {
        maxHeight: '1000px',
        gridAutoFlow: 'row',
        ...shorthands.transition('height', '300ms', 'ease-in-out'),
        ...shorthands.overflow('hidden'),
        fontWeight: 'bold',
    },
    checkbox: {
        display: 'flex',
        marginLeft: 'auto',
    },
    fields: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '150px',
        alignItems: 'center',
    },
    values: {
        display: 'flex',
        ...shorthands.margin('5px'),
        ...shorthands.gap('20px'),
        alignItems: 'center',
    },
    validation: {
        color: 'red',
    },
    sourceLabel: {
        width: '183px',
        ...shorthands.margin('8px'),
        fontWeight: 'normal',
    },
    dropdown: {
        width: '183px',
    },
})

const ImportVehicleData = ({
    vehicle,
    readyVehicles,
    setReadyVehicles,
    setVehicleImportList,
    carUsage,
    consentType,
    includeVolvoBilData,
}: IImportVehicleDataProps) => {
    const classes = useStyles()

    const [vehicleReady, setVehicleReady] = useState(true)
    const [currentVehicle, setCurrentVehicle] = useState<VehicleData>(
        vehicle.reflect
    )
    const [validation, setValidation] = useState<string | undefined>()
    const [openItems, setOpenItems] = useState([])

    const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
        setOpenItems(data.openItems)
    }

    const customOrder = [
        'firstName',
        'lastName',
        'cdsid',
        'phone',
        'email',
        'regNo',
        'carUsage',
        'consentType',
        'scheduledDeliveryDate',
        'scheduledReturnDate',
        'deliveredDate',
        'returnedDate',
        'orderDate',
        'cancelledDate',
        'poId',
        'workshopDealer',
        'employeeId',
        'doin',
    ]

    const isVolvoBilField = (name: string) => {
        const isVolvoBilDateField = (field: string) =>
            [
                'deliveredDate',
                'scheduledDeliveryDate',
                'returnedDate',
                'scheduledReturnDate',
                'orderDate',
                'cancelledDate',
            ].includes(field)

        const isVolvoBilStringField = (field: string) =>
            ['poId', 'workshopDealer', 'doin', 'employeeId'].includes(field)
        return isVolvoBilDateField(name) || isVolvoBilStringField(name)
    }

    const columnDefinitions = [
        { name: 'source', value: Object.keys(currentVehicle) },

        ...Object.entries(currentVehicle)
            .sort(
                ([keyA], [keyB]) =>
                    customOrder.indexOf(keyA) - customOrder.indexOf(keyB)
            )
            .map(([key, value]) => ({
                name: key,
                value,
            })),
    ]

    const handleToggleChange = (checked?: boolean) => {
        if (checked) {
            const newVehicle: ImportVehicle = {
                vin: vehicle.excel.vin,
                ready: true,
                data: { ...currentVehicle },
            }

            setReadyVehicles(prev => [...prev, newVehicle])
        } else {
            setReadyVehicles(prev =>
                prev.filter(item => item.vin !== vehicle.excel.vin)
            )
        }

        setVehicleReady(prev => !prev)
    }

    useEffect(() => {
        setVehicleImportList(prev => [
            ...prev.filter(v => v.vin !== vehicle.excel.vin),
            {
                vin: vehicle.excel.vin,
                ready: vehicleReady,
                data: currentVehicle,
            },
        ])
    }, [vehicleReady, currentVehicle, setVehicleImportList, vehicle.excel.vin])

    useEffect(() => {
        if (carUsage && carUsage !== currentVehicle.carUsage)
            setCurrentVehicle(prev => ({
                ...prev,
                carUsage: String(carUsage),
            }))

        if (consentType && consentType !== currentVehicle.consentType)
            setCurrentVehicle(prev => ({
                ...prev,
                consentType: String(consentType),
            }))
        if (includeVolvoBilData) {
            if (vehicle.volvoBil) {
                setCurrentVehicle(prev => ({
                    ...prev,
                    deliveredDate: vehicle.volvoBil.deliveredDate,
                    scheduledDeliveryDate:
                        vehicle.volvoBil.scheduledDeliveryDate,
                    returnedDate: vehicle.volvoBil.returnedDate,
                    scheduledReturnDate: vehicle.volvoBil.scheduledReturnDate,
                    orderDate: vehicle.volvoBil.orderDate,
                    cancelledDate: vehicle.volvoBil.cancelledDate,
                    doin: vehicle.volvoBil.doin,
                    workshopDealer: vehicle.volvoBil.workshopDealer,
                    employeeId: vehicle.volvoBil.employeeId,
                }))
            }
        }
    }, [
        carUsage,
        consentType,
        includeVolvoBilData,
        vehicle.volvoBil,
        setReadyVehicles,
        currentVehicle.carUsage,
        currentVehicle.consentType,
    ])

    useEffect(() => {
        if (
            !currentVehicle.cdsid &&
            (currentVehicle.firstName ||
                currentVehicle.lastName ||
                currentVehicle.email ||
                currentVehicle.phone)
        ) {
            setValidation('You need to add a CDSID to change user information')
        } else {
            setValidation(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(currentVehicle)])

    return (
        <>
            <Accordion
                openItems={openItems}
                onToggle={handleToggle}
                multiple
                collapsible
            >
                <AccordionItem value={vehicle.excel.vin}>
                    <AccordionHeader className={classes.header}>
                        <b>VIN:</b> &nbsp; {vehicle.excel.vin}
                        <div className={classes.checkbox}>
                            <Checkbox
                                shape='circular'
                                label={'Ready'}
                                checked={
                                    Array.isArray(readyVehicles)
                                        ? readyVehicles
                                              .map(v =>
                                                  v.vin.includes(
                                                      vehicle.excel.vin
                                                  )
                                              )
                                              .filter(Boolean).length > 0
                                        : false
                                }
                                onChange={(_e, data) =>
                                    handleToggleChange(!!data?.checked)
                                }
                            />
                        </div>
                    </AccordionHeader>
                    <AccordionPanel className={classes.panel}>
                        <div className={classes.container}>
                            {validation && (
                                <MessageBar intent='error'>
                                    <MessageBarBody>
                                        {validation}
                                    </MessageBarBody>
                                </MessageBar>
                            )}
                            {columnDefinitions
                                .filter(col => col.name !== 'vin')
                                .map(({ name }) =>
                                    !includeVolvoBilData &&
                                    isVolvoBilField(name) ? null : (
                                        <>
                                            <div
                                                key={name}
                                                className={classes.fields}
                                            >
                                                <Label>
                                                    {camelToReadable(name)}
                                                </Label>
                                                <div className={classes.values}>
                                                    {name === 'source' ? (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <div
                                                                className={
                                                                    classes.sourceLabel
                                                                }
                                                            >
                                                                Data to save
                                                            </div>
                                                            {Object.keys(
                                                                vehicle
                                                            )
                                                                .filter(
                                                                    key =>
                                                                        vehicle[
                                                                            key
                                                                        ] !==
                                                                        null
                                                                )
                                                                .map(v => (
                                                                    <>
                                                                        <div
                                                                            className={
                                                                                classes.sourceLabel
                                                                            }
                                                                        >
                                                                            {camelToReadable(
                                                                                v
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                ))
                                                                .reverse()}
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {name ===
                                                            'carUsage' ? (
                                                                <div
                                                                    className={
                                                                        classes.dropdown
                                                                    }
                                                                >
                                                                    <CarUsages
                                                                        noLabel
                                                                        showHint={
                                                                            false
                                                                        }
                                                                        selectedItem={
                                                                            currentVehicle[
                                                                                name
                                                                            ]
                                                                        }
                                                                        setSelectedItem={newValue =>
                                                                            setCurrentVehicle(
                                                                                prev => ({
                                                                                    ...prev,
                                                                                    [name]: String(
                                                                                        newValue
                                                                                    ),
                                                                                })
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : name ===
                                                              'consentType' ? (
                                                                <div
                                                                    className={
                                                                        classes.dropdown
                                                                    }
                                                                >
                                                                    <ConsentCategory
                                                                        noLabel
                                                                        selectedItem={
                                                                            currentVehicle[
                                                                                name
                                                                            ]
                                                                        }
                                                                        setSelectedItem={newValue =>
                                                                            setCurrentVehicle(
                                                                                prev => ({
                                                                                    ...prev,
                                                                                    [name]: String(
                                                                                        newValue
                                                                                    ),
                                                                                })
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <span>
                                                                    <Input
                                                                        disabled={isVolvoBilField(
                                                                            name
                                                                        )}
                                                                        value={
                                                                            currentVehicle[
                                                                                name
                                                                            ] ||
                                                                            ''
                                                                        }
                                                                        onChange={(
                                                                            _e,
                                                                            data
                                                                        ) =>
                                                                            setCurrentVehicle(
                                                                                prev => ({
                                                                                    ...prev,
                                                                                    [name]: String(
                                                                                        data.value
                                                                                    ),
                                                                                })
                                                                            )
                                                                        }
                                                                    ></Input>
                                                                </span>
                                                            )}

                                                            <VehicleDataFields
                                                                vehicle={
                                                                    vehicle
                                                                }
                                                                isVolvoBilField={
                                                                    isVolvoBilField
                                                                }
                                                                name={name}
                                                                setCurrentVehicle={
                                                                    setCurrentVehicle
                                                                }
                                                                currentVehicle={
                                                                    currentVehicle
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )
                                )}
                        </div>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </>
    )
}

export default ImportVehicleData
