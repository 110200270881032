import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { apiSlice, rivenDellApiSlice } from 'api/apiSlice'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

import authReducer from 'slices/authSlice'
import gridReducer from 'slices/gridSlice'
import messageReducer from 'slices/messageSlice'
import preferenceReducer from 'slices/preferenceSlice'

const rootReducer = combineReducers({
    auth: authReducer,
    grid: gridReducer,
    message: messageReducer,
    preference: preferenceReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [rivenDellApiSlice.reducerPath]: rivenDellApiSlice.reducer,
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            apiSlice.middleware,
            rivenDellApiSlice.middleware
        ),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
