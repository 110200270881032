import { makeStyles, shorthands } from '@fluentui/react-components'

import { PropsWithChildren } from 'react'

const useStyles = makeStyles({
    wrapper: {
        height: '100%',
        ...shorthands.padding('5px'),
        backgroundColor: '#ededed',
    },
})

const AgGridContainer = ({ children }: PropsWithChildren<unknown>) => {
    const classes = useStyles()
    return (
        <div className={'ag-theme-alpine ' + classes.wrapper}>{children}</div>
    )
}

export default AgGridContainer
