import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    MessageBar,
} from '@fluentui/react-components'
import { selectLastOdataQuery, selectRowCount } from 'slices/gridSlice'
import {
    useAddVehicleTestMutation,
    useAddVehiclesToTestMutation,
} from 'api/vehicleTests'
import { useAppDispatch, useAppSelector } from 'store'
import { useMemo, useState } from 'react'

import ModalStatusEnum from 'constants/ModalStatusEnum'
import ROUTES from 'constants/routes'
import VehicleTestForm from '../VehicleTest/VehicleTestForm'
import VehicleTestSelect from 'components/VehicleTest/VehicleTestSelect'
import reflectApiAxiosInstance from 'components/Axios/ReflectApiAxios'
import { triggerMessage } from 'slices/messageSlice'
import { useGrid } from 'contexts/GridContext'
import { useNavigate } from 'react-router-dom'

type VehicleTestActionsProps = {
    toggleOpen: () => void
    modalStatus: ModalStatusEnum
}

const VehicleTestActions = ({
    toggleOpen,
    modalStatus,
}: VehicleTestActionsProps) => {
    const [isShowForm, setShowForm] = useState(false)
    const [selectedId, setSelectedId] = useState<number>()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [addVehicles, { isLoading }] = useAddVehiclesToTestMutation()
    const [addVehicleTest] = useAddVehicleTestMutation()

    const lastODataQuery = useAppSelector(selectLastOdataQuery)
    const rowCount = useAppSelector(selectRowCount)

    const { gridRef } = useGrid()
    const selectedVehicleIds = useMemo(
        () => gridRef.current?.api.getSelectedRows().map(({ Id }) => Id),
        [gridRef]
    )

    const collectAllVehicleIds = async () => {
        const param = new URLSearchParams(lastODataQuery)
        param.delete('$skip')
        param.delete('$top')
        param.delete('$select')
        param.append('$select', 'Id')

        const { data } = await reflectApiAxiosInstance.get(
            decodeURIComponent(param.toString())
        )

        return data.value.map(({ Id }) => Id)
    }

    const onAddVehicle = async () => {
        if (modalStatus === ModalStatusEnum.Selected) {
            await addVehicles({
                id: selectedId,
                vehicleIds: selectedVehicleIds,
            })
        } else if (modalStatus === ModalStatusEnum.Filtered) {
            const vehicleIds = await collectAllVehicleIds()
            await addVehicles({ id: selectedId, vehicleIds })
        }
    }

    const onSave = () => {
        onAddVehicle().then(() => toggleOpen())
    }

    const onSaveAndNavigate = () => {
        onAddVehicle().then(() =>
            navigate(`/${ROUTES.VEHICLE_TESTS}/${String(selectedId)}`)
        )
    }

    const handleSubmit = async (
        { plannedFinish, plannedStart, ...rest }: IVehicleTest,
        setSubmitting: (isSubmitting: boolean, inEditMode: boolean) => void
    ) => {
        setSubmitting(true, false)
        const response = await addVehicleTest({
            ...rest,
            plannedStart: plannedStart
                ? new Date(plannedStart).toISOString()
                : null,
            plannedFinish: plannedFinish
                ? new Date(plannedFinish).toISOString()
                : null,
        })
        setSubmitting(false, false)
        if ('data' in response) {
            setShowForm(false)
            setSelectedId(response.data.id)
            dispatch(
                triggerMessage({
                    intent: 'success',
                    message: 'Vehicle test created successfully.',
                })
            )
        } else if ('data' in response.error) {
            dispatch(
                triggerMessage({
                    intent: 'error',
                    title: 'Error',
                    message: `${response.error.data}`,
                })
            )
        }
    }

    const count = selectedVehicleIds?.length || rowCount

    return (
        <>
            <DialogContent>
                {count > 1000 && (
                    <MessageBar intent='warning'>
                        Warning! This action can take a very long time!
                    </MessageBar>
                )}
                <div
                    style={{ marginTop: '6px', marginBottom: '8px' }}
                >{`Adding ${count} vehicles to test`}</div>

                {isShowForm ? (
                    <VehicleTestForm onSave={handleSubmit} />
                ) : (
                    <VehicleTestSelect
                        onSelect={(vehicleTestId: number) => {
                            setSelectedId(vehicleTestId)
                        }}
                        selectedId={selectedId}
                    />
                )}
            </DialogContent>
            {!isShowForm && (
                <DialogActions fluid>
                    <Button
                        onClick={() => setShowForm(prev => !prev)}
                        appearance='primary'
                    >
                        Create new test
                    </Button>
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <MenuButton appearance='primary'>Save</MenuButton>
                        </MenuTrigger>

                        <MenuPopover>
                            <MenuList>
                                <MenuItem
                                    onClick={onSave}
                                    disabled={!selectedId || isLoading}
                                >
                                    Save
                                </MenuItem>
                                <MenuItem
                                    onClick={onSaveAndNavigate}
                                    disabled={!selectedId || isLoading}
                                >
                                    Save and go to test
                                </MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                    <DialogTrigger disableButtonEnhancement>
                        <Button onClick={toggleOpen}>Cancel</Button>
                    </DialogTrigger>
                </DialogActions>
            )}
        </>
    )
}

export default VehicleTestActions
