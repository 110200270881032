/* eslint-disable max-len */
/* eslint-disable quotes */

import { ColumnState } from 'ag-grid-community'
import ColumnType from 'constants/ColumnTypeEnum'
import CustomFilterType from 'Types/CustomFilterType'
import { ExtendedOdataProvider } from 'components/Search/ResultGrid/ResultGridProvider'
import FilterModelEnum from 'constants/FilterModelEnum'
import createYearWeekFilterString from 'helpers/createYearWeekFilterString'
import dayjs from 'dayjs'
import isUuid from 'helpers/isUuid'

const ResultGridHelper = {
    /**
     * Check column definition from search view and set the type of column filter in the column toolbar.
     * @param filter the type of input
     * @returns agTextColumnFilter | agDateColumnFilter | agNumberColumnFilter | agSetColumnFilter
     */
    GetFilterType: (filter?: string) => {
        switch (filter) {
            case ColumnType.Set:
                return 'agSetColumnFilter'
            case ColumnType.Date:
                return 'agDateColumnFilter'
            case ColumnType.Number:
                return 'agNumberColumnFilter'
            case ColumnType.StructureWeek:
                return 'agStructureWeek'
            case ColumnType.Guid:
                return 'agGuidFilter'
            case ColumnType.ModelCode:
                return 'modelCodeFilter'
            default:
                return 'agTextColumnFilter'
        }
    },

    /**
     * Create the query param string for the URL.
     * @param params Array of SortState
     * @returns URI encoded (component) string with parameters for query string.
     */
    CreateQueryParamString: (params: Array<SortState>) => {
        let paramSortString = ''

        params.forEach((sort, index) => {
            paramSortString += `${sort.colId}:${sort.sort}:${sort.sortIndex}${
                index + 1 !== params.length ? ',' : ''
            }`
        })

        return encodeURIComponent(paramSortString)
    },

    /**
     * Input the current column definition, filter out the checkbox-select column and any hidden column. Return a string array
     * @param columns ColumnState
     * @returns ['colId1', 'colId2]
     */
    GetExportColumnKeys: (columns: ColumnState[] | ColumnState[] = []) => {
        const filteredColumns: Array<string> = []

        columns.forEach(column => {
            if (
                !column.hide &&
                column.colId &&
                column.colId !== 'checkBoxSelect'
            ) {
                filteredColumns.push(column.colId)
            }
        })

        return filteredColumns
    },

    /**
     * Get the URL params.
     * @param searchParams URLSearchParams
     * @param gridParams GridParamsType
     * @returns URI decoded (component) string with parameters from query string
     */
    GetUrlGridParams: (
        searchParams: URLSearchParams,
        gridParams: IUrlParam | undefined
    ) => {
        const sortParams = searchParams.get('sort')
        const applySortParams: Array<SortState> = []

        if (sortParams && !gridParams?.sortQuery) {
            const sortParamsArray = decodeURIComponent(sortParams).split(',')

            sortParamsArray.forEach(param => {
                const paramSettings = param.split(':')

                applySortParams.push({
                    colId: paramSettings[0],
                    sort: paramSettings[1] as 'asc' | 'desc',
                    sortIndex: parseInt(paramSettings[2], 10),
                })
            })
        }

        return applySortParams
    },

    CreateExpandedOdataQuery: (searchString: string, view: string) => {
        const searchStrings: string | undefined = searchString
            ?.split(',')
            .map(s => s.trim())
            .filter(s => !isUuid(s))
            .map(s => `'${s}'`)
            .filter(s => s !== "''")
            .join(',')

        const guidStrings = searchString
            ?.split(',')
            .map(s => s.trim())
            .filter(s => isUuid(s))
            .join(',')

        if (!searchStrings && !guidStrings) return []

        const odataQueryStrings: string[] = []

        if (guidStrings) odataQueryStrings.push(`(Id in (${guidStrings}))`)

        if (searchStrings) {
            if (view === 'OtaVehicleViews' || view === 'ReadVehicleViews') {
                odataQueryStrings.push(
                    `(Vin in (${searchStrings}) or RegistrationNo in (${searchStrings}))`
                )
            } else {
                odataQueryStrings.push(
                    `(Vin in (${searchStrings}) or RegistrationNo in (${searchStrings}) or Cdsid in (${searchStrings}))`
                )
            }
        }

        const finalQuery = `(${odataQueryStrings.join(' or ')})`

        return [finalQuery]
    },

    /**
     * Setup default export params, using exportColumnKeys and rowsSelected
     * @returns CsvExportParams
     */
    defaultExcelExportParams: (
        exportColumnKeys: string[],
        rowsSelected?: number
    ) => ({
        columnKeys: exportColumnKeys,
        onlySelected: !!rowsSelected,
    }),

    getCustomFilters: (schema: SearchViewColumn[]): CustomFilterType => {
        /**
         * Create filter function for set columns
         */
        const setFilterFunction = (
            colName: string,
            col: { values: string },
            _isCaseSensitive: boolean,
            provider: ExtendedOdataProvider
        ) =>
            col.values && col.values.length > 0
                ? provider.odataOperator.inWithNullAndUrlEncode(
                      colName,
                      col.values
                  )
                : false

        /**
         * Create filter function for guid columns
         */
        const guidFilterFunction = (
            colName: string,
            col: string,
            _isCaseSensitive: boolean,
            provider: ExtendedOdataProvider
        ) =>
            col?.length > 0
                ? provider.odataOperator.guidFilter(colName, col)
                : false

        /**
         * Create filter function for color code column
         */
        const colorCodeFilterFunction = (
            colName: string,
            col: { values: string },
            _isCaseSensitive: boolean,
            provider: ExtendedOdataProvider
        ) => {
            return col.values && col.values.length > 0
                ? col.values.includes(null)
                    ? provider.odataOperator.equals(colName, 0)
                    : provider.odataOperator.equals(colName, col.values)
                : false
        }

        /**
         * Create filter function for boolean columns
         */
        const booleanFilterFunction = (
            colName: string,
            col: { values: string },
            _isCaseSensitive: boolean,
            provider: ExtendedOdataProvider
        ) => {
            return col.values && col.values.length > 0
                ? col.values.includes(null)
                    ? provider.odataOperator.blanks(colName)
                    : provider.odataOperator.equals(colName, col.values)
                : false
        }

        /**
         * Create filter function for test purposes columns
         */
        const vehicleTestsFilterFunction = (
            _colName: string,
            col: { values: string },
            _isCaseSensitive: boolean,
            provider: ExtendedOdataProvider,
            status: string
        ) =>
            col
                ? provider.odataOperator.vehicleTestsFilter({
                      values: col.values,
                      status,
                  })
                : false

        /**
         * Create filter function for driver studies columns
         */
        const driverStudiesFilterFunction = (
            _colName: string,
            col: { values: string },
            _isCaseSensitive: boolean,
            provider: ExtendedOdataProvider,
            status: string
        ) =>
            col
                ? provider.odataOperator.driverStudiesFilter({
                      values: col.values,
                      status,
                  })
                : false

        /**
         * Create filter function for in collection columns
         */
        const inCollectionFilterFunction = (
            colName: string,
            col: { values: string },
            _isCaseSensitive: boolean,
            provider: ExtendedOdataProvider
        ) =>
            col.values && col.values.length > 0
                ? provider.odataOperator.inCollection(colName, col.values)
                : false

        /**
         * Create filter function for vehicle groups columns
         */
        const vehicleGroupsFilterFunction = (
            _colName: string,
            col: { values: string },
            _isCaseSensitive: boolean,
            provider: ExtendedOdataProvider,
            groupTypeId: string
        ) =>
            col
                ? provider.odataOperator.vehicleGroupsFilter({
                      values: col.values,
                      groupTypeId,
                  })
                : false

        /**
         * Create filter function for feedback tools columns
         */
        const feedbackToolsFilterFunction = (
            _colName: string,
            col: { values: string },
            _isCaseSensitive: boolean,
            provider: ExtendedOdataProvider
        ) =>
            col.values && col.values.length > 0
                ? provider.odataOperator.feedbackToolsFilter(col.values)
                : false

        /**
         * Create filter function for consent category columns
         */
        const consentCategoryFilterFunction = (
            _colName: string,
            col: { values: string },
            _isCaseSensitive: boolean,
            provider: ExtendedOdataProvider
        ) =>
            col.values && col.values.length > 0
                ? col.values.includes(null)
                    ? provider.odataOperator.blanks(_colName)
                    : provider.odataOperator.consentCategoryFilter(col.values)
                : false

        /**
         * Create filter function for csv columns
         */
        const csvFilterFunction = (
            colName: string,
            col: { value: string },
            _isCaseSensitive: boolean,
            provider: ExtendedOdataProvider
        ) =>
            col.value
                ? provider.odataOperator.csvFilter(colName, col.value)
                : false

        /**
         * Create filter function for structure week columns
         */
        const StructureWeekFilterFunction = (
            colName: string,
            col: {
                values: string[]
                type: string
                structureWeekFrom: string
                structureWeekIn: string[]
                structureWeekTo: string
            },
            _isCaseSensitive: boolean,
            provider: ExtendedOdataProvider
        ) => {
            if (
                col.type === FilterModelEnum.In &&
                col.structureWeekIn &&
                col.structureWeekIn.length > 0
            ) {
                return provider.odataOperator.inWithNullAndUrlEncode(
                    colName,
                    col.structureWeekIn
                )
            }
            if (col.type === FilterModelEnum.Equals && col.structureWeekFrom) {
                return provider.odataOperator.equals(
                    colName,
                    `'${createYearWeekFilterString(
                        new Date(col.structureWeekFrom)
                    )}'`
                )
            }
            if (
                col.type === FilterModelEnum.GreaterThan &&
                col.structureWeekFrom
            ) {
                return provider.odataOperator.greaterThan(
                    colName,
                    `'${createYearWeekFilterString(
                        new Date(col.structureWeekFrom)
                    )}'`
                )
            }
            if (
                col.type === FilterModelEnum.LessThan &&
                col.structureWeekFrom
            ) {
                return provider.odataOperator.lessThan(
                    colName,
                    `'${createYearWeekFilterString(
                        new Date(col.structureWeekFrom)
                    )}'`
                )
            }
            if (
                col.type === FilterModelEnum.InRange &&
                col.structureWeekFrom &&
                col.structureWeekTo
            ) {
                return provider.odataOperator.inRange(
                    colName,
                    `'${createYearWeekFilterString(
                        new Date(col.structureWeekFrom)
                    )}'`,
                    `'${createYearWeekFilterString(
                        new Date(col.structureWeekTo)
                    )}'`
                )
            }
            return false
        }

        const customDateFilterFunction = (
            colName: string,
            col: {
                dateFrom: string
                dateTo: string
                type: string
                filterType: string
            },
            _isCaseSensitive: boolean,
            provider: ExtendedOdataProvider
        ) => {
            const operator = provider.odataOperator
            const from = new Date(col.dateFrom)
            const to = new Date(col.dateTo)

            const format = (date: Date, includeEndOfDay: boolean) => {
                if (includeEndOfDay)
                    return dayjs(date)
                        .endOf('day')
                        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                return dayjs(date).format('YYYY-MM-DD')
            }

            switch (col.type) {
                case 'blank':
                    return operator.blank(colName, '', false, col.filterType)
                case 'notBlank':
                    return operator.notBlank(colName, '', false, col.filterType)
                case 'inRange': {
                    if (from.getTime() === to.getTime()) {
                        return operator.equals(
                            colName,
                            `${format(from, false)}`
                        )
                    } else if (from.getTime() < to.getTime()) {
                        return operator.inRange(
                            colName,
                            `${format(from, false)}`,
                            `${format(to, true)}`
                        )
                    }
                    return operator.inRange(
                        colName,
                        `${format(to, true)}`,
                        `${format(from, false)}`
                    )
                }
                default:
                    return operator[col.type](colName, `${format(from, false)}`)
            }
        }

        /**
         * array of filter functions with keys
         */
        const filters = [
            {
                keys: schema
                    .filter(c => c.columnType === ColumnType.Date)
                    .map(({ displayName }) => displayName),
                filter: customDateFilterFunction,
            },
            {
                keys: schema
                    .filter(c => c.columnType === ColumnType.Set)
                    .map(({ displayName }) => displayName),
                filter: setFilterFunction,
            },
            {
                keys: schema
                    .filter(c => c.columnType === ColumnType.Guid)
                    .map(({ displayName }) => displayName),
                filter: guidFilterFunction,
            },
            {
                keys: ['ColorCode'],
                filter: colorCodeFilterFunction,
            },
            {
                keys: ['IsArchived', 'Blacklist', 'FirstUpdateDone'],
                filter: booleanFilterFunction,
            },
            {
                keys: ['ExceptionLists'],
                filter: inCollectionFilterFunction,
            },
            {
                keys: ['ExclusionLists'],
                filter: (...args) =>
                    vehicleGroupsFilterFunction.apply(this, [
                        ...args,
                        '72353e29-1c29-4bb6-81c9-2b1a0b212c2f',
                    ]),
            },
            {
                keys: ['TagLists'],
                filter: (...args) =>
                    vehicleGroupsFilterFunction.apply(this, [
                        ...args,
                        'A1C2B482-6B75-4FF4-B027-C358E5E0B1A6',
                    ]),
            },
            {
                keys: ['InclusionList'],
                filter: (...args) =>
                    vehicleGroupsFilterFunction.apply(this, [
                        ...args,
                        '67CE9EE7-8702-4BA6-A815-8C44FAA49490',
                    ]),
            },
            {
                keys: ['VehicleTestsPlanned'],
                filter: (...args) =>
                    vehicleTestsFilterFunction.apply(this, [
                        ...args,
                        'Planned',
                    ]),
            },
            {
                keys: ['VehicleTestsOngoing'],
                filter: (...args) =>
                    vehicleTestsFilterFunction.apply(this, [...args, 'Active']),
            },
            {
                keys: ['VehicleTestsFinished'],
                filter: (...args) =>
                    vehicleTestsFilterFunction.apply(this, [...args, 'Ended']),
            },
            {
                keys: ['DriverStudiesPlanned'],
                filter: (...args) =>
                    driverStudiesFilterFunction.apply(this, [
                        ...args,
                        'Planned',
                    ]),
            },
            {
                keys: ['DriverStudiesOngoing'],
                filter: (...args) =>
                    driverStudiesFilterFunction.apply(this, [
                        ...args,
                        'Ongoing',
                    ]),
            },
            {
                keys: ['DriverStudiesFinished'],
                filter: (...args) =>
                    driverStudiesFilterFunction.apply(this, [...args, 'Ended']),
            },
            {
                keys: ['FeedbackTools'],
                filter: feedbackToolsFilterFunction,
            },
            {
                keys: ['ConsentCategory'],
                filter: consentCategoryFilterFunction,
            },
            {
                keys: ['ModelCode', 'Vin', 'Cdsid'],
                filter: csvFilterFunction,
            },
            {
                keys: ['StructureWeek'],
                filter: StructureWeekFilterFunction,
            },
        ]

        return filters.reduce<CustomFilterType>(
            (accumulation, { keys, filter }) => {
                keys.forEach(key => {
                    accumulation[key] = filter
                })
                return accumulation
            },
            {}
        )
    },
}

export default ResultGridHelper
