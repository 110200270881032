import HttpRequestHelper from 'components/Axios/HttpRequestHelper'
import { acquireApiAccessToken } from 'authInstance'
import axios from 'axios'

const reflectApiAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_REFLECT_BACKEND_API,
})

/**
 * Request interceptor for API calls
 */
reflectApiAxiosInstance.interceptors.request.use(
    async config => {
        const accessToken = await acquireApiAccessToken()
        config.headers.set('Authorization', `Bearer ${accessToken}`)

        return config
    },
    error => {
        Promise.reject(error)
    }
)

reflectApiAxiosInstance.interceptors.response.use(response => {
    return response
})

reflectApiAxiosInstance.interceptors.response.use(
    response => response,
    error => {
        HttpRequestHelper.GetGlobalErrorMessage(
            error.response.status,
            `${error.response.config.baseURL}${error.response.config.url}`,
            error.response.data
        )

        return Promise.reject(error)
    }
)

export default reflectApiAxiosInstance
