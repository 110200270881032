import { ICellRendererParams } from 'ag-grid-community'
import NewsItemActions from './NewsItemActions'
import PortalNewsDialog from './PortalNewsDialog'
import PublishNewsDialog from './PublishNewsDialog'
import { makeStyles } from '@fluentui/react-components'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridGap: '10px',
    },
})

const PortalNewsActions = (props: ICellRendererParams<IPortalNews>) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <PortalNewsDialog
                data={props.data}
                formTitle={'Edit News Item'}
                {...props}
            />
            <PublishNewsDialog
                id={props.data.Id}
                data={props.data}
                {...props}
            />
            <NewsItemActions data={props.data} />
        </div>
    )
}

export default PortalNewsActions
