import { lowerCaseKeys } from 'helpers/objectManipulation'
import { rivenDellApiSlice } from './apiSlice'

const portalNewsApi = rivenDellApiSlice.injectEndpoints({
    endpoints: builder => ({
        getPortalNews: builder.query<IPortalNews[], string | void>({
            query: (query = '') => `/odata/PortalNews${query}`,
            transformResponse: (response: IODataResponse<IPortalNews[]>) =>
                lowerCaseKeys(response.value) as unknown as IPortalNews[],
        }),
        postPortalNews: builder.mutation<
            FormData,
            { file: File; fileName: string }
        >({
            query: ({ file, fileName }) => {
                const formData = new FormData()
                formData.append('image', file, fileName)
                return {
                    url: 'api/PortalNews/UploadImage',
                    method: 'POST',
                    body: formData,
                }
            },
        }),
        editNewsItem: builder.mutation<
            IPortalNews,
            {
                Id: number
                Title: string
                Text: string
                ImageUrl: string
                ThumbnailUrl: string
                Introduction: string
                PublishDate?: string
                UnPublishDate?: string
                Countries?: string[]
                NewsLists?: IVehicleGroup[]
            }
        >({
            query: ({
                Id: id,
                Title: title,
                Text: text,
                Introduction: introduction,
                ImageUrl: imageUrl,
                ThumbnailUrl: thumbnailUrl,
                PublishDate: publishDate,
                UnPublishDate: unPublishDate,
                Countries: countries,
                NewsLists: newsLists,
            }) => ({
                url: `api/PortalNews/${id}`,
                method: 'PATCH',
                body: [
                    {
                        OperationType: 'Add',
                        path: 'title',
                        value: title,
                    },

                    {
                        OperationType: 'Add',
                        path: 'text',
                        value: text,
                    },
                    {
                        OperationType: 'Add',
                        path: 'introduction',
                        value: introduction,
                    },
                    {
                        OperationType: 'Add',
                        path: 'imageUrl',
                        value: imageUrl,
                    },
                    {
                        OperationType: 'Add',
                        path: 'thumbnailUrl',
                        value: thumbnailUrl,
                    },
                    {
                        OperationType: 'Add',
                        path: 'publishDate',
                        value: publishDate,
                    },
                    {
                        OperationType: 'Add',
                        path: 'unPublishDate',
                        value: unPublishDate,
                    },
                    {
                        OperationType: 'Add',
                        path: 'countries',
                        value: countries,
                    },
                    {
                        OperationType: 'Add',
                        path: 'tagLists',
                        value: newsLists,
                    },
                ],
            }),
        }),
        postCreateNewsItem: builder.mutation<
            IPortalNews,
            {
                Id?: number
                Title: string
                Text: string
                ImageUrl: string
                ThumbnailUrl: string
                Introduction: string
                PublishDate: string
                UnPublishDate: string
                Countries: string[]
                NewsLists: IVehicleGroup[]
            }
        >({
            query: ({
                Id: id,
                Title: title,
                Text: text,
                Introduction: introduction,
                ImageUrl: imageUrl,
                ThumbnailUrl: thumbnailUrl,
                PublishDate: publishDate,
                UnPublishDate: unPublishDate,
                Countries: countries,
                NewsLists: newsLists,
            }) => {
                return {
                    url: 'api/PortalNews',
                    method: 'POST',
                    body: {
                        id,
                        title,
                        text,
                        introduction,
                        imageUrl,
                        thumbnailUrl,
                        publishDate,
                        unPublishDate,
                        countries,
                        newsLists,
                    },
                }
            },
        }),
        deleteNewsItem: builder.mutation<void, number>({
            query: id => ({
                url: `api/PortalNews/${id}`,
                method: 'DELETE',
            }),
        }),
        getMarkets: builder.query<string[], string | void>({
            query: () => 'api/Countries',
        }),
    }),
})

export const {
    useGetPortalNewsQuery,
    usePostPortalNewsMutation,
    useEditNewsItemMutation,
    usePostCreateNewsItemMutation,
    useDeleteNewsItemMutation,
    useGetMarketsQuery,
} = portalNewsApi
