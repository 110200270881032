enum FilterModelEnum {
    Equals = 'equals',
    GreaterThan = 'greaterThan',
    LessThan = 'lessThan',
    NotEqual = 'notEqual',
    InRange = 'inRange',
    In = 'in',
    Any = 'notBlank',
    None = 'blank',
}

export default FilterModelEnum

export const defaultFilterModel = {
    key: FilterModelEnum.Equals,
    text: 'Equals',
}
