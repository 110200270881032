import * as yup from 'yup'

import {
    Button,
    Checkbox,
    DialogActions,
    DialogTrigger,
    Field,
    Input,
    Select,
    Textarea,
    makeStyles,
} from '@fluentui/react-components'
import { ChangeEvent, useEffect } from 'react'

import CdsidSelect from './TestForm/CdsidSelect'
import DateTimePicker from 'components/Ui/DateTimePicker'
import { useFormik } from 'formik'
import {
    useGetVehicleTestTypesQuery,
    useGetVehicleTestsQuery,
} from 'api/vehicleTests'

interface IVehicleTestFormProps {
    data?: Partial<IVehicleTest>
    onSave?: (
        value: Partial<IVehicleTest>,
        setSubmitting: (isSubmitting: boolean) => void
    ) => void
}

const initialValues: Partial<IVehicleTest> = {
    title: '',
    description: '',
    responsible: '',
    responsibleTeam: '',
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gridColumnStart: 1,
        gridColumnEnd: 4,

        '& > div:last-of-type': {
            marginTop: '1rem',
            alignSelf: 'flex-end',
        },
    },
})

const VehicleTestForm = ({
    data = initialValues,
    onSave,
}: IVehicleTestFormProps) => {
    const classes = useStyles()

    const { data: testTypes } = useGetVehicleTestTypesQuery()
    const { data: vehicleTests } = useGetVehicleTestsQuery()

    const {
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        setSubmitting,
        touched,
        isSubmitting,
    } = useFormik({
        initialValues: data,
        validateOnBlur: true,
        validationSchema: yup.object({
            title: yup
                .string()
                .ensure()
                .required('Please fill out this field')
                .test(
                    'unique-name',
                    ({ value }) =>
                        `"${value}" is already in use, please try again`,
                    async value =>
                        value === data.title ||
                        vehicleTests?.find(
                            tp => tp.title === value && !tp.deleted
                        ) === undefined
                ),
            plannedStart: yup.date().nullable(),
            plannedFinish: yup
                .date()
                .nullable()
                .when('plannedStart', (plannedStart, schema) =>
                    plannedStart
                        ? schema.min(
                              plannedStart,
                              'End date has to be after start date'
                          )
                        : schema
                ),

            responsible: yup
                .string()
                .ensure()
                .required('Please fill out this field'),
            responsibleTeam: yup
                .string()
                .required('Please fill out this field'),
            viraLink: yup.string().nullable(),
        }),
        onSubmit: v => {
            onSave(v, setSubmitting)
        },
    })

    useEffect(() => {
        if (!data.testTypeId && testTypes?.length > 0) {
            setFieldValue('testTypeId', testTypes[0].id)
        }
    }, [testTypes, setFieldValue, data.testTypeId])

    const filterInput = event => {
        // Adds a check if the user tries to add an & and commas which are not allowed
        if (event.target.value.match(/^[^&,\v]*$/, '')) {
            handleChange(event)
        }
    }

    return (
        <form
            data-cy='vehicle-test-form'
            className={classes.root}
            onSubmit={handleSubmit}
        >
            <Field
                required
                label='Title'
                validationMessage={touched.title ? errors.title : null}
            >
                <Input
                    data-cy='title-input'
                    name='title'
                    value={values.title ?? ''}
                    onChange={event => filterInput(event)}
                    onBlur={handleBlur}
                />
            </Field>

            <Field label='Description'>
                <Textarea
                    data-cy='description-input'
                    name='description'
                    value={values.description ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Field>

            <DateTimePicker
                nowTime
                copyFromClipboard
                label='Planned start'
                value={values.plannedStart || ''}
                onChange={newStartdate => {
                    setFieldValue('plannedStart', newStartdate)
                }}
                validationMessage={
                    touched.plannedStart && errors.plannedStart
                        ? errors.plannedStart
                        : null
                }
            />

            <DateTimePicker
                nowTime
                copyFromClipboard
                label='Planned finish'
                value={values.plannedFinish || ''}
                onChange={newStartdate => {
                    setFieldValue('plannedFinish', newStartdate)
                }}
                validationMessage={
                    touched.plannedFinish && errors.plannedFinish
                        ? errors.plannedFinish
                        : null
                }
            />

            <CdsidSelect
                required
                label='Responsible'
                defaultValue={values.responsible}
                onSelect={value => {
                    setFieldValue('responsible', value)
                }}
            />

            <Field
                required
                label='Responsible Team'
                validationMessage={
                    touched.responsibleTeam ? errors.responsibleTeam : null
                }
            >
                <Input
                    data-cy='responsible-team-input'
                    name='responsibleTeam'
                    value={values.responsibleTeam ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Field>

            <Field label='Vira Link Number'>
                <Input
                    data-cy='vira-link-input'
                    name='viraLink'
                    value={values.viraLink ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Field>

            <Field label='Type'>
                <Select
                    data-cy='test-type-select'
                    name='testTypeId'
                    onChange={(_e, d) => {
                        setFieldValue('testTypeId', parseInt(d.value, 10))
                    }}
                    value={values.testTypeId}
                >
                    {testTypes?.map(tt => (
                        <option key={tt.id} value={tt.id}>
                            {tt.title}
                        </option>
                    ))}
                </Select>
            </Field>

            <Checkbox
                data-cy='requires-workshop-visit-checkbox'
                label='Requires workshop visit'
                name='requiresWorkshopVisit'
                checked={values.requiresWorkshopVisit}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(event.target.name, event.target.checked)
                }}
            />

            <DialogActions>
                <Button
                    data-cy='submit-button'
                    appearance='primary'
                    type='submit'
                    disabled={isSubmitting}
                >
                    Submit
                </Button>

                <DialogTrigger disableButtonEnhancement>
                    <Button disabled={isSubmitting} data-cy='close-button'>
                        Cancel
                    </Button>
                </DialogTrigger>
            </DialogActions>
        </form>
    )
}

export default VehicleTestForm
