import {
    Button,
    MessageBar,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import CarUsages from 'components/ApiDropdowns/CarUsages'
import ConsentCategory from 'components/ApiDropdowns/ConsentCategory'

type BatchEditVehicleImportProps = {
    carUsage: string
    setCarUsage: Dispatch<SetStateAction<string>>
    consentType: string
    setConsentType: Dispatch<SetStateAction<string>>
    isBatchEditOpen: boolean
    setIsBatchEditOpen: Dispatch<SetStateAction<boolean>>
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        ...shorthands.padding('16px'),
    },
    buttons: {
        marginLeft: 'auto',
        marginTop: '10px',
    },
    message: {
        whiteSpace: 'initial',
        wordWrap: 'break-word',
    },
})

const BatchEditVehicleImport = ({
    carUsage,
    setCarUsage,
    consentType,
    setConsentType,
    isBatchEditOpen,
    setIsBatchEditOpen,
}: BatchEditVehicleImportProps) => {
    const classes = useStyles()

    const [localCarUsage, setLocalCarUsage] = useState(carUsage)
    const [localConsentType, setLocalConsentType] = useState(consentType)

    useEffect(() => {
        if (isBatchEditOpen) {
            setLocalCarUsage(carUsage)
            setLocalConsentType(consentType)
        }
    }, [isBatchEditOpen, carUsage, consentType])

    const onSave = () => {
        setCarUsage(localCarUsage)
        setConsentType(localConsentType)
        setIsBatchEditOpen(false)
    }

    const onCancel = () => {
        setCarUsage(carUsage)
        setConsentType(consentType)
        setIsBatchEditOpen(false)
    }

    return (
        <div className={classes.container}>
            <MessageBar intent='warning' className={classes.message}>
                Warning! This action will change Car Usage and Consent Type
                choices for all imported vehicles.
            </MessageBar>
            <CarUsages
                selectedItem={localCarUsage}
                setSelectedItem={setLocalCarUsage}
            />
            <ConsentCategory
                selectedItem={localConsentType}
                setSelectedItem={setLocalConsentType}
            />

            <div className={classes.buttons}>
                <Button
                    appearance='primary'
                    onClick={onSave}
                    style={{
                        marginRight: '8px',
                    }}
                >
                    Save
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </div>
    )
}

export default BatchEditVehicleImport
