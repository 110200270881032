import Base from 'components/ApiDropdowns/Base'

interface IApiDropdownProps {
    disabled?: boolean
    selectedItem: string
    setSelectedItem: (value: string) => void
}

const CarCategories = ({
    disabled,
    selectedItem,
    setSelectedItem,
}: IApiDropdownProps) => (
    <Base
        apiListName='CarCategories'
        disabled={disabled}
        label='Car category'
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
    />
)

export default CarCategories
