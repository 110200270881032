import { Dispatch, SetStateAction } from 'react'
import { Field, Input } from '@fluentui/react-components'

import sanitize from 'sanitize-filename'
import { selectRowCount } from 'slices/gridSlice'
import { useAppSelector } from 'store'

type OTAReportFormProps = {
    collection: OTAReportObject
    setCollection: Dispatch<SetStateAction<OTAReportObject>>
}

const OTAReportForm = ({ collection, setCollection }: OTAReportFormProps) => {
    const rowCount = useAppSelector(selectRowCount)

    return (
        <>
            {rowCount >= 10000 && (
                <p>
                    <b>WARNING!</b>&nbsp; This operation will take a long time
                    to complete
                </p>
            )}

            <Field label='Filename'>
                <Input
                    placeholder='Filename'
                    value={collection.filename ?? ''}
                    onChange={(_e, data) => {
                        setCollection({
                            ...collection,
                            filename: sanitize(data.value),
                        })
                    }}
                />
            </Field>

            <p>
                <b>{`Export all filtered vehicles (${rowCount})`}</b>
            </p>
        </>
    )
}

export default OTAReportForm

OTAReportForm.defaultProps = {
    numberOfLoadedVehicles: undefined,
}
