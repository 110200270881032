import { Dispatch, SetStateAction } from 'react'

import PGWCollection from 'components/ApiDropdowns/PGWCollection'
import PGWCollectionFormFields from './PGWCollectionFormFields'

interface IPGWCollectionFormProps {
    collection: IPGWCollection
    setCollection: Dispatch<SetStateAction<IPGWCollection>>

    setFetchAllVinsFromFilteredVehicles: Dispatch<SetStateAction<boolean>>
}

const UpdatePGWCollectionForm = ({
    collection,
    setCollection,

    setFetchAllVinsFromFilteredVehicles,
}: IPGWCollectionFormProps) => (
    <>
        <PGWCollection
            label='Select a collection'
            onSelect={(value: IPGWCollection) => setCollection(value)}
        />
        <PGWCollectionFormFields
            collection={collection}
            setCollection={setCollection}
            setFetchAllVinsFromFilteredVehicles={
                setFetchAllVinsFromFilteredVehicles
            }
            disableFields={!collection.id}
        />
    </>
)

export default UpdatePGWCollectionForm
