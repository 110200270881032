import { ChangeEvent, useEffect, useMemo } from 'react'
import { Field, Select, SelectOnChangeData } from '@fluentui/react-components'

import { useGetDriverStudiesQuery } from 'api/driverStudies'

interface IDriverStudySelect {
    onSelect: (spreadId: number) => void
    selectedId: number
}

const DriverStudySelect = ({ onSelect, selectedId }: IDriverStudySelect) => {
    const { data: driverStudies = [] } = useGetDriverStudiesQuery()

    const options = useMemo(
        () =>
            [...driverStudies]
                .sort((a, b) => {
                    if (a.id > b.id) return -1
                    else if (a.id < b.id) return 1
                    else return 0
                })
                .filter(study => !study.isArchived && !study.isDeleted)
                .map(({ id, title }) => ({
                    key: id,
                    text: title,
                })),
        [driverStudies]
    )

    const handleChange = (
        _event: ChangeEvent<HTMLSelectElement>,
        option?: SelectOnChangeData
    ) => {
        onSelect(option.value as unknown as number)
    }

    useEffect(() => {
        if (!selectedId && options.length)
            onSelect(options[0].key as unknown as number)
    }, [onSelect, options, selectedId])

    return (
        <Field>
            <Select
                style={{
                    width: '100%',
                    marginBottom: '8px',
                    marginTop: '6px',
                }}
                onChange={handleChange}
            >
                {options.map(i => (
                    <option key={i.key} value={i.key}>
                        {i.text}
                    </option>
                ))}
            </Select>
        </Field>
    )
}

export default DriverStudySelect
