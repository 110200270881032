import Permissions from 'constants/PermissionsEnum'
import { PropsWithChildren } from 'react'
import { selectActiveRoles } from 'slices/authSlice'
import { useAppSelector } from 'store'

type RestrictedProps = {
    to: keyof typeof Permissions
    fallback?: JSX.Element
}

/**
 * A component that restricts access to its children based on the user's permissions
 * @param to the permission to check
 * @param fallback [optional] the component to render if the user is not allowed to access the children
 * @returns
 */
const Restricted = ({
    to,
    fallback = null,
    children,
}: PropsWithChildren<RestrictedProps>) => {
    const isAllowedTo = useAppSelector(selectActiveRoles)?.includes(
        Permissions[to]
    )

    if (isAllowedTo) {
        return <>{children}</>
    }

    // Otherwise, render the fallback if any
    return <>{fallback}</>
}

export default Restricted
