import {
    Button,
    Spinner,
    Text,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import { useMemo, useState } from 'react'

import { GridApi } from 'ag-grid-community'
import Restricted from 'components/Restricted'
import VehicleEditFieldType from 'components/Ui/VehicleEditFieldType'
import { triggerMessage } from 'slices/messageSlice'
import { useAppDispatch } from 'store'
import { useUpdateVehicleDetailsMutation } from 'api/vehicleDetails'

type VehicleDetailsListProps = {
    data: IVehicleInfoOdata
    api: GridApi
}

const useStyles = makeStyles({
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '8px',
        ...shorthands.margin('16px'),
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        gridGap: '8px',
    },
    actionRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        gridGap: '8px',
        marginBottom: '16px',
    },
})

const WHITELISTED_LIST_COLUMNS: Array<keyof IVehicleInfoOdata> = [
    'Cdsid',
    'Vin',
    'RegistrationNo',
    'Model',
    'DeliveryCountry',
]
const WHITELISTED_EDIT_COLUMNS: Array<keyof IVehicleInfoOdata> = [
    'Project',
    'CarUsage',
    'CustomDelivered',
    'CustomReturned',
    'Comments',
]

const VehicleDetailsList = ({ data, api }: VehicleDetailsListProps) => {
    const classes = useStyles()
    const dispatch = useAppDispatch()

    const vehicleDetails = useMemo(() => {
        return Object.keys(data)
            .filter((key: keyof IVehicleInfoOdata) =>
                WHITELISTED_EDIT_COLUMNS.includes(key)
            )
            .map(key => ({
                key: key,
                value: data[key],
            }))
    }, [data])

    const [vehiclePostData, setVehiclePostData] = useState<{
        [key: string]: unknown
    }>({})

    const [updateVehicleDetails, { isLoading: isLoadingUpdate }] =
        useUpdateVehicleDetailsMutation()

    const onSaveVehicle = async () => {
        await Promise.all([
            Object.keys(vehiclePostData).forEach(key => {
                updateVehicleDetails({
                    vehicleId: data.Id,
                    attribute: key,
                    data: vehiclePostData[key],
                })
            }),
        ])
            .then(() => {
                dispatch(
                    triggerMessage({
                        message: 'Vehicle updated!',
                        intent: 'success',
                    })
                )
                setVehiclePostData({})
                api.refreshServerSide()
            })
            .catch(() => {
                dispatch(
                    triggerMessage({
                        message:
                            'Failed to update the vehicle, please try again. Contact technical support of the error persists.',
                        intent: 'error',
                    })
                )
            })
    }

    const handleChange = (newValue: unknown, column: string) => {
        setVehiclePostData(pre => ({
            ...pre,
            [column]: newValue ? String(newValue) : null,
        }))
    }

    return (
        <div data-cy='vehicle-details-details-body' className={classes.grid}>
            <div className={classes.column}>
                {WHITELISTED_LIST_COLUMNS.map(column => (
                    <div key={column}>
                        <Text block weight='semibold'>
                            {column}
                        </Text>
                        <Text block>{String(data[column])}</Text>
                    </div>
                ))}
            </div>

            <div className={classes.column}>
                <Restricted to='columnsEdit'>
                    {vehicleDetails.map(row => (
                        <div key={row.key}>
                            <VehicleEditFieldType
                                column={row.key}
                                value={
                                    row.key in vehiclePostData
                                        ? vehiclePostData[row.key]
                                        : row.value
                                }
                                onChangeTextField={handleChange}
                            />
                        </div>
                    ))}

                    <div className={classes.actionRow}>
                        <Button
                            appearance='primary'
                            onClick={onSaveVehicle}
                            disabled={
                                !Object.keys(vehiclePostData).length ||
                                isLoadingUpdate
                            }
                        >
                            {isLoadingUpdate ? <Spinner /> : 'Save Changes'}
                        </Button>
                    </div>
                </Restricted>
            </div>
        </div>
    )
}

export default VehicleDetailsList
