/* eslint-disable max-len */

import { makeStyles, shorthands } from '@fluentui/react-components'

const useStyles = makeStyles({
    svg: {
        height: '40px',
        width: '100px',
        ...shorthands.padding('0', '20px'),
    },
})

const ReflectLogo = () => {
    const classes = useStyles()
    return (
        <svg
            width='90'
            height='12'
            viewBox='0 0 90 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={classes.svg}
        >
            <path
                d='M10.064 11.2H6.656L4.464 7.536H2.784V11.2H0V0.288C1.6 0.288 3.52 0.256001 4.784 0.256001C6.96 0.256001 9.52 0.896 9.52 3.776C9.52 5.408 8.72 6.48 7.376 7.056L10.064 11.2ZM2.784 2.56V5.264C3.376 5.28 4.032 5.28 4.688 5.28C6.176 5.28 6.576 4.576 6.576 3.84C6.576 3.12 6.176 2.544 4.64 2.544C3.968 2.544 3.36 2.56 2.784 2.56Z'
                fill='white'
            />
            <path
                d='M14.7188 11.2V0.288H23.1028V2.448H17.5028V4.608H22.3507V6.688H17.5028V9.024H23.3748V11.2H14.7188Z'
                fill='white'
            />
            <path
                d='M28.5156 11.2V0.288H36.5316V2.48H31.2996V5.008H35.8276V7.12H31.2996V11.2H28.5156Z'
                fill='white'
            />
            <path
                d='M41.5469 11.2V0.288H44.3309V8.8H49.2109V11.2H41.5469Z'
                fill='white'
            />
            <path
                d='M74.7324 7.056L77.3724 7.664C76.7004 10.176 74.8124 11.488 72.3004 11.488C69.1804 11.488 66.9244 9.328 66.9244 5.76C66.9244 1.872 69.6604 0 72.3164 0C74.7644 0 76.5084 1.184 77.2604 3.632L74.5724 4.256C74.2204 3.072 73.4204 2.496 72.2844 2.496C70.9724 2.496 69.8684 3.616 69.8684 5.728C69.8684 7.696 70.8764 8.96 72.3484 8.96C73.5164 8.96 74.4124 8.304 74.7324 7.056Z'
                fill='white'
            />
            <path
                d='M83.9944 11.2V2.576H81.0344V0.288H89.8024V2.576H86.8424V11.2H83.9944Z'
                fill='white'
            />
            <path
                d='M62.3477 0.199999L62.3477 11.112L53.6917 11.112L53.6917 8.952L59.5637 8.952L59.5637 6.792L54.7157 6.792L54.7157 4.712L59.5637 4.712L59.5637 2.376L54.1917 2.376L54.1917 0.199998L62.3477 0.199999Z'
                fill='white'
            />
        </svg>
    )
}

export default ReflectLogo
