import { acquireGraphAccessToken } from 'authInstance'
import axios from 'axios'

const reflectAxiosGraphInstance = axios.create({
    baseURL: 'https://graph.microsoft.com/v1.0',
})

/**
 * Request interceptor for Graph calls
 */
reflectAxiosGraphInstance.interceptors.request.use(
    async config => {
        const accessToken = await acquireGraphAccessToken()
        config.headers.set('Authorization', `Bearer ${accessToken}`)
        return config
    },
    error => {
        Promise.reject(error)
    }
)

export default reflectAxiosGraphInstance
