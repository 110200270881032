import AxiosErrorEnum from 'constants/AxiosErrorEnum'
import sanitizeInput from 'helpers/sanitizeInput'
import { toast } from 'react-toastify'

const HttpRequestHelper = {
    GetGlobalErrorMessage: (
        errorNumber: number | undefined = undefined,
        url: string | undefined = undefined,
        message: string | undefined = undefined
    ) => {
        const sanitizedErrorNumber = sanitizeInput(errorNumber)
        const sanitizedUrl = sanitizeInput(url)
        const santizedMessage = sanitizeInput(message)

        switch (errorNumber) {
            case AxiosErrorEnum.NotAuthorized:
                toast.error(
                    `${sanitizedErrorNumber} - Your user role does not allow usage of this function.`
                )
                break
            case AxiosErrorEnum.Forbidden:
                toast.error(
                    `${sanitizedErrorNumber} - The server responded that your connection is forbidden. Please contact technical support.`
                )
                break
            case AxiosErrorEnum.NotFound:
                toast.error(
                    `${sanitizedErrorNumber} - ${sanitizedUrl} - The requested endpoint was not found. Please contact technical support.`
                )
                break
            case AxiosErrorEnum.UnknownBackendError:
                toast.error(
                    `${sanitizedErrorNumber} -
                    An unknown error occured in the backend while processing your request. Please contact technical support.`
                )
                break
            case AxiosErrorEnum.BadRequest:
                toast.error(`${sanitizedErrorNumber} - ${santizedMessage}`)
                break
            default:
                toast.error(
                    `${sanitizedErrorNumber} - An unknown error occured while processing your request. Please contact technical support.`
                )
        }
    },
}

export default HttpRequestHelper
