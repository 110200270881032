import { Avatar, makeStyles } from '@fluentui/react-components'
import { ChangeEvent, useState } from 'react'

import { ImageAddRegular } from '@fluentui/react-icons'
import Loader from 'components/Ui/Loader'
import { toast } from 'react-toastify'
import { usePostPortalNewsMutation } from 'api/portalNews'

const useStyles = makeStyles({
    image: {
        width: '60px',
        height: '60px',
    },
    addimage: {
        fontSize: '40px',
        cursor: 'pointer',
        color: 'grey',
    },
})
interface IMediaUploader {
    title: string
    media?: string
    setMediaUrl: (url: string) => void
}

const MediaUpload = ({ title, media, setMediaUrl }: IMediaUploader) => {
    const classes = useStyles()
    const [image, setImage] = useState<{ name: string; url: string }>()
    const [loading, setLoading] = useState<boolean>(false)

    const [postPortalNews] = usePostPortalNewsMutation()

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files[0]) {
            return
        }

        setLoading(true)
        try {
            if (event.target.files && event.target.files[0]) {
                const file = event.target.files[0]
                const fileName = event.target.files[0].name
                const response = await postPortalNews({ file, fileName })
                const result = Object.values(response)[0]
                setMediaUrl(result.data)
                setImage({
                    name: event.target.files[0].name,
                    url: result.data,
                })
            }
        } catch (error) {
            toast.error(String(error))
        } finally {
            setLoading(false)
        }
    }

    const clearImage = () => {
        setMediaUrl('')
        setImage(undefined)
    }

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <input
                style={{ display: 'none' }}
                id={title}
                type='file'
                accept='image/*'
                onChange={handleFileChange}
            />
            <div>
                <label htmlFor={title}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            border: '1px dotted black',
                            borderRadius: '5px',
                            width: '150px',
                            height: '100px',
                            overflow: 'hidden',
                        }}
                    >
                        {image || media ? (
                            <>
                                <Avatar
                                    shape='square'
                                    className={classes.image}
                                    name={image?.name || title}
                                    image={{ src: image?.url || media }}
                                    onClick={clearImage}
                                />
                                <span>{title}</span>
                            </>
                        ) : (
                            <>
                                <ImageAddRegular className={classes.addimage} />
                                {title}
                            </>
                        )}
                    </div>
                </label>
            </div>
        </>
    )
}

export default MediaUpload
