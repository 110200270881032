import { ChangeEvent, useEffect, useMemo } from 'react'
import { Field, Select, SelectOnChangeData } from '@fluentui/react-components'

import { useGetVehicleTestsQuery } from 'api/vehicleTests'

interface IVehicleTestSelectProps {
    onSelect: (vehicleTestId: number) => void
    selectedId?: number
}

const VehicleTestSelect = ({
    onSelect,
    selectedId,
}: IVehicleTestSelectProps) => {
    const { data: vehicleTests } = useGetVehicleTestsQuery()

    const options = useMemo(() => {
        return vehicleTests?.length > 0
            ? vehicleTests
                  .filter(
                      test => !test.archived && !test.deleted && !test.canceled
                  )
                  .map(({ id, title, createdOn }) => ({
                      key: id,
                      text: title,
                      createdOn,
                  }))
                  .sort((a, b) => {
                      if (new Date(a.createdOn) > new Date(b.createdOn))
                          return -1
                      else if (new Date(a.createdOn) < new Date(b.createdOn))
                          return 1
                      else return 0
                  })
            : []
    }, [vehicleTests])

    const handleChange = (
        _event: ChangeEvent<HTMLSelectElement>,
        option?: SelectOnChangeData
    ) => {
        onSelect(option.value as unknown as number)
    }

    useEffect(() => {
        if (!selectedId && options.length)
            onSelect(options[0].key as unknown as number)
    }, [onSelect, options, selectedId])

    return (
        <Field>
            <Select
                style={{
                    width: '90%',
                    marginBottom: '8px',
                    marginTop: '6px',
                }}
                onChange={handleChange}
            >
                {options.map(i => (
                    <option key={i.key} value={i.key}>
                        {i.text}
                    </option>
                ))}
            </Select>
        </Field>
    )
}

export default VehicleTestSelect
