import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'

import DOMPurify from 'dompurify'
import NewsItem2Preview from 'components/Ui/PhonePreview/NewsItem2Preview'
import NewsItemPreview from 'components/Ui/PhonePreview/NewsItemPreview'

const useStyles = makeStyles({
    container: {
        display: 'flex',
        position: 'relative',
    },
    title: {
        display: 'block',
        position: 'absolute',
        top: '105px',
        width: '100px',
        height: '20px',
        left: '9%',
        fontSize: '11px',
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    introduction: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        WebkitLineClamp: 3,
        fontSize: '10px',
        width: '120px',
        height: '50px',
        position: 'absolute',
        top: '140px',
        left: '7%',
    },
    avatarThumbnail: {
        width: '50px',
        height: '50px',
        display: 'flex',
        position: 'absolute',
        top: '120px',
        left: '33%',
    },
    date: {
        display: 'flex',
        position: 'absolute',
        top: '195px',
        left: '7%',
        fontSize: '8px',
        color: 'gray',
    },
    previewContent: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '170px',
        right: '30px',
        width: '200px',
        height: '300px',
        ...shorthands.overflow('hidden'),
    },
    largeAvatar: {
        width: '224px',
        height: '100px',
        display: 'flex',
        position: 'absolute',
        top: '85px',
        right: '19px',
    },
})

interface IPreviewCreateNewsProps {
    showPreview: boolean
    hideAllDialogs: () => void
    hidePreviewDialog: () => void
    setShowPreview: (value: boolean) => void
    formData: Partial<IPortalNews>
    onSave: () => void
    isDisabled?: boolean
}

const PreviewCreateNews = ({
    showPreview,
    hideAllDialogs,
    hidePreviewDialog,
    setShowPreview,
    formData,
    onSave,
    isDisabled,
}: IPreviewCreateNewsProps) => {
    const classes = useStyles()

    const callback = () => {
        hideAllDialogs()
        onSave()
    }

    const formatDate = (timestamp: string): string => {
        if (!timestamp) return ''
        const date = new Date(timestamp)

        const formattedDate = date.toISOString().split('T')[0]
        const formattedTime = date.toTimeString().split(' ')[0].slice(0, 5)

        return `${formattedDate} at ${formattedTime}`
    }

    return (
        <div>
            <Dialog
                open={showPreview}
                onOpenChange={(_event, data) => setShowPreview(data.open)}
            >
                <DialogTrigger>
                    <Button
                        appearance='primary'
                        onClick={() => {
                            setShowPreview(true)
                        }}
                        disabled={isDisabled}
                    >
                        Preview
                    </Button>
                </DialogTrigger>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Preview</DialogTitle>
                        <DialogContent>
                            <div className={classes.container}>
                                <h5 className={classes.title}>
                                    {formData?.Title}
                                </h5>
                                <small className={classes.introduction}>
                                    {formData?.Introduction}
                                </small>
                                <Avatar
                                    shape='square'
                                    image={{
                                        src: formData?.ThumbnailUrl || '',
                                    }}
                                    className={classes.avatarThumbnail}
                                />
                                <small className={classes.date}>
                                    {formatDate(formData?.PublishDate)}
                                </small>
                                <NewsItemPreview />
                                <div className={classes.previewContent}>
                                    <h5>{formData?.Title}</h5>
                                    <small
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                formData?.Introduction
                                            ),
                                        }}
                                    />
                                    <small
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                formData?.Text
                                            ),
                                        }}
                                    />
                                </div>
                                <Avatar
                                    shape='square'
                                    className={classes.largeAvatar}
                                    image={{ src: formData?.ImageUrl || '' }} // the wrong imageurl
                                />
                                <NewsItem2Preview />
                            </div>
                        </DialogContent>
                        <DialogActions fluid>
                            <Button onClick={hidePreviewDialog}>Back</Button>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance='primary' onClick={callback}>
                                    Save
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    )
}

export default PreviewCreateNews
