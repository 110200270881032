import FilterModelEnum from 'constants/FilterModelEnum'

const filterTypeDropdown = [
    { key: FilterModelEnum.Equals, text: 'Equals' },
    { key: FilterModelEnum.GreaterThan, text: 'Greater Than' },
    { key: FilterModelEnum.LessThan, text: 'Less Than' },
    { key: FilterModelEnum.InRange, text: 'In Range' },
    { key: FilterModelEnum.Any, text: 'Any' },
    { key: FilterModelEnum.None, text: 'None' },
]

export default filterTypeDropdown
